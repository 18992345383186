import React, { Component } from 'react';
import { shortDayObj } from '../../constants';
import GalleryCarousel from '../common/GalleryCarousel';

export default class DestinationGuideThingToDoDetail extends Component {
  constructor(props) {
    super(props);
    this.bind = this.bind.bind(this);
  }

  componentDidMount() {
    window.addEventListener('load', this.bind);

    if (document.readyState === "complete" || document.readyState === "interactive") {
      setTimeout(() => {
        this.bind();
      }, 100);
    }
  }

  bind() {
    const $ = window.$$;
    const { config } = this.props;
    const sliderConfig = typeof config !== 'undefined' && typeof config.slider !== 'undefined' ? config.slider : {};
    const carouselConfig = typeof config !== 'undefined' && typeof config.carousel !== 'undefined' ? config.carousel : {};
    $(this.refs.galleryCarousel).flexslider({
      animation: "slide",
      controlNav: false,
      animationLoop: false,
      slideshow: false,
      itemWidth: 72,
      itemMargin: 10,
      maxItems: 4,
      asNavFor: this.refs.gallerySlider,
      ...sliderConfig
    });

    $(this.refs.gallerySlider).flexslider({
      animation: "slide",
      controlNav: false,
      ...carouselConfig
    });
  }

  render() {
    const { data } = this.props;
    console.log(data, "data")
    return (
      <div className="vd-inner-block">
        <div className="vd-left-block">
          <div className="vd-slider-block">
            <div>
              {
                typeof data.landscape_image !== 'undefined'
                &&
                <div className="gallery-slide flexslider" ref="gallerySlider">
                  <ul className="slides">
                    {
                      <li>
                        <img src={data.landscape_image.path} alt="" />
                      </li>
                    }
                  </ul>
                </div>
              }
            </div>
          </div>
          {
            data.entry_fees && data.entry_fees.length > 0 &&
            <div class="address-block">
              <div class="top-block">
                <ul>
                  <li>
                    <label>Entry Fees</label>
                    {
                      data.entry_fees && data.entry_fees.length > 0 &&
                      data.entry_fees.map(data => {
                        return <p>Rs. {data.fee}/per person</p>
                      })
                    }
                  </li>
                  <li>
                    <label>Timings</label>
                    {
                      data.entry_fees && data.entry_fees.length > 0 &&
                      data.entry_fees.map(data => {
                        return <p>
                          <span>{data.from_time} to {data.to_time} ({shortDayObj[data.day_from]}-{shortDayObj[data.day_to]})</span>
                        </p>
                      })
                    }

                  </li>
                </ul>
              </div>
            </div>
          }


          {
            typeof data.description !== 'undefined' && data.description != ''
            &&
            <div className="detail-block">
              <h5>About the place</h5>
              <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
            </div>
          }

          {
            typeof data.how_to_reach !== 'undefined' && data.how_to_reach != ''
            &&
            <div className="detail-block reach-block">
              <h5>How To Reach</h5>
              <ul>
                <li dangerouslySetInnerHTML={{ __html: data.how_to_reach }}></li>
              </ul>
            </div>
          }

        </div>
      </div>
    );
  }
}