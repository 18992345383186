import React, { Component } from 'react';
import { connect } from 'react-redux';
import DestinationPackageBannerInfo from './DestinationPackageBannerInfo';
import DestinationPackageInfoBlock from './DestinationPackageInfoBlock';

class DestinationPackageBasicInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      destInfo: props.destInfo,
      status: props.status
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log('nextProps', nextProps);
    if (this.state.status != nextProps.status) {
      this.setState({
        destInfo: nextProps.destInfo,
        status: nextProps.status
      });
    }
  }

  render() {

    return (
      <section className="destination-banner-block" style={{ backgroundImage: `url(${this.state.destInfo.landscape_image})` }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12">
              <DestinationPackageBannerInfo data={this.state.destInfo} />
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12">
              <DestinationPackageInfoBlock destinationName={this.props.destinationName} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    destInfo: { ...state.destinationPackage.destinationinfo },
    status: state.destinationPackage.status
  }
}

export default connect(mapStatesToProps)(DestinationPackageBasicInfo);