import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export {_dispatch};

export const HEADER_MAIN_MENU = 'HEADER_MAIN_MENU';
export const HEADER_MAIN_MENU_SUCCESS = 'HEADER_MAIN_MENU_SUCCESS';

export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
export const NOTIFICATION_LIST_SUCCESS = 'NOTIFICATION_LIST_SUCCESS';

const ROOT_URL = API_ROOT;
var token = "";

export function headerMainMenu(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/mainmenunavigation`,
  });

  return {
    type: HEADER_MAIN_MENU,
    payload: request
  };
}

export function headerMainMenuSuccess(payload){
	return {
    	type: HEADER_MAIN_MENU_SUCCESS,
    	payload: payload
  	}
}

export function notificationList(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/notifications`,
  });

  return {
    type: NOTIFICATION_LIST,
    payload: request
  };
}

export function notificationListSuccess(payload){
  return {
      type: NOTIFICATION_LIST_SUCCESS,
      payload: payload
    }
}

export function readAllNotification(data){
  return axios({
    method: 'POST',
    data: {data},
    url: `${API_ROOT}/setreadall`,
  });
}
