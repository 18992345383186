import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateRangePicker from '../../common/DateRangePicker';
import DateMonthPicker from '../../common/DateMonthPicker';
import QuantityBox from '../../common/QuantityBox';
import Dropdown from '../../common/Dropdown';
import ThemeTypes from '../../common/ThemeTypes';
import Error from '../../common/Error';
import AllDestinations from '../../common/AllDestinations';
import { saveFirstStep, fetchFirstStep } from '../../../actions/customPackage';
import GooglePlacesPredictions from '../../common/GooglePlacesPredictions';

class StepOne extends Component {

  constructor(props) {
    super(props);
    this.state = {
      themes: props.themes,
      destinations: props.destinations,
      data: { ...props.data, flexible_timing: (typeof props.data.flexible_timing !== 'undefined' ? props.data.flexible_timing : false) },
      quoteId: typeof props.data.quote_id !== 'undefined' ? props.data.quote_id : 0,
      status: props.status,
      errors: props.errors
    };

    this.submit = this.submit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status != this.state.status && nextProps.compName == 'firststep') {
      // Goto next step - 2
      if (Object.keys(nextProps.errors).length > 0) {
        this.setState({
          errors: nextProps.errors,
          status: nextProps.status
        });
        return;
      }
      this.props.updateStep(2);
    } else {
      const { themes, destinations } = this.state;
      if (typeof themes === 'undefined' && nextProps.themes !== 'undefined') {
        this.setState({
          themes: nextProps.themes,
          destinations: nextProps.destinations
        });
      }
    }
  }

  // Toggle between Fixed Date or Month
  toggleFlexibleTiming(e) {
    e.preventDefault();
    this.setState({
      data: { ...this.state.data, flexible_timing: !this.state.data.flexible_timing }
    });
  }

  submit(e) {
    e.preventDefault();
    const { selected_theme, destination, departure, email_id, contact_number, country_code, no_of_days, departure_date, name } = this.refs;

    // Call action
    this.props.saveFirstStep({ selected_theme: selected_theme.state.selectedValue, destination: destination.state.value, departure: departure.state.value, email_id: email_id.value, contact_number: contact_number.value, country_code: country_code.refs.country_code.value, no_of_days: no_of_days.refs.no_of_days.value, departure_date: departure_date.state.selectedValue, name: name.value, package_id: this.props.packageId, quote_id: this.state.quoteId, flexible_timing: this.state.data.flexible_timing });
  }

  render() {
    const { samePackage } = this.props;
    const { themes, destinations, errors } = this.state;
    const { selected_theme, destination, departure, email_id, contact_number, country_code, no_of_days, departure_date, name, flexible_timing } = this.state.data;
    console.log(this.state.data);
    const countryCodes = ['+91', '+01'];
    return (
      <div className="customize-right-block first-step">
        <div className="right-top-block">
          <h3>Your Preferences</h3>
          <button type="button" className="close cncl-btn" data-dismiss="modal">CLOSE</button>
        </div>
        <div className="customize-form-block">
          <div className="row">
            <div className="col-sm-12">
              <div className="form-group">
                <label className="form-title">Type</label>
                <div className="packages-types check-box-list">
                  <ThemeTypes ref="selected_theme" options={themes} selectedValue={selected_theme} realOnly={this.props.realOnly} theme_id={this.props.theme_id} />
                </div>
                {typeof errors.selected_theme !== 'undefined' && <Error text={errors.selected_theme} />}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-title required">WHERE YOU WANT TO GO</label>
                {
                  <AllDestinations index={this.props.index} realOnly={this.props.realOnly} selectedValue={destination} ref="destination" />
                }
                {typeof errors.destination !== 'undefined' && <Error text={errors.destination} />}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-title">LEAVING FROM</label>
                <GooglePlacesPredictions value={departure} ref="departure" placeholder="Eg. Mohali" />
                {typeof errors.departure !== 'undefined' && <Error text={errors.departure} />}
              </div>
            </div>
            <div className="col-sm-6 ft-block">
              <div className="row">
                <div className="col-sm-8">
                  <div className="form-group">
                    <label className="form-title">&nbsp;</label>
                    <ul>
                      <li>
                        <button className={`flexible-timing ${flexible_timing ? "active" : ""}`} onClick={this.toggleFlexibleTiming.bind(this)}>
                          <ion-icon name="resize"></ion-icon>
                                                Flexible Timings
                                            </button>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label className="form-title">DAYS</label>
                    <div className="person-block sidenumber">
                      <div className="age-block">
                        <QuantityBox min={2} selectedValue={no_of_days} name="no_of_days" ref="no_of_days" />
                      </div>
                    </div>
                    {typeof errors.no_of_days !== 'undefined' && <Error text={errors.no_of_days} />}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-title required">
                  {flexible_timing ? 'DEPARTURE MONTH' : 'DEPARTURE DATE'}
                </label>
                <div className="custom-datepicker">
                  {
                    flexible_timing
                      ?
                      <DateMonthPicker selectedValue={departure_date} single={true} name="departure_date" ref="departure_date" />
                      :
                      <DateRangePicker selectedValue={departure_date} single={true} name="departure_date" ref="departure_date" />
                  }
                </div>
                {typeof errors.departure_date !== 'undefined' && <Error text={errors.departure_date} />}
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-title required">YOUR NAME</label>
                <input ref="name" type="text" placeholder="your name" defaultValue={name} />
                {typeof errors.name !== 'undefined' && <Error text={errors.name} />}
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="form-title required">YOUR EMAIL ID</label>
                <input ref="email_id" type="text" placeholder="you@example.com" defaultValue={email_id} />
                {typeof errors.email_id !== 'undefined' && <Error text={errors.email_id} />}
              </div>
            </div>
            <div className="col-md-12 col-sm-12">
              <div className="form-group">
                <label className="form-title required">YOUR PHONE NUMBER</label>
                <div className="number-field">
                  <div className="custom-selecter">
                    <Dropdown selectedValue={country_code} options={countryCodes} name="country_code" ref="country_code" />
                  </div>
                  <div className="pn-field">
                    <input defaultValue={contact_number} ref="contact_number" type="tel" className="form-control" placeholder="99999-99999" />
                  </div>
                </div>
                {typeof errors.country_code !== 'undefined' && <Error text={errors.country_code} />}
                {typeof errors.contact_number !== 'undefined' && <Error text={errors.contact_number} />}
              </div>
            </div>
            <div className="col-sm-12">
              <button className="cta-btn step1" type="submit" onClick={this.submit}>
                PLAN MY HOLIDAYS
                          <i className="next-iocn">
                  <img src="/images/Proceed_icon.svg" alt="" />
                </i>
              </button>
            </div>
            <div className="col-sm-12 secure-content">
              <p><ion-icon ios="ios-lock" md="ios-lock"></ion-icon>Your Information will be secured & confidential</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  const data = typeof ownProps.data !== 'undefined' ? ownProps.data : {};
  console.log('DATAA', data);
  return {
    data: { ...state.CustomPackage.firstStep, email_id: state.Auth.user.email, name: state.Auth.user.name, contact_number: state.Auth.user.contact_number, country_code: state.Auth.user.country_code, ...data },
    status: state.CustomPackage.status,
    compName: state.CustomPackage.compName,
    errors: { ...state.CustomPackage.errors },
  }
}

export default connect(mapStatesToProps, { saveFirstStep, fetchFirstStep })(StepOne);