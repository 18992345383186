import React, { Component } from 'react';
import DestinationGuidePopularPlacesSlide from './DestinationGuidePopularPlacesSlide';

export default class DestinationGuidePopularPlaces extends Component {
  constructor(props){
    super(props);
    this.state = {
      popularvisitcity: props.popularvisitcity,
      destination: props.destination
    };

    this.bindSlider = this.bindSlider.bind(this);
  }

  componentDidMount(){
      window.addEventListener('load', this.bindSlider);

      if(document.readyState === "complete" || document.readyState === "interactive"){
        this.bindSlider();
      }
  }

  componentDidUpdate(){
      window.addEventListener('load', this.bindSlider);

      if(document.readyState === "complete" || document.readyState === "interactive"){
        this.bindSlider();
      }
  }

  componentWillReceiveProps(nextProps){
      if(nextProps.destination != this.state.destination){
          this.setState({
            popularvisitcity: nextProps.popularvisitcity,
            destination: nextProps.destination
          });
      }
  }

  bindSlider(){
    const $ = window.$$;
    if($(window).width() > 991){
      $(this.refs.visitedSliderTheme).owlCarousel({
        margin:15,
        pagination: false,
        nav:true,
        navText: ["<img src='/images/slider_icon_next.svg'/> ", "<img src='/images/slider_icon_next.svg'/> "],
        responsive:{
          0:{
              items:1.2
          },
          400:{
            items:1.4
          },
          500:{
            items:2.2
          },
          768:{
              items:2
          },
          991:{
              items:3
          },
          1000:{
              items:3
          }
        }
      })
    }
  }


	render() {
    	return (
    		<div className="puplar-block">
          <div className="heading-poplar">
            <div className="container">
              <h3>Places to Visit in {this.state.destination}</h3>
            </div>
          </div>
              
          <div className="slider-visited slider-full">
            <div className="container">
              <div className="owl-carousel owl-theme visited-slider-theme" ref="visitedSliderTheme">
                {
                  this.state.popularvisitcity && this.state.popularvisitcity.length > 0 &&
                  this.state.popularvisitcity.map((obj, index) => (
                    <DestinationGuidePopularPlacesSlide key={index} slideData = {obj} />
                  ))
                }
              </div>                
            </div>
          </div>
        </div>
	   	);
    }
}