// Vikas
import React, { Component } from 'react';

class PackageItineraryItem extends Component {
  constructor(props) {
    super(props);
    this.bind = this.bind.bind(this);
  }

  componentDidMount() {
    window.addEventListener('load', this.bind);

    if (document.readyState === "complete" || document.readyState === "interactive") {
      this.bind();
    }
  }

  bind() {
    window.$$(this.refs.itinerarySlider).bxSlider({
      mode: 'fade',
      auto: true,
      controls: true,
      preloadImages: 'visible',
      autoDelay: 5
    });
  }

  render() {
    const { obj } = this.props;

    return (
      <div className="ltierary-detail-content">
        <div className="days-ltinerary">
          <div className="no-days">
            <label>{obj.day}</label>
            <span>Day</span>
          </div>
          <div className="detail-days-ltinerary">
            <div className="block-days-in">
              <div className="head-ltinerary--day">
                <h3>{obj.title}, {obj.name}</h3>
                <div className="slider-status">
                  <ul ref="itinerarySlider" className="bxslider-status">
                    {
                      obj.gallery && obj.gallery.length > 0 && obj.gallery.map((gal, gndex) => (

                        <li key={gndex}>
                          <div className="days-slider">Day {obj.day}</div>
                          <div className="station-name">{obj.title}</div>
                          <img src={gal} alt="" />
                        </li>

                      ))
                    }


                  </ul>
                </div>
                <p dangerouslySetInnerHTML={{ __html: obj.description }} />
              </div>
              <div className="block-ltinerery-fields">
                <label>Activities</label>
                <ul className="activities-fields">
                  {
                    obj.activities && obj.activities.length > 0 && obj.activities.map((chk, endex) => (
                      <li key={endex}>{chk}</li>
                    ))
                  }
                </ul>
              </div>
              <div className="block-ltinerery-fields">
                <label>Total Duration</label>
                <div className="duration-ltinerery">{obj.duration}</div>
              </div>
              <div className="block-ltinerery-fields">
                <label>Locations</label>
                <div className="ltinerary-text-content">
                  <ul>
                    {
                      obj.locations && obj.locations.length > 0 && obj.locations.map((loc, lndex) => (
                        <li key={lndex}>{loc}</li>
                      ))
                    }
                  </ul>
                </div>
              </div>
              <div className="block-ltinerery-fields">
                <label>Hotel</label>
                <div className="ltinerary-text-content">
                  <ul>
                    {
                      obj.hotels && obj.hotels.length > 0 && obj.hotels.map((hotel, lndex) => (
                        <li key={lndex}>{hotel.name}</li>
                      ))
                    }
                  </ul>
                </div>
              </div>
              <div className="block-ltinerery-fields">
                <label>Optional Activities<span className="info-warning">Extra Charges</span></label>
                <div className="ltinerary-text-content">
                  <ul>
                    {
                      obj.optional_activities && obj.optional_activities.length > 0 && obj.optional_activities.map((act, andex) => (
                        <li key={andex}>{act}</li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PackageItineraryItem;