import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { acceptQuotation, rejectQuotation, reactivateQuotation } from '../../actions/quotations';
import RejectQuoteForm from './RejectQuoteForm';
import AcceptQuoteForm from './AcceptQuoteForm';
import BookedQuoteForm from './BookedQuoteForm';
import Modal from '../../Modal';

class QuoteActions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      quoteAccepted: props.quoteAccepted,
      accepted: props.accepted,
      invoice_status: props.invoice_status,
      installment_status: props.installment_status,
      id: props.id,
      index: props.index
    };
    this.hideRejectForm = this.hideRejectForm.bind(this);
    this.acceptQuoteForm = this.acceptQuoteForm.bind(this);
    //console.log(this.state.invoice_status,' test ',this.state.installment_status);
  }

  hideRejectForm() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  rejectQuoteForm() {
    window.getFooter().setState({
      renderElement: <Modal
        id="reject-quote-modal"
        show={true}
        onHide={this.hideRejectForm}
        header={<div><h3>Reject Quote</h3></div>}
        body={<RejectQuoteForm onReject={this.reject.bind(this)} history={this.props.history} id={this.state.id} />}
      />
    });
  }

  quoteBooked() {
    window.getFooter().setState({
      renderElement: <Modal
        id="booked-quote-modal"
        show={true}
        onHide={this.hideRejectForm}
        header={<div><h3>Booked Quote</h3></div>}
        body={<BookedQuoteForm history={this.props.history} id={this.state.id} />}
      />
    });
  }

  acceptQuoteForm() {
    window.getFooter().setState({
      renderElement: <Modal
        id="accept-quote-modal"
        show={true}
        onHide={this.hideRejectForm}
        header={<div><h3>Booked</h3></div>}
        body={<AcceptQuoteForm history={this.props.history} id={this.state.id} />}
      />
    });
  }

  accept() {
    acceptQuotation(this.state.id).then((response) => {
      this.props.updateQuoteAcceptenceStatus(this.state.index, true);
      this.acceptQuoteForm();
      // toast.success("Quote has been accepted!", {
      //   position: toast.POSITION.TOP_RIGHT
      // });
    }).catch((error) => {
      toast.error(error.response.data.flash_message, {
        position: toast.POSITION.TOP_RIGHT
      });
    });
  }

  reject(reasonType, comment) {
    rejectQuotation(this.state.id, reasonType, comment).then((response) => {
      this.props.updateQuoteAcceptenceStatus(this.state.index, false);
      setTimeout(() => {
        this.hideRejectForm();
        toast.error("Quote has been rejected!", {
          position: toast.POSITION.TOP_RIGHT
        });
      }, 1000);

    }).catch((error) => {
      toast.error(error.response.data.flash_message, {
        position: toast.POSITION.TOP_RIGHT
      });
    });
  }

  reactivate() {
    reactivateQuotation(this.state.id).then((response) => {
      this.props.updateQuoteAcceptenceStatus(this.state.index, false);
      setTimeout(() => {
        this.hideRejectForm();
        toast.error("Quote has been activated!", {
          position: toast.POSITION.TOP_RIGHT
        });
      }, 1000);
      this.props.callback();
    }).catch((error) => {
      toast.error(error.response.data.flash_message, {
        position: toast.POSITION.TOP_RIGHT
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.id != this.state.id || nextProps.quoteAccepted != this.state.quoteAccepted) {
      this.setState({
        id: nextProps.id,
        index: nextProps.index,
        quoteAccepted: nextProps.quoteAccepted,
        accepted: nextProps.accepted === 0 ? 0 : parseInt(nextProps.accepted),
        invoice_status: nextProps.invoice_status,
        installment_status: nextProps.installment_status
      });
    }
  }

  render() {
    const { accepted, quoteAccepted, invoice_status, installment_status } = this.state;
    //console.log(accepted,' == ' ,quoteAccepted,' == ' , this.state.installment_status, ' == ', this.state.invoice_status);
    return (
      <div className="action-btn">
        <a href="javascript:;" className="btn-green mob-show"> <img src="/images/phone-white.svg" alt="" /> REQUEST A CALLBACK</a>
        {
          (accepted > 0 || (quoteAccepted === null || quoteAccepted === false))
          &&
          (
            accepted === 0
              ?
              <div>
                <a href="javascript:void(0);" className="btn-red" onClick={this.accept.bind(this)}>BOOK NOW</a>

                <a href="javascript:void(0);" className="btn-r-outline" onClick={this.rejectQuoteForm.bind(this)}>REJECT QUOTE</a>

                {/*<a href="javascript:void(0);" className="btn-r-outline" onClick={this.reject.bind(this)}>REJECT QUOTE</a>*/}

              </div>
              :
              (
                accepted === 1
                  ?
                  <div>
                    {
                      (typeof this.state.invoice_status !== 'undefined' && (this.state.invoice_status == 0))
                        ?
                        <a href="javascript:void(0);" className="btn-red" onClick={this.quoteBooked.bind(this)}>QUOTE BOOKED</a>
                        :
                        ''
                    }
                    {
                      (this.state.installment_status)
                        ?
                        ''
                        :
                        <a href="javascript:void(0);" className="btn-r-outline" onClick={this.rejectQuoteForm.bind(this)}>REJECT QUOTE</a>
                    }
                  </div>
                  :
                  <a className="btn-r-outline" style={{ width: '100%' }} onClick={this.reactivate.bind(this)}>Reactivate Quote</a>
              )
          )
        }

      </div>
    );
  }
}

export default QuoteActions;