import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Widget, addResponseMessage, addUserMessage, toggleWidget, titleAvatar, renderCustomComponent } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import {fetchQuoteChat, sendQuoteMessage} from './actions/quotations';
import UserMessageSnippet from './UserMessageSnippet';
import ResponseMessageSnippet from './ResponseMessageSnippet';

export default class QuoteChat extends Component {

  constructor(props){
    super(props);
    this.state = {
      quote: props.quote,
      quoteIndex: parseInt(props.index),
      lastMsgId: 0
    };

    this.loadMessages = this.loadMessages.bind(this);
    this.startMessageStream = this.startMessageStream.bind(this);
    this.chatInterval = null;
  }

  componentDidMount() {
    addResponseMessage("How may I assist you?");
    // Fetch chat history
    this.loadMessages(this.state.quote.quote_id);
    // Fetch chat message at every 10 seconds of interval
    this.startMessageStream();
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.message !== null && nextProps.status != this.props.status){
      if(document.getElementById('messages') === null){
        toggleWidget(); 
      }
      this.openChatWindow(nextProps);
    }
  }

  startMessageStream(){
    this.chatInterval = setInterval(() => {
      if(document.getElementsByClassName('rcw-new-message').length > 0){
        this.loadMessages(this.state.quote.quote_id);
      }
    }, 10000);
  }

  loadMessages(quoteId){
    fetchQuoteChat(quoteId, this.state.lastMsgId).then((response) => {
      if(response.data.payload.length > 0){
        this.setState({
          lastMsgId: response.data.payload[0].id
        });
        response.data.payload.reverse().map((item) => {
          if(item.role_id != 0)
            renderCustomComponent(ResponseMessageSnippet, {data: item});
          else{
            renderCustomComponent(UserMessageSnippet, {data: item});
          }
        });
      }
    });
  }

  openChatWindow(nextProps){
    setTimeout(() => {
      const msgElem = document.getElementsByClassName('rcw-new-message')[0];
      msgElem.value = `#${nextProps.message}: `;
      msgElem.focus();
    }, 1);
  }
 
  handleNewUserMessage = (message) => {
    if(message.replace(/\s+/, '').length > 0){
      const {quote_id} = this.state.quote;
      // Stop any scheduled chat interval
      clearInterval(this.chatInterval);
      sendQuoteMessage(quote_id, message).then((response) => {
        this.setState({
          lastMsgId: response.data.payload
        });
        // Restart message stream
        this.startMessageStream();
      }).catch((response) => {
        renderCustomComponent(UserMessageSnippet, {error: "This message is not delivered"});
      });
    }
  }
 
  render() {
    const {quote, quoteIndex} = this.state;

    return (
      <div ref="chatWindow">
        <Widget
          handleNewUserMessage={this.handleNewUserMessage}
          profileAvatar={quote.tripadvisor_image}
          title="Welcome to Travalot"
          subtitle={`Chat related to Quotation`}
        />
      </div>
    );
  }       
}
