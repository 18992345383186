import React, { Component } from 'react';
import AddToWishlistButton from './AddToWishlistButton';

class PackageActions extends Component {

	render() {
    	return (
        	<div className="option-detail-head">
            {/* <a href="javascript:void(0);"  className="btn white-btn icon-btn gallery_button" onClick={() => {
              window.$$(".img-fluid").trigger("click");
            }}>
                <img src="/images/ic_photos.svg" alt=""/>
                <span>PHOTOS</span>
            </a> */}
            <AddToWishlistButton packageId={this.props.packageId} />
            <a href="javascript:void(0);" className="btn white-btn icon-btn" data-dismiss="modal" data-toggle="modal" data-target="#share-modal">
              <img src="/images/ic_share.svg" alt=""/>
              <span>Share</span>
            </a>
          </div>
    	);
    }
}

export default PackageActions;