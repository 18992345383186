import React, { Component } from 'react';
import PackageGallery from './PackageGallery';
import PackageActions from './PackageActions';

export default class PackageDetailInnerBanner extends Component {

	render() {
    	return (
        	<section className="banner-detail-block demo-gallery">
              <PackageGallery data={this.props.gallery} />
              {/* <PackageActions packageId={this.props.packageId} /> */}
        	</section> 
    	);
    }
}