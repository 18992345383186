import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { ROUTES } from './routesList';
import AllQueryQuotaionDetail from './components/quotation/AllQueryQuotaionDetail';
import QuoteDetailSideWidget from './QuoteDetailSideWidget';
import { quotationListDetail, quotationListDetailSuccess } from './actions/quotations';
import CancelTheRequestForm from './CancelTheRequestForm';
import CancelTheBookingForm from './CancelTheBookingForm';
import Modal from './Modal';
import QuoteRequestProgressBar from './QuoteRequestProgressBar';
import QuoteInvoiceBlock from './QuoteInvoiceBlock';
import { dateFormat } from './constants';
import BookingButtonStyle2 from './BookingButtonStyle2';
import { quote_status, quote_status_clas } from './constants';


class RequestQuoteDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quoteDetail: {},
      currentQuoteIndex: 0
    };
    this.updateCurrentQuoteIndex = this.updateCurrentQuoteIndex.bind(this);
    this.hideCancelTheRequestForm = this.hideCancelTheRequestForm.bind(this);
    this.loadQuery = this.loadQuery.bind(this);
  }

  componentDidMount() {
    this.loadQuery();
  }

  updateCurrentQuoteIndex(currentQuoteIndex) {
    this.setState({
      currentQuoteIndex
    });
  }

  hideCancelTheRequestForm() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  cancelTheRequest() {
    window.getFooter().setState({
      renderElement: <Modal
        id="cancel-request-modal"
        show={true}
        onHide={this.hideCancelTheRequestForm}
        header={<div><h3>Cancel Trip Request</h3></div>}
        body={<CancelTheRequestForm history={this.props.history} id={this.state.quoteDetail.query.id} />}
      />
    });
  }

  cancelTheBooking() {
    window.getFooter().setState({
      renderElement: <Modal
        id="cancel-request-modal"
        show={true}
        onHide={this.hideCancelTheRequestForm}
        header={<div><h3>Cancel Booking</h3></div>}
        body={<CancelTheBookingForm history={this.props.history} id={this.state.quoteDetail.query.id} />}
      />
    });
  }

  loadQuery() {
    this.setState({
      quoteDetail: {}
    });
    this.props.quotationListDetail({ id: this.props.match.params.index }).then((response) => {
      if (response.value.data.result === 'success') {
        this.setState({ quoteDetail: response.value.data.payload });
      }
    });
  }

  reloadQuery() {
    this.setState({
      quoteDetail: {}
    });
    this.loadQuery();
  }

  render() {
    const { currentQuoteIndex } = this.state;

    return (
      <div className="main-body-content">
        <div className="main-body-content">
          <section className="profile-section quotes-detail">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-xs-12">
                  <div className="dashboard-block my-profile-block">
                    <div className="ui-blocks-main">
                      <div className="uiblock-head">
                        <h3><Link to="/dashboard/quoteslist"><img src="/images/ic_back_quotes.svg" alt="<" />BACK TO QUOTES</Link></h3>
                        {
                          this.state.quoteDetail.query
                          &&
                          <span className="time-block">
                            Sent {dateFormat(this.state.quoteDetail.query.created_at)}
                          </span>
                        }
                      </div>
                      <div className="quotes-id">
                        <div className="left-content">
                          <p>Booking ID: {this.state.quoteDetail.query ? this.state.quoteDetail.query.id : <Skeleton width={50} height={20} />}</p>
                          <span className={`status-quotes ${quote_status_clas[this.state.quoteDetail.query ? this.state.quoteDetail.query.status : 0]}`}> {quote_status[this.state.quoteDetail.query ? this.state.quoteDetail.query.status : 0]}</span>
                        </div>
                        <div className="right-content links-block">
                          {
                            ((typeof this.state.quoteDetail.query !== 'undefined') && (this.state.quoteDetail.query && this.state.quoteDetail.query.status % 2 != 0))
                            &&
                            <BookingButtonStyle2 callback={this.reloadQuery.bind(this)} tripId={this.state.quoteDetail.query.id} customClass="" label="VIEW/EDIT PREFERENCES" />
                          }

                          {
                            this.state.quoteDetail.query && this.state.quoteDetail.query.status % 2 != 0 &&
                            <a href="javascript: void(0);" onClick={this.cancelTheRequest.bind(this)}>CANCEL REQUEST</a>
                          }

                          {
                            this.state.quoteDetail.query && this.state.quoteDetail.query.status == 5 &&
                            <a href="javascript: void(0);" onClick={this.cancelTheBooking.bind(this)}>CANCEL BOOKING</a>
                          }
                        </div>
                      </div>

                      <div className="quotes-heading">
                        <h3>{this.state.quoteDetail.query ? `${this.state.quoteDetail.query.departure} → ${this.state.quoteDetail.query.destination}` : <Skeleton width={400} height={30} />}</h3>
                        <p className="sub-heading">
                          {
                            this.state.quoteDetail.query
                              ?
                              `${this.state.quoteDetail.query.total_days_nights} · Hotel ${this.state.quoteDetail.query.hotel_preferences} Star · ${this.state.quoteDetail.query.title}`
                              :
                              <Skeleton width={300} height={10} />
                          }
                        </p>
                        <p>
                          {
                            this.state.quoteDetail.query
                              ?
                              `Showing ${this.state.quoteDetail.quote_count} quotes prepared by Travalot Experts for ${this.state.quoteDetail.query.departure} to ${this.state.quoteDetail.query.destination}`
                              :
                              <Skeleton width={500} height={10} />
                          }
                        </p>
                      </div>
                      <QuoteRequestProgressBar quoteDetail={this.state.quoteDetail} />
                      {
                        this.state.quoteDetail.invoice
                        &&
                        <QuoteInvoiceBlock updateCurrentQuoteIndex={this.updateCurrentQuoteIndex} quotes={this.state.quoteDetail.quotation} data={this.state.quoteDetail.invoice} />
                      }
                      {
                        this.state.quoteDetail.quotation
                          ?
                          <AllQueryQuotaionDetail quoteIndex={currentQuoteIndex} updateCurrentQuoteIndex={this.updateCurrentQuoteIndex} quotationDetail={this.state.quoteDetail.quotation} flight_status={this.state.quoteDetail.query.flight_status} />
                          :
                          <div class="request-msg-block">
                            <h4>Great! We have received your request.</h4>
                            <p>Your Trip advisor is preparing the handpicked package for you as per your requirements and get back to you within 24 - 48 hours. </p>
                          </div>
                      }
                    </div>
                  </div>
                </div>
                {
                  <QuoteDetailSideWidget query={this.state.quoteDetail.query} accepted={this.state.quoteDetail.accepted} updateCurrentQuoteIndex={this.updateCurrentQuoteIndex} index={currentQuoteIndex} quotes={this.state.quoteDetail.quotation} callback={this.reloadQuery.bind(this)} />
                }
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

// const mapStatesToProps = (state, ownProps) => {
//   return {
//     quoteDetail: state.quotations.quotaiondetail,
//     status: state.quotations.status
//   }
// }

const mapDispatchToProps = (dispatch) => {
  return {
    quotationListDetail: (params) => {
      return dispatch(quotationListDetail(params));
    },
    quotationListDetailSuccess: (payload) => {
      dispatch(quotationListDetailSuccess(payload));
    }
  };
}

const RequestQuoteDetailWrapper = connect(null, mapDispatchToProps)(RequestQuoteDetail);

export default withRouter(RequestQuoteDetailWrapper);