import React, { Component } from 'react';
import GalleryCarousel from '../common/GalleryCarousel';

export default class DestinationGuidePopularCityDetail extends Component {
  
	render() {
      const {data} = this.props;
    	return (
    	   <div className="vd-inner-block">
            <div className="vd-left-block">
              {
                typeof data.gallery !== 'undefined' && data.gallery.length > 0
                &&
                <div className="vd-slider-block">
                    <GalleryCarousel 
                      data={data.gallery} 
                      sliderId="jmodal-slide" 
                      carouselId="jmodal-thumbanil"
                      config={
                        {
                          slider: {
                              animation: "slide",
                              controlNav: false,
                              animationLoop: false,
                              slideshow: false,
                              itemWidth: 96,
                              itemMargin: 15,
                              maxItems: 6
                            },
                          carousel: {
                              animation: "slide",
                              controlNav: true,
                              animationLoop: false,
                              sync: "#jmodal-thumbanil"
                            }
                        }
                      } 
                    />
                 </div>
               }
               {
                 typeof data.top_sights !== 'undefined' && data.top_sights != ''
                 &&
                 <div className="detail-block">
                    <h5>Top Sights in {data.name}</h5>
                    <p dangerouslySetInnerHTML={{__html: data.top_sights}}></p>
                 </div>
               }

               {
                 typeof data.description !== 'undefined' && data.description != ''
                 &&
                 <div className="detail-block">
                    <h5>About the place</h5>
                    <p dangerouslySetInnerHTML={{__html: data.description}}></p>
                 </div>
               }

               {
                 typeof data.how_to_reach !== 'undefined' && data.how_to_reach != ''
                 &&
                 <div className="detail-block reach-block">
                    <h5>How To Reach</h5>
                    <ul>
                       <li dangerouslySetInnerHTML={{__html: data.how_to_reach}}></li>
                    </ul>
                 </div>
               }
               
               {
                 typeof data.foodplaces !== 'undefined' && data.foodplaces.length > 0
                 &&
                 <div className="detail-block bf-jaipur">
                    <h5>Best Food Places</h5>
                    <ul>
                      {
                        data.foodplaces.map((item, index) => {
                          return <li key={index}>
                                    <h4>{item.title}</h4>
                                    <p dangerouslySetInnerHTML={{__html: item.detail}}></p>
                                 </li>
                        })
                      }
                       
                    </ul>
                 </div>
               }
               
               {
                 typeof data.activities !== 'undefined' && data.activities != ''
                 &&
                 <div className="detail-block">
                    <h5>Activities in {data.name}</h5>
                    <div dangerouslySetInnerHTML={{__html: data.activities}}></div>
                 </div>
               }
            </div>
          </div>
	   	);
    }
}