import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class StaticNavigationPanel extends Component {
	render() {
		return(
               <div className="terms-listing">
                    <ul>
                         <li><Link to="/terms-condition">Terms & Conditions</Link></li>
                         <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                         <li><Link to="/cancellation-policy">Cancellations</Link></li>
                         <li><Link to="/faqs">FAQ’s</Link></li>
                    </ul>
               </div>
		);
	}
}