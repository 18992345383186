import React, { Component } from 'react';
import { currencyFormat } from './constants';
import QuoteActions from './components/common/QuoteActions';
import { toggleWidget } from 'react-chat-widget';

export default class QuoteDetailSideWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accepted: props.accepted,
      query: props.query,
      modified: typeof props.query !== 'undefined' && props.query.is_modified == '1' ? 1 : 0,
      quotes: props.quotes,
      quoteIndex: props.index
    };
    this.bind = this.bind.bind(this);
    this.updateQuoteAcceptenceStatus = this.updateQuoteAcceptenceStatus.bind(this);
  }

  componentDidMount() {
    window.addEventListener('load', this.bind);

    if (document.readyState === "complete" || document.readyState === "interactive") {
      this.bind();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.quotes === 'undefined') {
      this.setState({
        quotes: undefined
      });
      return;
    }
    if (typeof this.state.quotes === 'undefined' && typeof nextProps.quotes !== 'undefined') {
      this.setState({
        accepted: nextProps.accepted,
        quotes: nextProps.quotes,
        quoteIndex: nextProps.index,
        query: nextProps.query,
        modified: nextProps.query.is_modified
      });
    } else if (nextProps.index != this.state.quoteIndex) {
      this.setState({
        quoteIndex: nextProps.index
      });

      window.$$(this.refs.quoteslist).selectpicker('val', nextProps.index);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (typeof prevProps.quotes === 'undefined' && typeof this.state.quotes !== 'undefined') {
      this.bind();
    }
  }

  bind() {
    window.$$(this.refs.quoteslist).selectpicker();
  }

  selectQuote(e) {
    this.props.updateCurrentQuoteIndex(e.target.value);
  }

  openChatWindow() {
    if (document.getElementById('messages') === null) {
      toggleWidget();
    }
  }

  updateQuoteAcceptenceStatus(index, accepted) {
    const { quotes } = this.state;
    quotes[index].accepted_quote = accepted === true ? 1 : 2;
    this.setState({
      accepted,
      quotes
    });
  }

  render() {
    const { quotes, quoteIndex, modified, query } = this.state;

    console.log('test', this.state.query);

    return (
      <div className="col-lg-4 col-xs-12">
        <aside className="profile-left-block">
          <div className="aside-inner-block">
            <div className="top-title">
              <label>QUOTE DETAILS</label>
            </div>
            {
              typeof quotes !== 'undefined'
                ?
                <div className="q-detail-block">
                  <div className="profile-head">
                    <div className="custom-select-box quotation-select">
                      <select ref="quoteslist" className="selectpicker picker-style" onChange={this.selectQuote.bind(this)} defaultValue={quoteIndex}>
                        {
                          quotes.map((detail, index) => {
                            return <option value={index} key={index} data-content={`<p><span className='full-width'>Quote ${index + 1}</span> ${currencyFormat(parseInt(detail.total_package_price), detail.currency)} <span className='small-font'>(${detail.price_type})</span></p>`}>
                            </option>
                          })
                        }

                      </select>
                    </div>
                    <div className="rp-block">
                      <div className="left-block">
                        <figure>
                          {quotes[quoteIndex].tripadvisor_image != '' && <img src={quotes[quoteIndex].tripadvisor_image} alt="" />}

                        </figure>
                        <figcaption>
                          <h4>{quotes[quoteIndex].tripadvisor_name != '' && quotes[quoteIndex].tripadvisor_name}</h4>
                          <p>{quotes[quoteIndex].contact_number != '' && quotes[quoteIndex].contact_number}</p>
                        </figcaption>
                      </div>
                      <div className="right-block">
                        <a href="javascript: void(0);" className="msg" onClick={this.openChatWindow}>MESSAGE</a>
                      </div>
                    </div>
                  </div>
                  {
                    modified == '0'
                      ?
                      <QuoteActions updateQuoteAcceptenceStatus={this.updateQuoteAcceptenceStatus} quoteAccepted={this.state.accepted} index={quoteIndex} id={quotes[quoteIndex].id} accepted={quotes[quoteIndex].accepted_quote} invoice_status={quotes[quoteIndex].invoice_status} installment_status={quotes[quoteIndex].installment_status} callback={this.props.callback} />
                      :

                      typeof query !== 'undefined' && query.status % 2 != 0
                        ?
                        <div className="query-modified-msg">
                          <p>You have updated your trip preferences.</p>
                          <p>So, please wait until our advisor send you an updated quote as per your preferences.</p>
                        </div>
                        :
                        ''
                  }
                </div>
                :
                <div class="detail-available-block">
                  <figure><ion-icon name="list-box" role="img" class="hydrated" aria-label="list box"></ion-icon></figure>
                  <figcaption>
                    <h4>Quote Details Not Available</h4>
                    <p>Quote details and Trip advisor information will be visible when you get your quote for trip</p>
                  </figcaption>
                </div>
            }

          </div>
          <div className="action-btn mob-none">
            <a href="javascript:void(0);" className="btn-green"><img src="/images/phone-white.svg" alt="" /> REQUEST A CALLBACK</a>
          </div>
        </aside>
      </div>
    );
  }
}