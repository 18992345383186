import {
  _dispatch, FEATURED_PACKAGES_LIST_SUCCESS, THEME_PACKAGES_LIST_SUCCESS, WEEKEND_PACKAGES_LIST_SUCCESS, PACKAGE_DETAIL_SUCCESS, TRAVEL_PACKAGE_LIST_SUCCESS, SAVED_PACKAGES_SUCCESS, DELETE_SAVED_PACKAGES_SUCCESS, UPDATE_FILTERS_PACKAGE, RESET_FILTERS_PACKAGE, SEARCH_PACKAGES_SUCCESS
} from '../actions/packages';


const INITIAL_STATE = { featuredpackages:[], themepackages:[], weekendpackages:[], packagedetail:[], travelpackages:[], savedPackages: [], searchpackages: [], status:null };

export default function(state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch(action.type) {

    case FEATURED_PACKAGES_LIST_SUCCESS:
      return (() => {
        const featuredpackages = action.payload;
        const totalpackages = action.totalpackages;
        return _dispatch({ ...state, featuredpackages, totalpackages}, true, 'best-selling-packages');
      })();

    case THEME_PACKAGES_LIST_SUCCESS:
      return (() => {
        const themepackages = action.payload;
        return _dispatch({ ...state, themepackages}, true);
      })();

    case WEEKEND_PACKAGES_LIST_SUCCESS:
      return (() => {
        const weekendpackages = action.payload;
        return _dispatch({ ...state, weekendpackages}, true);
      })();

    case PACKAGE_DETAIL_SUCCESS:
      return (() => {
        const packagedetail = action.payload;
        return _dispatch({ ...state, packagedetail}, true);
      })();

    case TRAVEL_PACKAGE_LIST_SUCCESS:
      return (() => {
        const travelpackages = action.payload;
        return _dispatch({ ...state, travelpackages}, true);
      })();

    case SAVED_PACKAGES_SUCCESS:
      return (() => {
        return _dispatch({ ...state, savedPackages: action.payload}, true, 'savedPackages');
      })();

    case DELETE_SAVED_PACKAGES_SUCCESS:
      return (() => {
        const savedPackages = [...state.savedPackages];
        savedPackages.splice(action.payload, 1);

        return _dispatch({ ...state, savedPackages}, true, 'savedPackages');
      })();

    case UPDATE_FILTERS_PACKAGE:
      return (() => {
        const filters = {...state.filters, ...action.payload}
        return _dispatch({ ...state, filters}, true, 'filters');
      })();

    case RESET_FILTERS_PACKAGE:
      console.log(action.payload);
      return (() => {
        return _dispatch({ ...state, filters: action.payload}, action.rerender, 'filters'); 
      })();

    case SEARCH_PACKAGES_SUCCESS:
      return (() => {
        const searchpackages = action.payload;
        return _dispatch({ ...state, searchpackages}, true, 'search');
      })();

    default:
      return state;
  }
}