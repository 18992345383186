import React, { Component } from 'react';
import {connect} from 'react-redux'; 
import ProfilePicture from './ProfilePicture';
import Error from './Error';
import {uploadProfilePicture} from '../../actions/auth';

class UploadPicture extends Component {
  constructor(props){
    super(props);
    this.state = {
      src: null,
      status: props.status,
      errors: {}
    };

    this.upload = this.upload.bind(this);
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.status != this.state.status){
      if(nextProps.compName == 'photo-error'){
        this.setState({
          errors: nextProps.errors,
          status: nextProps.status
        });
      }else if(nextProps.compName == 'photo'){
        this.setState({
          errors: {},
          status: nextProps.status
        });
      }
    }
  }

  upload(e){
    const reader = new FileReader();
    const type = e.target.files[0]["type"];
    reader.onload = (e) => {
      this.setState({
        src: e.target.result,
        type
      });
    };

    reader.readAsDataURL(e.target.files[0]);

    // Upload picture on server
    const formData = new FormData();
    formData.append('file', e.target.files[0]); 

    this.props.uploadProfilePicture(formData);
  }

	render() {
      const {src, errors, type} = this.state;

    	return (
    		<div className="profile-pic-upload">
          <figure>
              <ProfilePicture defaultSrc={src} type={type} />
          </figure>
          <div className="upload-profile-btn">
              <label htmlFor="profile-pic">
                <img src="/images/upload-profile-icon.svg" alt="Upload Icon" />
                UPDATE PICTURE
              </label>
              <input accept="image/*" type="file" id="profile-pic" onChange={this.upload} />
              {typeof errors.image !== 'undefined' && <Error text={errors.image} />}
          </div>
        </div>
    	);
    }
}

const mapStatesToProps = (state) => {
    return {
        errors: state.Auth.errors,
        status: state.Auth.status,
        compName: state.Auth.compName
    };
}

export default connect(mapStatesToProps, {uploadProfilePicture})(UploadPicture);