import React, { Component } from 'react';
import TravelPackageSlide from './TravelPackageSlide';
import { connect } from 'react-redux';
import { travelPackageList } from '../../actions/packages';
import PackageListSkeleton from '../common/PackageListSkeleton';

class TravelPackageListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packages: props.data,
      status: props.status,
      loaded: false,
      skeletonLoader: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status) {
      if (nextProps.compName == 'filters') {
        this.setState({ loaded: false, packages: null });
        this.props.travelPackageList({ to_destination: this.props.destinationName, ...nextProps.filters }).then((response) => {
          if (response.value.data.result === 'success') {
            this.setState({ status: nextProps.status, packages: response.value.data.payload.data, loaded: true });
          }
        });
      }
    }
  }

  componentDidMount() {
    this.props.travelPackageList().then((response) => {
      if (response.value.data.result === 'success') {
        this.setState({ packages: response.value.data.payload.data, loaded: true });
      }
    });
  }

  render() {
    return (
      <div className="pacakages-listing-block">
        {
          !this.state.loaded
          &&
          <>
            {
              this.state.skeletonLoader.map(data => {
                return <PackageListSkeleton key={data} />
              })
            }
          </>
        }
        {
          this.state.packages
          &&
          this.state.packages.map((obj, index) => (
            <TravelPackageSlide key={index} slideData={obj} status={this.state.status} />
          ))
        }
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    status: state.packages.status,
    compName: state.packages.compName,
    filters: { ...state.packages.filters }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    travelPackageList: (params) => {
      return dispatch(travelPackageList(params));
    }
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(TravelPackageListing);
