import React, { Component } from 'react';
import { currencyFormat } from '../../constants';
import { updateFilters, resetFilters } from '../../actions/destinations';
import { connect } from 'react-redux';

class DestinationFilterOption extends Component {
  constructor(props) {
    super(props);
    this.bindSlider = this.bindSlider.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.monthChange = this.monthChange.bind(this);
    this.themeChange = this.themeChange.bind(this);
    this.state = {
      filter_value: props.filter_value,
      holidayTypes: props.holidayTypes,
      status: props.status,
      themeName: 'All',
      seasonName: 'Jan to Dec',
      filters: {}
    }
  }

  componentDidMount() {
    var obj = this;
    window.addEventListener('load', () => {
      obj.bindSlider();
    });

    if (document.readyState === "complete" || document.readyState === "interactive") {
      this.bindSlider();
    }
  }

  componentWillReceiveProps(nextProps) {
    //if(this.state.status != nextProps.status){
    this.setState({
      filter_value: nextProps.filter_value,
      status: nextProps.status
    })
    //}
  }

  bindSlider() {
    if (typeof this.refs.range_03 === 'undefined') {
      return;
    }
    window.$$(this.refs.range_03).ionRangeSlider({
      type: "double",
      grid: false,
      min: this.state.filter_value && this.state.filter_value.min_price,
      max: this.state.filter_value && this.state.filter_value.max_price,
      from: this.state.filter_value && this.state.filter_value.min_price,
      to: this.state.filter_value && this.state.filter_value.max_price,
      prefix: "<span class='rupees-sign'>₹</span>",
      prettify_enabled: true,
      prettify_separator: ",",
      onFinish: (data) => {
        // Called every time handle position is changed
        const filters = { ...this.state.filters, budget_from: data.from, budget_to: data.to };
        this.setState({
          filters
        });
        this.props.updateFilters(filters);
      },
    });
  }

  clearAll(e) {
    e.preventDefault();
    const budslider = window.$$(this.refs.range_03).data("ionRangeSlider");
    budslider.reset();
    document.getElementById('themesform').reset();
    document.getElementById('seasonform').reset();
    document.getElementById('durationform').reset();
    this.setState({
      filters: {}
    });
    this.props.updateFilters();
  }

  handleChange(e) {
    const filters = { ...this.state.filters, duration: e.target.value };
    this.setState({
      filters
    });
    this.props.updateFilters(filters);
  }

  monthChange(e) {
    const filters = { ...this.state.filters, months: e.target.value };
    this.setState({
      seasonName: e.target.getAttribute('data-seasonname'),
      filters
    });
    this.props.updateFilters(filters);
  }

  themeChange(e) {
    const filters = { ...this.state.filters, category: e.target.value };
    this.setState({
      themeName: e.target.getAttribute('data-themename'),
      filters
    });
    this.props.updateFilters(filters);
  }

  render() {
    return (
      <div className="col-lg-5 col-md-12 col-sm-12">
        <div className="left-form-block">
          <div className="heading-block">
            <h3 className="title">Choose Your Destination by 4 Simple Filters</h3>
            <p className="sub-title">Filter by Budget, Duration, Season & Holiday themes</p>
          </div>
          <div className="choose-tabs-block">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" href="#themes" role="tab" data-toggle="tab">THEMES</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#price" role="tab" data-toggle="tab">PRICE</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#duration" role="tab" data-toggle="tab">DURATION</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#season" role="tab" data-toggle="tab">SEASON</a>
              </li>
            </ul>

            <div className="tab-content">
              <div role="tabpanel" className="tab-pane fade in" id="price">
                <div className="tab-inner-connetnt">
                  <div className="range-heading">
                    <h4>Choose Price Range </h4>
                    {
                      Object.keys(this.state.filters).length > 0 ?
                        <button style={{ cursor: "pointer" }} onClick={this.clearAll.bind(this)}>RESET</button>
                        : null
                    }
                  </div>
                  <div className="custom-range-slider">
                    <div className="line-graph">
                      <ul className="back-bars">
                        <li style={{ height: "10%" }}></li>
                        <li style={{ height: "15%" }}></li>
                        <li style={{ height: "30%" }}></li>
                        <li style={{ height: "40%" }}></li>
                        <li style={{ height: "100%" }}></li>
                        <li style={{ height: "20%" }}></li>
                        <li style={{ height: "50%" }}></li>
                        <li style={{ height: "60%" }}></li>
                        <li style={{ height: "70%" }}></li>
                        <li style={{ height: "35%" }}></li>
                      </ul>
                    </div>
                    <input type="text" ref="range_03" name="example_name" />
                    <div className="price-text-block">
                      <h5 className="price-print"> From {this.state.filter_value && this.state.filter_value.min_price && currencyFormat(this.state.filter_value.min_price, 'INR')} - {this.state.filter_value && this.state.filter_value.max_price && currencyFormat(this.state.filter_value.max_price, 'INR')}</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div role="tabpanel" className="tab-pane fade duration-tab" id="duration">
                <div className="tab-inner-connetnt">
                  <div className="range-heading">
                    <h4>Select Your Trip Duration</h4>
                    {
                      Object.keys(this.state.filters).length > 0 ?
                        <button style={{ cursor: "pointer" }} onClick={this.clearAll.bind(this)}>RESET</button>
                        : null
                    }
                  </div>

                  <div className="duration-steps">
                    <form onChange={this.handleChange} id="durationform">
                      <ul>
                        <li className="active">
                          <div>
                            <input type="radio" name="day" id="days1" defaultValue="1-3" />
                            <label htmlFor="days1">1-3<span>DAYS</span></label>
                            <p className="line"></p>
                          </div>
                        </li>

                        <li className="active">
                          <div>
                            <input type="radio" name="day" id="days2" defaultValue="4-6" />
                            <label htmlFor="days2">4-6<span>DAYS</span></label>
                            <p className="line"></p>
                          </div>
                        </li>

                        <li className="active">
                          <div>
                            <input type="radio" name="day" id="days3" defaultValue="7-9" />
                            <label htmlFor="days3">7-9<span>DAYS</span></label>
                            <p className="line"></p>
                          </div>
                        </li>

                        <li className="active">
                          <div>
                            <input type="radio" name="day" id="days4" defaultValue="10-12" />
                            <label htmlFor="days4">10-12<span>DAYS</span></label>
                            <p className="line"></p>
                          </div>
                        </li>

                        <li className="active">
                          <div>
                            <input type="radio" name="day" id="days5" defaultValue="13-50" />
                            <label htmlFor="days5">MORE THAN<span>13 DAYS</span></label>
                            <p className="line"></p>
                          </div>
                        </li>
                      </ul>
                    </form>
                  </div>

                  <div className="price-text-block">
                    <h5 className="price-print"> {this.state.filter_value && this.state.filter_value.min_duration && this.state.filter_value.min_duration} to {this.state.filter_value && this.state.filter_value.max_duration && this.state.filter_value.max_duration} days duration</h5>
                  </div>

                </div>
              </div>
              <div role="tabpanel" className="tab-pane fade duration-tab" id="season">
                <div className="tab-inner-connetnt">
                  <div className="range-heading">
                    <h4>Select Your Trip Season</h4>
                    {
                      Object.keys(this.state.filters).length > 0 ?
                        <button style={{ cursor: "pointer" }} onClick={this.clearAll.bind(this)}>RESET</button>
                        : null
                    }
                  </div>

                  <div className="season-listing">
                    <form onChange={this.monthChange} id="seasonform">
                      <ul>
                        <li>
                          <input type="radio" name="month" id="season1" defaultValue="1-2-3" data-seasonname="Jan - Feb - Mar" />
                          <label htmlFor="season1"> Jan - Feb - Mar</label>
                        </li>
                        <li>
                          <input type="radio" name="month" id="season2" defaultValue="4-5-6" data-seasonname="Apr - May - Jun" />
                          <label htmlFor="season2"> Apr - May - Jun</label>
                        </li>
                        <li>
                          <input type="radio" name="month" id="season3" defaultValue="7-8-9" data-seasonname="Jul - Aug - Sep" />
                          <label htmlFor="season3"> Jul - Aug - Sep</label>
                        </li>
                        <li>
                          <input type="radio" name="month" id="season4" defaultValue="10-11-12" data-seasonname="Oct - Nov - Dec" />
                          <label htmlFor="season4"> Oct - Nov - Dec</label>
                        </li>
                      </ul>
                    </form>
                  </div>

                  <div className="price-text-block">
                    <h5 className="price-print"> From  {this.state.seasonName}</h5>
                  </div>

                </div>
              </div>
              <div role="tabpanel" className="tab-pane fade duration-tab theme-tab active show" id="themes">
                <div className="tab-inner-connetnt">
                  <div className="range-heading">
                    <h4>Filter by Each Theme</h4>
                    {
                      Object.keys(this.state.filters).length > 0 ?
                        <button style={{ cursor: "pointer" }} onClick={this.clearAll.bind(this)}>RESET</button>
                        : null
                    }
                  </div>

                  <div className="theme-listing">
                    <form id="themesform">
                      <ul>
                        {
                          this.state.holidayTypes && this.state.holidayTypes.length > 0 &&
                          this.state.holidayTypes.map((obj, index) => (
                            <li key={index}>
                              <input name="category" type="radio" id={"theme" + obj.id} defaultValue={obj.id} data-themename={obj.title} onChange={(e) => this.themeChange(e)} />
                              <label htmlFor={"theme" + obj.id}><span className={"theme-icons icon" + parseInt(index + 1)}></span>{obj.title}</label>
                            </li>
                          ))
                        }
                      </ul>
                    </form>
                  </div>

                  <div className="price-text-block">
                    <h5 className="price-print"> {this.state.themeName} Packages </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    holidayTypes: [...state.holidayTypes.holidaytypes],
    status: state.holidayTypes.status
  }
}



// const mapDispatchToProps = (dispatch) => {
//   return {
//     updateFilters: (params) => {
//       return dispatch(updateFilters(params));
//     },

//   };
// }

export default connect(mapStatesToProps, { updateFilters })(DestinationFilterOption);
