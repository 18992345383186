import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routesList';
import { connect } from 'react-redux';
import { currencyFormat } from '../../constants';
import { themePackagesList, themePackagesListSuccess } from '../../actions/packages';


class HoneymoonSpecial extends Component {
  constructor(props) {
    super(props);
    this.bindSlider = this.bindSlider.bind(this);
    this.state = {
      themePackages: props.themePackages,
      status: props.status
    }
  }

  componentDidMount() {
    this.props.themePackagesList({ slug: "honeymoon" }).then((response) => {
      if (response.value.data.result === 'success') {
        this.props.themePackagesListSuccess(response.value.data.payload);
      }
    })

    window.addEventListener('load', () => {
      this.bindSlider();
    });

    if (document.readyState === "complete" || document.readyState === "interactive") {
      this.bindSlider();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status) {
      this.setState({
        themePackages: nextProps.themePackages,
        status: nextProps.status
      })
    }
  }

  bindSlider() {
    const $ = window.$$;
    if ($(window).width() > 991) {
      $('.h-slider2').owlCarousel({
        loop: false,
        margin: 15,
        pagination: false,
        nav: true,
        navText: ["<img src='images/slider_icon_next.svg'/> ", "<img src='images/slider_icon_next.svg'/> "],
        responsive: {
          0: {
            items: 1.2
          },
          450: {
            items: 2
          },
          575: {
            items: 2
          },
          576: {
            items: 1
          },
          767: {
            items: 1
          },
          768: {
            items: 2
          },
          992: {
            items: 3
          }
        }
      })

    }
  }
  render() {
    return (
      <section className="h-package-block">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-5">
              <div className="add-banner add1-block">
                {/* <Link to={{ pathname: '/travel-packages', state: 'categories[]=honeymoon' }}> */}
                <Link to='/travel-packages?categories[]=honeymoon'>
                  <figure><img src="images/honemoon.png" alt="" /></figure>
                  <figcaption className="add-banner-content">
                    <label>EXPLORE</label>
                    <h4>Honeymoon Special</h4>
                    {/* <p>under 1 Lac.</p> */}
                    <button href="javascript:;" className="n-blue-btn"> VIEW ALL</button>
                  </figcaption>
                </Link>
              </div>
            </div>
            <div className="col-lg-9 col-md-8 col-sm-7">
              <div className="owl-carousel owl-theme h-slider2">
                {
                  this.state.themePackages && this.state.themePackages.length > 0 &&
                  this.state.themePackages.map((obj, index) => (

                    <div className="item" key={index}>
                      <div className="carousel-inner">
                        <Link to={"/package/detail/" + obj.slug}>
                          <figure><img src={obj.landscape_image} alt="" /></figure>
                          <figcaption className="package-detail-block">
                            <ul className="r-btn">
                              <li className="tag-btn">
                                <ion-icon ios="ios-heart" md="md-heart"></ion-icon>
                                <span>Romantic</span>
                              </li>
                              <li>{obj.tags}</li>
                            </ul>
                            <h5>{obj.package_name}</h5>
                            <p>{obj.title}</p>
                            <label className="price-label">Starts from {currencyFormat(obj.minimumprice, 'INR')}/-</label>
                          </figcaption>
                        </Link>
                      </div>
                    </div>
                  ))
                }


              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    themePackages: [...state.packages.themepackages],
    status: state.packages.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    themePackagesList: (params) => {
      return dispatch(themePackagesList(params));
    },
    themePackagesListSuccess: (payload) => {
      dispatch(themePackagesListSuccess(payload));
    },

  };
}


export default connect(mapStatesToProps, mapDispatchToProps)(HoneymoonSpecial);