import React, { Component } from 'react';
import { connect } from 'react-redux';
import { homeAdList, homeAdListSuccess } from '../../actions/testimonial';
import { Link } from 'react-router-dom';

class HomeAd extends Component {
   constructor(props){
      super(props);
      this.state = {
         chkAds: props.chkAds,
         status: props.status
      }
   }

   componentDidMount(){
      this.props.homeAdList().then((response) => {
         if(response.value.data.result === 'success'){
           this.props.homeAdListSuccess(response.value.data.payload);
         }
      })
   }

   componentWillReceiveProps(nextProps){
      if(this.props.status != nextProps.status){
         this.setState({
           chkAds: nextProps.chkAds,
           status: nextProps.status
         })
      }

   }

	render() {
		return(
			<section className="offer-banner-block">
         	<div className="container">
            		<div className="row">
               		<div className="col-sm-12">
                  		<div className="offer-inner-banner">
                           {
                              this.state.chkAds && this.state.chkAds.length > 0 &&
                            this.state.chkAds.map((obj, index) => (
                                    
                                 obj.type == 'offer_of_month' 
                                 &&  
                                 <a href={obj.ad_url} key={index}>
                                    <img src={obj.path} target="_blank" alt={obj.type} title={obj.type} />
                                 </a>
                                 
                              ))
                           }
                     	</div>
                  	</div>
               </div>
         	</div>
      	</section>
		);
	}
}

const mapStatesToProps = (state, ownProps) => {
  return {
    chkAds: state.testimonial.homeadlist,
    status: state.testimonial.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    homeAdList: () => {
      return dispatch(homeAdList());
    },
    homeAdListSuccess: (payload) => {
      dispatch(homeAdListSuccess(payload));
    },

  };
}


export default connect(mapStatesToProps, mapDispatchToProps)(HomeAd);