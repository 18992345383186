import React, { Component } from 'react';

export default class CabOptions extends Component {
    
  constructor(props){
    super(props);
    this.state = {
      disabled: typeof props.disabled !== 'undefined' ? props.disabled : false,
      selectedValue: typeof props.selectedValue !== 'undefined' ? props.selectedValue : ''
    };
    this.selectValue = this.selectValue.bind(this);
  }

  selectValue(e){
    this.setState({
      selectedValue: e.target.value
    });
  }

	render() {
      const {selectedValue, disabled} = this.state;

    	return (
    		<ul className="hotel-rating-block">
            <li>
                <input disabled={disabled} checked={selectedValue == 1 ? true : false} ref="cab_option" type="radio" name="cab_option" id="cab1" defaultValue="1" onClick={this.selectValue} />
                <label htmlFor="cab1">
                    <i className="cstm-icon"></i>
                    Yes, I want Cabs
                </label>
            </li>
            <li>
                <input disabled={disabled} checked={selectedValue == 0 ? true : false} ref="cab_option" type="radio" name="cab_option" id="cab2" defaultValue="0" onClick={this.selectValue} />
                <label htmlFor="cab2">
                    <i className="cstm-icon"></i>
                    No, I don’t want Cabs
                </label>
            </li>
        </ul>
    	);
    }
}