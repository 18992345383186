import React, { Component } from 'react';
import PackageFilters from './PackageFilters';
import TravelPackageSection from './TravelPackageSection'

export default class TravelPackageBlock extends Component {
  render() {
    return (
      <section className="main-content-block ct-destination" id="show-listing">
        <div className="container">
          <div className="row">
            <div id="filterToShow" className="col-xl-3 col-lg-12 col-md-12 col-sm-12 sidebar-outer"> {/* add class activefilter to show filters */}
              <div className="wrap"></div>
              {/* all left side filters */}
              <PackageFilters />
            </div>
            <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
              <TravelPackageSection />
            </div>
          </div>
        </div>
      </section>
    );
  }
}