import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {currencyFormat} from '../../constants';

export default class TripItem extends Component {

	render() {
      const {data} = this.props;

    	return (
        <div className="ui-blocks-trip-main">
        	<div className="row">
              <div className="col-md-8">
                <div className="left-side-trip-card">
                  <div className="caption-block-trip">
                    <figure>
                      <a href="javascript:void(0);">
                        <img src={data.imagepath} alt="" />
                      </a>
                    </figure>
                    <figcaption>
                      <span>{data.days} Days {data.nights} Nights - {data.title} Trip</span>
                      <a href="javascript:void(0);">{data.destination} From {data.departure}</a>
                    </figcaption>
                  </div>
                  <div className="trip-detail-sm">
                    <div className="row">
                      <div className="col-md-4">
                        <span>CITIES</span>
                        <div className="trip-title-info trip-city-block">
                          <ul>
                            <li>Dubai,</li>
                            <li>+2 more</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <span>HOTEL</span>
                        <div className="trip-title-info trip-rating-block">
                          <label>{data.hotel_preferences} Star</label>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <span>TRAVEL DATE</span>
                        <div className="trip-title-info trip-dates-block">
                          <ul>
                            <li>{data.start_date}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="price-block price-block-single">
                    <p>
                      <span className="start-text">Total Amount</span>
                      {currencyFormat(data.total_cost, data.currency)}
                      <span className="start-text">{data.price_type}</span>
                    </p>
                </div>
                <div className="action-upcoming-trip">
                  <a href="javascript:void(0);" className="btn chat-btn"><img src="/images/chat-icon.svg" alt="" /></a>
                  <Link to={`/trip_request/${data.id}`} className="btn btn-pink-lg">VIEW DETAILS</Link>
                </div>
              </div>
            </div>
          </div>
        );
    }
}