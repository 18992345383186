import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ProfilePicture from './components/common/ProfilePicture';

class ProfileWidget extends Component {

  toggle(e) {
    window.$$(".dropdown-menu").toggleClass("active");
  }

  render() {
    const { name, profile_image } = this.props.user;

    return (
      <div className="header-login-profile dropdown mob-none">
        <a href="javascript: void(0);" className="profile-btn" onClick={this.toggle}>
          <span className="user-image">
            <ProfilePicture />
          </span>
          <span className="user-name">
            {name}
            <ion-icon name="arrow-dropdown"></ion-icon>
          </span>
        </a>
        <ul className="dropdown-list dropdown-menu">
          <li><Link to="/dashboard">My Account</Link></li>
          {/* <li><a href="javascript:;">Purchases</a></li> */}
          <li><Link to="/logout">Logout</Link></li>
        </ul>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    user: { ...state.Auth.user }
  }
}

export default connect(mapStatesToProps)(ProfileWidget);