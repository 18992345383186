import React, { Component } from 'react';

export default class WhyTravelAtlas extends Component {
  render() {
    return (
      <section className="w-travel-block text-center">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="w-travel-heading">
                <h3>Why Choose Travalot?</h3>
                <p>Travalot is Fastest Growing Holiday Marketplace which is easy, fast, secure & reliable</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="travel-steps-block">
                <figure> <img src="/images/ease_of_use.svg" alt="" /></figure>
                <figcaption>
                  <h5>Easy to use</h5>
                  <p>We provide you Instant booking in real time with the best experience</p>
                </figcaption>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="travel-steps-block">
                <figure> <img src="/images/fast.svg" alt="" /></figure>
                <figcaption>
                  <h5>Fast & Responsive</h5>
                  <p>We provide fast & uninterrupted experience to all the devices.</p>
                </figcaption>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="travel-steps-block">
                <figure> <img src="/images/secure.svg" alt="" /></figure>
                <figcaption>
                  <h5>Secure & Reliable</h5>
                  <p>Travalot is fully secured with multi-factor authentication.</p>
                </figcaption>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="travel-steps-block">
                <figure> <img src="/images/support.svg" alt="" /></figure>
                <figcaption>
                  <h5>24/7 Support</h5>
                  <p>We provide you 24/7 support to help you with everything.</p>
                </figcaption>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}