import React, { Component } from 'react';
import {connect} from 'react-redux'; 
import {months, budgetPreferences, tripDuration} from '../../constants';

class ViewHolidayPreferences extends Component {

	render() {
		const {source, destination, month, budget_preference, flight_status, duration} = this.props.preferences;

    	return (
    		<div className="holiday-form-block view-holiday-block" style={{display: "block"}}>
                <form>
                  	<div className="row">
                    	<div className="col-md-6">
                      		<div className="form-field-block">
                        		<figure>
                          			<img src="/images/location.svg" alt="" />
                        		</figure>
                        		<div className="holiday-field-content">
                          			<label>From where you want to Start?</label>
                          			<div className="block-text-fields">
                            			<span className="view-field">{source}</span>
                          			</div>
                        		</div>
                      		</div>
                    	</div>
                    	<div className="col-md-6">
                      		<div className="form-field-block">
                        		<figure>
                          			<img src="/images/travel.svg" alt="" />
                        		</figure>
                        		<div className="holiday-field-content">
                          			<label>Your Next Travel Destination</label>
                          			<div className="block-text-fields">
                            			<span className="view-field">{destination}</span>
                          			</div>
                        		</div>
                      		</div>
                    	</div>
                        <div className="col-md-6">
                          	<div className="form-field-block">
                            	<figure>
                             		<img src="/images/calendar-icon.svg" alt="" />
                            	</figure>
                            	<div className="holiday-field-content">
                              		<label>In which month you like to go?</label>
                              		<div className="block-text-fields">
                                		<span className="view-field">{months[month]}</span>
                              		</div>
                            	</div>
                          	</div>
                        </div>
                        <div className="col-md-6">
                          	<div className="form-field-block">
                            	<figure>
                              		<img src="/images/rupee-budget.svg" alt="" />
                            	</figure>
                            	<div className="holiday-field-content">
                              		<label>Your Budget Preference</label>
                              		<div className="block-text-fields">
                                		<span className="view-field">{budgetPreferences[budget_preference]}</span>
                              		</div>
                            	</div>
                          	</div>
                        </div>
                        <div className="col-md-6">
                          	<div className="form-field-block">
                            	<figure>
                              		<img src="/images/destication-icon.svg" alt="" />
                            	</figure>
                            	<div className="holiday-field-content">
                              		<label>Do you want to include Flights?</label>
                              		<div className="block-text-fields">
                                		<span className="view-field">{flight_status ? 'Yes' : 'No'}</span>
                              		</div>
                            	</div>
                          	</div>
                        </div>
                        <div className="col-md-6">
                          	<div className="form-field-block">
                            	<figure>
                              		<img src="/images/duration-clock.svg" alt="" />
                            	</figure>
                            	<div className="holiday-field-content">
                              		<label>Duration of your trip</label>
                              		<div className="block-text-fields">
                                		<span className="view-field">{tripDuration[duration]}</span>
                              		</div>
                            	</div>
                          	</div>
                        </div>
                    </div>
                  	<div className="submit-holiday-action">
                    	<a href="javascript:void(0);" className="n-blue-btn edit-detail" onClick={this.props.toggle}>EDIT DETAILS</a>
                  	</div>
                </form>
            </div>
    	);
    }
}

const mapStatesToProps = (state) => {
    return {
        preferences: {...state.Auth.holiday_preferences}
    }
}

export default connect(mapStatesToProps)(ViewHolidayPreferences);