import React, { Component } from 'react';
//import DestinationGuideThemeOptions from '../common/DestinationGuideThemeOptions';
import DestinationGuideNavigation from '../common/DestinationGuideNavigation';
import BookingButtonStyle1 from '../../BookingButtonStyle1';

class DestinationGuideBanner extends Component {
  constructor(props) {
    super(props);
    this.bindSlider = this.bindSlider.bind(this);
    this.state = {
      destname: props.destname,
      destimage: props.destimage,
      tagline: props.tagline,
      destTags: props.destTags
    };
  }

  componentDidMount() {
    window.addEventListener('load', () => {
      this.bindSlider();
    });
    if (document.readyState === "complete" || document.readyState === "interactive") {
      setTimeout(this.bindSlider, 100);
    }
  }

  bindSlider() {
    const $ = window.$$;
    const windowNavHgt = $(window).height();
    $.fn.bannerHeight = function () {
      $('.guide-banner').css('height', windowNavHgt + 'px')
    }
    $.fn.bannerHeight();
    $(window).on('resize', function () {
      $.fn.bannerHeight();
    });
    $(document).ready(function () {
      if ($('.guide-banner').length > 0 && $('#stick-here').length > 0) {
        if ($(window).width() > 991) {
          function sticktothetop() {
            var window_top = $(window).scrollTop();
            var top = $('#stick-here').offset().top;
            if (window_top > top) {
              $('#stickThis').addClass('stick');
              $('#stick-here').height($('#stickThis').outerHeight());
            } else {
              $('#stickThis').removeClass('stick');
              $('#stick-here').height(0);
            }
          }
          $(function () {
            $(window).bind('scroll', sticktothetop);
            sticktothetop();
          });
        }
      }
    });

    $(window).scroll(function () {
      var scrollDistance = $(window).scrollTop();
      // Assign active class to nav links while scolling
      $('.scroll-block').each(function (i) {
        if ($(this).position().top - 100 <= scrollDistance) {
          $('.nav-listing li.active').removeClass('active');
          $('.nav-listing li').eq(i).addClass('active');
        }
      });
    }).scroll();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      destname: nextProps.destname,
      destimage: nextProps.destimage,
      tagline: nextProps.tagline,
      destTags: nextProps.destTags
    });
  }

  componentWillUnmount() {
    window.$$(window).unbind('scroll');
  }

  render() {
    return (
      <section className="guide-banner parallax-window">
        <div className="bg-image parallax-bg" style={{ backgroundImage: `url(${this.state.destimage})` }}></div>
        <div className="g-banner-content">
          <div className="gb-block1">
            <div className="container">
              <div className="gb-top-block">
                <div className="left-block">
                  <label>Travel Guide</label>
                  <h1>{this.state.destname}</h1>
                  {/* <p>{ this.state.tagline }</p> */}
                </div>
                <div className="right-block">

                  {/*<div className="ts-block">
                			<a href="javascript:void(0);">
			                  <ul className="ts-profile">
			                    <li><img src="../images/t-img1.jpeg" alt="" /></li>
			                    <li><img src="../images/t-img1.jpeg" alt="" /></li>
			                    <li><img src="../images/t-img1.jpeg" alt="" /></li>
			                  </ul>
                        <div className="ts-content">
                          <h5>Read Travel Stories</h5>
			                    <p>of 40+ travellers visited Rajasthan</p>
			                  </div>
			                </a>
                    </div>*/}

                  <ul className="banner-tags">
                    {
                      this.state.destTags && this.state.destTags.length > 0 &&
                      this.state.destTags.map((obj, index) => (
                        <li key={index}>{obj}</li>
                      ))
                    }
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="stick-here"></div>
          <div className="banner-navigation" id="stickThis">
            <div className="container">
              <DestinationGuideNavigation />
              <div className="customize-btn">
                <BookingButtonStyle1 label="CUSTOMIZE YOUR TRIP" customClass="" index={this.props.index} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default DestinationGuideBanner;