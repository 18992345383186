import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export {_dispatch};

export const HOME_BLOG_LIST = 'HOME_BLOG_LIST';
export const HOME_BLOG_LIST_SUCCESS = 'HOME_BLOG_LIST_SUCCESS';

export const BLOG_LIST = 'BLOG_LIST';
export const BLOG_LIST_SUCCESS = 'BLOG_LIST_SUCCESS';

export const BLOG_DETAIL = 'BLOG_DETAIL';
export const BLOG_DETAIL_SUCCESS = 'BLOG_DETAIL_SUCCESS';

export const MOST_VIEW_BLOG = 'MOST_VIEW_BLOG';
export const MOST_VIEW_BLOG_SUCCESS = 'MOST_VIEW_BLOG_SUCCESS';

const ROOT_URL = API_ROOT;
var token = "";

export function homeBlogList(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/trvelotblogs?random=1`,
    
  });

  return {
    type: HOME_BLOG_LIST,
    payload: request
  };
}

export function homeBlogListSuccess(payload, total){
	return {
    	type: HOME_BLOG_LIST_SUCCESS,
    	payload: payload,
      total: total
  	}
}

export function blogList(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/bloglisting`,
  });

  return {
    type: BLOG_LIST,
    payload: request
  };
}

export function blogListSuccess(payload, total){
  return {
      type: BLOG_LIST_SUCCESS,
      payload: payload
    }
}

export function blogDetail(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/trvelotblogs`,
  });

  return {
    type: BLOG_DETAIL,
    payload: request
  };
}

export function blogDetailSuccess(payload){
  return {
      type: BLOG_DETAIL_SUCCESS,
      payload: payload
    }
}

export function mostViewBlog(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/mostviewedblogs`,
  });

  return {
    type: MOST_VIEW_BLOG,
    payload: request
  };
}

export function mostViewBlogSuccess(payload){
  return {
      type: MOST_VIEW_BLOG_SUCCESS,
      payload: payload
    }
}

