import {
  _dispatch, HOME_BLOG_LIST_SUCCESS, BLOG_LIST_SUCCESS, BLOG_DETAIL_SUCCESS, MOST_VIEW_BLOG_SUCCESS
} from '../actions/blog';

const INITIAL_STATE = { homebloglist: [], totalblogs:0, bloglisting:[], blogdetail:[], mostviewblog:[], status:null };

export default function(state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch(action.type) {

    case HOME_BLOG_LIST_SUCCESS:
      return (() => {
        const homebloglist = action.payload;
        const totalblogs = action.total;
        return _dispatch({ ...state, homebloglist, totalblogs}, true);
      })();

    case BLOG_LIST_SUCCESS:
      return (() => {
        const bloglisting = action.payload;
        return _dispatch({ ...state, bloglisting }, true);
      })();

    case BLOG_DETAIL_SUCCESS:
      return (() => {
        const blogdetail = action.payload;
        return _dispatch({ ...state, blogdetail }, true);
      })();

    case MOST_VIEW_BLOG_SUCCESS:
      return (() => {
        const mostviewblog = action.payload;
        return _dispatch({ ...state, mostviewblog }, true);
      })();


    default:
      return state;
  }
}