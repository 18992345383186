import React, { Component } from 'react';

export default class HotelRatings extends Component {
    
  constructor(props){
    super(props);
    this.state = {
      selectedValue: typeof props.selectedValue !== 'undefined' ? props.selectedValue : '',
      disabled: typeof props.disabled !== 'undefined' ? props.disabled : false,
      ratings: [3, 4, 5]
    };
    this.selectValue = this.selectValue.bind(this);
  }

  selectValue(e){
    this.setState({
      selectedValue: e.target.value
    });
  }

	render() {
      const {selectedValue, ratings, disabled} = this.state;

    	return (
    		<div>
          <ul className="hotel-rating-block">
              {
                ratings.map((rating) => {
                    return <li key={rating}>
                              <input disabled={disabled} checked={selectedValue == rating ? true : false}  ref="hotel_rating" type="radio" name="hotel_rating" id={`hotel-rating${rating}`} defaultValue={rating} onClick={this.selectValue} />

                              <label htmlFor={`hotel-rating${rating}`}>
                                  <ion-icon ios="ios-star" md="ios-star"></ion-icon>
                                  {rating} Star
                              </label> 
                          </li>
                })
              }
          </ul>
          <div className="need-hotel-block">
              <input disabled={disabled} checked={selectedValue == "0" ? true : false} defaultValue="0" type="checkbox" name="hotel_rating" ref="hotel_rating" id="hotel-rating0" onClick={this.selectValue} />
              <label htmlFor="hotel-rating0">
                  <ion-icon name="bed"></ion-icon>
                  I don’t need a Hotel
              </label>
          </div>
        </div>
    	);
    }
}