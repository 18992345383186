import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export {_dispatch};

export const DESTINATION_PACKAGE_LIST = 'DESTINATION_PACKAGE_LIST';
export const DESTINATION_PACKAGE_LIST_SUCCESS = 'DESTINATION_PACKAGE_LIST_SUCCESS';

export const DESTINATION_INFO_LIST = 'DESTINATION_INFO_LIST';
export const DESTINATION_INFO_LIST_SUCCESS = 'DESTINATION_INFO_LIST_SUCCESS';

export const UPDATE_FILTERS_DESTINATION = 'UPDATE_FILTERS_DESTINATION';

export const RESET_FILTERS_DESTINATION = 'RESET_FILTERS_DESTINATION';


const ROOT_URL = API_ROOT;
var token = "";

export function resetFilters(filters, fetchNewRecords){
  return {
    type: RESET_FILTERS_DESTINATION,
    payload: filters,
    fetch_records: fetchNewRecords
  };
}

export function destinationPackageList(params) {
  //console.log('rrrrr', params);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/browsingdestinationpackage`,
    
  });

  return {
    type: DESTINATION_PACKAGE_LIST,
    payload: request
  };
}

export function destinationPackageListSuccess(payload){
  return {
      type: DESTINATION_PACKAGE_LIST_SUCCESS,
      payload: payload
    }
}

export function destinationInfoList(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/browsingdestination`,
    
  });

  return {
    type: DESTINATION_INFO_LIST,
    payload: request
  };
}

export function destinationInfoListSuccess(payload){
  return {
      type: DESTINATION_INFO_LIST_SUCCESS,
      payload: payload
    }
}

export function updateFilters(payload){
  return {
      type: UPDATE_FILTERS_DESTINATION,
      payload: payload
    }
}

