import React, { Component, Fragment } from 'react';
import ThemeMobileFilterBlock from './components/partials/ThemeMobileFilterBlock';
import DestinationMainContentBlock from './components/destination/DestinationMainContentBlock';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import { resetFilters } from './actions/holidayType';
import Loader from './components/common/loader';

const queryString = require('query-string');

class DestinationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status,
      loading: true,
    };
  }

  componentWillMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 500);
    let filters = {};
    if (this.props.location.search != '') {
      filters = { ...filters, ...queryString.parse(this.props.location.search, { arrayFormat: 'bracket' }) };
      this.props.resetFilters(filters, false);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status) {
      if (nextProps.compName == 'filters') {
        console.log(nextProps.location, '===', this.props.location)
        if (nextProps.location === this.props.location) {
          this.props.history.push({
            pathname: nextProps.location.pathname,
            search: "?" + window.$$.param(nextProps.filters)
          });
        }
      }
    }
  }

  onOpenFilter() {
    let element = document.getElementById("filterToShow");
    ReactDOM.findDOMNode(element).classList.add("activefilter");
  }

  render() {
    return (
      <Fragment>
        {
          this.state.loading ? <Loader /> :
            <div className="main-body-content">
              {/* <ThemeMobileFilterBlock /> */}
              <section className="mf-block">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <ul className="filter-listing">
                        <li onClick={this.onOpenFilter}>Filters</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              <section className="packages-banner all-packages">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12">
                      <div className="inner-banner-content">
                        <h1>All Destinations</h1>
                        <p>Lorem ipsum is a pseudo-Latin text used in web design</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <DestinationMainContentBlock />
            </div>
        }
      </Fragment>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    status: state.holidayTypes.status,
    compName: state.holidayTypes.compName,
    filters: { ...state.holidayTypes.filters }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetFilters: (filters, slugRequired) => {
      dispatch(resetFilters(filters, slugRequired));
    }
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(DestinationsList);