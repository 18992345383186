import {
  _dispatch, DESTINATION_GUIDE_DETAIL_SUCCESS, DESTINATION_GUIDE_CITYINFO_SUCCESS, DESTINATION_GUIDE_POPULARPACK_SUCCESS

} from '../actions/destinationGuide';

const INITIAL_STATE = { destinationguidedetail:null, destinationguidecityinfo:[], destinationguidepopularpack:[], status:null };

export default function(state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch(action.type) {

    case DESTINATION_GUIDE_DETAIL_SUCCESS:
      return (() => {
        const destinationguidedetail = action.payload;
        return _dispatch({ ...state, destinationguidedetail }, true);
      })();

    case DESTINATION_GUIDE_CITYINFO_SUCCESS:
      return (() => {
        const destinationguidecityinfo = action.payload;
        return _dispatch({ ...state, destinationguidecityinfo }, true);
      })();

    case DESTINATION_GUIDE_POPULARPACK_SUCCESS:
      return (() => {
        const destinationguidepopularpack = action.payload;
        return _dispatch({ ...state, destinationguidepopularpack }, true);
      })();


    default:
      return state;
  }
}