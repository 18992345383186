import React, { Component } from 'react';

class AboutUs extends Component {

  constructor(props){
    super(props);
    this.bindSlider = this.bindSlider.bind(this);
  }

  componentDidMount(){
    window.addEventListener('load', () => {
      this.bindSlider();
    });

    if(document.readyState === "complete" || document.readyState === "interactive"){
      this.bindSlider();
    }
  }

  bindSlider(){
    const $ = window.$$;
    if ($('#testmonial-block').length >0) {
      //console.log('aaaaa');

        // The slider being synced must be initialized first
        $('#testmonial-block').flexslider({
          animation: false,
          controlNav: false,
          animationLoop: false,
          slideshow: true,
          itemWidth: 210,
          itemMargin: 5,
          direction: "vertical", 
          asNavFor: '#slider-testimonial'
        });
       
        $('#slider-testimonial').flexslider({
          animation: "fade",
          controlNav: false,
          animationLoop: false,
          slideshow: true,
          directionNav:false,
          sync: "#testmonial-block"
        });
      
    }

  }

  
  render() {
      return (
          <div className="main-body-content">
                
          <section className="about-banner-block" style={{backgroundImage: `url(/images/about-banner.jpg)`}}>
              <div className="container">
                  <div className="row">
                      <div className="col-sm-6">
                          <div className="inner-banner-content">
                              <h1>About travalot</h1>
                              <span>A better way to customize and plan your perfect holiday experience</span>
                              <p>Founded in 2015, Travalot is India’s leading online holiday marketplace bringing both the travelers, and trusted & expert travel agents on a common platform. With the recent Series C funding of $12 Million from Nandan Nilekani and Sanjeev Aggarwal backed Fundamentum in early 2018, it is on its way of encompassing all the components of holiday eco-system through its highly innovative and technology-focused product...</p>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          

          <section className="main-content-about">
              <div className="feature-different">
                  <div className="container">
                      <div className="heading-about-block">
                          <h3>What makes us different?</h3>
                          <p>Travalot is Fastest Growing Holiday Marketplace which is easy, fast, secure & reliable</p>
                      </div>
                      <div className="block-box-feature">
                          <div className="row">
                              <div className="col-sm-4">
                                  <div className="ui-block-feature">
                                      <figure>
                                          <img src="/images/customization.png" />
                                      </figure>
                                      <h3>Complete Customization</h3>
                                      <p>We provide you Instant booking in real time with the best experienceWe provide you Instant booking in real time with the best experienceWe provide you Instant booking</p>
                                  </div>
                              </div>
                              <div className="col-sm-4">
                                  <div className="ui-block-feature">
                                      <figure>
                                          <img src="/images/secure.png" />
                                      </figure>
                                      <h3>Secure & Reliable</h3>
                                      <p>We provide you Instant booking in real time with the best experienceWe provide you Instant booking in real time with the best experienceWe provide you Instant booking</p>
                                  </div>
                              </div>
                              <div className="col-sm-4">
                                  <div className="ui-block-feature">
                                      <figure>
                                          <img src="/images/support.png" />
                                      </figure>
                                      <h3>24/7 Support</h3>
                                      <p>We provide you Instant booking in real time with the best experienceWe provide you Instant booking in real time with the best experienceWe provide you Instant booking</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="founders-block">
                  <div className="container">
                      <div className="heading-about-block">
                          <h3>Meet the Founders</h3>
                          <p>Travalot is Fastest Growing Holiday Marketplace which is easy, fast, secure & reliable</p>
                      </div>
                      <div className="row">
                          <div className="col-sm-6">
                              <div className="block-founder-section">
                                  <img src="/images/founder-1.jpg" />
                                  <div className="about-founder">
                                      <label>Elise Beverley</label>
                                      <span>CEO & Founder</span>
                                  </div>
                              </div>
                          </div>
                          <div className="col-sm-6">
                              <div className="block-founder-section">
                                  <img src="/images/founder-1.jpg" />
                                  <div className="about-founder">
                                      <label>Gabriel Soares</label>
                                      <span>CEO & Founder</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="team-mates">
                  <div className="container">
                      <div className="heading-about-block">
                          <h3>Our Team</h3>
                          <p>Hereos behind the progress</p>
                      </div>
                      <div className="row">
                          <div className="col-sm-2">
                              <div className="mates-block">
                                  <figure>
                                      <img src="/images/team-1.jpg" />
                                  </figure>
                                  <label>Angu Tamba</label>
                                  <p>Travel Director</p>
                              </div>
                          </div>
                          <div className="col-sm-2">
                              <div className="mates-block">
                                  <figure>
                                      <img src="/images/team-2.jpg" />
                                  </figure>
                                  <label>Débora Barbosa</label>
                                  <p>Finance Manager</p>
                              </div>
                          </div>
                          <div className="col-sm-2">
                              <div className="mates-block">
                                  <figure>
                                      <img src="/images/team-3.jpg" />
                                  </figure>
                                  <label>Higashi Mako</label>
                                  <p>Content Writer</p>
                              </div>
                          </div>
                          <div className="col-sm-2">
                              <div className="mates-block">
                                  <figure>
                                      <img src="/images/team-4.jpg" />
                                  </figure>
                                  <label>Harrison Phillips</label>
                                  <p>Marketing Manager</p>
                              </div>
                          </div>
                          <div className="col-sm-2">
                              <div className="mates-block">
                                  <figure>
                                      <img src="/images/team-5.jpg" />
                                  </figure>
                                  <label>Nicolina Lindholm</label>
                                  <p>Package Designer</p>
                              </div>
                          </div>
                          <div className="col-sm-2">
                              <div className="mates-block">
                                  <figure>
                                      <img src="/images/team-6.jpg" />
                                  </figure>
                                  <label>Nombeko Mabandla</label>
                                  <p>Social Media Manager</p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="about-testimonial">
                  <div className="container">
                      <div className="block-testmonial-container">
                          <div className="testimonial-block-head">
                              <h2>CUSTOMER REVIEWS</h2>
                              <label>So happy with Travalot</label>
                          </div>
                          <div id="slider-testimonial" className="flexslider">
                            <ul className="slides">
                              <li>
                                  <div className="testimonial-block-content">
                                      <p>I had planned my honeymoon to maldives through TravelAtlas. I am delighted to have such amazing services at better quotes than competitors. Special thanks to Ayushman and his team.              
                                      </p>                                  
                                      <div className="about-client-disc">              
                                          <h3>Hashim Briscam</h3>
                                          <span>Chandigarh</span>
                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div className="testimonial-block-content">
                                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s            
                                      </p>                                  
                                      <div className="about-client-disc">              
                                          <h3>Nathan Lopez</h3>   
                                          <span>Chandigarh</span>
                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div className="testimonial-block-content">
                                      <p>t is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum             
                                      </p>                                  
                                      <div className="about-client-disc">              
                                          <h3>Hashim Briscam</h3>
                                          <span>Chandigarh</span>
                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div className="testimonial-block-content">
                                      <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classNameical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia.            
                                      </p>                                  
                                      <div className="about-client-disc">              
                                          <h3>June Cha</h3>
                                          <span>Chandigarh</span>
                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div className="testimonial-block-content">
                                      <p>Looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classNameical literature, discovered the undoubtable source.             
                                      </p>                                  
                                      <div className="about-client-disc">              
                                          <h3>Jesiah Neely</h3>
                                          <span>Chandigarh</span>
                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div className="testimonial-block-content">
                                      <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.        
                                      </p>                                  
                                      <div className="about-client-disc">              
                                          <h3>Zachary Levi</h3>
                                          <span>New Delhi</span>
                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div className="testimonial-block-content">
                                      <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.             
                                      </p>                                  
                                      <div className="about-client-disc">              
                                          <h3>Hashim Briscam</h3>
                                          <span>Chandigarh</span>
                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div className="testimonial-block-content">
                                      <p>I had planned my honeymoon to maldives through TravelAtlas. I am delighted to have such amazing services at better quotes than competitors. Special thanks to Ayushman and his team.              
                                      </p>                                  
                                      <div className="about-client-disc">              
                                          <h3>Sean PJPGR Doran</h3>
                                          <span>Chandigarh</span>
                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div className="testimonial-block-content">
                                      <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit.              
                                      </p>                                  
                                      <div className="about-client-disc">              
                                          <h3>Ana De Armas</h3>
                                          <span>New Delhi</span>
                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div className="testimonial-block-content">
                                      <p>Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus.              
                                      </p>                                  
                                      <div className="about-client-disc">              
                                          <h3>Hashim Briscam</h3>
                                          <span>Chandigarh</span>
                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div className="testimonial-block-content">
                                      <p>omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus.              
                                      </p>                                  
                                      <div className="about-client-disc">              
                                          <h3>Jacob Ginnish</h3>
                                          <span>Chandigarh</span>
                                      </div>
                                  </div>
                              </li>
                              <li>
                                  <div className="testimonial-block-content">
                                      <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire.             
                                      </p>                                  
                                      <div className="about-client-disc">              
                                          <h3>Tim Schoch</h3>
                                          <span>Chandigarh</span>
                                      </div>
                                  </div>
                              </li>
                              
                            </ul>
                          </div>
                          <div id="testmonial-block" className="flexslider-thumb">
                            <ul className="slides">
                              <li>
                                <img src="/images/user1.png" />
                              </li>
                              <li>
                                <img src="/images/user2.png" />
                              </li>
                              <li>
                                <img src="/images/user3.png" />
                              </li>
                              <li>
                                <img src="/images/user4.png" />
                              </li>
                              <li>
                                <img src="/images/user5.png" />
                              </li>
                              <li>
                                <img src="/images/user6.png" />
                              </li>
                              <li>
                                <img src="/images/user7.png" />
                              </li>
                              <li>
                                <img src="/images/user8.png" />
                              </li>
                              <li>
                                <img src="/images/user9.png" />
                              </li>
                              <li>
                                <img src="/images/user10.png" />
                              </li>
                              <li>
                                <img src="/images/user11.png" />
                              </li>
                              <li>
                                <img src="/images/user12.png" />
                              </li>
                             
                            </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </section>

          <section className="follow-block">
              <div className="container">
                  <div className="heading-about-block">
                          <h3>Follow us on Social</h3>
                          <p>Hereos behind the progress</p>
                      </div>
                   <div className="row">
                        <div className="col-sm-4">
                             <div className="follow-inner">
                               <img src="/images/pos1-min.png" alt=""/>
                             </div>
                        </div>
                        <div className="col-sm-4">
                             <div className="follow-inner">
                               <img src="/images/pos2-min.png" alt=""/>
                             </div>
                        </div>

                        <div className="col-sm-4">
                             <div className="follow-inner">
                               <img src="/images/pos3-min.png" alt=""/>
                             </div>
                        </div>
                   </div>
              </div>
          </section>
      </div>
    );
  }
}
export default AboutUs;