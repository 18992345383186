import React, { Component } from 'react';
import {fetchDestinations} from '../../actions/destinations';

export default class DestinationListing extends Component {

  constructor(props){
    super(props);
    this.state = {
      destinations: [],
      allDestinations: [],
      selection: typeof props.selection !== 'undefined' ? props.selection : []
    };

    this.handleChange = this.handleChange.bind(this);
    this.searchChange = this.searchChange.bind(this);
  }

  componentWillMount(){
    fetchDestinations().then((response) => {
      this.setState({
        destinations: response.data.payload,
        allDestinations: response.data.payload
      });
    });
  }

  componentWillReceiveProps(nextProps){
      if(typeof nextProps.selection === 'undefined'){
          this.setState({
              selection: []
          });
      }
  }

  searchChange(e){
    const {name, value} = e.target;
    const {allDestinations} = this.state;
    this.setState({
      destinations: allDestinations.filter((item) => {
                      return item.destination_name.toLowerCase().indexOf(value.toLowerCase().replace(/\s+/, '')) == 0
                    })
    });
  }

  handleChange(e){
      const {name, value, checked} = e.target;
      let {selection} = this.state;

      if(checked){
          if(selection.indexOf(0) > -1)
            selection.splice(selection.indexOf(0), 1);
          if(value == 0)
            selection = [0];
          else
            selection.push(parseInt(value));
      }else{
          selection.splice(selection.indexOf(parseInt(value)), 1);
      }

      this.setState({
          selection
      });
      //console.log(selection);
      this.props.updateFilters({destinations: selection});
  }

	render() {
      const {destinations, selection} = this.state;

    	return (
          <div className="inner-content">
            <div className="search-field">
                <input type="text" placeholder="Search Destination" name="dest_search" onChange={this.searchChange} />
            </div>
            <form onChange={this.handleChange}>
  			      <ul className="checkbox-listing" id="myList">
                 	<li>
                    	<input checked={selection.indexOf(0) > -1} name="destination_name" type="checkbox" id="all-destinations" value="0" />
                    	<label for="all-destinations">All Destinations</label>
                 	</li>
                  {
                    destinations.map((destination) => {
                      return <li key={destination.id}>
                                  <input checked={selection.indexOf(destination.id) > -1} name="destination_name" type="checkbox" id={`destination${destination.id}`} value={destination.id} />
                                  <label for={`destination${destination.id}`}>{destination.destination_name}</label>
                              </li>
                    })
                  }
              </ul>
            </form>
          </div>
        );
    }
}   