import axios, {post} from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export {_dispatch};
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const FORGOTPASSWORD_SUCCESS = 'FORGOTPASSWORD_SUCCESS';
export const FORGOTPASSWORD_ERROR = 'FORGOTPASSWORD_ERROR';
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS';
export const UPLOAD_PHOTO_ERROR = 'UPLOAD_PHOTO_ERROR';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';
export const SAVE_PREFERENCES_SUCCESS = 'SAVE_PREFERENCES_SUCCESS';
export const SAVE_PREFERENCES_ERROR = 'SAVE_PREFERENCES_ERROR';
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS';
export const QUOTES_SUCCESS = 'QUOTES_SUCCESS';
export const ADD_TO_WISHLIST_SUCCESS = 'ADD_TO_WISHLIST_SUCCESS';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';


const ROOT_URL = API_ROOT;
/**
 * Verify user login
 */
export function verify(credentials){
  
  return (dispatch) => { 
    const request = axios({
      method: 'POST',
      data: credentials,
      url: `${API_ROOT}/user/login`,
    }).then((response) => dispatch({
        type: LOGIN_SUCCESS,
        payload: response.data.payload
    })).catch((error) => dispatch({
        type: LOGIN_ERROR,
        payload: error.response.data.error
    }));
  };
}

export function autoLogin(payload){
  return {
    type: LOGIN_SUCCESS,
    payload
  };
}


/**
 * Register a new user
 */
export function signup(credentials){
  
  return (dispatch) => { 
    const request = axios({
      method: 'POST',
      data: credentials,
      url: `${API_ROOT}/user/register`,
    }).then(response => dispatch({
        type: SIGNUP_SUCCESS
    })).catch((error) => dispatch({
        type: SIGNUP_ERROR,
        payload: error.response.data.error
    }));
  };
}

/**
 * forgot password
 */
export function forgotpassword(credentials){
  
  return (dispatch) => { 
    const request = axios({
      method: 'POST',
      data: credentials,
      url: `${API_ROOT}/password/forgot`,
    }).then(response => dispatch({
        type: FORGOTPASSWORD_SUCCESS
    })).catch((error) => dispatch({
        type: FORGOTPASSWORD_ERROR,
        payload: error.response.data.error
    }));
  };
}

/**
 * Update profile
 */
export function updateProfle(data){
  
  return (dispatch) => { 
    const request = axios({
      method: 'POST',
      data: data,
      url: `${API_ROOT}/updateprofileinfo`,
    }).then(response => dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: data
    })).catch((error) => dispatch({
        type: UPDATE_PROFILE_ERROR,
        payload: error.response.data.error
    }));
  };
}

/**
 * Update profile
 */
export function saveHolidayPreferences(data){
  
  return (dispatch) => { 
    const request = axios({
      method: 'POST',
      data: data,
      url: `${API_ROOT}/saveholidaypreferences`,
    }).then(response => dispatch({
        type: SAVE_PREFERENCES_SUCCESS,
        payload: data
    })).catch((error) => dispatch({
        type: SAVE_PREFERENCES_ERROR,
        payload: error.response.data.error
    }));
  };
}

/**
 * Uploads a profile picture
 */
export function uploadProfilePicture(formData){
  const config = {
      headers: {
          'content-type': 'multipart/form-data'
      }
  };

  return (dispatch) => { 
    post(`${API_ROOT}/updateprofileimage`, formData, config)
      .then(response => dispatch({
        type: UPLOAD_PHOTO_SUCCESS,
        payload: response.data.payload
      }))
      .catch((error) => dispatch({
          type: UPLOAD_PHOTO_ERROR,
          payload: error.response.data.error
      }));
  };
}

/**
 * Fetch dashboard data
 */
export function fetchDashboardInfo(){

  return (dispatch) => { 
    const request = axios({
      method: 'GET',
      url: `${API_ROOT}/userdashboardinfo`,
    }).then(response => dispatch({
        type: DASHBOARD_SUCCESS,
        payload: response.data.payload
    }));
  };
}

/**
 * Fetch user quotes list
 */
export function fetchQuotesList(){

  return (dispatch) => { 
    const request = axios({
      method: 'GET',
      url: `${API_ROOT}/userquerylist`,
    }).then(response => dispatch({
        type: QUOTES_SUCCESS,
        payload: response.data.payload
    }));
  };
}

export function addToWishlist(packageId){
  return (dispatch) => { 
    const request = axios({
      method: 'POST',
      data: {package_id: packageId},
      url: `${API_ROOT}/savepackage`
    }).then((response) => dispatch({
        type: ADD_TO_WISHLIST_SUCCESS,
        payload: packageId
    }));
  };
}

export function resetPassword(data) {
  const request = axios({
    method: 'POST',
    data,
    url: `${ROOT_URL}/password/reset`,
  });

  return {
    type: RESET_PASSWORD,
    payload: request
  };
}

export function resetPasswordSuccess(payload){
  return {
      type: RESET_PASSWORD_SUCCESS,
      payload: payload
    }
}

/**
 * SET PASSWORD
 */
export function setPassword(data){
  return (dispatch) => { 
    const request = axios({
      method: 'POST',
      data: data,
      url: `${API_ROOT}/setpassword`,
    }).then(response => dispatch({
        type: UPDATE_PASSWORD_SUCCESS,
        payload: data
    })).catch((error) => dispatch({
        type: UPDATE_PASSWORD_ERROR,
        payload: error.response.data.error
    }));
  };
}