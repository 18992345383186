import React, { Component } from 'react';

export default class QuoteRequestProgressBar extends Component {

  render() {
      const {quoteDetail} = this.props;
      console.log(quoteDetail ,"quoteDetailquoteDetailquoteDetailquoteDetail")

      return ( 
          <div className="quotes-steps-block">
              {
                typeof quoteDetail.query !== 'undefined' && (quoteDetail.query.status == '6')
                ?
                  <div className="blur-block"> 
                      <div className="blur-inner-content">
                          <i className="cancel-icon"><img src="/images/ic_cancel_booking.svg" alt="" /></i>
                         <p>Booking Cancelled</p>
                     </div>
                  </div>
                :
                <ul>
                    <li className="in-progress"> 
                        <span className="circle"></span> 
                        <p>Quote Requested</p>
                    </li>
                    <li className={typeof quoteDetail.query !== 'undefined' && quoteDetail.query.confirm_status == '1' ? "in-progress" : ""}> 
                        <span className="circle"></span> 
                        <p>Request Confirmed</p>
                    </li>
                    <li className={quoteDetail.quotation ? "in-progress" : ""}> 
                        <span className="circle"></span> 
                        <p>Quote Received</p>
                    </li>
                    <li className={typeof quoteDetail.query !== 'undefined' && (quoteDetail.query.status == '3' || quoteDetail.query.status == '5' || quoteDetail.query.status == '6' || quoteDetail.query.query_invoice_time != null) ? "in-progress" : ""}> 
                        <span className="circle"></span> 
                        <p>Invoice Received</p>
                    </li>
                    <li className={typeof quoteDetail.query !== 'undefined' && (quoteDetail.query.status == '5'|| quoteDetail.query.status == '6') ? "in-progress" : ""}> 
                        <span className="circle"></span> 
                        <p>Booking Confirmed</p>
                    </li>
                    <li className={typeof quoteDetail.query !== 'undefined' && (quoteDetail.query.upload_voucher_status == '1') ? "in-progress" : ""}> 
                        <span className="circle"></span> 
                        <p>Vouchers Received</p>
                    </li>

                </ul>
              }
          </div> 
      );
    }
}
