import React, { Component } from 'react';
import { connect } from 'react-redux';
import { shortMonths } from '../../constants';

export default class MonthFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      month: [],
      selection: typeof props.selection !== 'undefined' ? props.selection.map((item) => parseInt(item)) : []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.selection === 'undefined') {
      this.setState({
        month: [],
        selection: []
      });
    }
  }

  handleChange(e) {
    const { name, value, checked } = e.target;
    let { selection } = this.state;

    if (checked) {
      if (selection.indexOf(0) > -1)
        selection.splice(selection.indexOf(0), 1);
      if (value == 0)
        selection = [0];
      else
        selection.push(parseInt(value));
    } else {
      selection.splice(selection.indexOf(parseInt(value)), 1);
    }
    this.setState({
      selection
    });
    this.props.updateFilters({ month: selection });

  }

  render() {
    const { month, selection } = this.state;

    return (
      <div className="content-block month-tab">
        <div className="card">
          <div className="sidebar-heading-block">
            <a className="card-link" data-toggle="collapse" href="#month" aria-expanded="true" aria-controls="menuone">
              Month of travel
                  <ion-icon ios="ios-arrow-down" md="ios-arrow-down"></ion-icon>
            </a>
            <div className="select-element">JANUARY</div>
          </div>
          <div id="month" className="collapse show">
            <div className="inner-content">
              <ul className="month-listing">
                <form onChange={this.handleChange}>
                  {
                    shortMonths.map((value, index) => {
                      if (value == '') {
                        return null;
                      }
                      return <li key={index}>
                        <input checked={selection.indexOf(index) > -1} type="checkbox" id={`mth${index}`} value={index} name="month" />
                        <label htmlFor={`mth${index}`}>{value}</label>
                      </li>
                    })
                  }
                </form>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     updateFilters: (params) => {
//       return dispatch(updateFilters(params));
//     },

//   };
// }
// export default connect(null, mapDispatchToProps)(MonthFilter);