import React, { Component } from 'react';
import { connect } from 'react-redux';

export default class NationalFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destination_type: typeof props.selection !== 'undefined' ? props.selection : []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.selection === 'undefined') {
      this.setState({
        destination_type: []
      });
    }
  }

  handleChange(e) {
    const { name, value, checked } = e.target;
    let { destination_type } = this.state;
    if (checked) {
      destination_type.push(value);
    } else {
      destination_type.splice(destination_type.indexOf(value), 1);
    }
    this.setState({
      destination_type
    });
    this.props.updateFilters({ destination_type });
  }
  
  render() {
    const { destination_type } = this.state;

    return (
      <div className="inner-content ">
        <form onChange={this.handleChange}>
          <ul className="checkbox-listing i-block">
            <li>
              <input checked={destination_type.indexOf('domestic') > -1} type="checkbox" id="pacakage1" value="domestic" />
              <label for="pacakage1">India</label>
            </li>
            <li>
              <input checked={destination_type.indexOf('international') > -1} type="checkbox" id="pacakage2" value="international" />
              <label for="pacakage2">International</label>
            </li>
          </ul>
        </form>
      </div>
    );
  }
}