import React, { Component } from 'react';
import { connect } from 'react-redux';
import DestinationGuidePopularPlaces from './DestinationGuidePopularPlaces';
import DestinationGuidePopularCities from './DestinationGuidePopularCities';
import { destinationGuideCityInfo } from '../../actions/destinationGuide';

class DestinationGuidePopular extends Component {
  constructor(props){
    super(props);
    this.state = {
      destcityinformation: null,
      slug: props.slug
    };
  }

  componentDidMount(){
      this.fetchRecords(this.state.slug);
  }

  componentWillReceiveProps(nextProps){
      if(nextProps.slug != this.state.slug){
          this.fetchRecords(nextProps.slug);
      }
  }

  fetchRecords(slug){
    this.props.destinationGuideCityInfo({to_destination: slug}).then((response) => {
      if(response.value.data.result === 'success'){
        this.setState({
          destcityinformation: response.value.data.payload,
          slug
        });
      }
    });
  }


	render() {
      if(this.state.destcityinformation === null)
        return null;

      const {popular_cities, visitplaces} = this.state.destcityinformation;
      if(!popular_cities.length && !visitplaces.length)
        return null;
      
    	return (
    		<section className="popular-places scroll-block" id="popularplaces">
          		<div className="container">
            		<div className="sm-heading-main">
	              		<h4>Popular Places in {this.props.destination}</h4>
	              		<p>Let the experts guide you to the best of this mesmerizing destination</p>
	            	</div>
	          	</div>
          		<div className="slider-block-full">
            		{popular_cities.length > 0 && <DestinationGuidePopularCities popularcityinfo = {popular_cities} slug={ this.state.slug } />}
            		{visitplaces.length > 0 && <DestinationGuidePopularPlaces destination={this.props.destination} popularvisitcity = {visitplaces} />}
        		</div>
      		</section>
    	);
    }
}

const mapDispatchToProps = (dispatch) => {
  return {
    destinationGuideCityInfo: (params) => {
      return dispatch(destinationGuideCityInfo(params));
    }
  };
}

export default connect(null, mapDispatchToProps)(DestinationGuidePopular);