// Vikas
import React, { Component } from 'react';
import moment from 'moment';

export default class DateMonthPicker extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      selectedValue: typeof props.selectedValue !== 'undefined' ? props.selectedValue : '',
      disabled: typeof props.disabled !== 'undefined' ? props.disabled : false
    };

    this.bind = this.bind.bind(this);
  }

  componentDidMount(){
    window.addEventListener('load', this.bind);

    if(document.readyState === "complete" || document.readyState === "interactive"){
      this.bind();
    }
  }

  bind(){
    const $ = window.$$;
    const elem = this.refs.datemonth;
    const {selectedValue} = this.state;
    const settings = {
        autoclose: true,
        startDate: new Date(),
        minViewMode: 1,
        format: 'dd/mm/yyyy'
    };

    if(selectedValue != ''){
      settings.startDate = moment(selectedValue, "YYYY-MM-DD").format();
      $(elem).html(moment(selectedValue, "YYYY-MM-DD").format('MMMM'));
    }

    $(elem).datepicker(settings).on('changeDate', (selected) => {
        $(elem).html(moment(selected.date).format('MMMM'));
        this.setState({
          selectedValue: moment(selected.date).format('YYYY-MM-DD')
        });
    }); 
  }

	render() {
      const {disabled} = this.state;
    	return (
    		<div className="datepicker-block">
            <button disabled={disabled} ref="datemonth" name="datemonth" className="datepicker-btn">Choose Month</button>
            <div className="drp-buttons">
              <span className="drp-selected"></span>
            </div>
        </div>
    	);
    }
}