import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginModal from '../../LoginModal';
import SignupModal from '../../SignupModal';
import { headerMainMenu, headerMainMenuSuccess } from '../../actions/navigation';

class MobileNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeMenu: null,
      dropdownType: null,
      chkNavigation: props.chkNavigation,
      status: props.status
    };
    this.toggle = this.toggle.bind(this);
    this.showModal = this.showModal.bind(this);
    this.showSignupModal = this.showSignupModal.bind(this);
  }

  componentDidMount() {
    this.props.headerMainMenu().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.headerMainMenuSuccess(response.value.data.payload);
      }
    })

    window.addEventListener('click', (e) => {
      if (e.target.className !== 'menu-item' && this.state.activeMenu !== null) {
        this.setState({
          activeMenu: null
        });
      }
    })

    window.$$(document).ready(function () {
      window.$$(".first-tab").on("click", function () {
        window.$$("#indian-package").addClass("active");
        window.$$("#international-package").removeClass("active");
      })

      window.$$(".second-tab").on("click", function () {
        console.log('HERERE')
        window.$$("#international-package").addClass("active");
        window.$$("#indian-package").removeClass("active");
      })

      window.$$(".package-heading li a").on("click", function () {
        window.$$(this).parent().addClass('active').siblings().removeClass('active');
      });


      window.$$(".mobile-navigation li.dropdown a").on("click", function () {
        window.$$(".mobile-navigation").addClass("active");
      })

      window.$$(".close-btn").on("click", function () {
        window.$$(".mobile-navigation").toggleClass("active");
        window.$$(".mobile-navigation .middle-block li").removeClass("show");
        window.$$(".mobile-navigation .middle-block li .dropdown-menu").removeClass("show");
      });

      window.$$(".msg-close-btn").on("click", function () {
        window.$$(".alert-msg").hide();
      });

      window.$$('.mobile-navigation .dropdown-menu').click(function (e) {
        e.stopPropagation();
      });


    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status) {
      if (this.props.status != nextProps.status) {
        this.setState({
          chkNavigation: nextProps.chkNavigation,
          status: nextProps.status
        })
      }
    }
  }

  componentDidUpdate() {
    // window.$$(document).ready(function () {
    //   window.$$(".first-tab").on("click", function () {
    //     window.$$("#indian-package").addClass("active");
    //     window.$$("#international-package").removeClass("active");
    //   })

    //   window.$$(".second-tab").on("click", function () {
    //     console.log()
    //     window.$$("#international-package").addClass("active");
    //     window.$$("#indian-package").removeClass("active");
    //   })

    //   window.$$(".package-heading li a").on("click", function () {
    //     window.$$(this).parent().addClass('active').siblings().removeClass('active');
    //   });


    //   window.$$(".mobile-navigation li.dropdown a").on("click", function () {
    //     window.$$(".mobile-navigation").addClass("active");
    //   })

    //   window.$$(".close-btn").on("click", function () {
    //     window.$$(".mobile-navigation").toggleClass("active");
    //     window.$$(".mobile-navigation .middle-block li").removeClass("show");
    //     window.$$(".mobile-navigation .middle-block li .dropdown-menu").removeClass("show");
    //   });

    //   window.$$(".msg-close-btn").on("click", function () {
    //     window.$$(".alert-msg").hide();
    //   });

    //   window.$$('.mobile-navigation .dropdown-menu').click(function (e) {
    //     e.stopPropagation();
    //   });


    // });
  }

  toggle(e, activeMenu) {
    e.preventDefault();
    this.setState({
      activeMenu
    });
  }

  hideModal() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  showModal() {
    window.getFooter().setState({
      renderElement: <LoginModal onHide={this.hideModal.bind(this)} />
    });
  }

  showSignupModal() {
    window.getFooter().setState({
      renderElement: <SignupModal onHide={this.hideModal.bind(this)} />
    });
  }

  render() {
    const { auth, user, src } = this.props
    const { activeMenu } = this.state;

    return (
      <div className="mobile-navigation">
        <div className="top-block">
          <div className="uleft-block">
            <figure>
              {
                auth && src ? <img src={src} alt={""} /> : <ion-icon name="person"></ion-icon>
              }
            </figure>
          </div>
          <div className="uright-block">
            {
              auth ? <h4>{user.name && user.name}</h4> :
                <>
                  <h4>Hello User</h4>
                  <ul>
                    <li><a href="javascript:;" id="login" onClick={this.showModal.bind(this)}>Login</a></li>
                    <li><a href="javascript:;" onClick={this.showSignupModal.bind(this)} >Signup</a></li>
                  </ul>
                </>
            }
          </div>
        </div>

        <div className="middle-block">
          <ul className="navbar-nav">
            {/* {auth ? <li><a href="javascript:;">My Bookings</a></li> : null} */}
            <li className="nav-item dropdown"><a href="javascript:;" id="menu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Holiday Packages</a>
              <ul className="dropdown-menu" aria-labelledby="menu1">
                <li>
                  <div className="submenu-block">
                    <div className="submenu-heading">
                      <h3>Holiday Packages</h3>
                      <button className="close-btn">
                        <span></span>
                        <span></span>
                      </button>
                    </div>

                    <div className="submenu-dropdown">
                      {/* <ul className="active" id="indian-package">
                        {
                          this.state.chkNavigation.holiday_packages_menu && this.state.chkNavigation.holiday_packages_menu.domestic && this.state.chkNavigation.holiday_packages_menu.domestic.length > 0 &&
                          this.state.chkNavigation.holiday_packages_menu.domestic.map((obj, index) => (
                            <li>
                              <Link to={"/destinationpackage/" + obj.slug}>
                                {obj.destination_name}
                              </Link>
                            </li>
                          ))
                        }
                      </ul> */}

                      <ul className="active" id="international-package1">
                        {
                          this.state.chkNavigation.holiday_packages_menu && this.state.chkNavigation.holiday_packages_menu.international && this.state.chkNavigation.holiday_packages_menu.international.length > 0 &&
                          this.state.chkNavigation.holiday_packages_menu.international.map((obj, index) => (
                            <li>
                              <Link to={"/destinationpackage/" + obj.slug}>{obj.destination_name}</Link>
                            </li>
                          ))
                        }
                      </ul>
                    </div>

                    <div className="package-heading">
                      <ul>
                        {/* <li className="first-tab active"><a href="javascript:;">INDIAN</a></li> */}
                        <li className="second-tab active"><a href="javascript:;">INTERNATIONAL</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown"><a href="javascript:;" id="menu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Destinations Guides</a>
              <ul className="dropdown-menu" aria-labelledby="menu2">
                <li>
                  <div className="submenu-block">
                    <div className="submenu-heading">
                      <h3>Destinations Guides</h3>
                      <button className="close-btn">
                        <span></span>
                        <span></span>
                      </button>
                    </div>

                    <div className="submenu-dropdown">
                      <ul className="active" id="indian-package">
                        {
                          this.state.chkNavigation.destination_guide_menu && this.state.chkNavigation.destination_guide_menu.domestic && this.state.chkNavigation.destination_guide_menu.domestic.length > 0 &&
                          this.state.chkNavigation.destination_guide_menu.domestic.map((obj, index) => (
                            <li>
                              <Link to={"/destinationguide/" + obj.slug}>{obj.destination_name}</Link>
                            </li>
                          ))
                        }
                        {/* <li><a href="javascript:;">Kerala</a></li>
                          <li><a href="javascript:;">Himachal</a></li>
                          <li><a href="javascript:;">Sikkim</a></li>
                          <li><a href="javascript:;">Andaman</a></li>
                          <li><a href="javascript:;">Kashmir</a></li>
                          <li><a href="javascript:;">Goa</a></li>
                          <li><a href="javascript:;">Rajasthan</a></li>
                          <li><a href="javascript:;">Leh Ladakh</a></li>
                          <li><a href="javascript:;">Spiti</a></li>
                          <li><a href="javascript:;">Uttrakhand</a></li>
                          <li><a href="javascript:;">Gujrat</a></li>
                          <li><a href="javascript:;">Assam</a></li>
                          <li><a href="javascript:;">Karnataka</a></li> */}
                      </ul>

                      <ul id="international-package">
                        {
                          this.state.chkNavigation.destination_guide_menu && this.state.chkNavigation.destination_guide_menu.international && this.state.chkNavigation.destination_guide_menu.international.length > 0 &&
                          this.state.chkNavigation.destination_guide_menu.international.map((obj, index) => (
                            <li key={index}>
                              <Link to={"/destinationguide/" + obj.slug}>{obj.destination_name}</Link>
                            </li>
                          ))
                        }
                        {/* <li><a href="javascript:;">Kerala</a></li>
                          <li><a href="javascript:;">Himachal</a></li>
                          <li><a href="javascript:;">Sikkim</a></li>
                          <li><a href="javascript:;">Andaman</a></li>
                          <li><a href="javascript:;">Kashmir</a></li>
                          <li><a href="javascript:;">Goa</a></li>
                          <li><a href="javascript:;">Rajasthan</a></li>
                          <li><a href="javascript:;">Leh Ladakh</a></li> */}
                      </ul>
                    </div>

                    <div className="package-heading">
                      <ul>
                        <li className="first-tab active"><a href="javascript:;">INDIAN</a></li>
                        <li className="second-tab"><a href="javascript:;">INTERNATIONAL</a></li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </li>

            <li className="nav-item dropdown"><a href="javascript:;" id="menu3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Themes</a>
              <ul className="dropdown-menu" aria-labelledby="menu3">
                <li>
                  <div className="submenu-block theme-package">
                    <div className="submenu-heading">
                      <h3>Themes Packages</h3>
                      <button className="close-btn">
                        <span></span>
                        <span></span>
                      </button>
                    </div>
                    <div className="theme-listing">
                      <ul>
                        {
                          this.state.chkNavigation.theme_menu && this.state.chkNavigation.theme_menu.length > 0 &&
                          this.state.chkNavigation.theme_menu.map((obj, index) => (
                            <li key={index}>
                              <Link to={"/theme/" + obj.slug}>
                                <span className={"theme-icons t-icon" + parseInt(index + 1)}></span>
                                {obj.title}
                              </Link>
                            </li>
                          ))
                        }
                        {/* <li><a href="javascript:;"> <span className="theme-icons t-icon1"></span> Honeymoon</a></li>
                          <li><a href="javascript:;"> <span className="theme-icons t-icon2"></span> Family</a></li>
                          <li><a href="javascript:;"> <span className="theme-icons t-icon3"></span> Friends</a></li>
                          <li><a href="javascript:;"> <span className="theme-icons t-icon4"></span> Nature</a></li>
                          <li><a href="javascript:;"> <span className="theme-icons t-icon5"></span> Adventure</a></li>
                          <li><a href="javascript:;"> <span className="theme-icons t-icon6"></span> Wildlife</a></li> */}
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </li>

            {
              auth ?
                <>
                  <li><Link to={"/notifications"}>Notifications</Link></li>
                  <li><Link to="/logout">Logout</Link></li>
                </> : null
            }
          </ul>
        </div>
        <div className="bottom-block"><a href="javascript::" className="btn-1">Plan My Holidays</a></div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    auth: state.Auth.auth,
    user: { ...state.Auth.user },
    src: state.Auth.user.profile_image,
    chkNavigation: state.navigation.headermainmenu,
    status: state.navigation.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    headerMainMenu: () => {
      return dispatch(headerMainMenu());
    },
    headerMainMenuSuccess: (payload) => {
      dispatch(headerMainMenuSuccess(payload));
    },

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(MobileNavigation);