import React, { Component } from 'react';

export default class QuantityBox extends Component {

  constructor(props) {
    super(props);
    const min = typeof props.min !== 'undefined' ? props.min : 0;
    this.state = {
      name: props.name,
      min: min,
      disabled: typeof props.disabled !== 'undefined' ? props.disabled : false,
      selectedValue: typeof props.selectedValue !== 'undefined' ? props.selectedValue : min
    };
    this.bind = this.bind.bind(this);
  }

  componentDidMount() {

    if (this.state.disabled === false) {
      window.onload = () => {
        this.bind();
      };
      if (document.readyState === "complete" || document.readyState === "interactive") {
        this.bind();
      }
    }
  }

  bind() {
    const $ = window.$$;
    const elem = this.refs.quantityBox;
    $('<div class="pt_QuantityNav"><div class="pt_QuantityButton pt_QuantityUp">+</div><div class="pt_QuantityButton pt_QuantityDown">-</div></div>').insertAfter(".pt_Quantity input");

    $(elem).each(function () {
      var spinner = $(this),
        input = spinner.find('input[type="number"]'),
        btnUp = spinner.find(".pt_QuantityUp"),
        btnDown = spinner.find(".pt_QuantityDown"),
        min = input.attr("min"),
        max = input.attr("max"),
        valOfAmout = input.val(),
        newVal = 0;

      btnUp.on("click", function () {
        var oldValue = parseFloat(input.val());

        if (oldValue >= max) {
          var newVal = oldValue;
        } else {
          var newVal = oldValue + 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
      });
      btnDown.on("click", function () {
        var oldValue = parseFloat(input.val());
        if (oldValue <= min) {
          var newVal = oldValue;
        } else {
          var newVal = oldValue - 1;
        }
        spinner.find("input").val(newVal);
        spinner.find("input").trigger("change");
      });
    });
  }

  render() {
    const { name, selectedValue, disabled, min } = this.state;

    return (
      <div ref="quantityBox" className="pt_Quantity">
        <input disabled={disabled} defaultValue={selectedValue} ref={name} name={name} type="number" min={min} max="100" step="1" data-inc="1" />
      </div>
    );
  }
}