import {
  _dispatch, TESTIMONIAL_LIST_SUCCESS, HOME_AD_LIST_SUCCESS
} from '../actions/testimonial';

const INITIAL_STATE = { testimoniallist: [], homeadlist:[], status:null };

export default function(state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch(action.type) {

    case TESTIMONIAL_LIST_SUCCESS:
      return (() => {
        const testimoniallist = action.payload;
        return _dispatch({ ...state, testimoniallist}, true, 'testimonials');
      })();

    case HOME_AD_LIST_SUCCESS:
      return (() => {
        const homeadlist = action.payload;
        return _dispatch({ ...state, homeadlist}, true);
      })();

    default:
      return state;
  }
}