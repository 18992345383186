import React, { Component } from 'react';
import InternationalFeaturedDestination from './InternationalFeaturedDestination';
// import DomesticFeaturedDestination from './DomesticFeaturedDestination';

export default class FeaturedDestination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activate: "international"
    };
    // this.toggle = this.toggle.bind(this);
  }

  // toggle(activate) {
  //   this.setState({
  //     activate
  //   });
  // }

  render() {
    const { activate } = this.state;

    return (
      <section className="featured-block">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="featured-main-block">
                <div className="heading-block">
                  <h3 className="title">Featured Destinations</h3>
                  <p className="sub-title">The selection of best destinations around the world to travel </p>
                </div>
                {/* <ul className="portfolioFilter">
                  <li className="">
                    <a onClick={(e) => this.toggle("domestic")} href="javascript: void(0);" className="india-btn domestic btn-2">INDIA</a>
                  </li>
                  <li className="selected">
                    <a onClick={(e) => this.toggle("international")} href="javascript: void(0);" className="inter-btn btn-2">INTERNATIONAL</a>
                  </li>
                </ul> */}
                {/* {
                  activate == 'domestic'
                  &&
                  <div className="domestic-block">
                    <div className="tab-heading-mob">
                      <h3> India </h3>
                    </div>
                    <DomesticFeaturedDestination />
                  </div>
                } */}
                {
                  activate == 'international'
                  &&
                  <div className="international-block">
                    <div className="tab-heading-mob">
                      <h3> international </h3>
                    </div>
                    <InternationalFeaturedDestination />
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}