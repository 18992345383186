import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {ROUTES} from '../../routesList';
import {currencyFormat} from '../../constants';

export default class WeekendGatewaySlide extends Component {
  constructor(props){
      super(props);
      this.state = {
        slideData: props.slideData
      }
  }
 	render() {
 		return(
 			<div className="item">
              <div className="carousel-inner">
                  <Link to={ "/package/detail/"+ this.state.slideData.slug }>
                      <figure><img src={ this.state.slideData.landscape_image } alt=""/></figure>
                  	<figcaption className="package-detail-block">
                      	<ul className="blue-btn">
                         		<li className="tag-btn">
                            		<ion-icon ios="ios-happy" md="ios-happy"></ion-icon>
                            		Weekend
                         		</li>
                         		<li>{ this.state.slideData.tags }</li>
                      	</ul>
                      	<h5>{ this.state.slideData.title }</h5>
                      	<p>{ this.state.slideData.package_name }</p>
                      	<label className="price-label">Starts from {currencyFormat(this.state.slideData.minimumprice, 'INR', false)}/-</label>
                   	</figcaption>
                  </Link>
              </div>
          </div>
 		);
 	}
}