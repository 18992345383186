import React, { Component } from 'react';
import QuotationDetailSlide from './QuotationDetailSlide';
import { currencyFormat } from '../../constants';

export default class AllQueryQuotaionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quotationDetail: props.quotationDetail,
      flight_status: props.flight_status,
      status: props.status,
      quote_index: props.quoteIndex
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.quoteIndex != this.state.quote_index) {
      this.setState({
        quote_index: nextProps.quoteIndex
      });
    }
  }

  updateQuoteIndex(quote_index) {
    this.setState({ quote_index });
    this.props.updateCurrentQuoteIndex(quote_index);
  }

  render() {
    const { quote_index, quotationDetail, flight_status } = this.state;

    return (
      <div className="quotation-tab-block">
        <ul id="tabs" className="nav nav-tabs" role="tablist">
          {
            quotationDetail
            &&
            quotationDetail.length > 0
            &&
            quotationDetail.map((detail, index) => (
              <li className="nav-item" key={index}>
                <a id={"tab-" + index} href={"#pane-" + index} className={index == quote_index ? 'nav-link active' : 'nav-link'} data-toggle="tab" role="tab" onClick={(e) => this.updateQuoteIndex(index)}>
                  <h4>Quotation {index + 1}</h4>
                  <p>
                    {currencyFormat(parseInt(detail.total_package_price), detail.currency)} ({detail.price_type})</p>
                </a>
              </li>
            ))
          }
        </ul>

        {
          quotationDetail
          &&
          <QuotationDetailSlide quotationDetail={quotationDetail[quote_index]} quote_index={quote_index} flight_status={flight_status} />
        }
      </div>
    );
  }
}