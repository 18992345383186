import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routesList';
import UserCoTraveler from './UserCoTraveler';

export default class UserProfile extends Component {
  render() {
    return (
      <div className="dashboard-block my-profile-block">
        <div className="ui-blocks-main">
          <div className="uiblock-head">
            <h3>MY ACCOUNT DETAILS</h3>
          </div>
          <div className="ui-block-content">
            <div className="profile-complete-block">
              <div className="row">
                <div className="col-sm-8">
                  <div className="profile-complete-left">
                    <figure>
                      <img src="/images/user-big-img.png" alt="" />
                    </figure>
                    <figcaption>
                      <h4 className="name-user">Chinelo Chyke</h4>
                      <label className="email-user">Chinelo234@gmail.com</label>
                      <div className="profile-complte-status">
                        <span className="progress-bar-line"></span>
                        <i className="percentage-calculate">32%</i>
                      </div>
                      <a href="javascript:void(0)" className="note-complete-profile">Complete your profile</a>
                    </figcaption>
                  </div>
                </div>
                <div className="col-sm-4">
                  <Link className="edit-profile-btn n-blue-btn-icon" to="/dashboard/editprofile"><img src="images/edit-icon.svg" alt="" />EDIT PROFILE</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <UserCoTraveler />
      </div>
    );
  }
}