import React, { Component } from 'react';
import { connect } from 'react-redux';
import SocialLogin from './SocialLogin';
import Error from './components/common/Error';
import { verify } from './actions/auth';
import ForgotPasswordModal from './ForgotPasswordModal';

class LoginForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      status: props.status,
      errors: {},
      passwordShow: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  showModal() {
    window.getFooter().setState({
      renderElement: <ForgotPasswordModal onHide={this.hideModal.bind(this)} />
    });
  }

  hideModal() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  /**
   * Submit login form
   */
  submit(e) {
    e.preventDefault();
    const { email, password } = this.state;

    // Verify login credentials and login on success
    this.props.verify({ email, password });
    document.body.style = null;
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status != this.state.status && nextProps.compName == 'login-error') {
      if (Object.keys(nextProps.errors).length > 0) {
        this.setState({
          errors: nextProps.errors,
          status: nextProps.status
        });
      }
    }
  }

  render() {
    const { errors } = this.state;

    return (
      <div>
        <div className="top-block">
          <SocialLogin />
        </div>
        <div className="border-block">
          <span>OR</span>
        </div>
        <div className="middle-content">
          <form onChange={this.handleChange}>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">EMAIL</label>
              <input autoFocus name="email" type="email" className="form-control" placeholder="you@example.com" />
              {typeof errors.email !== 'undefined' && <Error text={errors.email} />}
            </div>
            <div className="form-group">
              <label htmlFor="exampleInputEmail1">PASSWORD</label>
              <a href="javascript: void(0);" className="fp-block" onClick={this.showModal.bind(this)}>Forgot Password?</a>
              <div className="pw-field">
                <input name="password" type={this.state.passwordShow ? "text" : "password"} className="form-control" placeholder="**********" />
                <span className="show-pd"><ion-icon ios="ios-eye-off" md="md-eye-off" onClick={() => this.setState({ passwordShow: !this.state.passwordShow })}></ion-icon></span>
                {typeof errors.password !== 'undefined' && <Error text={errors.password} />}
              </div>
            </div>
            <button type="submit" className="btn cta-btn" onClick={this.submit.bind(this)}>Login</button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    status: state.Auth.status,
    compName: state.Auth.compName,
    errors: state.Auth.errors,
    auth: state.Auth.auth
  }
}

export default connect(mapStatesToProps, { verify })(LoginForm);