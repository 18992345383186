import React, { Component } from 'react';
import { connect } from 'react-redux';

class BookedQuoteForm extends Component {
  constructor(props){
    super(props);
    this.hideForm = this.hideForm.bind(this);
  }

  hideForm(){
    document.body.className = '';
    window.getFooter().setState({
        renderElement: null
    });
  }
  
	render() {
      return ( 
          <div className="popup-infobox">
            <p>Hey {this.props.username},</p>
            <p>Your invoice is getting ready. Will update you soon!.</p>
            <button className="btn n-blue-btn" onClick={this.hideForm.bind(this)}>Ok</button>
            <button className="btn n-blue-btn">Call Trip Advisor</button>
          </div>
    	);
    }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    username: state.Auth.user.name
  }
}


export default connect(mapStatesToProps, null)(BookedQuoteForm);