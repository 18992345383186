import React, { Component } from 'react';

class CityListSlide extends Component {
	render() {
    	return (
        	<li>
            <input type="checkbox" id="city1" checked />
            <label for="city1">Dubai</label>
          </li>
    	);
    }
}

export default CityListSlide;