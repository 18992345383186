import React, { Component } from 'react';
import GalleryCarousel from '../common/GalleryCarousel';
import {shortDayNames} from '../../constants';
import {currencyFormat} from '../../constants';

export default class DestinationGuidePopularPlaceDetail extends Component {
  
	render() {
      const {data} = this.props;
    	return (
    	   <div className="vd-inner-block">
            <div className="vd-left-block">
              {
                typeof data.gallery !== 'undefined' && data.gallery.length > 0
                &&
                <div className="vd-slider-block">
                    <GalleryCarousel 
                      data={data.gallery} 
                      sliderId="jmodal-slide" 
                      carouselId="jmodal-thumbanil"
                      config={
                        {
                          slider: {
                              animation: "slide",
                              controlNav: false,
                              animationLoop: false,
                              slideshow: false,
                              itemWidth: 96,
                              itemMargin: 15,
                              maxItems: 6
                            },
                          carousel: {
                              animation: "slide",
                              controlNav: true,
                              animationLoop: false,
                              sync: "#jmodal-thumbanil"
                            }
                        }
                      } 
                    />
                 </div>
               }

               {
                <div class="address-block">
                    <div class="top-block">
                       <ul>
                          <li>
                             <label>Address</label>
                             <p>{data.address}</p>
                          </li>
                          {
                            typeof data.timings !== 'undefined' && data.timings.length > 0
                            &&
                            <li>
                               <label>Timings</label>
                               <p>
                                  {
                                    data.timings.map((item, index) => {
                                        return <span key={index}>{item.from_time} to {item.to_time} ({shortDayNames[item.day_from]}-{shortDayNames[item.day_to]})</span>
                                    })
                                  }
                               </p>
                            </li>
                          }
                          
                       </ul>
                    </div>
                    <div class="bottom-block">
                       <ul>
                          {
                            typeof data.timings !== 'undefined' && data.timings.length > 0
                            &&
                            <li>
                               <label>Entry Fees</label>
                               <p>
                                  {
                                    data.timings.map((item, index) => {
                                        return <span key={index}>{typeof item.fee === 'undefined' || !item.fee ? "Free" : `${currencyFormat(item.fee, 'INR')}/per person`}</span>
                                    })
                                  }
                               </p>
                            </li>
                          }
                          <li>
                             <label>Suitable for</label>
                             <p>{typeof data.suitable_for === 'undefined' || data.suitable_for == '' ? "Everyone" : data.suitable_for}</p>
                          </li>
                       </ul>
                    </div>
                 </div>
               }

               {
                 typeof data.top_sights !== 'undefined' && data.top_sights != ''
                 &&
                 <div className="detail-block">
                    <h5>Top Sights in {data.name}</h5>
                    <p dangerouslySetInnerHTML={{__html: data.top_sights}}></p>
                 </div>
               }

               {
                 typeof data.description !== 'undefined' && data.description != ''
                 &&
                 <div className="detail-block">
                    <h5>About the place</h5>
                    <p dangerouslySetInnerHTML={{__html: data.description}}></p>
                 </div>
               }

               {
                 typeof data.how_to_reach !== 'undefined' && data.how_to_reach != ''
                 &&
                 <div className="detail-block">
                    <h5>How To Reach</h5>
                    <ul>
                       <li dangerouslySetInnerHTML={{__html: data.how_to_reach}}></li>
                    </ul>
                 </div>
               }
               
               {
                 typeof data.tips !== 'undefined' && data.tips != ''
                 &&
                 <div className="detail-block vd-listing-block">
                    <h5>Tips for {data.name}</h5>
                    <p>Here are some important tips and useful information about {data.name}.</p>
                    <ul>
                      <li dangerouslySetInnerHTML={{__html: data.tips}}></li>
                    </ul>
                 </div>
               }
               
               {
                 typeof data.extra_activities !== 'undefined' && data.extra_activities != ''
                 &&
                 <div className="detail-block">
                    <h5>Extras Activities</h5>
                    <div dangerouslySetInnerHTML={{__html: data.extra_activities}}></div>
                 </div>
               }
            </div>
          </div>
	   	);
    }
}