import React, { Component } from 'react';
import {currencyFormat} from './constants';
import { Link } from 'react-router-dom';

export default class QuoteInvoiceBlock extends Component {

  constructor(props){
    super(props);

    this.state = {
      quoteIndex: 0
    };
  }

  componentWillMount(){
    const {data, quotes} = this.props;
    quotes.map((item, index) => {
      if(parseInt(item.id) === parseInt(data.quotation_id)){
        this.setState({
          quoteIndex: index
        });
      } 
    }); 
  }

  gotoQuote(){
    this.props.updateCurrentQuoteIndex(this.state.quoteIndex);
  }

  render() {
      const {data} = this.props;
      return (
        <div className="invoice-generate-block">
            <div className="top-heading-block">
            <h4>Your Invoice is Ready</h4>
            <div className="btn-group invoice-btn">
                <Link to={"/trip_invoice/" + data.quote_id } className="btn outline-btn"> View Invoice</Link>
                <a href="javascript: void(0);" className="btn fill-btn">
                  <ion-icon name="download" role="img" className="hydrated" aria-label="download"></ion-icon>Download PDF
                </a>
            </div>
        </div>
            <div className="invoice-content-block">
                <div className="left-block">
                    <label>Grand Total</label>
                    <span className="price">{currencyFormat(data.total_price, data.currency)}/-</span>
                    <p>{data.finalduration}</p>
                    <a href="javascript: void(0);" className="view-quote-link" onClick={this.gotoQuote.bind(this)}> View Quote</a>
                </div>
                {
                  parseInt(data.installment_due) > 0
                  ?
                  <div className="right-block">
                      <label>Current Installment <span>({data.currentinstallment})</span></label>
                      <span className="due-date-block">Due Date : {data.duedate}</span>
                      <span class="price">{currencyFormat(data.installment_due, data.currency)}/-</span>
                  </div>
                  :
                  <div className="right-block">
                      <span className="due-date-block">No Dues</span>
                      <span class="price">All Paid</span>
                  </div>
                }
            </div>

        </div>  
      );
    }
}