import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import DestinationsReducer from './reducer_destinations';
import HolidayTypeReducer from './reducer_holidayType';
import PackagesReducer from './reducer_packages';
import TestimonialReducer from './reducer_testimonial';
import DestinationPackageReducer from './reducer_destinationPackage';
import DestinationGuideReducer from './reducer_destinationGuide';
import QuotationsReducer from './reducer_quotations';
import AuthReducer from './reducer_auth';
import CustomPackage from './reducer_customPackage';
import NavigationReducer from './reducer_navigation';
import BlogReducer from './reducer_blog';
import TravelStoryReducer from './reducer_travelstory';
import FaqReducer from './reducer_faq';

const appReducer = combineReducers({
  destinations: DestinationsReducer,
  holidayTypes: HolidayTypeReducer,
  packages: PackagesReducer,
  testimonial: TestimonialReducer,
  destinationPackage: DestinationPackageReducer,
  destinationGuide: DestinationGuideReducer,
  quotations:QuotationsReducer,
  navigation: NavigationReducer,
  blog: BlogReducer, 
  Auth: AuthReducer,
  travelotfaq: FaqReducer,
  travelstory: TravelStoryReducer,
  CustomPackage
});

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer;