import React, { Component } from 'react';

class ThemeMobileFilterBlock extends Component {
  render() {
    return (
      <section className="mf-block">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <ul className="filter-listing">
                <li id="pacakage">Package Type </li>
                <li id="price"> Price </li>
                <li id="more-filter">More filter </li>
              </ul>
              <div className="mobile-filter-wrap">
                <div className="close-block">
                  <div className="close-btn">&times;</div>
                  <div className="clear-btn">Clear</div>
                </div>
                <div className="content-block pacakge-type" >
                  <div className="sidebar-heading-block">
                    <label>Package Type</label>
                  </div>
                  <div className="inner-content ">
                    <ul className="checkbox-listing i-block">
                      <li>
                        <input type="checkbox" id="pacakage01" checked />
                        <label htmlFor="pacakage01">India</label>
                      </li>
                      <li>
                        <input type="checkbox" id="pacakage02" />
                        <label htmlFor="pacakage02">International</label>
                      </li>
                    </ul>
                    <a href="javascript:;" className="apply-bttn"> Apply</a>
                  </div>
                </div>
                {/* <div className="content-block budget-tab">
	                           		<div className="card">
	                              		<div className="sidebar-heading-block">
	                                 		<a className="card-link" data-toggle="collapse" href="#Budget" aria-expanded="true" aria-controls="menuone">Budget Per Person <span>(in Rs.)</span>
	                                 		</a>
	                              		</div>
	                              		<div id="Budget" className="collapse show">
	                                 		<div className="inner-content">
	                                    		<div className="custom-range-slider">
	                                       			<label className="range-heading">Choose Price Range</label>
	                                       			<input type="text" id="range_06" name="example_name" value="" />
	                                    		</div>
	                                 		</div>
	                              		</div>
	                              		<a href="javascript:;" className="apply-bttn"> Apply</a>
	                           		</div>
	                        	</div> */}
                <aside className="sidebar-block filter-sidebar ">
                  <div className="content-block category-tab">
                    <div className="card">
                      <div className="sidebar-heading-block">
                        <a className="card-link" data-toggle="collapse" href="#menuone1" aria-expanded="true" aria-controls="menuone">
                          Categories
	                                       <ion-icon ios="ios-arrow-down" md="ios-arrow-down"></ion-icon>
                        </a>
                        <div className="select-element">Honeymoon</div>
                      </div>
                      <div id="menuone1" className="collapse show">
                        <div className="inner-content">
                          <ul className="checkbox-listing">
                            <li>
                              <input type="checkbox" id="category01" checked />
                              <label htmlFor="category01">Honeymoon</label>
                            </li>
                            <li>
                              <input type="checkbox" id="category02" />
                              <label htmlFor="category02">Family</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="content-block detination-tab">
                    <div className="card">
                      <div className="sidebar-heading-block">
                        <a className="card-link" data-toggle="collapse" href="#destination1" aria-expanded="true" aria-controls="menuone">
                          Destination
	                                       <ion-icon ios="ios-arrow-down" md="ios-arrow-down"></ion-icon>
                        </a>
                        <div className="select-element">All Destinations</div>
                      </div>
                      <div id="destination1" className="collapse show">
                        <div className="inner-content">
                          <div className="search-field">
                            <input type="text" placeholder="Search Destination" />
                          </div>
                          <ul className="checkbox-listing">
                            <li>
                              <input type="checkbox" id="destination01" checked />
                              <label htmlFor="destination01">All Destinations</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination02" />
                              <label htmlFor="destination02">Andaman Islands</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination03" />
                              <label htmlFor="destination03">Assam</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination04" />
                              <label htmlFor="destination04">Dharamsala</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination05" />
                              <label htmlFor="destination05">Sikkim</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination06" />
                              <label htmlFor="destination06">Shimla</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination07" />
                              <label htmlFor="destination07">Goa</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination08" />
                              <label for="destination08">Kerala</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination09" />
                              <label htmlFor="destination09">Hyderabad</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination010" />
                              <label htmlFor="destination010">Manali</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination011" />
                              <label htmlFor="destination011">Kullu</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination012" />
                              <label htmlFor="destination12">Mumbai</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination013" />
                              <label htmlFor="destination013">Sikkim</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination014" />
                              <label htmlFor="destination014">Shimla</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination015" />
                              <label htmlFor="destination015">Goa</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination016" />
                              <label htmlFor="destination016">Kerala</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination017" />
                              <label htmlFor="destination017">Hyderabad</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination018" />
                              <label htmlFor="destination018">Manali</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination019" />
                              <label htmlFor="destination019">Kullu</label>
                            </li>
                            <li>
                              <input type="checkbox" id="destination020" />
                              <label htmlFor="destination020">Mumbai</label>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="content-block duration-tab">
	                              <div className="card">
	                                 <div className="sidebar-heading-block">
	                                    <a className="card-link" data-toggle="collapse"  href="#Duration1" aria-expanded="true" aria-controls="menuone">
	                                       Duration (in days)
	                                       <ion-icon ios="ios-arrow-down" md="ios-arrow-down"></ion-icon>
	                                    </a>
	                                    <div className="select-element">1-3 Days</div>
	                                 </div>
	                                 <div id="Duration1" className="collapse show">
	                                    <div className="inner-content">
	                                       <ul className="duration-days">
	                                          <li className="active"><a href="javascript:;">1-3 Days</a></li>
	                                          <li><a href="javascript:;">4-6</a></li>
	                                          <li><a href="javascript:;">7-9</a></li>
	                                          <li><a href="javascript:;">10-12</a></li>
	                                          <li><a href="javascript:;">More than 12</a></li>
	                                       </ul>
	                                    </div>
	                                 </div>
	                              </div>
	                           </div> */}
                  <div className="content-block month-tab">
                    <div className="card">
                      <div className="sidebar-heading-block">
                        <a className="card-link" data-toggle="collapse" href="#month1" aria-expanded="true" aria-controls="menuone">
                          Month of travel
	                                       <ion-icon ios="ios-arrow-down" md="ios-arrow-down"></ion-icon>
                        </a>
                        <div className="select-element">JANUARY</div>
                      </div>
                      <div id="month1" className="collapse show">
                        <div className="inner-content">
                          <ul className="month-listing">
                            <li className="active"><a href="javascript:;">jan</a></li>
                            <li><a href="javascript:;">Feb</a></li>
                            <li><a href="javascript:;">Mar</a></li>
                            <li><a href="javascript:;">Apr</a></li>
                            <li><a href="javascript:;">May</a></li>
                            <li><a href="javascript:;">Jun</a></li>
                            <li><a href="javascript:;">Jul</a></li>
                            <li><a href="javascript:;">Aug</a></li>
                            <li><a href="javascript:;">Sep</a></li>
                            <li><a href="javascript:;">Oct</a></li>
                            <li><a href="javascript:;">Nov</a></li>
                            <li><a href="javascript:;">Dec</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="content-block hotel-tab">
	                              <div className="card">
	                                 <div className="sidebar-heading-block">
	                                    <a className="card-link" data-toggle="collapse"  href="#hotel-type1" aria-expanded="true" aria-controls="menuone">
	                                       Hotel Type
	                                       <ion-icon ios="ios-arrow-down" md="ios-arrow-down"></ion-icon>
	                                    </a>
	                                    <div className="select-element">
	                                       <ion-icon ios="ios-star" md="ios-star"></ion-icon>
	                                       4 & 5 Stars
	                                    </div>
	                                 </div>
	                                 <div id="hotel-type1" className="collapse show">
	                                    <div className="inner-content">
	                                       <ul className="checkbox-listing rating-listing">
	                                          <li>
	                                             <input type="checkbox" id="rating01" checked />
	                                             <label htmlFor="rating01">
	                                                <div className="rating-block">
	                                                   <ion-icon ios="ios-star" md="ios-star"></ion-icon>
	                                                   <ion-icon ios="ios-star" md="ios-star"></ion-icon>
	                                                   <ion-icon ios="ios-star" md="ios-star"></ion-icon>
	                                                   <ion-icon ios="ios-star" md="ios-star"></ion-icon>
	                                                   <ion-icon ios="ios-star" md="ios-star"></ion-icon>
	                                                   <span>5 Star </span>
	                                                </div>
	                                             </label>
	                                          </li>
	                                          <li>
	                                             <input type="checkbox" id="rating02" checked />
	                                             <label htmlFor="rating02">
	                                                <div className="rating-block">
	                                                   <ion-icon ios="ios-star" md="ios-star"></ion-icon>
	                                                   <ion-icon ios="ios-star" md="ios-star"></ion-icon>
	                                                   <ion-icon ios="ios-star" md="ios-star"></ion-icon>
	                                                   <ion-icon ios="ios-star" md="ios-star"></ion-icon>
	                                                   <span>4 Star </span>
	                                                </div>
	                                             </label>
	                                          </li>
	                                          <li>
	                                             <input type="checkbox" id="rating03" />
	                                             <label htmlFor="rating03">
	                                                <div className="rating-block">
	                                                   <ion-icon ios="ios-star" md="ios-star"></ion-icon>
	                                                   <ion-icon ios="ios-star" md="ios-star"></ion-icon>
	                                                   <ion-icon ios="ios-star" md="ios-star"></ion-icon>
	                                                   <span>3 Star </span>
	                                                </div>
	                                             </label>
	                                          </li>
	                                          <li>
	                                             <input type="checkbox" id="rating04" />
	                                             <label htmlFor="rating04">
	                                                <div className="rating-block">
	                                                   <ion-icon ios="ios-star" md="ios-star"></ion-icon>
	                                                   <ion-icon ios="ios-star" md="ios-star"></ion-icon>
	                                                   <span>2 Star </span>
	                                                </div>
	                                             </label>
	                                          </li>
	                                       </ul>
	                                    </div>
	                                 </div>
	                              </div>
	                           </div> */}
                  <a href="javascript:;" className="apply-bttn "> Apply</a>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ThemeMobileFilterBlock;