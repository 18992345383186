import React, { Component } from 'react';
import { connect } from 'react-redux';
import BestCurrentMonthPlacesSlide from './BestCurrentMonthPlacesSlide';
import { currentMonthBestDestinationList, currentMonthBestDestinationListSuccess } from '../../actions/destinations';
import { months } from '../../constants';
import { Link } from 'react-router-dom';

class BestCurrentMonthPlaces extends Component {
  constructor(props) {
    super(props);
    this.bindSlider = this.bindSlider.bind(this);
    this.state = {
      currentMonthDestinations: props.currentMonthDestinations,
      totalPlaces: props.totalPlaces,
      status: props.status
    };
  }

  componentWillMount() {
    this.props.currentMonthBestDestinationList().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.currentMonthBestDestinationListSuccess(response.value.data.payload, response.value.data.total_places);
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status && nextProps.compName == 'best-places-current-month') {
      this.setState({
        currentMonthDestinations: nextProps.currentMonthDestinations,
        totalPlaces: nextProps.totalPlaces,
        status: nextProps.status
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.status == this.state.status)
      return;
    window.addEventListener('load', this.bindSlider);
    if (document.readyState === "complete" || document.readyState === "interactive") {
      this.bindSlider();
    }
  }

  bindSlider() {
    const $ = window.$$;
    if ($(window).width() > 991) {
      $(this.refs.packageSlides).owlCarousel({
        loop: false,
        margin: 15,
        pagination: false,
        nav: true,
        navText: ["<img src='images/slider_icon_next.svg'/> ", "<img src='images/slider_icon_next.svg'/> "],
        responsive: {
          0: {
            items: 1.2
          },
          400: {
            items: 1.4
          },
          500: {
            items: 2.2
          },
          768: {
            items: 3
          },
          1000: {
            items: 4
          }
        }
      });
    }
  }

  render() {
    const mth = parseInt(new Date().getMonth()) + 1;
    return (
      <section className="selling-block best-places">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="selling-inner-block">
                <div className="heading-block">
                  <h3 className="title">Best Places to visit in {months[mth]}</h3>
                  <p className="sub-title">Take your pick from these handcrafted holidays and set out to explore the world.</p>
                </div>
                <div className="gallery-block">
                  <div className="owl-carousel owl-theme package-slides" ref="packageSlides">
                    {
                      this.state.currentMonthDestinations && this.state.currentMonthDestinations.length > 0 &&
                      this.state.currentMonthDestinations.map((obj, index) => (
                        <BestCurrentMonthPlacesSlide key={index} slideData={obj} />
                      ))
                    }
                  </div>
                </div>
                <div className="view-all pos-top">
                  <Link to={"/destination?month[]=" + mth} className="n-blue-btn"> Show ALL ({this.state.totalPlaces}) <img src="/images/button_arrow.svg" alt="" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    currentMonthDestinations: [...state.destinations.currentmonthbestdestination],
    totalPlaces: state.destinations.total_places,
    status: state.destinations.status,
    compName: state.destinations.compName
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    currentMonthBestDestinationList: () => {
      return dispatch(currentMonthBestDestinationList());
    },
    currentMonthBestDestinationListSuccess: (payload, total_places) => {
      dispatch(currentMonthBestDestinationListSuccess(payload, total_places));
    },

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(BestCurrentMonthPlaces);