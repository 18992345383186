import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import ThemeMobileFilterBlock from './components/partials/ThemeMobileFilterBlock';
import DestinationPackageBasicInfo from './components/package/DestinationPackageBasicInfo';
import DestinationThemePackageBlock from './components/package/DestinationThemePackageBlock';
import { Link } from 'react-router-dom';
import { ROUTES } from './routesList';
import { connect } from 'react-redux';
import Loader from './components/common/loader';
import { destinationInfoList, destinationInfoListSuccess, resetFilters } from './actions/destinationPackage';
const queryString = require('query-string');

class DestinationPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destinationName: this.props.match.params.index,
      loading: true,
    };
  }

  componentWillMount() {
    let filters = {};
    if (this.props.location.search != '') {
      filters = queryString.parse(this.props.location.search, { arrayFormat: 'bracket' });
      this.props.resetFilters(filters);
    }
    this.props.destinationInfoList({ to_destination: this.props.match.params.index }).then((response) => {
      if (response.value.data.result === 'success') {
        this.setState({ loading: false });
        this.props.destinationInfoListSuccess(response.value.data.payload);
      }
    });
  }

  // Update URL query string with filter change
  componentWillReceiveProps(nextProps) {
    if (nextProps.compName == 'filters' && nextProps.location === this.props.location) {
      this.props.history.push({
        pathname: nextProps.location.pathname,
        search: "?" + window.$$.param(nextProps.filters)
      });
    } else if (this.props.match.params.index != nextProps.match.params.index) {
      this.props.resetFilters({}, false);
      this.setState({ loading: true });
      this.props.destinationInfoList({ to_destination: nextProps.match.params.index }).then((response) => {
        if (response.value.data.result === 'success') {
          this.props.destinationInfoListSuccess(response.value.data.payload);
          this.setState({
            destinationName: nextProps.match.params.index, 
            loading: false 
          });
        }
      });
    }
  }

  onOpenFilter() {
    let element = document.getElementById("filterToShow");
    ReactDOM.findDOMNode(element).classList.add("activefilter");
  }

  render() {
    const { destinationName, loading } = this.state;

    return (
      <Fragment>
        {
          loading ? <Loader /> :
            <div className="main-body-content">
              {/* <ThemeMobileFilterBlock /> */}
              <section className="mf-block">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <ul className="filter-listing">
                        <li onClick={this.onOpenFilter}>Filters</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>
              <DestinationPackageBasicInfo destinationName={destinationName} />
              <DestinationThemePackageBlock destinationName={destinationName} />
            </div>
        }
      </Fragment>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    compName: state.destinationPackage.compName,
    filters: { ...state.destinationPackage.filters }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    destinationInfoList: (params) => {
      return dispatch(destinationInfoList(params));
    },
    destinationInfoListSuccess: (payload) => {
      dispatch(destinationInfoListSuccess(payload));
    },
    resetFilters: (filters, fetchRecords) => {
      dispatch(resetFilters(filters, fetchRecords));
    }
  };
}
export default connect(mapStatesToProps, mapDispatchToProps)(DestinationPackage);