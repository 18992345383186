import React, { Component } from 'react';
import TripItem from './TripItem';

export default class PastCancelTrips extends Component {

  constructor(props){
    super(props);
    this.state = {
      data: typeof props.data === 'undefined' ? [] : props.data
    };
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      data: nextProps.data
    });
  }

	render() {
      const {data} = this.state;
      if(!data.length)
        return null;

    	return (
        	<div className="block-profile-trips">
            <h3>PAST CANCELLED TRIPS</h3>
              {
                data.map((item, index) => {
                    return <TripItem key={index} data={item} />
                })
              }
          </div>
        );
    }
}