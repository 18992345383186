import {
  _dispatch, HOLIDAY_TYPE_LIST_LIMIT_SUCCESS, HOLIDAY_TYPE_LIST_WITH_DESTINATION_SUCCESS, HOLIDAY_TYPE_LIST_WITH_PACKAGE_SUCCESS, UPDATE_FILTERS_THEME, RESET_FILTERS_THEME, HOLIDAY_TYPE_LIST_SUCCESS, HOLIDAY_TYPE_LIST_SUMMARY, HOLIDAY_TYPE_LIST_SUMMARY_PENDING, HOLIDAY_TYPE_LIST_SUMMARY_FULFILLED
} from '../actions/holidayType';

const INITIAL_STATE = { holidaytypes: [], holidaytypesdestinations: { destinations: [] }, holidaytypespackages: [], filters: {}, holidaytypelist: [], holidaytypesummary: [], status: null };

export default function (state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch (action.type) {

    case HOLIDAY_TYPE_LIST_LIMIT_SUCCESS:
      return (() => {
        const holidaytypes = action.payload;
        const totalthemes = action.total;
        return _dispatch({ ...state, holidaytypes, totalthemes }, true, 'packages');
      })();

    case HOLIDAY_TYPE_LIST_WITH_PACKAGE_SUCCESS:
      return (() => {
        const holidaytypespackages = action.payload;
        return _dispatch({ ...state, holidaytypespackages }, true, 'info');
      })();

    case HOLIDAY_TYPE_LIST_WITH_DESTINATION_SUCCESS:
      return (() => {
        const holidaytypesdestinations = action.payload;
        return _dispatch({ ...state, holidaytypesdestinations }, true, 'info');
      })();

    case HOLIDAY_TYPE_LIST_SUMMARY:
      return (() => {
        const holidaytypesummary = action.payload;
        return _dispatch({ ...state, holidaytypesummary }, true, 'info');
      })();

    case HOLIDAY_TYPE_LIST_SUMMARY_PENDING:
      return (() => {
        return _dispatch({ ...state }, false);
      })();

    case HOLIDAY_TYPE_LIST_SUMMARY_FULFILLED:
      return (() => {
        return _dispatch({ ...state }, true, 'summary');
      })();

    case UPDATE_FILTERS_THEME:
      return (() => {
        const filters = { ...state.filters, ...action.payload }
        return _dispatch({ ...state, filters }, true, 'filters');
      })();

    case RESET_FILTERS_THEME:
      return (() => {
        let filters = action.payload;
        const { slugRequired } = action;
        if ((typeof slugRequired === 'undefined' || slugRequired === true) && typeof state.filters.slug !== 'undefined') {
          // filters = { ...state.filters, ...filters };
          filters = [];
        }
        return _dispatch({ ...state, filters }, true, 'filters');
      })();

    case HOLIDAY_TYPE_LIST_SUCCESS:
      return (() => {
        const holidaytypelist = action.payload;
        return _dispatch({ ...state, holidaytypelist }, true, 'info');
      })();

    default:
      return state;
  }
}