import {
  _dispatch,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  FORGOTPASSWORD_SUCCESS,
  FORGOTPASSWORD_ERROR,
  UPLOAD_PHOTO_SUCCESS,
  UPLOAD_PHOTO_ERROR,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ERROR,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
  SAVE_PREFERENCES_SUCCESS,
  SAVE_PREFERENCES_ERROR,
  DASHBOARD_SUCCESS,
  QUOTES_SUCCESS,
  ADD_TO_WISHLIST_SUCCESS
} from '../actions/auth';

const INITIAL_STATE = { auth: false, token: null, errors: {}, saved_package: [], user: {}, holiday_preferences: {}, dashboard: {dashboard_basic_info: {}, upcoming_trips: [], past_trips: []}, quotes: {past_query: [], new_query: []} };

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case LOGIN_SUCCESS:
      return _dispatch({ ...state, auth: true, errors: {}, token: action.payload.token, user: action.payload.user, holiday_preferences: action.payload.holiday_preferences, saved_package: action.payload.saved_package}, true, 'login');

    case LOGIN_ERROR:
      return _dispatch({ ...state, errors: action.payload}, true, 'login-error');

    case SIGNUP_SUCCESS:
      return _dispatch({ ...state, errors: {}}, true, 'signup');

    case SIGNUP_ERROR:
      return _dispatch({ ...state, errors: action.payload}, true, 'signup');

    case FORGOTPASSWORD_SUCCESS:
      return _dispatch({ ...state, errors: {}}, true, 'forgotpassword');

    case FORGOTPASSWORD_ERROR:
      return _dispatch({ ...state, errors: action.payload}, true, 'forgotpassword');

    case UPLOAD_PHOTO_SUCCESS:
      return _dispatch({ ...state, user: {...state.user, profile_image: action.payload}, errors: {}}, true, 'photo');

    case UPLOAD_PHOTO_ERROR:
      return _dispatch({ ...state, errors: action.payload}, true, 'photo-error');

    case UPDATE_PROFILE_SUCCESS:
      return _dispatch({ ...state, user: {...state.user, ...action.payload}, errors: {}}, true, 'profile');

    case UPDATE_PROFILE_ERROR:
      return _dispatch({ ...state, errors: action.payload}, true, 'profile');

    case SAVE_PREFERENCES_SUCCESS:
      return _dispatch({ ...state, holiday_preferences: action.payload, errors: {}}, true, 'preferences');

    case SAVE_PREFERENCES_ERROR:
      return _dispatch({ ...state, errors: action.payload}, true, 'preferences');

    case DASHBOARD_SUCCESS:
      return _dispatch({ ...state, dashboard: action.payload}, true, 'dashboard');

    case QUOTES_SUCCESS:
      return _dispatch({ ...state, quotes: action.payload}, true, 'quotes');

    case ADD_TO_WISHLIST_SUCCESS:
      return (() => {
        const saved_package = [...state.saved_package];
        if(saved_package.indexOf(action.payload) < 0){
            saved_package.push(action.payload); 
        }
        return _dispatch({ ...state, saved_package }, true, 'wishlist-success');
      })();

    case UPDATE_PASSWORD_SUCCESS:
      return _dispatch({ ...state, user: {...state.user, ...action.payload}, errors: {}}, true, 'password');

    case UPDATE_PASSWORD_ERROR:
      return _dispatch({ ...state, errors: action.payload}, true, 'password');

    default:
      return state;
  }
}