import React, { Component } from 'react';
import TalkToUs from './TalkToUs';
import PlanMyHolidayFilter from './PlanMyHolidayFilter';

export default class PlanMyHoliday extends Component {
   	render() {
   		return(
   			<section className="holiday-form-block">
	            <div className="container">
	               	<div className="row">
	               		<PlanMyHolidayFilter />
	                  	<TalkToUs />
	               </div>
	            </div>
	        </section>
   		);
   	}
}