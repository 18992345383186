import React, { Component } from 'react';
import { connect } from 'react-redux';
import PackageItem from '../package/PackageItem';
import { fetchSavedPackages } from '../../actions/packages';

class UserSavedPackages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packages: [],
      status: props.status
    };
  }

  componentWillMount() {
    this.props.fetchSavedPackages();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status && nextProps.compName == 'savedPackages') {
      this.setState({
        packages: nextProps.savedPackages
      });
    }
  }

  render() {
    const { packages } = this.state;
    //console.log('pppp',packages.length);

    return (
      <div className="dashboard-block my-profile-block">
        <div className="ui-blocks-main">
          <div className="uiblock-head">
            <h3>SAVED PACKAGES</h3>
          </div>
          <div className="saved-packages-block">
            <div className="list-saved-packages">
              {
                packages.map((item, index) => {
                  return <PackageItem key={item.id} data={item} index={index} />
                })
              }
            </div>

            <div className="no-more-record">
              <div className="dots-load">
                <span></span>
                <span></span>
                <span></span>
              </div>
              {
                packages.length > 0
                  ?
                  <p>THAT’S ALL FOR NOW!</p>
                  :
                  <p>Saved package list is empty for now.</p>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    savedPackages: [...state.packages.savedPackages],
    status: state.packages.status,
    compName: state.packages.compName
  }
}

export default connect(mapStatesToProps, { fetchSavedPackages })(UserSavedPackages);