import React, { Component } from 'react';

import DestinationFilterList from './DestinationFilterList';

export default class DestinationFilter extends Component {
   	render() {
      	return (
      		<section className="choose-package-block">
	            <div className="container">
               		{/* destination list as per filter */}
	                <DestinationFilterList />
	            </div>
	        </section>
      	);
    }
}
