import React, { Component } from 'react';
import Autosuggest from 'react-bootstrap-autosuggest';
import { fetchAllDestinations } from '../../actions/destinations';

export default class AllDestinations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      placeholder: typeof props.placeholder !== 'undefined' ? props.placeholder : 'Choose your destination!',
      value: typeof props.selectedValue !== 'undefined' ? props.selectedValue : ''
    };
  }

  componentWillMount() {
    fetchAllDestinations().then((response) => {
      this.setState({
        options: response.data.payload.map((item) => {
          return item.name;
        })
      });
    });
  }

  render() {
    const { options, value, placeholder } = this.state;
    // console.log(this.props.index, "this.props.index")
    // console.log(typeof value, "valueeeeeeee");

    // console.log(options, "options")

    if (value === '') {
      this.setState({
        value: this.props.index
      });
    }

    if (!options.length)
      return null;

    return (
      <Autosuggest
        readOnly={this.props.realOnly ? true : false}
        value={value}
        datalist={options}
        placeholder={placeholder}
        onSelect={(data) => {
          this.setState({
            value: data
          });
        }}
      />
    );
  }
}