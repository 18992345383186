import {
  _dispatch, DESTINATION_PACKAGE_LIST_SUCCESS, DESTINATION_INFO_LIST_SUCCESS, UPDATE_FILTERS_DESTINATION, RESET_FILTERS_DESTINATION

} from '../actions/destinationPackage';

const INITIAL_STATE = { destinationpackage:[], destinationinfo:[], filters:{}, fetch_records: true, status:null };

export default function(state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch(action.type) {

    case DESTINATION_PACKAGE_LIST_SUCCESS:
      return (() => {
        const destinationpackage = action.payload;
        return _dispatch({ ...state, destinationpackage}, true, 'packages');
      })();

    case DESTINATION_INFO_LIST_SUCCESS:
      return (() => {
        const destinationinfo = action.payload;
        return _dispatch({ ...state, destinationinfo}, true, 'info');
      })();

    case UPDATE_FILTERS_DESTINATION:
    //console.log('reducer');
      return (() => {
        const filters = {...state.filters, ...action.payload}
        //console.log(filters);
        return _dispatch({ ...state, filters, fetch_records: true}, true, 'filters');
      })();

    case RESET_FILTERS_DESTINATION:
      return (() => {
        const fetch_records = typeof action.fetch_records === 'undefined' ? true : action.fetch_records;
        return _dispatch({ ...state, filters: action.payload, fetch_records}, true, 'filters');
      })();

    default:
      return state;
  }
}