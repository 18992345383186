import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export {_dispatch};

export const TESTIMONIAL_LIST = 'TESTIMONIAL_LIST';
export const TESTIMONIAL_LIST_SUCCESS = 'TESTIMONIAL_LIST_SUCCESS';

export const HOME_AD_LIST = 'HOME_AD_LIST';
export const HOME_AD_LIST_SUCCESS = 'HOME_AD_LIST_SUCCESS';

export const CONTACT_US = 'CONTACT_US';
export const CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS';


const ROOT_URL = API_ROOT;
var token = "";

export function testimonialList(params) {
  const request = axios({
    method: 'GET',
    // params: params,
    url: `${ROOT_URL}/testimonials`,
    
  });

  return {
    type: TESTIMONIAL_LIST,
    payload: request
  };
}

export function testimonialListSuccess(payload){
	return {
    	type: TESTIMONIAL_LIST_SUCCESS,
    	payload: payload
  	}
}

export function homeAdList(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/gethomeads`,
    
  });

  return {
    type: HOME_AD_LIST,
    payload: request
  };
}

export function homeAdListSuccess(payload){
  return {
      type: HOME_AD_LIST_SUCCESS,
      payload: payload
    }
}

export function contactUs(data) {
  const request = axios({
    method: 'POST',
    data,
    url: `${ROOT_URL}/contactus`,
    
  });

  return {
    type: CONTACT_US,
    payload: request
  };
}

export function contactUsSuccess(payload){
  return {
      type: CONTACT_US_SUCCESS,
      payload: payload
    }
}