import React, { Component } from 'react';
import Modal from './Modal';

export default class VerifyForgotPasswordModal extends Component {
  render() {
    return (
        <Modal 
        	id="verifyforgotpassword-modal"
            header={<div><h3>Email Sent!</h3></div>}
            body={
                <div>
                    
                    <p> 
                        We have sent you an email. Please check your inbox.
                    </p>
                </div>
            }
            footer={<p data-dismiss="modal" data-toggle="modal" data-target="#login-modal">Already have an account? <a href="javascript: void(0);">Log in</a></p>}

        />
    );
  }
}