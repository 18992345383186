import React, { Component } from 'react';
import TravelStorySlide from './TravelStorySlide';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { homeStoryList } from '../../actions/travelstory';

class TravelStories extends Component {
  constructor(props) {
    super(props);
    this.bindSlider = this.bindSlider.bind(this);
    this.state = {
      homeStory: [],
      totalStory: 0
    };
  }

  componentDidMount() {
    this.props.homeStoryList().then((response) => {
      if (response.value.data.result === 'success') {
        this.setState({
          homeStory: response.value.data.payload,
          totalStory: response.value.data.total
        });

        window.addEventListener('load', this.bindSlider);

        if (document.readyState === "complete" || document.readyState === "interactive") {
          this.bindSlider();
        }
      }
    });
  }

  bindSlider() {
    const $ = window.$$;

    $('.story-img-slider').owlCarousel({
      loop: true,
      margin: 15,
      pagination: false,
      nav: true,
      navText: ["<ion-icon name='ios-arrow-back'></ion-icon>", "<ion-icon name='ios-arrow-forward'></ion-icon>"],
      responsive: {
        0: {
          items: 1
        },
        600: {
          items: 1
        },
        1000: {
          items: 1
        }
      }
    });

    if ($('.story-slider li').length > 0) {
      $('.story-slider').cardslider({
        swipe: true,
        dots: false
      });
    }
  }

  render() {
    return (
      <section className="stories-block">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 justify-content-center align-self-center">
              <div className="heading-block">
                <label className="storie-tag">#TravelStories</label>
                <h3 className="title">Over {this.state.totalStory}+ Happy Travelers</h3>
                <p className="sub-title">Real Travelers and Real Stories because Real opinions help you to make the right choice</p>
              </div>
              <div className="view-all d-none d-lg-block">
                <Link to="/travelstories" className="orange-btn"> Show all ({this.state.totalStory}+) <img src="images/button_arrow.svg" alt="" /></Link>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12 justify-content-center align-self-center">
              <div className={`story-slider-block ${this.state.homeStory && this.state.homeStory.length > 1 ? "" : "nav_none"}`}>
                <div className="story-slider">
                  <ul>
                    {/* Travel story list in slides */}
                    {
                      this.state.homeStory && this.state.homeStory.length > 0 &&
                      this.state.homeStory.map((obj, index) => (
                        <TravelStorySlide key={index} slideData={obj} />
                      ))
                    }
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-sm-12 d-block d-lg-none">
              <div className="view-all mobile-btn">
                <Link to="/travelstories" className="orange-btn">Show all ({this.state.totalStory}+) <img src="/images/button_arrow.svg" alt="" /></Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    homeStoryList: () => {
      return dispatch(homeStoryList());
    }
  };
}

export default connect(null, mapDispatchToProps)(TravelStories);