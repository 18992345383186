import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ShareModal from '../common/ShareModal';
import Button from '../../BookingButtonStyle1';
import { removeSavedPackage } from '../../actions/packages';

class PackageItem extends Component {
  constructor(props) {
    super(props);
    this.hide = this.hide.bind(this);
    this.shareModalOpen = this.shareModalOpen.bind(this);
  }

  remove() {
    this.props.removeSavedPackage(this.props.data.id, this.props.index);
  }
  hide() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  shareModalOpen() {
    window.getFooter().setState({
      renderElement: <ShareModal toggle={this.hide} />
    });
  }

  render() {
    const { id, durationtext, minimum_price, package_name, slug } = this.props.data;

    return (
      <div className="row">
        <div className="col-sm-9">
          <div className="left-side-saved">
            <div className="rating mob-hide">
              <ion-icon ios="ios-star" md="ios-star" role="img" className="hydrated" aria-label="star"></ion-icon>
              <span>4.1</span>
            </div>
            <Link to={`/package/detail/${slug}`} className="heading2">{package_name}</Link>
            <label>{durationtext}</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="right-side-package">
            <div className="price-block">
              <p>
                <span className="start-text">Starts from</span>
                                 ₹{minimum_price}
                <span className="per-person-text">Per Person</span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="saved-packages-action">
            <Button />
            {/* <a href="javascript:void(0);" className="n-blue-btn">CUSTOMIZE & BOOK</a> */}
            <a href="javascript:void(0);" onClick={this.shareModalOpen} className="btn gray-btn-second">
              <img src="/images/share-blue.svg" alt="" />
                      		SHARE
                      	</a>
            <a href="javascript:void(0);" className="n-pink-btn" onClick={this.remove.bind(this)}>
              <img src="/images/delete-icon-pink.svg" alt="" />
                      		REMOVE
                      	</a>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(null, { removeSavedPackage })(PackageItem);