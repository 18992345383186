import React, { Component } from 'react';
import Header from './components/common/Header';
import HomeBanner from './components/partials/HomeBanner';
import FeaturedDestination from './components/destination/FeaturedDestination';
import PackageType from './components/package/PackageType';
import BestSellingPackage from './components/package/BestSellingPackage';
import HoneymoonSpecial from './components/theme/HoneymoonSpecial';
import BestCurrentMonthPlaces from './components/destination/BestCurrentMonthPlaces';
import HowItWorks from './components/common/HowItWorks';
import DestinationFilter from './components/destination/DestinationFilter';
import TravelStories from './components/stories/TravelStories';
import HomeAd from './components/partials/HomeAd';
import WeekendGateways from './components/theme/WeekendGateways';
import HalfScreenAds from './components/common/HalfScreenAds';
import RecommendedPackages from './components/package/RecommendedPackages';
import ListenLocals from './components/stories/ListenLocals';
import WhyTravelAtlas from './components/common/WhyTravelAtlas';
import BasicAnalytics from './components/partials/BasicAnalytics';
import PlanMyHoliday from './components/common/PlanMyHoliday';
import Testimonials from './components/testimonial/Testimonials';
import TravelBlogs from './components/blogs/TravelBlogs';
import FooterPanel from './components/common/FooterPanel';

class Home extends Component {
  render() {
    return (
      <div className="cs-wrapper">
        {/* start header */}
        <Header />  
        {/* end header */}

        {/* start banner */}
        <HomeBanner />  
        {/* end banner */}

        {/* start featured destination */}
        <FeaturedDestination />  
        {/* end featrued destination */}

        {/* start package type */}
        <PackageType />  
        {/* end package type */}
         
        {/* start selling package */}
        <BestSellingPackage />  
        {/* end selling package */} 
         
        {/* start honeymoon special */}
        <HoneymoonSpecial />  
        {/* end honeymoon special */}
         
        {/* start best current month places */}
        <BestCurrentMonthPlaces />  
        {/* end best current month places */} 
          
        {/* start how it works */}
        <HowItWorks />  
        {/* end how it works */}

        {/* start destination filter */}
        <DestinationFilter />  
        {/* end destination filter */}

        {/* start travel stories */}
        <TravelStories />  
        {/* end travel stories */}         
         
        {/* start home ad */}
        <HomeAd />  
        {/* end homead */}         
          
        {/* start weekend gateway panel */}
        {/* <WeekendGateways />   */}
        {/* end weekend gateway panel */}         
         
        {/* start half screen ad panel */}
        <HalfScreenAds />  
        {/* end half screen ad panel */} 

        {/* start recommended packages */}
        {/* <RecommendedPackages />   */}
        {/* end recommended packages */}

        {/* start listen locals */}
        {/* <ListenLocals />   */}
        {/* end listen locals */}
         
        {/* start why triphono */}
        <WhyTravelAtlas />  
        {/* end why triphono */}
         
        {/* start basic analytics */}
        <BasicAnalytics />  
        {/* end basic analytics */}
         
        {/* start basic analytics */}
        <PlanMyHoliday />  
        {/* end basic analytics */} 
         
        {/* start testimonials */}
        <Testimonials />  
        {/* end testimonials */}  
         
        {/* start Travel blogs */}
        <TravelBlogs />  
        {/* end Travel blogs */}  
         
        {/* start Footer panel */}
        <FooterPanel />  
        {/* end Footer panel */}
        
      </div>
    );
  }
}

export default Home;
