import React, { Component } from 'react';

class InclusionListSlide extends Component {
	render() {
    	return (
        	<li><a href="javascript:;"><i className="b-icons meal-icon"></i>Free Meals</a></li>
    	);
    }
}

export default InclusionListSlide;