import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from './Modal';
import Sidebar from './components/partials/request/Sidebar';
import StepOne from './components/partials/request/StepOne';
import StepTwo from './components/partials/request/StepTwo';
import StepThree from './components/partials/request/StepThree';
import StepFour from './components/partials/request/StepFour';
import { fetchBasicInfo, resetQuoteRequestForm } from './actions/customPackage';

class CustomizeRequestModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      basicinfo: {},
      packageId: typeof props.packageId !== 'undefined' ? props.packageId : 0,
      step: 1,
    };

    this.hide = this.hide.bind(this);
    this.updateStep = this.updateStep.bind(this);
  }

  componentWillMount() {
    fetchBasicInfo().then(response => {
      this.setState({
        basicinfo: response.data.payload
      });
    });
  }

  componentWillUnmount() {
    this.props.resetQuoteRequestForm();
  }

  updateStep(step) {
    this.setState({
      step
    });
  }

  hide() {
    this.props.toggle();
  }

  render() {
    const { step, basicinfo, packageId } = this.state;

    // console.log(basicinfo.destinations, "basicinfo.destinations");

    return (
      <div className="cutsomize-pacakage-block">
        <div className="modal-block">
          <Modal
            id="customize-modal"
            show={true}
            onHide={this.hide}
            body={
              <div>
                <Sidebar step={step} testimonial={basicinfo.testimonials} />
                {step == 1 && <StepOne index={this.props.index} theme_id={this.props.theme_id} realOnly={this.props.realOnly}
                  destinations={basicinfo.destinations} themes={basicinfo.themes} updateStep={this.updateStep} packageId={packageId} data={this.props.data} samePackage={this.props.samePackage} />}
                {step == 2 && <StepTwo updateStep={this.updateStep} />}
                {step == 3 && <StepThree activities={basicinfo.activities} updateStep={this.updateStep} />}
                {step == 4 && <StepFour themes={basicinfo.themes} updateStep={this.updateStep} />}
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

export default connect(null, { resetQuoteRequestForm })(CustomizeRequestModal);