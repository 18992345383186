import React, { Component } from 'react';
import queryString from 'query-string';
import { confirmTripRequest } from './actions/customPackage';
import { toast } from 'react-toastify';
import {autoLogin} from './actions/auth';
import {connect} from 'react-redux'; 

class ConfirmTrip extends Component {

  constructor(props){
    super(props);
    this.state = {
      auth: props.auth
    };
  }

  componentWillMount(){
    const {auth} = this.state;
    const qs = queryString.parse(this.props.location.search);
    confirmTripRequest(qs).then((response) => {
      console.log(response, auth);
      if(auth !== true){
        this.props.autoLogin(response.data.payload);
      }
      toast.success("Your trip request has been confirmed!", {
        position: toast.POSITION.TOP_RIGHT
      });
    }).catch((error) => {
      toast.error(error.response.data.error, {
        position: toast.POSITION.TOP_RIGHT
      });
    });
    // Redirect on home
    if(auth === true){
      this.props.history.push('/dashboard');
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.state.auth !== true && nextProps.auth === true){
      this.props.history.push('/dashboard');
    }
  }

  render() {
    return null;
  }
}

const mapStatesToProps = (state) => {
    return {
        auth: state.Auth.auth
    }
}

export default connect(mapStatesToProps, {autoLogin})(ConfirmTrip);