import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { filteredDomesticInternationalDestinations, filteredDomesticInternationalDestinationsSuccess } from '../../actions/destinations';
import { currencyFormat } from '../../constants';
import DestinationFilterOption from './DestinationFilterOption';

class DestinationFilterList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filteredDestinations: props.filteredDestinations,
      status: props.status,
      showBlock: 'international'
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.props.filteredDomesticInternationalDestinations().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.filteredDomesticInternationalDestinationsSuccess(response.value.data.payload);
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.status != nextProps.status && nextProps.compName == 'destinations') {
      this.setState({
        filteredDestinations: nextProps.filteredDestinations,
        status: nextProps.status
      });
    }
  }

  toggle(showBlock) {
    this.setState({ showBlock });
  }

  render() {
    const { showBlock } = this.state;

    return (
      <div className="row">

        <DestinationFilterOption filter_value={this.state.filteredDestinations.filter_custom_value} status={this.state.status} />

        <div className="col-lg-7 col-md-12 col-sm-12 d-none d-md-none d-lg-block">
          <div className="right-tab-block">
            <ul className="tabs-links">
              <li className="selected">
                <a onClick={() => this.toggle('international')} href="javascript: void(0);" className="inter-btn2 btn-2">INTERNATIONAL</a>
              </li>
              {/* <li>
                <a onClick={() => this.toggle('domestic')} href="javascript: void(0);" className="domestic2 btn-2">DOMESTIC</a>
              </li> */}
            </ul>
            {
              showBlock == 'international'
              &&
              <div className="international2-block">
                <ul className="" id="international2">

                  {
                    this.state.filteredDestinations.international_destination && this.state.filteredDestinations.international_destination.length > 0 &&
                    this.state.filteredDestinations.international_destination.map((obj, index) => (
                      <li className="destination-listing" key={index}>
                        <div className="carousel-inner">
                          <Link to={"/destinationpackage/" + obj.slug}>
                            <figure><img src={obj.landscape_image} alt={obj.destination_name} /></figure>
                            <figcaption className="package-detail-block">
                              <h5>{obj.destination_name}</h5>
                              {
                                obj.tag_line ? <p>{obj.tag_line}</p> : <p style={{ visibility: "hidden" }}>some tags</p>
                              }
                              <label className="price-label">Starts from {currencyFormat(obj.minimumprice, 'INR')}/-</label>
                            </figcaption>
                          </Link>
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </div>
            }
            {
              showBlock == 'domestic'
              &&
              <div className="domestic2-block">
                <ul className="" id="domestic2">
                  {
                    this.state.filteredDestinations.domestic_destination && this.state.filteredDestinations.domestic_destination.length > 0 &&
                    this.state.filteredDestinations.domestic_destination.map((obj, index) => (
                      <li className="destination-listing" key={index}>
                        <div className="carousel-inner">
                          <Link to={"/destinationpackage/" + obj.slug}>
                            <figure><img src={obj.landscape_image} alt={obj.destination_name} /></figure>
                            <figcaption className="package-detail-block">
                              <h5>{obj.destination_name}</h5>
                              <p>{obj.tag_line}</p>
                              <label className="price-label">Starts from {currencyFormat(obj.minimumprice, 'INR')}/-</label>
                            </figcaption>
                          </Link>
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </div>
            }
            <div className="view-all pos-top">
              <Link to={"/destination" + this.state.filteredDestinations.showallurl} className="n-blue-btn"> Show all ({this.state.filteredDestinations && this.state.filteredDestinations.total > 20 ? "20+" : this.state.filteredDestinations && this.state.filteredDestinations.total}) <img src="/images/button_arrow.svg" alt="" /></Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    filteredDestinations: { ...state.destinations.domesticinternationaldestination },
    status: state.destinations.status,
    compName: state.destinations.compName
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    filteredDomesticInternationalDestinations: () => {
      return dispatch(filteredDomesticInternationalDestinations());
    },
    filteredDomesticInternationalDestinationsSuccess: (payload) => {
      dispatch(filteredDomesticInternationalDestinationsSuccess(payload));
    },

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(DestinationFilterList);