import React, { Component } from 'react';
import DestinationThemePackageListing from './DestinationThemePackageListing';
import BreadCrumb from '../common/BreadCrumb';
import TotalResultCounter from '../common/TotalResultCounter'
import { connect } from 'react-redux';

class DestinationThemePackageSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destPackage: props.destPackage,
      destinationName: props.destinationName,
      status: props.status
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status && nextProps.compName == 'packages') {
      this.setState({
        destPackage: nextProps.destPackage,
        destinationName: nextProps.destinationName,
        status: nextProps.status
      });
    } else if (this.props.destinationName != nextProps.destinationName) {
      this.setState({
        destinationName: nextProps.destinationName
      });
    }
  }

  render() {
    return (
      <div className="listing-right-block">
        <div className="right-heading-block">
          <BreadCrumb breadcrumb={this.state.destPackage.breadcrumb} />
          <TotalResultCounter totalresult={this.state.destPackage.total} />
        </div>
        <DestinationThemePackageListing destinationName={this.state.destinationName} />
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    destPackage: { ...state.destinationPackage.destinationpackage },
    status: state.destinationPackage.status,
    compName: state.destinationPackage.compName
  }
}

export default connect(mapStatesToProps)(DestinationThemePackageSection);