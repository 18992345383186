import React, { Component } from 'react'

export default class Loader extends Component {

  // componentDidMount() {
  //   const $ = window.$$;
  //   var preloader = $('#preloader');
  //   $(window).load(function () {
  //     preloader.addClass('loading').fadeOut('slow', function () { $(this).remove(); });
  //   });
  // }

  render() {
    return (
      <div id="preloader">
        <div class="loader-inner-wrapper">
          <img class="loader-icon" src="/images/loadericon.svg" alt="" />
        </div>
      </div>
    )
  }
}
