import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class FooterLogo extends Component {
   	render() {
   		return(
   			<div className="footer-logo">
               <Link to="/">
	            	<img src="/images/logo.svg" className="dsk-logo" alt="logo"/>
	          </Link>
            </div>



   		);
   	}
}