import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import HolidayPackages from './HolidayPackages';

class HolidayPackagesFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chkNavigation: props.chkNavigation,
      status: props.status
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status) {
      this.setState({
        chkNavigation: nextProps.chkNavigation,
        status: nextProps.status
      });
    }
  }

  render() {
    return (
      <div className="footer-navs">
        <h4>Themes</h4>
        <ul>
          {
            this.state.chkNavigation.theme_menu && this.state.chkNavigation.theme_menu.length > 0 &&
            this.state.chkNavigation.theme_menu.map((obj, index) => (
              <li key={index}>
                <Link to={"/travel-packages?categories[]=" + obj.slug}>{obj.title}</Link>
              </li>
            ))
          }
          {/* <HolidayPackages /> */}
        </ul>
      </div>
    );
  }
}
const mapStatesToProps = (state, ownProps) => {
  return {
    chkNavigation: state.navigation.headermainmenu,
    status: state.navigation.status
  }
}

export default connect(mapStatesToProps)(HolidayPackagesFooter);