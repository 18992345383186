import React, { Component } from 'react';

export default class TripActivities extends Component {

  constructor(props) {
    super(props);
    this.state = {
      disabled: typeof props.disabled !== 'undefined' ? props.disabled : false,
      selectedValue: typeof props.selectedValue !== 'undefined' ? props.selectedValue.map(item => parseInt(item)) : [],
      options: props.activities.slice(0, 8)
    };
    this.selectValue = this.selectValue.bind(this);
  }

  selectValue(value) {
    //const {value, checked} = e.target;
    const selectedValue = [...this.state.selectedValue];

    if (selectedValue.indexOf(value) > -1) {
      selectedValue.splice(selectedValue.indexOf(value), 1);
    } else {
      selectedValue.push(value);
    }

    this.setState({
      selectedValue
    });
  }

  showAll(e) {
    e.preventDefault();
    this.setState({
      options: this.props.activities
    });
  }

  clearAll(e) {
    e.preventDefault();
    this.setState({
      selectedValue: []
    });
  }

  render() {
    const { selectedValue, options, disabled } = this.state;

    return (
      <div>
        <div className="activite-selcted">
          <span className="select-count">{selectedValue.length} Selected </span>
          <a href="javascript: void(0);" className="cls-btn" onClick={this.clearAll.bind(this)}>CLEAR</a>
        </div>
        <ul className="choose-activite">
          {
            options.map((item, index) => {
              return <li key={index} style={{ display: "inline-block" }} className={selectedValue.indexOf(item.id) > -1 ? "active" : ""} onClick={() => this.selectValue(item.id)}>
                <input disabled={disabled} checked={selectedValue.indexOf(item.id) > -1 ? true : false} type="radio" id={`activity${item.id}`} defaultValue={item.id} />
                <label>{item.title}</label>
              </li>
            })
          }
        </ul>
        {
          options.length != this.props.activities.length && <a className="addmore-activite" onClick={this.showAll.bind(this)}> +8 </a>
        }
      </div>
    );
  }
}