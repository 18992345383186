import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routesList';
import AuthLink from '../../AuthLink';
import AuthModals from '../../AuthModals';
import { connect } from 'react-redux';
import BookingButtonStyle1 from '../../BookingButtonStyle1';
import { headerMainMenu, headerMainMenuSuccess } from '../../actions/navigation';

class MenuHeaderNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chkNavigation: props.chkNavigation,
      status: props.status
    }
  }

  componentDidMount() {
    this.props.headerMainMenu().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.headerMainMenuSuccess(response.value.data.payload);
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status) {
      if (this.props.status != nextProps.status) {
        this.setState({
          chkNavigation: nextProps.chkNavigation,
          status: nextProps.status
        })
      }
    }
  }

  render() {
    return (
      <div className="custom-dropdown-nav">
        {
          this.state.chkNavigation.holiday_packages_menu && this.state.chkNavigation.holiday_packages_menu.domestic ?
            <div className="submenu-block">
              <h4>Indian</h4>
              <ul>
                {
                  this.state.chkNavigation.holiday_packages_menu && this.state.chkNavigation.holiday_packages_menu.domestic && this.state.chkNavigation.holiday_packages_menu.domestic.length > 0 &&
                  this.state.chkNavigation.holiday_packages_menu.domestic.map((obj, index) => (
                    <li>
                      <Link to={"/destinationpackage/" + obj.slug}>
                        {obj.destination_name}
                      </Link>
                    </li>
                  ))
                }
              </ul>
            </div> : null
        }
        <div className="submenu-block">
          <h4>International</h4>
          <ul>
            {
              this.state.chkNavigation.holiday_packages_menu && this.state.chkNavigation.holiday_packages_menu.international && this.state.chkNavigation.holiday_packages_menu.international.length > 0 &&
              this.state.chkNavigation.holiday_packages_menu.international.map((obj, index) => (
                <li>
                  <Link to={"/destinationpackage/" + obj.slug}>{obj.destination_name}</Link>
                </li>
              ))
            }
          </ul>
        </div>
        <div className="submenu-block plan-holiday-nav">
          <p>The journey of a thousand miles begins with a single step.</p>
          <BookingButtonStyle1 customClass="btn n-blue-btn" label="PLAN MY HOLIDAYS" />
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    chkNavigation: state.navigation.headermainmenu,
    status: state.navigation.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    headerMainMenu: () => {
      return dispatch(headerMainMenu());
    },
    headerMainMenuSuccess: (payload) => {
      dispatch(headerMainMenuSuccess(payload));
    },

  };
}


export default connect(mapStatesToProps, mapDispatchToProps)(MenuHeaderNavigation);