import React, { Component } from 'react';
import ThemeDestinationListing from './ThemeDestinationListing';
import BreadCrumb from '../common/BreadCrumb';
import TotalResultCounter from '../common/TotalResultCounter'
import { holidayTypeListWithDestination, holidayTypeListWithDestinationSuccess, resetFilters } from '../../actions/holidayType';
import { connect } from 'react-redux';

class ThemeDestinationMainBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themeDetail: null,
      status: props.status,
      loaded: false
    };
  }

  componentWillMount() {
    this.props.holidayTypeListWithDestination(this.props.filters).then((response) => {
      if (response.value.data.result === 'success') {
        this.setState({ themeDetail: response.value.data.payload, loaded: true });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status && nextProps.compName == 'filters') {
      this.setState({ loaded: false });
      this.props.holidayTypeListWithDestination(nextProps.filters).then((response) => {
        if (response.value.data.result === 'success') {
          this.setState({ status: nextProps.status, themeDetail: response.value.data.payload, loaded: true });
        }
      });
    }
  }
  
  render() {
    //console.log('ppppp',this.state.themeDetail);
    if (!this.state.loaded) {
      return null;
    }
    return (
      <div className="listing-right-block">
        <div className="right-heading-block">
          <TotalResultCounter totalresult={this.state.themeDetail && this.state.themeDetail.total} />
        </div>
        <ThemeDestinationListing destinationlist={this.state.themeDetail.data && this.state.themeDetail.data} />
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    status: state.holidayTypes.status,
    compName: state.holidayTypes.compName,
    filters: { ...state.holidayTypes.filters }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    holidayTypeListWithDestination: (params) => {
      return dispatch(holidayTypeListWithDestination(params));
    },
    holidayTypeListWithDestinationSuccess: (payload) => {
      dispatch(holidayTypeListWithDestinationSuccess(payload));
    }
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(ThemeDestinationMainBlock);