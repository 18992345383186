// Vikas - 08-01-2018
import React, { Component, Fragment } from 'react';

export default class PackageGallery extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hidden: "hidden",
      imagesGallery: []
    };
    this.bind = this.bind.bind(this);
  }

  componentDidMount() {
    const { data } = this.props;
    let array = []
    if (this.props.data) {
      this.props.data.map(data => {
        array.push({
          "src": data.image,
          "thumb": data.image
        })
      })
    }
    this.setState({ imagesGallery: array });
    window.onload = () => {
      this.bind();
    }
    if (document.readyState === "complete" || document.readyState === "interactive") {
      return typeof data !== 'undefined' && data !== null && data.length > 5 ? null : this.bind();
    }
  }

  bind() {
    const { data } = this.props;
    const $ = window.$$;
    const gallery = this.refs.gallery;

    // $(gallery).bxSlider({
    //   mode: 'fade',
    //   captions: true,
    //   controls: true,
    //   infiniteLoop: true,
    //   auto: true,
    //   onSliderLoad: (currentIndex) => {
    //     this.setState({
    //       hidden: "visible"
    //     });
    //   }
    // });
    $(gallery).lightGallery({
      pager: true,
      html: true,
      dynamic: typeof data !== 'undefined' && data !== null && data.length > 5 ? true : false,
      dynamicEl: this.state.imagesGallery
    });
  }

  render() {
    const { data } = this.props;

    return (
      <Fragment>
        <div className="container" >
          <div className="images-collage" onClick={this.bind} id="lightgallery" ref="gallery">
            {
              typeof data !== 'undefined' && data !== null && data.length > 0
              &&
              data.slice(0, 5).map((obj, index) => (
                <Fragment key={index}>
                  <div className={`figure-blk item${index + 1}`} data-responsive="images/goa-beaches.jpg 375, images/goa-beaches.jpg 480, images/goa-beaches.jpg 800" data-src={obj.image} data-sub-html="<h4>Explore the best beaches in Goa</h4><p>BAGA BEACH, GOA</p>">
                    {
                      typeof data !== 'undefined' && data !== null && data.length > 5
                        && index + 1 == 5 ?
                        <div className="overlay">
                          <button className="btn" data-responsive="images/goa-beaches.jpg 375, images/goa-beaches.jpg 480, images/goa-beaches.jpg 800" data-src={obj.image} data-sub-html="<h4>Explore the best beaches in Goa</h4><p>BAGA BEACH, GOA</p>">
                            Show all <span className="hide-phone">{data.length} photos</span>
                          </button>
                        </div> : null
                    }
                    <figure>
                      <img className="img-fluid" src={obj.image} alt="img" />
                    </figure>
                  </div>
                </Fragment>
              ))
            }
          </div>
        </div>


        {/* <div style={{ visibility: this.state.hidden }} ref="gallery" className="bxslider banner-img-slider" id="lightgallery">
          {
            typeof data !== 'undefined' && data !== null && data.length > 0
            &&
            data.map((obj, index) => (
              <div key={index} className="slider-item" data-responsive="images/goa-beaches.jpg 375, images/goa-beaches.jpg 480, images/goa-beaches.jpg 800" data-src={obj.image} data-sub-html="<h4>Explore the best beaches in Goa</h4><p>BAGA BEACH, GOA</p>">
                <img src={obj.image} alt="" />
                <label className="caption">{obj.caption}</label>
              </div>
            ))
          }
        </div> */}
      </Fragment>
    );
  }
}