import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { dateFormat } from '../../constants';
import BookingButtonStyle1 from '../../BookingButtonStyle1';

export default class TravelStories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideData: props.slideData
    }
    // this.bind = this.bind.bind(this);
  }

  test(words) {
    var n = words.split(" ");
    return n[n.length - 1];

  }

  // componentDidMount() {
  //   window.addEventListener('load', () => {
  //     this.bind();
  //   });
  // }

  // bind() {
  //   window.$$(document).ready(function () {
  //     if (window.$$('.stories-block').length > 0) {
  //       window.$$('.story-slider').cardslider({
  //         nav: false,
  //       });
  //     }
  //   });
  // }

  render() {
    let location = null
    if (this.state.slideData) {
      location = this.test(this.state.slideData.location);
    }

    return (
      <li>
        <div className="carousel-inner">
          <figure>
            <div className="owl-carousel owl-theme story-img-slider">

              {
                this.state.slideData && this.state.slideData.gallery && this.state.slideData.gallery.length > 0 &&
                this.state.slideData.gallery.map((obk, index) => (
                  <div className="item" key={index}>
                    <img src={obk.guid} alt="" />
                  </div>
                ))
              }
            </div>
          </figure>
          <figcaption className="story-slder-content">
            <div className="rating-block">
              <div className="rating-icons">
                <span>{this.state.slideData.rating && this.state.slideData.rating} </span>
                <a href="javascript:;">
                  <ion-icon ios="ios-star" md="md-star"></ion-icon>
                  <ion-icon ios="ios-star" md="md-star"></ion-icon>
                  <ion-icon ios="ios-star" md="md-star"></ion-icon>
                  <ion-icon ios="ios-star" md="md-star"></ion-icon>
                  <ion-icon ios="ion-ios-star-outline" md="md-star"></ion-icon>
                </a>
              </div>
              <p className="location d-lg-none">{this.state.slideData.location && this.state.slideData.location}</p>

            </div>
            <h5>{this.state.slideData.package_theme && this.state.slideData.package_theme}</h5>
            <h4>{this.state.slideData.post_title}</h4>
            <p>{this.state.slideData.post_excerpt && this.state.slideData.post_excerpt}
            </p>
          </figcaption>
        </div>
        <div className="bottom-bar-block">
          <div className="left-content story-hide-mob">
            <h5>{this.state.slideData.package_theme && this.state.slideData.package_theme}</h5>
            <p>{this.state.slideData.location && this.state.slideData.location}</p>
          </div>
          <div className="right-content">
            <Link to={"/travelstories/" + this.state.slideData.post_name} className="btn-5">READ FULL STORY</Link>
            <BookingButtonStyle1 customClass="n-blue-btn" label={`GET QUOTE FOR ${this.state.slideData.location}`} index={location} />
          </div>
        </div>
      </li>
    );
  }
}