import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import {clearSession} from '../../helpers';

export default class LogoutView extends Component {
    
    componentWillMount(){
      clearSession();
    }

    render() {
      
      return null;
    }
}