import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { blogList, blogListSuccess } from './actions/blog';
import {dateFormat} from './constants';

class BlogList extends Component {
	constructor(props){
	    super(props);
	    this.bindSlider = this.bindSlider.bind(this);
	    this.state = {
	      blogListing: props.blogListing,
	      status: props.status
	    }
	}

	componentDidMount(){
		this.props.blogList().then((response) => {
	      if(response.value.data.result === 'success'){
	        this.props.blogListSuccess(response.value.data.payload);
	      }
	    })


      	const $ = window.$$;
      	const windowNavHgt = $(window).height();
        $.fn.bannerHeight = function(){ 
         	$('.slider-hero-blog').css('height', windowNavHgt+'px');
        }
        $.fn.bannerHeight();
        $(window).on('resize',function() {
           $.fn.bannerHeight();
        });

        window.addEventListener('load', () => {
	      this.bindSlider();
	    });

	    if(document.readyState === "complete" || document.readyState === "interactive"){
	      this.bindSlider();
	    }
    }

    componentWillReceiveProps(nextProps){
	    if(this.props.status != nextProps.status){
	      	this.setState({
		        blogListing: nextProps.blogListing,
		        status: nextProps.status
	      	})
	    }
	}

    bindSlider(){
	    const $ = window.$$;
	    //fx_slider_hero();
		//function fx_slider_hero() {
		if ($('#blog-hero-slider').length >0) {
		  	$('#blog-thumbanil').flexslider({
			    animation: "slide",
			    controlNav: false,
			    animationLoop: true,
			    slideshow: false,
			    itemMargin: 0,
			    itemWidth: 25,
			    maxItems: 4,
			    asNavFor: '#blog-hero-slider'
		  	});
		 
		  	$('#blog-hero-slider').flexslider({
			    animation: "fade",
			    controlNav: false,
			    animationLoop: true,
			    sync: "#blog-thumbanil",
			    slideshowSpeed: 4000,
			    animationSpeed: 500,  
		  	});
		  	$("#blog-thumbanil li a").hover(function(e){
		    	$(this).parent('li').click();
		  	});
		}

		//}
	}



	render() {
    	return (
            <div className="main-body-content">
                <div className="vd-slider-block slider-hero-blog">           
                    <div id="blog-hero-slider" className="gallery-slide flexslider">
                       <ul className="slides">
                       		{
					          this.state.blogListing.featureblog && this.state.blogListing.featureblog.length > 0 &&
					          this.state.blogListing.featureblog.map((obj, index) => (
					            	<li key={index}>
		                          		<div className="item-slide" style={{backgroundImage: `url(${obj.thumbnail})`}}>
		                                 	<div className="content-blog-hero">
		                                    	<div className="container">
		                                        	<div className="blog-content-in">
			                                            <h3>{ obj.post_title }</h3>
			                                            <p>{ obj.post_excerpt}</p>
			                                            <Link className="btn btn-white" to={"/blog/" + obj.post_name }>Read more</Link>
		                                        	</div>
		                                    	</div>
		                                 	</div>
		                             	</div>
		                          	</li>
					          ))
					        }
                       	</ul>
                    </div>
                    <div id="blog-thumbanil" className="gallery-carousel blog-thumbnail flexslider">
                       	<h3 className="heading-blog">FEATURED ARTICLES</h3>
                       	<ul className="slides">
                       		{
					          this.state.blogListing.featureblog && this.state.blogListing.featureblog.length > 0 &&
					          this.state.blogListing.featureblog.map((obj, index) => (
					            	<li key={index}>
		                            	<Link to={"/blog/" + obj.post_name }>
		                              		<div className="thumb-blog-ui">
			                                	<p>{ obj.post_title }</p>
			                                	<span>{dateFormat(obj.post_date)} · {obj.tags}</span>
		                              		</div>
		                            	</Link>
		                          	</li>
					          ))
					        }
                        </ul>
                    </div>
                </div>
                <section className="lastest-article-block">
                    <div className="head-block-blog">
                        <div className="container">
                            <h3>Latest Articles</h3>
                            <p>Here’re some of the latest articles</p>
                        </div>
                    </div>
                    <div className="container">
                      <div className="row">
                      	{
				          this.state.blogListing.blogresult && this.state.blogListing.blogresult.length > 0 &&
				          this.state.blogListing.blogresult.map((obj, index) => (
				            	<div className="col-sm-4" key={index}>
		                            <article className="blog-ui">
		                                <Link to={"/blog/" + obj.post_name }>
		                                    <figure>
		                                        <img src={obj.thumbnail} alt={ obj.post_title } />
		                                    </figure>
		                                    <figcaption>
		                                        <span>{dateFormat(obj.post_date)} · {obj.tags}</span>
		                                        <label>{ obj.post_title }</label>
		                                    </figcaption>
		                                </Link>
		                            </article>
		                        </div>
				          ))
				        }
                       
                      </div>
                      
                    </div>
                </section>
            </div>
    	);
    }
}

const mapStatesToProps = (state, ownProps) => {
  	return {
    	blogListing: {...state.blog.bloglisting},
    	status: state.blog.status
  	}
}

const mapDispatchToProps = (dispatch) => {
  	return {
    	blogList: () => {
      		return dispatch(blogList());
    	},
    	blogListSuccess: (payload, total) => {
      		dispatch(blogListSuccess(payload, total));
    	},
  	};
}

export default connect(mapStatesToProps, mapDispatchToProps)(BlogList);