import React, { Component } from 'react';

class InclusionsList extends Component {

  render() {
    const { grouped } = this.props
    return (
      <ul>
        <li className={typeof grouped["Cruise"] == "undefined" ? "disable" : null}><figure><img src="/images/cruise-icon.svg" alt="" /></figure><figcaption> <p>Cruise</p></figcaption></li>
        <li className={typeof grouped["Sight Seeing"] == "undefined" ? "disable" : null}><figure><img src="/images/sight-seen.svg" alt="" /></figure><figcaption> <p>Sight Seeing</p></figcaption></li>
        <li className={typeof grouped["Hotel Stay"] == "undefined" ? "disable" : null}><figure><img src="/images/hotel-icon.svg" alt="" /></figure><figcaption> <p>Hotel Stay</p></figcaption></li>
        <li className={typeof grouped["Meal Plan"] == "undefined" ? "disable" : null}><figure><img src="/images/meal-icon.svg" alt="" /></figure><figcaption> <p>Meals</p></figcaption></li>
        <li className={typeof grouped["Freebies"] == "undefined" ? "disable" : null}><figure><img src="/images/freebies-icon.svg" alt="" /></figure><figcaption> <p>Freebies</p></figcaption></li>
        <li className={typeof grouped["AirPort Transfers"] == "undefined" ? "disable" : null}><figure><img src="/images/flight-icon.svg" alt="" /></figure><figcaption> <p>Flights</p></figcaption></li>
      </ul>
    );
  }
}
export default InclusionsList;