import React, { Component } from 'react';
import { connect } from 'react-redux';
import StarRating from '../common/StarRating';

export default class HotelRatingType extends Component {
  constructor(props){
      super(props);
      this.state = {
          hotels: typeof props.selection !== 'undefined' ? props.selection.map(value => parseInt(value)) : []
      };

      this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps){
      if(typeof nextProps.selection === 'undefined'){
          this.setState({
              hotels: []
          });
      }
  }

  handleChange(e){
      const {name, value, checked} = e.target;
      let {hotels} = this.state;

      if(checked){
          hotels.push(parseInt(value));
      }else{
          hotels.splice(hotels.indexOf(parseInt(value)), 1);
      }

      this.setState({
          hotels
      });

      this.props.updateFilters({hotels});
  }

	render() {
      const {hotels} = this.state;
      const ratings = [5,4,3,2,1];

    	return (
    		<div className="content-block hotel-tab">
          <div className="card">
              <div className="sidebar-heading-block">
                 <a className="card-link" data-toggle="collapse"  href="#hotel-type" aria-expanded="true" aria-controls="menuone">
                    Hotel Type
                    <ion-icon ios="ios-arrow-down" md="ios-arrow-down"></ion-icon>
                 </a>
                 <div className="select-element">
                    <ion-icon ios="ios-star" md="ios-star"></ion-icon>
                    4 & 5 Stars
                 </div>
              </div>
              <div id="hotel-type" className="collapse show">
                 <div className="inner-content">
                    <form onChange={this.handleChange}>
                      <ul className="checkbox-listing rating-listing">
                          {
                            ratings.map((value, index) => {
                                return <li>
                                          <input checked={hotels.indexOf(value) > -1} type="checkbox" id={`rating${index}`} value={value} />
                                          <label htmlFor={`rating${index}`}>
                                             <StarRating value={value} />
                                          </label>
                                       </li>
                            })
                          }
                      </ul>
                    </form>
                 </div>
              </div>
          </div>
        </div>
    	);
    }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     updateFilters: (params) => {
//       return dispatch(updateFilters(params));
//     },

//   };
// }
// export default connect(null, mapDispatchToProps)(HotelRatingType);