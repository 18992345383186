import React, { Component } from 'react';
import InclusionListSlide from './InclusionListSlide';

class InclusionFilterList extends Component {
	render() {
    	return (
        	<div className="content-block inclusion-tab">
               	<div className="card">
                  	<div className="sidebar-heading-block">
                    	<a className="card-link" data-toggle="collapse"  href="#inclusion" aria-expanded="true" aria-controls="menuone">
                        	Inclusions
                        	<ion-icon ios="ios-arrow-down" md="ios-arrow-down"></ion-icon>
                     	</a>
                    <div className="select-element">Free Meals</div>
                  	</div>
                  	<div id="inclusion" className="collapse show">
                     	<div className="inner-content">
                        	<ul className="inclusion-listing">
                        		<InclusionListSlide />
                        		<InclusionListSlide />
                        		<InclusionListSlide />
                        	</ul>
                     	</div>
                  	</div>
               	</div>
            </div>
    	);
    }
}

export default InclusionFilterList;