import React, { Component } from 'react';
//import PackageTypeFilters from './PackageTypeFilters';
import DestinationTypeFilters from '../destination/DestinationTypeFilters';
import DestinationThemePackageSection from './DestinationThemePackageSection'

export default class DestinationThemePackageBlock extends Component {

  render() {
    return (
      <section className="main-content-block ct-destination" id="show-listing">
        <div className="container">
          <div className="row">
            <div id="filterToShow" className="col-xl-3 col-lg-12 col-md-12 col-sm-12 sidebar-outer">
              <div className="wrap"></div>
              {/* all left side filters */}
              <DestinationTypeFilters />
            </div>
            <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
              <DestinationThemePackageSection destinationName={this.props.destinationName} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}