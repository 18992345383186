import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addToWishlist } from '../../actions/auth';
import LoginModal from '../../LoginModal';

class AddToWishlistButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      status: props.status,
      saved: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status && nextProps.compName == 'login') {
      this.setState({
        status: nextProps.status
      });
      this.props.addToWishlist(this.props.packageId);
    } else if (this.props.status != nextProps.status && nextProps.compName == 'wishlist-success') {
      this.setState({
        saved: true,
        status: nextProps.status
      });
    } else if (nextProps.savedPackages.indexOf(nextProps.packageId) > -1) {
      this.setState({
        saved: true
      });
    }
  }

  handleClick() {
    if (this.state.saved === false) {
      this.props.addToWishlist(this.props.packageId);
    }
  }

  showModal() {
    window.getFooter().setState({
      renderElement: <LoginModal onHide={this.hideModal.bind(this)} />
    });
  }

  hideModal() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  render() {
    const { saved } = this.state;

    console.log(saved, "package saved")

    return (
      <span>
        {
          this.props.auth === false
            ?
            <a href="javascript:void(0);" className="btn white-btn icon-btn" onClick={this.showModal.bind(this)}>
              <img src="/images/ic_save.svg" alt="" />
              <span>Save</span>
            </a>
            :
            <a href="javascript:void(0);" className="btn white-btn icon-btn" onClick={this.handleClick.bind(this)}>
              <img src="/images/ic_save.svg" alt="" />
              <span>{saved === true ? "Saved" : "Save"}</span>
            </a>
        }
      </span>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    auth: state.Auth.auth,
    savedPackages: [...state.Auth.saved_package],
    status: state.Auth.status,
    compName: state.Auth.compName
  }
}

export default connect(mapStatesToProps, { addToWishlist })(AddToWishlistButton);