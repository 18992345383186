import React, { Component } from 'react';

export default class TotalResultCounter extends Component {
	constructor(props){
      super(props);
      this.state = {
        totalresult: props.totalresult
      }
    }
	render() {
    	return (
    		<div className="result-number">Showing <span>{this.state.totalresult} results</span></div>
    	);
    }
}