import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { faqList, faqListSuccess } from './actions/faq';
import { dateFormat } from './constants';

class TravelotFaq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      travelotFaq: props.travelotFaq,
      status: props.status
    }
  }

  componentDidMount() {
    this.props.faqList().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.faqListSuccess(response.value.data.payload);
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status != this.state.status) {
      this.setState({
        travelotFaq: nextProps.travelotFaq,
        status: nextProps.status
      })
    }
  }


  render() {
    console.log('asas', this.state.travelotFaq);
    return (
      <div className="main-body-content">
        <section className="static-banner-block faq-banner">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h2>Frequently Asked Questions</h2>
                <p>Choose any category or just scroll down to read</p>
              </div>
            </div>
          </div>
        </section>
        <section className="static-content-block faq-block">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="static-inner-block">
                  <div className="faq-icons-block">
                    <ul>
                      {
                        this.state.travelotFaq && this.state.travelotFaq.category && this.state.travelotFaq.category.length > 0 &&
                        this.state.travelotFaq.category.map((obj, index) => (
                          <li>
                            <a href={"#" + obj.title}>
                              <div className="icon"><img src={"/images/ic_faq_" + parseInt(index + 1) + ".png"} alt="" /></div>
                              <p>{obj.title}</p>
                            </a>
                          </li>
                        ))
                      }
                    </ul>
                  </div>
                  {
                    this.state.travelotFaq && this.state.travelotFaq.category && this.state.travelotFaq.category.length > 0 &&
                    this.state.travelotFaq.category.map((obj, index) => (

                      <div className="faq-accordian-block" id={obj.title}>
                        <h3>{obj.title}</h3>
                        <div className="question-accordion-container">
                          <div className="panel-group" id="accordion">

                            {
                              obj.faq && obj.faq.length > 0 && obj.faq.map((obk, infex) => (
                                <div className="panel panel-default">
                                  <div className="panel-heading">
                                    <h4 className="panel-title">
                                      <a data-toggle="collapse" data-parent="#accordion" href={"#collapse" + obk.id}><span className="glyphicon glyphicon-plus"></span>{obk.question}</a>
                                    </h4>
                                  </div>
                                  <div id={"collapse" + obk.id} className="panel-collapse collapse">
                                    <div className="panel-body">
                                      <p dangerouslySetInnerHTML={{ __html: obk.answer }} />
                                    </div>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    travelotFaq: state.travelotfaq.faqlist,
    status: state.travelotfaq.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    faqList: () => {
      return dispatch(faqList());
    },
    faqListSuccess: (payload) => {
      dispatch(faqListSuccess(payload));
    },
  };
}


export default connect(mapStatesToProps, mapDispatchToProps)(TravelotFaq);