import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { updateFilters } from '../../actions/destinationPackage';


export default class BudgetPerPerson extends Component {

  constructor(props) {
    super(props);
    this.state = {
      from: typeof props.from !== 'undefined' ? parseInt(props.from) : 0,
      to: typeof props.to !== 'undefined' ? parseInt(props.to) : 0
    };
    this.bindSlider = this.bindSlider.bind(this);
  }

  componentDidMount() {
    window.addEventListener('load', this.bindSlider);

    if (document.readyState === "complete" || document.readyState === "interactive") {
      this.bindSlider();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.from === 'undefined') {
      this.bindSlider();
      this.setState({
        from: 0,
        to: 0,
      });
    }
  }

  bindSlider() {
    const { from, to } = this.state;
    window.$$("#range_05").ionRangeSlider({
      type: "double",
      grid: false,
      min: 1000,
      max: 500000,
      from: 0,
      to: 0,
      prefix: "<span class='rupees-sign'>₹</span>",
      prettify_enabled: true,
      prettify_separator: ",",
      onChange: (data) => {
        // Called every time handle position is changed
        this.props.updateFilters({ budget_from: data.from, budget_to: data.to });
        this.setState({ from: data.from, to: data.to });
      },
    });
    let my_range = window.$$("#range_05").data("ionRangeSlider");
    my_range.reset();
    // document for this slider http://ionden.com/a/plugins/ion.rangeSlider/start.html
  }

  render() {
    return (
      <div className="content-block budget-tab">
        <div className="card">
          <div className="sidebar-heading-block">
            <a className="card-link" data-toggle="collapse" href="#Budget1" aria-expanded="true" aria-controls="menuone">
              Budget Per Person <span>(in Rs.)</span>
              <ion-icon ios="ios-arrow-down" md="ios-arrow-down"></ion-icon>
            </a>
            <div className="select-element">₹ {this.state.from} - ₹ {this.state.to}</div>
          </div>
          <div id="Budget1" className="collapse show">
            <div className="inner-content">
              <div className="custom-range-slider">
                <label className="range-heading">Choose Price Range</label>
                <input type="text" id="range_05" name="example_name" value="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     updateFilters: (params) => {
//       return dispatch(updateFilters(params));
//     },

//   };
// }
// export default connect(null, mapDispatchToProps)(BudgetPerPerson);