import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import SocialLogin from './SocialLogin';
import Error from './components/common/Error';
import { signup } from './actions/auth';

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status,
      errors: {},
      passwordShow: false,
      name: ''
    };

    this.reset = this.reset.bind(this);
    this.bind = this.bind.bind(this);
  }

  /**
   * Submit login form
   */
  submit(e) {
    e.preventDefault();
    const { name, email, country_code, contact_number, password } = this.refs;

    // Verify login credentials and login on success
    this.props.signup({ name: name.value, email: email.value, password: password.value, country_code: country_code.value, contact_number: contact_number.value });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status != this.state.status && nextProps.compName == 'signup') {
      if (typeof nextProps.errors != "undefined" && Object.keys(nextProps.errors).length > 0) {
        this.setState({
          errors: nextProps.errors,
          status: nextProps.status
        });
      } else {
        window.$$('#signup-modal').modal('hide');
        window.$$('#verifyemail-modal').modal('show');
        this.reset();
      }
    }
  }

  componentDidMount() {
    window.addEventListener('load', this.bindSlider);

    if (document.readyState === "complete" || document.readyState === "interactive") {
      this.bind();
    }
  }

  bind() {
    window.$$(this.refs.country_code).selectpicker();
  }



  /**
   * Reset form fields
   */
  reset() {
    Object.keys(this.refs).map((field) => {
      this.refs[field].value = '';
    });
  }

  render() {
    const { errors } = this.state;

    return (
      <div>
        <div className="top-block">
          <SocialLogin />
        </div>
        <div className="border-block">
          <span>OR</span>
        </div>
        <div className="middle-content">
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">Name</label>
            <input value={this.state.name} ref="name" type="text" className="form-control" placeholder="John Smith"
              onChange={(e) => {
                let name = e.target.value
                name = name.replace(/[^A-Za-z]/ig, ' ')
                this.setState({ name: name.charAt(0).toUpperCase() + name.slice(1) })
              }} />
            {typeof errors.name !== 'undefined' && <Error text={errors.name} />}
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">EMAIL</label>
            <input ref="email" type="email" className="form-control" placeholder="you@example.com" />
            {typeof errors.email !== 'undefined' && <Error text={errors.email} />}
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">PHONE NUMBER</label>
            <div className="number-field">
              <div className="custom-selecter">
                <select ref="country_code" className="selectpicker">
                  <option>+91</option>
                  <option>+12</option>
                  <option>+93</option>
                  <option>+94</option>
                  <option>+96</option>
                </select>
              </div>
              <div className="pn-field">
                <input ref="contact_number" type="tel" className="form-control" placeholder="99999-88888" />
              </div>
              {typeof errors.country_code !== 'undefined' && <Error text={errors.country_code} />}
              {typeof errors.contact_number !== 'undefined' && <Error text={errors.contact_number} />}
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="exampleInputEmail1">PASSWORD</label>
            <div className="pw-field">
              <input ref="password" type={this.state.passwordShow ? "text" : "password"} className="form-control" placeholder="**********" />
              <span className="show-pd"><ion-icon ios="ios-eye-off" md="md-eye-off" onClick={() => this.setState({ passwordShow: !this.state.passwordShow })}></ion-icon></span>
              {typeof errors.password !== 'undefined' && <Error text={errors.password} />}
            </div>
          </div>
          <button type="submit" className="btn cta-btn" onClick={this.submit.bind(this)}>Proceed</button>
          <div className="pv-block">
            <p>By creating an account, you agree to Travalo’s <a href="javacript:;">Terms of Service, Cookie Policy, Privacy Policy</a> and <a href="javascript:;" className="">Content Policies.</a></p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    status: state.Auth.status,
    compName: state.Auth.compName,
    errors: state.Auth.errors,
    auth: state.Auth.auth
  }
}

export default connect(mapStatesToProps, { signup })(SignupForm);
