import React, { Component } from 'react';
import CustomizeRequestModal from './CustomizeRequestModal';

export default class BookingButtonStyle1 extends Component {

  constructor(props) {
    super(props);

    this.showCustomRequestForm = this.showCustomRequestForm.bind(this);
    this.hideCustomRequestForm = this.hideCustomRequestForm.bind(this);
  }

  hideCustomRequestForm() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  showCustomRequestForm() {
    const { packageId, data, samePackage } = this.props;

    window.getFooter().setState({
      renderElement: <CustomizeRequestModal index={this.props.index} theme_id={this.props.theme_id} realOnly={this.props.realOnly}
        toggle={this.hideCustomRequestForm} packageId={packageId} data={data} samePackage={samePackage} />
    });
  }

  render() {
    const { customClass, label } = this.props;

    return (
      <a href="javascript: void(0);" className={`${typeof customClass !== 'undefined' ? customClass : "btn cta-btn"}`} onClick={this.showCustomRequestForm} dangerouslySetInnerHTML={{ __html: typeof label !== 'undefined' ? label : "CUSTOMIZE & BOOK" }}></a>
    );
  }
}