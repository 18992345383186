import {
  _dispatch, FAQ_LIST_SUCCESS
} from '../actions/faq';

const INITIAL_STATE = { faqlist: [], status:null };

export default function(state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch(action.type) {

    case FAQ_LIST_SUCCESS:
      return (() => {
        const faqlist = action.payload;
        return _dispatch({ ...state, faqlist}, true);
      })();

    default:
      return state;
  }
}