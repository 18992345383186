import React, { Component } from 'react';

export default class StarRating extends Component {
	render() {
      const {value} = this.props;

    	return (
    		<div className="rating-block">
            {
              Array.from(Array(value), () => 0).map(() => {
                  return <ion-icon ios="ios-star" md="ios-star"></ion-icon>
              })
            }
            <span> {value} Star </span>
         </div>
    	);
    }
}