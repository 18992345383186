import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routesList';
import { currencyFormat } from '../../constants';

export default class InternationalFeaturedDestinationSlide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideData: props.slideData
    }
  }

  render() {
    return (
      <li className=" gallery-listing" >
        <Link to={"/destinationpackage/" + this.state.slideData.slug}>
          <div className="featured-img-block">
            <figure><img src={this.state.slideData.landscape_image} alt="" /></figure>
            <figcaption>
              <div className="left-block">
                <h4>{this.state.slideData.destination_name}</h4>
                <p>Over {this.state.slideData.total_packages > 20 ? "20+" : this.state.slideData.total_packages} Packages</p>
              </div>
              <div className="right-block">
                <span className="price-tag">Starting from</span>
                <p className="price-text"> <span className="price-sign">₹</span>
                  {currencyFormat(this.state.slideData.minimumprice, 'INR', false)}
                </p>
              </div>
            </figcaption>
          </div>
        </Link>
      </li>
    );
  }
}