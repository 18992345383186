import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { notificationList, notificationListSuccess } from './actions/navigation';
import { dateFormat } from './constants';

class Notification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chkNotification: props.chkNotification,
      status: props.status
    }
  }

  componentDidMount() {
    this.props.notificationList().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.notificationListSuccess(response.value.data.payload);
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status != this.state.status && nextProps.compName == 'user-notification') {
      this.setState({
        chkNotification: nextProps.chkNotification,
        status: nextProps.status
      })
    }
  }


  render() {
    return (
      <div className="main-body-content">
        <section className="profile-section notification-section">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <div className="notification-inner-block">
                  <div className="notification-dropdown-block">
                    <div className="header-dropdown-ui">
                      <h5>NotificationS</h5>
                    </div>
                    <div className="n-list-view">
                      <ul>
                        {
                          this.state.chkNotification.data && this.state.chkNotification.data.length > 0 &&
                          this.state.chkNotification.data.map((obj, index) => (
                            <li className="package-list">
                              <Link to={obj.type_url}>
                                <figure>
                                  <img src="/images/customize-icon.svg" alt="" />
                                </figure>
                                <div className="n-detail">
                                  <h2>Package Quote</h2>
                                  <time>{dateFormat(obj.created_at)}</time>
                                  <p dangerouslySetInnerHTML={{ __html: obj.message }} />
                                </div>
                              </Link>
                            </li>
                          ))
                        }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    chkNotification: state.navigation.notificationlist,
    compName: state.navigation.compName,
    status: state.navigation.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    notificationList: () => {
      return dispatch(notificationList());
    },
    notificationListSuccess: (payload) => {
      dispatch(notificationListSuccess(payload));
    },

  };
}


export default connect(mapStatesToProps, mapDispatchToProps)(Notification);