import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import UploadPicture from '../common/UploadPicture';
import { ROUTES } from '../../routesList';
import Error from '../common/Error';
import Dropdown from '../common/Dropdown';
import { updateProfle } from '../../actions/auth';

class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.user,
      showPassword: false,
      status: props.status,
      errors: {}
    };

    this.submit = this.submit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status != this.state.status && nextProps.compName == 'profile') {
      if (Object.keys(nextProps.errors).length > 0) {
        this.setState({
          errors: nextProps.errors,
          status: nextProps.status
        });
      } else {
        toast.success("Your profile has been updated successfully!", {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  submit() {
    const { name, country_code, contact_number, newsletter_status, password, oldPassword } = this.refs;
    const request = { name: name.value, country_code: country_code.refs.country_code.value, contact_number: contact_number.value, newsletter_status: newsletter_status.checked };
    if (typeof oldPassword !== 'undefined') {
      request.oldPassword = oldPassword.value;
    }
    if (typeof password !== 'undefined') {
      request.password = password.value;
    }
    this.props.updateProfle(request);
  }

  updateNewsletterStatus(e) {
    this.setState({
      newsletter_status: e.target.checked ? 1 : 0
    })
  }

  showPassword() {
    this.setState({
      showPassword: true
    });
  }

  render() {
    const { name, email, country_code, contact_number, newsletter_status, errors, showPassword } = this.state;

    return (
      <div className="dashboard-block my-profile-block">
        <div className="ui-blocks-main">
          <div className="uiblock-head">
            <h3>EDIT ACCOUNT DETAILS</h3>
            <div className="submit-holiday-action btn-profile-submit-action">
              <a href="javascript: void(0);" className="n-blue-btn save-holiday-btn" onClick={this.submit}>SAVE DETAILS</a>
              <Link className="blue-border-btn" to="/dashboard">CANCEL</Link>
            </div>
          </div>
          <div className="edit-profile-block">
            <div className="profile-edit-main">
              <div className="block-profile-fields">
                <label className="title">PROFILE PICTURE</label>
                <UploadPicture />
              </div>
              <div className="block-profile-fields">
                <label className="title">FULL NAME</label>
                <div className="fiedset">
                  <input ref="name" type="text" placeholder="Enter Your Name" defaultValue={name} className="textfield" />
                  {typeof errors.name !== 'undefined' && <Error text={errors.name} />}
                </div>
              </div>
              <div className="block-profile-fields">
                <label className="title">Email</label>
                <div className="fiedset email-field-block">
                  <input disabled={true} type="email" placeholder="Enter Your Email" className="textfield" defaultValue={email} />
                </div>
              </div>
              <div className="block-profile-fields">
                <label className="title">PHONE NUMBER</label>
                <div className="fiedset">
                  <div className="country-mob-code">
                    <Dropdown options={['+91']} ref="country_code" name="country_code" customClass="custom-select-style" value={country_code} />
                  </div>
                  <div className="field-mob">
                    <input ref="contact_number" type="text" placeholder="99999-88888" className="textfield" defaultValue={contact_number} />
                  </div>
                  {typeof errors.country_code !== 'undefined' && <Error text={errors.country_code} />}
                  {typeof errors.contact_number !== 'undefined' && <Error text={errors.contact_number} />}
                </div>
              </div>
              {
                showPassword
                &&
                <div className="block-profile-fields change-password-field">
                  <label className="title">Old Password</label>
                  <div className="change-password fiedset">
                    <input autoFocus ref="oldPassword" className="textfield" type="password" placeholder="Enter your old password" />
                  </div>
                </div>
              }
              <div className="block-profile-fields change-password-field">
                <label className="title">Password</label>
                <div className="change-password fiedset">
                  {showPassword ? <input ref="password" className="textfield" type="password" placeholder="Set new password" /> : <span>***********</span>}
                  {!showPassword && <a href="javascript:void(0);" onClick={this.showPassword.bind(this)}>Change password</a>}
                </div>
                {typeof errors.password !== 'undefined' && <Error text={errors.password} />}
              </div>
              <div className="block-profile-fields">
                <label className="title">LINKED ACCOUNTS</label>
                <div className="link-social">
                  <a href="javascript:void(0);" className="fb-connected-btn"><ion-icon name="checkmark-circle"></ion-icon>Connected to facebook</a>
                </div>
              </div>
              <div className="block-profile-fields">
                <label className="title">NEWSLETTER</label>
                <div className="newletter-profile">
                  <label className="custom-checkbox-style">
                    <input onChange={this.updateNewsletterStatus.bind(this)} ref="newsletter_status" type="checkbox" checked={newsletter_status == '1' ? true : false} />
                    <span>Subscribe for Newsletter then you’ll get new offers and latest packages notifications on email.</span>
                  </label>
                </div>
              </div>
              <div className="submit-holiday-action btn-profile-submit-action">
                <a href="javascript: void(0);" className="n-blue-btn save-holiday-btn" onClick={this.submit}>SAVE DETAILS</a>
                <Link className="blue-border-btn" to="/dashboard">CANCEL</Link>
              </div>
            </div>

            <div className="deactivate-block">
              <h3>Deativate</h3>
              <p>Deactivating your account will <strong>remove it from Travalot</strong> within a few minutes. If you also opt for delete all data, then you can’t sign back in to reactivate your account and restore its content.</p>
              <a href="javascript:void(0);">Deactivate my account</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    user: { ...state.Auth.user },
    status: state.Auth.status,
    compName: state.Auth.compName,
    errors: state.Auth.errors
  }
}

export default connect(mapStatesToProps, { updateProfle })(EditProfile);