import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import NavigationList from './components/common/NavigationList';
import { connect } from 'react-redux';
import { readAllNotification } from './actions/navigation';
import { toast } from 'react-toastify';

class NotificationWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeNavig: false,
      notifications: props.notifications
    };
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', (e) => {
      console.log(e.target);
      if (
        this.state.activeNavig !== false
        &&
        e.target.className !== 'nav-link-dropdown'
        &&
        !window.$$(e.target).parents('.nav-link-dropdown').length
      ) {
        this.hide(e);
      }
    })
  }

  show(e) {
    e.preventDefault();
    window.$$(e.target).parents('.notifications-block').find(".notification-dropdown-block").addClass("active");
    this.setState({
      activeNavig: true
    });
  }

  hide(e) {
    e.preventDefault();
    window.$$(e.target).parents('.notifications-block').find(".notification-dropdown-block").removeClass("active");
    this.setState({
      activeNavig: false
    });
  }

  readAll() {
    readAllNotification().then((response) => {
      console.log(response, "response");
      this.setState({ notifications: 0 });
      // setTimeout(() => {
      //   toast.error("Notification has been set as Read!", {
      //     position: toast.POSITION.TOP_RIGHT
      //   });
      // }, 1000);
      this.props.callback();
    }).catch((error) => {

    });
  }

  render() {
    const { activeNavig } = this.state;

    return (
      <div className="notifications-block">
        <a href="javascript: void(0);" className="nav-link-dropdown" onClick={this.show}>
          <img src="/images/notification-icon.svg" alt="" />
          {
            this.state.notifications != 'undefined' && this.state.notifications > 0 &&
            <span className="online-icon"></span>
          }
        </a>
        <div className="notification-dropdown-block">
          {
            activeNavig == true
            &&
            <>
              <div className="header-dropdown-ui">
                <h5>Notifications</h5>
                <a href="javascript:void(0);" onClick={this.readAll.bind(this)}>MARK AS READ</a>
                <span className="dismiss-notification" onClick={this.hide}>&times;</span>
              </div>
              <NavigationList />

              <div className="see-all-notification">
                <Link to="/notifications">SEE ALL NOTIFICATIONS</Link>
              </div>
            </>
          }
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    notifications: state.Auth.user.notification_cnt
  }
}


export default connect(mapStatesToProps, null)(NotificationWidget);

