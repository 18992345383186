import React, { Component } from 'react';
import {connect} from 'react-redux'; 
import Error from '../common/Error';
import {months, budgetPreferences, tripDuration} from '../../constants';
import {saveHolidayPreferences} from '../../actions/auth';

class EditHolidayPreferences extends Component {

	constructor(props){
		super(props);
		this.state = {
	      status: props.status,
	      errors: {}
	    };
		this.saveDetails = this.saveDetails.bind(this);
	}

	componentDidMount(){
		window.$$('.selectpicker').selectpicker();
	}

	componentWillReceiveProps(nextProps){
		if(nextProps.status != this.state.status && nextProps.compName == 'preferences'){
		  if(Object.keys(nextProps.errors).length > 0){
		      this.setState({
		          errors: nextProps.errors,
		          status: nextProps.status
		      });
		  }else{
		  	this.props.toggle();
		  }
		}
	}

	saveDetails(){
		const {source, destination, month, budget_preference, flight_status, duration} = this.refs;
    	this.props.saveHolidayPreferences({source: source.value, destination: destination.value, month: month.value, budget_preference: budget_preference.value, flight_status: flight_status.value, duration: duration.value});
	}

	render() {
		const {source, destination, month, budget_preference, flight_status, duration} = this.props.preferences;
		const {errors} = this.state;

    	return (
    		<div className="holiday-form-block edit-holiday-block" style={{display: "block"}}>
        		<form>
          			<div className="row">
            			<div className="col-md-6">
              				<div className="form-field-block">
                				<figure>
                  					<img src="/images/location.svg" alt="" />
                				</figure>
                				<div className="holiday-field-content">
                  					<label>From where you want to Start?</label>
                  					<div className="block-text-fields">
                    					<input ref="source" type="text" className="text-field" placeholder="Type your location" defaultValue={source} />
                    					{typeof errors.source !== 'undefined' && <Error text={errors.source} />}
                  					</div>
                				</div>
              				</div>
            			</div>
            			<div className="col-md-6">
              				<div className="form-field-block">
                				<figure>
                  					<img src="/images/travel.svg" alt="" />
                				</figure>
                				<div className="holiday-field-content">
                  					<label>Your Next Travel Destination</label>
                  					<div className="block-text-fields">
                    					<input ref="destination" type="text" className="text-field" placeholder="Where you want to go?" defaultValue={destination} />
                    					{typeof errors.destination !== 'undefined' && <Error text={errors.destination} />}
                  					</div>
                				</div>
              				</div>
            			</div>
                        <div className="col-md-6">
                          	<div className="form-field-block">
                            	<figure>
                              		<img src="/images/calendar-icon.svg" alt="" />
                            	</figure>
                            	<div className="holiday-field-content">
                              		<label>In which month you like to go?</label>
                              		<div className="block-text-fields">
                                <select ref="month" className="selectpicker custom-select-style" defaultValue={month}>
                                  {
                                  	months.map((month, index) => {
                                  		return <option key={index} value={index}>{month}</option>
                                  	})
                                  }
                                </select>
                                {typeof errors.month !== 'undefined' && <Error text={errors.month} />}
                              </div>
                            </div>
                          </div>
                        </div>
            			<div className="col-md-6">
	                        <div className="form-field-block">
	                            <figure>
	                              <img src="/images/rupee-budget.svg" alt="" />
	                            </figure>
	                            <div className="holiday-field-content">
	                              	<label>Your Budget Preference</label>
	                              	<div className="block-text-fields">
	                                	<select ref="budget_preference" className="selectpicker custom-select-style" defaultValue={budget_preference}>
		                                  	{
			                                  	budgetPreferences.map((item, index) => {
			                                  		return <option key={index} value={index}>{item}</option>
			                                  	})
			                                  }
		                                </select>
		                                {typeof errors.budget_preference !== 'undefined' && <Error text={errors.budget_preference} />}
	                              	</div>
	                            </div>
	                        </div>
	                    </div>
                        <div className="col-md-6">
                          	<div className="form-field-block">
                            	<figure>
                              		<img src="/images/destication-icon.svg" alt="" />
                            	</figure>
                            	<div className="holiday-field-content">
                              		<label>Do you want to include Flights?</label>
                              		<div className="block-text-fields">
                                		<select ref="flight_status" className="selectpicker custom-select-style" defaultValue={flight_status}>
                                			<option value=''></option>
                                  			<option value='1'>Yes</option>
                                  			<option value='2'>No</option>
                                		</select>
                                		{typeof errors.flight_status !== 'undefined' && <Error text={errors.flight_status} />}
                              		</div>
                            	</div>
                          	</div>
                        </div>
                        <div className="col-md-6">
                          	<div className="form-field-block">
                            	<figure>
                              		<img src="/images/duration-clock.svg" alt="" />
                            	</figure>
                            	<div className="holiday-field-content">
                              		<label>Duration of your trip</label>
                              		<div className="block-text-fields">
		                                <select ref="duration" className="selectpicker custom-select-style" defaultValue={duration}>
		                                  	{
			                                  	tripDuration.map((item, index) => {
			                                  		return <option key={index} value={index}>{item}</option>
			                                  	})
			                                  }
		                                </select>
		                                {typeof errors.duration !== 'undefined' && <Error text={errors.duration} />}
		                            </div>
		                        </div>
	                        </div>
                        </div>
	                </div>
	                <div className="submit-holiday-action">
                    	<a href="javascript:void(0);" className="n-blue-btn save-holiday-btn" onClick={this.saveDetails}>SAVE DETAILS</a>
                    	<a href="javascript:void(0);" className="blue-border-btn" onClick={this.props.toggle}>CANCEL</a>
                  	</div>
                </form>
            </div>
    	);
    }
}

const mapStatesToProps = (state) => {
    return {
        preferences: {...state.Auth.holiday_preferences},
        status: state.Auth.status,
        compName: state.Auth.compName,
        errors: state.Auth.errors
    }
}

export default connect(mapStatesToProps, {saveHolidayPreferences})(EditHolidayPreferences);