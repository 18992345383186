import {
  _dispatch,
  SAVE_FIRSTSTEP_SUCCESS,
  SAVE_FIRSTSTEP_ERROR,
  SAVE_SECONDSTEP_SUCCESS,
  SAVE_SECONDSTEP_ERROR,
  SAVE_THIRDSTEP_SUCCESS,
  SAVE_THIRDSTEP_ERROR,
  FIRSTSTEP_SUCCESS,
  SECONDSTEP_SUCCESS,
  THIRDSTEP_SUCCESS,
  RESET_QUOTE_REQUEST_FORM,
  SAVE_FIRSTSTEP_PLAN_TRIP_SUCCESS,
  SAVE_FIRSTSTEP_PLAN_TRIP_ERROR
} from '../actions/customPackage';

const INITIAL_STATE = { firstStep: {}, secondStep: {}, thirdStep: {}, errors: {} };

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {

    case SAVE_FIRSTSTEP_SUCCESS:
      return _dispatch({ ...state, errors: {}, firstStep: action.payload}, true, 'firststep');

    case SAVE_FIRSTSTEP_ERROR:
      return _dispatch({ ...state, errors: action.payload}, true, 'firststep');

    case SAVE_FIRSTSTEP_PLAN_TRIP_SUCCESS:
      return _dispatch({ ...state, errors: {}, firstStep: action.payload}, true, 'firststep-plan-trip');

    case SAVE_FIRSTSTEP_PLAN_TRIP_ERROR:
      return _dispatch({ ...state, errors: action.payload}, true, 'firststep-plan-trip');

    case SAVE_SECONDSTEP_SUCCESS:
      return _dispatch({ ...state, errors: {}, secondStep: action.payload}, true, 'secondstep');

    case SAVE_SECONDSTEP_ERROR:
      return _dispatch({ ...state, errors: action.payload}, true, 'secondstep');

    case SAVE_THIRDSTEP_SUCCESS:
      return _dispatch({ ...state, errors: {}, firstStep: {}, secondStep: {}, thirdStep: {}}, true, 'thirdstep');

    case SAVE_THIRDSTEP_ERROR:
      return _dispatch({ ...state, errors: action.payload}, true, 'thirdstep');

    case RESET_QUOTE_REQUEST_FORM:
      return _dispatch({ ...state, firstStep: {}, secondStep: {}, thirdStep: {}, errors: {}}, true, 'reset-request');

    default:
      return state;
  }
}