import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export { _dispatch };

export const DESTINATION_GUIDE_DETAIL = 'DESTINATION_GUIDE_DETAIL';
export const DESTINATION_GUIDE_DETAIL_SUCCESS = 'DESTINATION_GUIDE_DETAIL_SUCCESS';

export const DESTINATION_GUIDE_CITYINFO = 'DESTINATION_GUIDE_CITYINFO';
export const DESTINATION_GUIDE_CITYINFO_SUCCESS = 'DESTINATION_GUIDE_CITYINFO_SUCCESS';

export const DESTINATION_GUIDE_POPULARPACK = 'DESTINATION_GUIDE_POPULARPACK';
export const DESTINATION_GUIDE_POPULARPACK_SUCCESS = 'DESTINATION_GUIDE_POPULARPACK_SUCCESS';

export const DESTINATION_GUIDE_THINGS_TO_DO = 'DESTINATION_GUIDE_THINGS_TO_DO';

const ROOT_URL = API_ROOT;
var token = "";

export function destinationGuideDetail(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/destinationguide`,

  });
  return {
    type: DESTINATION_GUIDE_DETAIL,
    payload: request
  };
}

export function destinationGuideDetailSuccess(payload) {
  return {
    type: DESTINATION_GUIDE_DETAIL_SUCCESS,
    payload: payload
  }
}

export function destinationGuideCityInfo(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/destinationguidecitiesinfo`,

  });
  return {
    type: DESTINATION_GUIDE_CITYINFO,
    payload: request
  };
}

export function destinationGuideCityInfoSuccess(payload) {
  return {
    type: DESTINATION_GUIDE_CITYINFO_SUCCESS,
    payload: payload
  }
}

export function destinationGuidePopularPack(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/destinationpopularpackages`,

  });
  return {
    type: DESTINATION_GUIDE_POPULARPACK,
    payload: request
  };
}

export function destinationGuidePopularPackSuccess(payload) {
  return {
    type: DESTINATION_GUIDE_POPULARPACK_SUCCESS,
    payload: payload
  }
}

export function destinationGuideThingsToDo(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/thingstodo/listing`,

  });
  return {
    type: DESTINATION_GUIDE_THINGS_TO_DO,
    payload: request
  };
}

export function destinationGuideThingsToDoDetail(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/thingstodo/detail`,

  });
  return {
    type: DESTINATION_GUIDE_THINGS_TO_DO,
    payload: request
  };
}