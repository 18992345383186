import React, { Component } from 'react';
import DestinationGuidePopularPlaceDetail from './DestinationGuidePopularPlaceDetail';
import Modal from '../../Modal';
import CustomizeRequestModal from '../../CustomizeRequestModal';

export default class DestinationGuidePopularPlacesSlide extends Component {

  constructor(props) {
    super(props);
    this.state = {
      slideData: props.slideData
    };

    this.showDetail = this.showDetail.bind(this);
    this.hideDetail = this.hideDetail.bind(this);
    this.showCustomRequestForm = this.showCustomRequestForm.bind(this);
    this.hideCustomRequestForm = this.hideCustomRequestForm.bind(this);
  }

  hideDetail() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  showDetail() {
    window.getFooter().setState({
      renderElement: <div className="view-detail-modal">
        <Modal
          id="jd-modal"
          show={true}
          onHide={this.hideDetail}
          header={
            <div className="vd-main-heading">
              <h3>{this.state.slideData.name}</h3>
              <p>{this.state.slideData.address}</p>
            </div>
          }
          body={<DestinationGuidePopularPlaceDetail data={this.state.slideData} />}
          bodyWrapperClass="vd-main-content"
          footer={
            <div>
              <h4>Want best packages handpicked of <span>{this.state.slideData.name}</span> just for you?</h4>
              <p>Tell us your package requirements and our expert will reach you asap.</p>
              <a href="javascript: void(0);" className="btn m-ctn-btn" onClick={this.showCustomRequestForm}>CUSTOMIZE & BOOK</a>
            </div>
          }
          footerClass="vd-footer-block"
        />
      </div>
    });
  }

  hideCustomRequestForm() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  showCustomRequestForm() {
    window.getFooter().setState({
      renderElement: <CustomizeRequestModal toggle={this.hideCustomRequestForm} />
    });
  }

  render() {
    return (
      <div className="item">
        <div className="block-visited-content">
          <figure>
            <a href="javascript:void(0);">
              <img src={this.state.slideData.city_image} alt="" />
            </a>
          </figure>
          <div className="visited-bottom-block">
            <div className="inner-visted-block">
              <a href="javascript:void(0);"><h3>{this.state.slideData.name}</h3></a>
              <label>{this.state.slideData.address}</label>
              <div className="action-visited-travel">
                <a href="javascript:void(0);" className="n-blue-btn" onClick={this.showDetail}>READ MORE</a>

                {/*<a href="javascript:void(0);" className="btn white-btn icon-btn">
                            		<svg width="16px" height="16px" viewBox="0 0 16 16">
                                		<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.600000024">
                                    		<g id="Goa---Package-Detail-Page-3" transform="translate(-1153.000000, -110.000000)" fill-rule="nonzero" stroke="#111D2D">
                                        		<g id="Group-5" transform="translate(1134.000000, 96.000000)">
                                        			<g id="Group-8">
                                                		<g id="Group-18" transform="translate(19.000000, 14.000000)">
                                                    		<g id="_ionicons_svg_md-photos">
                                                        		<path d="M15.5,13.6766544 L15.5,2.31908549 C15.5,1.31794945 14.6818182,0.5 13.6803977,0.5 L2.31960227,0.5 C1.31818182,0.5 0.5,1.31794945 0.5,2.31908549 L0.5,13.6809145 C0.5,14.6820506 1.31818182,15.5 2.31960227,15.5 L13.6846591,15.5 C14.6818182,15.4957398 15.5,14.6777904 15.5,13.6766544 Z M5.15767045,8.67949446 L7.08806818,11.4059926 L9.93039773,7.31624538 L13.6803977,12.7692417 L2.31960227,12.7692417 L5.15767045,8.67949446 Z" id="Shape"></path>
                                                    		</g>
                                                		</g>
                                            		</g>
                                        		</g>
                                    		</g>
                                		</g>
                            		</svg>
                            		<span>VIEW PHOTOS</span>
                          		</a>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}