import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export { _dispatch };

export const FAQ_LIST = 'FAQ_LIST';
export const FAQ_LIST_SUCCESS = 'FAQ_LIST_SUCCESS';


const ROOT_URL = API_ROOT;
var token = "";
export function faqList(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/faq/listing`,
  });

  return {
    type: FAQ_LIST,
    payload: request
  };
}

export function faqListSuccess(payload) {
  return {
    type: FAQ_LIST_SUCCESS,
    payload: payload
  }
}

