import React, { Component } from 'react';
import Error from './Error';
import { connect } from 'react-redux';
import {quotationRejectReason, quotationRejectReasonSuccess} from '../../actions/quotations';


class RejectQuoteForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      rejectReasons: props.rejectReasons ? props.rejectReasons : [],
      status: props.status,
      errors: {},
      reasonType: null
    };

    this.chkRejectType = this.chkRejectType.bind(this);
  }

  componentDidMount(){
    this.props.quotationRejectReason({type : 0}).then((response) => {
      if(response.value.data.result === 'success'){
        this.props.quotationRejectReasonSuccess(response.value.data.payload);
      }
    });
  }

  componentWillReceiveProps(nextProps){
    if(this.state.status != nextProps.status){
      this.setState({
        rejectReasons: nextProps.rejectReasons,
        status: nextProps.status
      });
    }
  }

  submit(e){
    e.preventDefault();
    const {reasonType} = this.state;
    const { comment } = this.refs;

    if(reasonType === null){
      this.setState({
        errors: {reject_quote: 'Please select a reason for rejection!'}
      });
    }else if(reasonType == '6'){
      const comment = this.refs.comment.value.replace(/\s+/, '');
      if(comment == ''){
        this.setState({
          errors: {reject_quote: 'Please enter some reason description!'}
        });
      } else {
        this.props.onReject(reasonType, comment);
      }
    }else{
      this.props.onReject(reasonType, comment);
    }
  }

  chkRejectType(e){
    this.setState({
        reasonType: e.target.value
    }); 
  }

	render() {
      const {errors, reasonType, rejectReasons} = this.state;

    	return ( 
        <form className="travelot-popup-panel" onSubmit={this.submit.bind(this)}>
          <ul>
            { 
                rejectReasons && rejectReasons.length > 0 &&
                rejectReasons.map((obj, index) => (
                 <li key={obj.id}>
                    <input type="radio" name="reason" defaultValue={obj.id} onClick={this.chkRejectType} ref="reason" />
                    <span>{ obj.title }</span>
                 </li>
              ))
            }
          </ul>
          {
            reasonType == '6' 
            &&
            <p>
              <label htmlFor="reject_comments">Type Other Reason</label>
              <textarea required ref="comment" id="reject_comments" name="reject_comments" rows="5" cols="55"></textarea>
              {typeof errors.reject_comments !== 'undefined' && <Error text={errors.reject_comments} />}
            </p>
          }
          
          <p>
            {typeof errors.reject_quote !== 'undefined' && <Error text={errors.reject_quote} />}
            <button className="btn n-blue-btn">Submit</button>
          </p>
        </form>
    	);
    }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    rejectReasons: state.quotations.quotaionrejectreason,
    status: state.quotations.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    quotationRejectReason: (params) => {
      return dispatch(quotationRejectReason(params));
    },
    quotationRejectReasonSuccess: (payload) => {
      dispatch(quotationRejectReasonSuccess(payload));
    },

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(RejectQuoteForm);