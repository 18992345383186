import React, { Component } from 'react';
import moment from 'moment';

export default class PackageDetailReviewSlide extends Component {
	render() {
      const {data} = this.props;

    	return (
    		<div className="col-4">
  				<div className="user-review-ui rate-3">
    				<div className="user-detail-reivew">
          					<figure>
            					{data.image_path !== null && <img src="/images/user-6.png" alt="" />}
          					</figure>
                  	<div className="name-user">
                    	<label>{data.name}</label>
                    	<time>{moment(data.created_at).format('DD MMMM YYYY')}</time>
                  	</div>
                  	<div className="rating"> 
                    	<ion-icon ios="ios-star" md="ios-star" role="img" className="hydrated" aria-label="star"></ion-icon> 
                    	<span>{parseFloat(data.rating).toFixed(1)}</span>
                  	</div>
                    </div>
                    <div className="comment-reivew">
                      	<p>{data.reviews}</p>
                    </div>
                </div>
            </div>
    	);
    }
}