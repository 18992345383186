import axios, {post} from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export {_dispatch};
export const BASICINFO_SUCCESS = 'BASICINFO_SUCCESS';
export const SAVE_FIRSTSTEP_SUCCESS = 'SAVE_FIRSTSTEP_SUCCESS';
export const SAVE_FIRSTSTEP_ERROR = 'SAVE_FIRSTSTEP_ERROR';
export const SAVE_SECONDSTEP_SUCCESS = 'SAVE_SECONDSTEP_SUCCESS';
export const SAVE_SECONDSTEP_ERROR = 'SAVE_SECONDSTEP_ERROR';
export const SAVE_THIRDSTEP_SUCCESS = 'SAVE_THIRDSTEP_SUCCESS';
export const SAVE_THIRDSTEP_ERROR = 'SAVE_THIRDSTEP_ERROR';

export const FIRSTSTEP_SUCCESS = 'FIRSTSTEP_SUCCESS';
export const SECONDSTEP_SUCCESS = 'SECONDSTEP_SUCCESS';
export const THIRDSTEP_SUCCESS = 'THIRDSTEP_SUCCESS';
export const RESET_QUOTE_REQUEST_FORM = 'RESET_QUOTE_REQUEST_FORM';

export const SAVE_FIRSTSTEP_PLAN_TRIP_SUCCESS = 'SAVE_FIRSTSTEP_PLAN_TRIP_SUCCESS';
export const SAVE_FIRSTSTEP_PLAN_TRIP_ERROR = 'SAVE_FIRSTSTEP_PLAN_TRIP_ERROR';

/**
 * Fetch basic info
 */
export function fetchBasicInfo(){

  return axios({
      method: 'GET',
      url: `${API_ROOT}/quotebelongings`,
  });
}

export function fetchUserPrefereneces(tripId){

  return axios({
      method: 'GET',
      params: {quote_id: tripId},
      url: `${API_ROOT}/getquerydetail`
  });
}

export function savePreferences(data){

  return axios({
      method: 'POST',
      data: data,
      url: `${API_ROOT}/updatetrippreferences`
  });
}

export function saveFirstStep(data){

  return (dispatch) => { 
    const request = axios({
      method: 'POST',
      data,
      url: `${API_ROOT}/quotebasicinfo`,
    }).then(response => dispatch({
        type: SAVE_FIRSTSTEP_SUCCESS,
        payload: response.data.payload
    })).catch((error) => dispatch({
        type: SAVE_FIRSTSTEP_ERROR,
        payload: error.response.data.error
    }));
  };
}

export function saveFirstStepPlanMyTrip(data){

  return (dispatch) => { 
    const request = axios({
      method: 'POST',
      data,
      url: `${API_ROOT}/quotebasicinfo`,
    }).then(response => dispatch({
        type: SAVE_FIRSTSTEP_PLAN_TRIP_SUCCESS,
        payload: response.data.payload
    })).catch((error) => dispatch({
        type: SAVE_FIRSTSTEP_PLAN_TRIP_ERROR,
        payload: error.response.data.error
    }));
  };
} 

export function fetchFirstStep(){
	return {
		type: FIRSTSTEP_SUCCESS
	};
}

export function saveSecondStep(data){

  return (dispatch) => { 
    const request = axios({
      method: 'POST',
      data,
      url: `${API_ROOT}/quotecustomizetrip`,
    }).then(response => dispatch({
        type: SAVE_SECONDSTEP_SUCCESS,
        payload: response.data.payload
    })).catch((error) => dispatch({
        type: SAVE_SECONDSTEP_ERROR,
        payload: error.response.data.error
    }));
  };
} 

export function fetchSecondStep(){
	return {
		type: SECONDSTEP_SUCCESS
	};
}

export function saveThirdStep(data){

  return (dispatch) => { 
    const request = axios({
      method: 'POST',
      data,
      url: `${API_ROOT}/finalquotebooking`,
    }).then(response => dispatch({
        type: SAVE_THIRDSTEP_SUCCESS,
        payload: response.data.payload
    })).catch((error) => dispatch({
        type: SAVE_THIRDSTEP_ERROR,
        payload: error.response.data.error
    }));
  };
} 

export function fetchThirdStep(){
	return {
		type: THIRDSTEP_SUCCESS
	};
}

export function resetQuoteRequestForm(){
  return {
    type: RESET_QUOTE_REQUEST_FORM
  };
}

export function confirmTripRequest(data){
  return axios({
      method: 'POST',
      data,
      url: `${API_ROOT}/confirmrequest`,
    });
}