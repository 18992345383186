import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MonthDropDown from '../common/MonthDropDown';
import { currencyFormat } from '../../constants';
import { destinationPackageList, destinationPackageListSuccess } from '../../actions/destinationPackage';


class DestinationPackageInfoBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destInfo: props.destInfo,
      status: props.status,
      compName: 'info'
    };

    this.filterListing = this.filterListing.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status && nextProps.compName == this.state.compName) {
      this.setState({
        destInfo: nextProps.destInfo,
        status: nextProps.status
      });
    }
  }

  filterListing(e) {
    e.preventDefault();
    this.props.destinationPackageList({ to_destination: this.props.destinationName, month: this.refs.month.state.selected }).then((response) => {
      if (response.value.data.result === 'success') {
        this.props.destinationPackageListSuccess(response.value.data.payload);
      }
    });
    // this.refs.month.state.selected
  }
  render() {
    console.log(this.state.destInfo, "this.state.destInfo")
    return (
      <div className="destination-form-block">
        <div className="heading-block">
          <div className="left-block">
            <h4>More about {this.state.destInfo.destination_name}</h4>
          </div>
          <div className="right-block">
            <Link to={"/destinationguide/" + this.state.destInfo.slug}>CLICK HERE</Link>
          </div>
        </div>
        <div className="middle-content">
          <div className="icons-blocks">
            <label className="icons-heading">THINGS TO DO</label>
            <ul>
              <li>
                <figure><img src="/images/cruise.svg" alt="" /></figure>
                <figcaption>
                  <p>Cruise</p>
                </figcaption>
              </li>
              <li>
                <figure><img src="/images/ic_sight_seeing.svg" alt="" /></figure>
                <figcaption>
                  <p>Sight Seeing</p>
                </figcaption>
              </li>
              <li>
                <figure><img src="/images/parachute.svg" alt="" /></figure>
                <figcaption>
                  <p>Paragliding</p>
                </figcaption>
              </li>
              <li>
                <figure><img src="/images/trekking.svg" alt="" /></figure>
                <figcaption>
                  <p>Trekking</p>
                </figcaption>
              </li>
              <li>
                <figure><img src="/images/beaches.svg" alt="" /></figure>
                <figcaption>
                  <p>Beach</p>
                </figcaption>
              </li>
            </ul>
          </div>
          {
            this.state.destInfo.bestmonth ?
              <div className="best-time-block">
                <label className="label-heading"> BEST TIME TO VISIT</label>
                <h4 className="value-heading">{this.state.destInfo.bestmonth}</h4>
              </div> : null
          }
          <div className="spacer10"></div>
          {
            this.state.destInfo.weather ?
              <div className="weather-block">
                <label className="label-heading"> Weather</label>
                <h4 className="value-heading">{this.state.destInfo.weather} <sup className="cellces"><span className="degree-icon"></span>c</sup> <span>(in this season)</span></h4>
              </div> : null
          }

          <div className="spacer10"></div>

          <MonthDropDown ref="month" />

          <div className="spacer10"></div>
          <div className="destination-price-block">
            <div className="left-block">
              <label className="label-heading"> STARTING FROM</label>
              <h4 className="value-heading">{currencyFormat(this.state.destInfo.price, 'INR')}  <span>(Per Person)</span></h4>
            </div>
            <div className="right-block">
              <a href="#show-listing" className="show-all-listing n-blue-btn">Show all <img src="../images/button_arrow.svg" alt="" /></a>{/* onClick={this.filterListing} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    destInfo: { ...state.destinationPackage.destinationinfo },
    status: state.destinationPackage.status,
    compName: state.destinationPackage.compName
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    destinationPackageList: (params) => {
      return dispatch(destinationPackageList(params));
    },
    destinationPackageListSuccess: (payload) => {
      dispatch(destinationPackageListSuccess(payload));
    }
  }
}

export default connect(mapStatesToProps, mapDispatchToProps)(DestinationPackageInfoBlock);