import React, { Component } from 'react';
import PackageDetailReviewSlide from './PackageDetailReviewSlide';

export default class PackageDetailReviews extends Component {
	constructor(props){
		super(props);
		this.state = {
			reviews: props.data.slice(0, 10),
			rating: props.rating
		}
	}

	readAll(){
		this.setState({
			reviews: this.props.data
		});
	}

	render() {
		const {reviews, rating} = this.state;
    	return (
    		<section className="reviews-section" id="reviewstab">
          		<div className="container">
            		<div className="reviews-head-block">
              			<div className="row">
			                <div className="col-xl-6 col-lg-5">
			                  	<div className="review-left-head-block">
			                    	<h2 className="heading-section">Reviews</h2>
			                    	<p>Read on to find out why our customers love us!</p>
			                  	</div>
			                </div>
			                <div className="col-xl-6 col-lg-7">
			                  	<div className="avg-review-block">
			                    	<span>Avg. Rating</span>
			                    	<label>{rating}</label>
			                    	<div className="rating-view">
					                    <ion-icon ios="ios-star" md="ios-star" role="img" className="hydrated" aria-label="star"></ion-icon> 
					                    <ion-icon ios="ios-star" md="ios-star" role="img" className="hydrated" aria-label="star"></ion-icon> 
					                    <ion-icon ios="ios-star" md="ios-star" role="img" className="hydrated" aria-label="star"></ion-icon> 
					                    <ion-icon ios="ios-star" md="ios-star" role="img" className="hydrated" aria-label="star"></ion-icon> 
					                    <ion-icon ios="ios-star" md="ios-star" role="img" className="hydrated" aria-label="star"></ion-icon> 
			                    	</div>
			                  	</div>
			                  	<div className="reviews-people">
			                    	<h4>{reviews.length} reviews from people who took this package</h4>
				                    <ul>
				                      <li className="rate-5"></li>
				                      <li className="rate-4" style={{ width: "132px" }}></li>
				                      <li className="rate-3" style={{ width: "74px" }}></li>
				                      <li className="rate-2" style={{ width: "38px" }}></li>
				                      <li className="rate-1" style={{ width: "17px" }}></li>
				                    </ul>
			                  	</div>
			                </div>
			            </div>
			        </div>
			        {
			        	reviews.length > 0
			        	?
			        	<div className="people-reviews-list">
	              			<div className="row">
	              				{
	              					reviews.map((item, index) => {
	              						return <PackageDetailReviewSlide key={index} data={item} />
	              					})
	              				}
					        </div>
					        {
					        	reviews.length != this.props.data.length
					        	&&
					        	<div className="view-all-n">
		                			<a href="javascript:void(0);" className="n-blue-btn" data-dismiss="modal" data-toggle="modal" data-target="#review-modal" onClick={this.readAll.bind(this)}>
		                				READ ALL {reviews.length}+ Reviews<img src="/images/button_arrow.svg" alt=">" />
		                			</a>
		              			</div>
					        }
	            		</div>
	            		:
	            		<div className="people-reviews-list">
	            			No review yet!
	            		</div>
			        }
            		
          		</div>
        	</section>
    	);
    }
}