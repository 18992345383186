import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export {_dispatch};

export const FEATURED_PACKAGES_LIST = 'FEATURED_PACKAGES_LIST';
export const FEATURED_PACKAGES_LIST_SUCCESS = 'FEATURED_PACKAGES_LIST_SUCCESS';
export const THEME_PACKAGES_LIST = 'THEME_PACKAGES_LIST';
export const THEME_PACKAGES_LIST_SUCCESS = 'THEME_PACKAGES_LIST_SUCCESS';
export const WEEKEND_PACKAGES_LIST = 'WEEKEND_PACKAGES_LIST';
export const WEEKEND_PACKAGES_LIST_SUCCESS = 'WEEKEND_PACKAGES_LIST_SUCCESS';
export const PACKAGE_DETAIL = 'PACKAGE_DETAIL';
export const PACKAGE_DETAIL_SUCCESS = 'PACKAGE_DETAIL_SUCCESS';
export const TRAVEL_PACKAGE_LIST = 'TRAVEL_PACKAGE_LIST';
export const TRAVEL_PACKAGE_LIST_SUCCESS = 'TRAVEL_PACKAGE_LIST_SUCCESS';
export const SAVED_PACKAGES_SUCCESS = 'SAVED_PACKAGES_SUCCESS';
export const DELETE_SAVED_PACKAGES_SUCCESS = 'DELETE_SAVED_PACKAGES_SUCCESS';
export const SEARCH_PACKAGES = 'SEARCH_PACKAGES';
export const SEARCH_PACKAGES_SUCCESS = 'SEARCH_PACKAGES_SUCCESS';

export const UPDATE_FILTERS_PACKAGE = 'UPDATE_FILTERS_PACKAGE';

export const RESET_FILTERS_PACKAGE = 'RESET_FILTERS_PACKAGE';

const ROOT_URL = API_ROOT;
var token = "";

export function resetFilters(filters, rerender = true){
  return {
    type: RESET_FILTERS_PACKAGE,
    payload: filters,
    rerender
  };
}

export function updateFilters(payload){
  return {
      type: UPDATE_FILTERS_PACKAGE,
      payload: payload
    }
}

export function featuredPackagesList(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/featuredpackage`,
  });

  return {
    type: FEATURED_PACKAGES_LIST,
    payload: request
  };
}

export function featuredPackagesListSuccess(payload, totalpackages){
	return {
    	type: FEATURED_PACKAGES_LIST_SUCCESS,
    	payload: payload,
      totalpackages: totalpackages
  	}
}

export function themePackagesList(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/themepackages`,
  });

  return {
    type: THEME_PACKAGES_LIST,
    payload: request
  };
}

export function themePackagesListSuccess(payload){
  return {
      type: THEME_PACKAGES_LIST_SUCCESS,
      payload: payload
    }
}

export function weekendPackagesList(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/weekendpackages`,
  });

  return {
    type: WEEKEND_PACKAGES_LIST,
    payload: request
  };
}

export function weekendPackagesListSuccess(payload){
  return {
      type: WEEKEND_PACKAGES_LIST_SUCCESS,
      payload: payload
    }
}

export function packageDetail(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/packagedetail`,
  });

  return {
    type: PACKAGE_DETAIL,
    payload: request
  };
}

export function packageDetailSuccess(payload){
  return {
      type: PACKAGE_DETAIL_SUCCESS,
      payload: payload
    }
}

export function travelPackageList(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/browsing/packages`,
  });

  return {
    type: TRAVEL_PACKAGE_LIST,
    payload: request
  };
}

export function travelPackageListSuccess(payload){
  return {
      type: TRAVEL_PACKAGE_LIST_SUCCESS,
      payload: payload
    }
}

export function removeSavedPackage(package_id, index){
  return (dispatch) => { 
    const request = axios({
      method: 'DELETE',
      data: {package_id},
      url: `${API_ROOT}/deletesavedpackage`
    }).then((response) => dispatch({
        type: DELETE_SAVED_PACKAGES_SUCCESS,
        payload: index
    }));
  };
}

export function fetchSavedPackages(){
  return (dispatch) => { 
    const request = axios({
      method: 'GET',
      url: `${API_ROOT}/savedpackagelist`,
    }).then((response) => dispatch({
        type: SAVED_PACKAGES_SUCCESS,
        payload: response.data.payload
    }));
  };
}

export function searchPackages(params) {
  console.log('params', params);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/search`,
  });

  return {
    type: SEARCH_PACKAGES,
    payload: request
  };
}

export function searchPackagesSuccess(payload, totalpackages){
  return {
      type: SEARCH_PACKAGES_SUCCESS,
      payload: payload
    }
}