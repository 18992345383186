import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BookingButtonStyle1 from '../../BookingButtonStyle1';
import { currencyFormat } from '../../constants';

export default class ThemePackageListSlide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideData: props.slideData,
      status: props.status
    }
  }

  render() {
    return (
      <li className="pacakage-list">
        <span className="most-tag">
          <i className="icons"> <img src="/images/most-viewed.svg" alt="" /></i> MOST VIEWED
           </span>

        <div className="left-block">
          <figure><img src={this.state.slideData.landscape_image} alt="" /></figure>
        </div>
        <div className="right-block">
          <div className="top-block">
            <div className="left-content">
              <label className="title">{this.state.slideData.tags}</label>
              <h3 className="product-name">{this.state.slideData.title}</h3>
              {
                this.state.slideData.rating && this.state.slideData.rating > 0 &&
                <div className="rating mob-show">
                  <ion-icon ios="ios-star" md="ios-star" role="img" className="hydrated" aria-label="star"></ion-icon>
                  <span>{this.state.slideData.rating}</span>
                </div>
              }

              <p className="mob-hide-block">{this.state.slideData.tag_line}</p>
            </div>
            <div className="right-content">
              <div className="cs-price-outer">
                {
                  this.state.slideData.rating && this.state.slideData.rating > 0 &&
                  <div className="rating mob-hide">
                    <ion-icon ios="ios-star" md="ios-star" role="img" className="hydrated" aria-label="star"></ion-icon>
                    <span>{this.state.slideData.rating}</span>
                  </div>
                }

                <div className="price-block">
                  <p><span className="start-text">Starts from</span>
                    {currencyFormat(this.state.slideData.minimumprice, 'INR')}
                  </p>
                  {/* {
                    this.state.slideData.minimumprice && this.state.slideData.minimumprice > 0 ?
                      <p><span className="start-text">Starts from</span>
                        {currencyFormat(this.state.slideData.minimumprice, 'INR')}
                      </p>
                      :
                      null
                  } */}
                </div>
              </div>
            </div>

            <div className="bi-block">
              <div className="time-visit-block">
                <label>Best time </label>
                <h4>{this.state.slideData.idealmonth}</h4>
              </div>
              {
                this.state.slideData.ideal_for && this.state.slideData.ideal_for != ''
                &&
                <div className="ideal-block">
                  <label>Ideal for </label>
                  <h4>{this.state.slideData.ideal_for}</h4>
                </div>
              }
            </div>

            <div className="price-block mob-show">
              <p><span className="start-text">Starts from</span>{currencyFormat(this.state.slideData.minimumprice, 'INR')}</p>
            </div>
          </div>
        </div>
        <div className="button-block">
          <ul>
            <li>
              <Link className="btn check-package-btn" to={`/destinationpackage/${this.state.slideData.slug}`}>EXPLORE PACKAGES</Link>{/*?categories%5B%5D=${this.props.type && this.props.type.slug} */}
            </li>
            <li>
              <BookingButtonStyle1 index={this.state.slideData.country} />
            </li>
          </ul>
        </div>
      </li>
    );
  }
}