import React, { Component } from 'react';
import { connect } from 'react-redux';
import { homeAdList, homeAdListSuccess } from '../../actions/testimonial';
import { Link } from 'react-router-dom';

class HalfScreenAds extends Component {
	constructor(props){
      super(props);
      this.state = {
         chkAds: props.chkAds,
         status: props.status
      }
   }
   	render() {
   		return(
   			<section className="add-banner-block">
	            <div className="container">
	               	<div className="row">
	               		{
                          this.state.chkAds && this.state.chkAds.length > 0 &&
                        this.state.chkAds.map((obj, index) => (
                            obj.type != 'offer_of_month' 
                            &&  
                            <div className="col-md-6 col-sm-6" key={index}>
                                <a href={obj.ad_url}>
                                    <img src={obj.path} target="_blank" alt={obj.type} title={obj.type} />
                                </a>
                            </div>
                             
                          ))
                       }
                    </div>
	            </div>
	        </section>
   		);
   	}
}

const mapStatesToProps = (state, ownProps) => {
  return {
    chkAds: state.testimonial.homeadlist,
    status: state.testimonial.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    homeAdList: () => {
      return dispatch(homeAdList());
    },
    homeAdListSuccess: (payload) => {
      dispatch(homeAdListSuccess(payload));
    },

  };
}


export default connect(mapStatesToProps, mapDispatchToProps)(HalfScreenAds);