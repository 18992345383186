import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ROUTES } from '../../routesList';
import InnerNonTransparentHeader from '../common/InnerNonTransparentHeader';
import RequestQuoteDetail from '../../RequestQuoteDetail';
import RequestInvoiceView from '../../RequestInvoiceView';

class LeftBigRightLayout extends Component {
  render() {
    console.log('called1');
    return (
      <div className="cs-wrapper">
        <InnerNonTransparentHeader />
        <Route path={ROUTES.REQUESTQUOTEDETAIL.URL} component={RequestQuoteDetail} />
        <Route path={ROUTES.REQUESTINVOICEVIEW.URL} component={RequestInvoiceView} />
      </div>
    );
  }
}
export default LeftBigRightLayout;
