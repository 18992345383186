import React, { Component, Fragment } from 'react';
import Modal from './ModalFrame';

class ShareModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };

    this.hide = this.hide.bind(this);
  }

  hide() {
    this.props.toggle();
  }

  render() {

    return (
      <Modal
        id="share-modal"
        show={true}
        onHide={this.hide}
        header={
          <div className="modal-header cstm-header">
            <button type="button" className="close" data-dismiss="modal">×</button>
            <h3>Share</h3>
            <p>Beachside Romantic Goa Honeymoon Package</p>
          </div>
        }
        body={
          <div className="modal-body">
            <div className="form-group">
              <label className="field-heading">Here is the link to share this package</label>
              <div className="share-field">
                <p>http://travalot.com/package/detail/extravagant-thailand <span className="copied-block">
                  <ion-icon class="copy-icon" ios="ios-copy" md="ios-copy"></ion-icon><span
                    class="copy-text">Copied</span>
                </span></p>
              </div>
            </div>
            <div className="form-group share-via">
              <label>Or Share via</label>
              <ul className="ss-icon-block">
                <li className="icons fb"><a href="javascript:;"><img src="/images/fbshare_icon.svg" alt="facebook" /></a>
                </li>
                <li className="icons twt"><a href="javascript:;"><img src="/images/twittershare_icon.svg"
                  alt="twitter" /></a></li>
                <li className="icons msg"><a href="javascript:;"><img src="/images/messshare_icon.svg"
                  alt="messanger" /></a></li>
                <li className="icons mail"><a href="javascript:;"><img src="/images/Shape Copy.svg" alt="mail" /></a>
                </li>
              </ul>
            </div>
          </div>
        }
      />
    );
  }
}

export default ShareModal;