import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class HeaderLogo extends Component {
	render() {
    	return (
    		<div className="logo">
        	<Link to="/">
            	<img src="/images/logo.svg" className="dsk-logo" alt="logo"/>
            	<img src="/images/m-logo.svg" className="mob-logo" alt="logo"/>
          </Link>
       	</div>
    	);
    }
}