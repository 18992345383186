import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { resetPassword, resetPasswordSuccess } from './actions/auth';

class ResetPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      submitResult : '',
      submitMessage : "Send",
      requestSent : false,
      token: this.props.match.params.index
    }
  }

  submitContactus(e){
    e.preventDefault();
    const $ = window.$$;
    var result = this.validateFields();
    if(result){
      this.setState({requestSent: true, submitMessage: ''});
      this.props.resetPassword(result).then((response) => {
      	console.log('response', response);
        this.setState({requestSent: false});
        if(response.value.data && response.value.data.result == 'success'){
          alert(response.value.data.flash_message);
        }
      }).catch(function (error) {
	    if (error.response) {
	      alert(error.response.data.error.password);
	      // console.log('data',error.response.data);
	      // console.log('status', error.response.status);
	      // console.log(error.response.headers);
	    }
	  });
    }
  }



  validateFields(){
    var password = this.refs.password.value.trim();
    var confirmpassword = this.refs.confirmpassword.value.trim();

    var errorMessagePassword = '';
    var errorMessageConfirmPassword = '';
    var token = this.state.token;
    var hasErrors = false;
    //alert(this.state.token);
    if(!password && password.length < 6){
      errorMessagePassword = "Please enter password";
      hasErrors = true;
    }

    if(!confirmpassword && confirmpassword.length < 6){
      errorMessageConfirmPassword = "Please enter confirm password";
      hasErrors = true;
    }

    if(hasErrors){
      this.setState({errorMessagePassword, errorMessageConfirmPassword});
      return false;
    }
    return {password, confirmpassword, token};
  }
  
  render() {
    return (
        <div className="main-body-content">
            <section className="contact-header">
                <div className="bg-contact" style={{ backgroundImage: `url(/images/contact-bg.jpg)` }}></div>
                <div className="container">
                    <div className="contact-head">
                        <h1 className="heading-main">Reset Password</h1>
                    </div>
                    <div className="contact-form">
                        <div className="row">
                            
                            <div className="col-12">
                                <div className="form-contact">
                                    <h3>Please fill following information -</h3>
                                    <form onSubmit = {(e) => this.submitContactus(e)} method = "post" ref = "contactUsForm" className = {this.state.requestSent?'loading':''}>
                                        <div className="form-fields">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className = {this.state.errorMessagePassword? 'field danger' : 'field'}>
                                                        <input type="password" ref = "password" className="text-field"  placeholder="Enter Password" />
                                                        <label>Password</label>
                                                        <span className="line-field"></span>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className = {this.state.errorMessageConfirmPassword? 'field danger' : 'field'}>
                                                        <input type="password" ref = "confirmpassword" className="text-field"  placeholder="Confirm Password" />
                                                        <label>Confirm Password</label>
                                                        <span className="line-field"></span>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="submit-contact">
                                                        <input type="submit" className="btn n-blue-btn" value="Recover Password" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    resetPassword: (data) => {
      return dispatch(resetPassword(data));
    }
  }
}

export default connect(null, mapDispatchToProps)(ResetPassword);