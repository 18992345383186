import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import NationalFilter from '../common/NationalFilter';
import CategoryFilter from '../theme/CategoryFilter';
import ThemeDestinationFilter from '../destination/ThemeDestinationFilter';
import DaysDurationFilter from '../partials/DaysDurationFilter';
import BudgetPerPerson from '../partials/BudgetPerPerson';
import MonthFilter from '../common/MonthFilter';
import HotelRatingType from '../hotel/HotelRatingType';
import InclusionsFilterList from '../common/InclusionsFilterList';
import CityFilterList from '../common/CityFilterList';
import { updateFilters, resetFilters } from '../../actions/holidayType';

class PackageTypeFilters extends Component {

  constructor(props) {
    super(props);
    this.state = {
      filters: props.filters,
      status: props.status
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.compName == 'filters' && nextProps.status != this.state.status) {
      this.setState({
        filters: nextProps.filters,
        status: nextProps.status
      });
    }
  }

  reset() {
    this.props.resetFilters({});
  }

  onCloseFilter() {
    let element = document.getElementById("filterToShow");
    ReactDOM.findDOMNode(element).classList.remove("activefilter");
  }

  render() {
    const { filters } = this.state;

    return (
      <aside className="sidebar-block filter-sidebar">
        <div className="close-block">
          <div className="close-btn" onClick={this.onCloseFilter}>×</div>
          <div className="clear-btn" onClick={this.reset.bind(this)}>Clear</div>
        </div>
        <div className="content-block">
          <div className="sidebar-heading-block">
            <label>Filters</label>
            <a href="javascript: void(0);" className="reset-btn" onClick={this.reset.bind(this)}> RESET ALL</a>
          </div>
          {/* start national filter */}
          {/* <NationalFilter updateFilters={this.props.updateFilters} selection={filters.destination_type} /> */}
        </div>

        {/* start inclusion filter 
          <InclusionsFilterList updateFilters={this.props.updateFilters} />*/}

        {/* start theme destination filter */}
        <ThemeDestinationFilter updateFilters={this.props.updateFilters} selection={filters.destinations} />

        {/* start duration filter */}
        <DaysDurationFilter updateFilters={this.props.updateFilters} selection={filters.duration} />

        {/* budget per person filter */}
        <BudgetPerPerson updateFilters={this.props.updateFilters} from={filters.budget_from} to={filters.budget_to} />

        {/* month filter */}
        <MonthFilter updateFilters={this.props.updateFilters} selection={filters.month} />

        {/* hotel rating type filter 
          <HotelRatingType updateFilters={this.props.updateFilters} selection={filters.hotels} />
          */}
        {/* start city filter */}
        {/*<CityFilterList updateFilters={this.props.updateFilters} />*/}
        <button className="btn cta-btn mob-show" onClick={this.onCloseFilter}>Done</button>
      </aside>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    filters: { ...state.holidayTypes.filters },
    status: state.holidayTypes.status,
    compName: state.holidayTypes.compName
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateFilters: (params) => {
      return dispatch(updateFilters(params));
    },
    resetFilters: (filters) => {
      dispatch(resetFilters(filters));
    }
  };
}
export default connect(mapStatesToProps, mapDispatchToProps)(PackageTypeFilters);