import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {submitCancellationReason} from './actions/quotations';

export default class CancelTheBookingForm extends Component {
  constructor(props){
    super(props);
    this.hideCancelTheRequestForm = this.hideCancelTheRequestForm.bind(this);
  }

  submit(e){
    e.preventDefault();
    this.hideCancelTheRequestForm();

    confirmAlert({
      title: '',
      message: 'Do you really want to cancel this booking.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            submitCancellationReason({id: this.props.id}).then((response) => {
              this.props.history.push('/dashboard/quoteslist');
              window.getFooter().setState({
                  renderElement: null
              });
            });
          }
        },
        {
          label: 'No'
        }
      ]
    })
    
    
  }

  hideCancelTheRequestForm(){
    window.getFooter().setState({
        renderElement: null
    });
  }

	render() {
    	return ( 
        <form className="travelot-popup-panel popup-infobox" onSubmit={this.submit.bind(this)}>
          <p>
            We’re sorry to hear that you are going to cancel your booking. Please go through the terms & conditions before proceeding further.
          </p>
          
          <p>
            <a href="/terms-condition" target="_blank">Terms & Conditions</a>
            <button className="btn n-blue-btn">Cancel Booking</button>
          </p>
        </form>
    	);
    }
}