// Vikas
import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';

class PackageItinerarySkeleton extends Component {
	render() {

    	return (
        	<div className="ltierary-detail-content">
                <div className="days-ltinerary">
                  	<div className="no-days">
                    	<label></label>
                    	<span></span>
                  	</div>
                    <div className="detail-days-ltinerary">
                        <div className="block-days-in">
                          	<div className="head-ltinerary--day">
                            	<h3><Skeleton width={400} height={30} /></h3>
                           	 	<div className="slider-status">
                              		<ul ref="itinerarySlider" className="bxslider-status">
                                		<li><Skeleton width={'100%'} height={277} /></li>
		                            </ul>
		                        </div>
		                        <Skeleton  width={'50%'} height={18} count={6} />
			                   	<Skeleton  width={'25%'} height={18} />
                          	</div>
                        </div>
                    </div>
                </div>
            </div>
    	);
    }
}

export default PackageItinerarySkeleton;