import React, { Component } from 'react';
import PopularDestination from './PopularDestination';
import { connect } from 'react-redux';
import { footerFeaturedDestinations, footerFeaturedDestinationsSuccess } from '../../actions/destinations';

class PopularDestinationFooter extends Component {
     constructor(props){
          super(props);
          this.state = {
               featuredDestinationsList: props.featuredDestinationsList,
               status: props.status
          }

     }

     componentDidMount(){
          this.props.footerFeaturedDestinations().then((response) => {
               if(response.value.data.result === 'success'){
                    this.props.footerFeaturedDestinationsSuccess(response.value.data.payload);
               }
          })
     }

     componentWillReceiveProps(nextProps){
          if(this.props.status != nextProps.status){
               this.setState({
                    featuredDestinationsList: nextProps.featuredDestinationsList,
                    status: nextProps.status
               })
          }
     }
	render() {
		return(
			<div className="footer-navs h-width">
                    <h4>Popular Destinations</h4>
                    <ul>
                         {
                              this.state.featuredDestinationsList && this.state.featuredDestinationsList.length > 0 &&
                              this.state.featuredDestinationsList.map((obj, index) => (
                                   <PopularDestination key={index} slideData = {obj} />
                              ))
                         }
                    </ul>
               </div>
		);
	}
}

const mapStatesToProps = (state, ownProps) => {
  return {
    featuredDestinationsList: [...state.destinations.footerfeatureddestination],
    status: state.destinations.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    footerFeaturedDestinations: () => {
      return dispatch(footerFeaturedDestinations());
    },
    footerFeaturedDestinationsSuccess: (payload) => {
      dispatch(footerFeaturedDestinationsSuccess(payload));
    },

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(PopularDestinationFooter);