// Vikas
import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import {ROUTES} from '../../routesList';
import {dateFormat, currencyFormat, quote_status, quote_status_clas} from '../../constants';
import Skeleton from 'react-loading-skeleton';

export default class QuoteItem extends Component {

	render() {
      const {data, customClassName, count} = this.props;

    	return (
          <>
          {
            typeof data === 'undefined'
            ?
            Array.from(Array(count), () => 0).map((value, index) => {
                return <div className="quotes-block-list" key={index}>
                      <div className="info-quotes-name">
                        <h4 className="location-quotes"><Skeleton height={20} width={300} /></h4>
                        <label className="hotel-info-quotes"><Skeleton width={250} /></label>
                        {/*<span className="city-quotes"><Skeleton width={250} /></span>*/}
                      </div>
                      <div className="quotes-right-block">
                        <span className="inactive"><Skeleton width={70} /></span>
                        <label className="total-quotes"><Skeleton width={100} /></label>
                        <label className="total-quotes"><Skeleton height={45} width={135} /></label>
                      </div>
                  </div>
            })
            :
            <Link to={`/trip_request/${data.id}`} className={`quotes-block-list ${customClassName} ${data.read_status == '0' ? "active-view" : ""}`}>
                <div className="info-quotes-name">
                  <h4 className="location-quotes">{data.departure} → {data.destination}<time className="time-ago">{dateFormat(data.created_at)}</time></h4>
                  <label className="hotel-info-quotes">{data.duration_text} · Hotel {data.hotel_preferences} Star · {data.title}</label>
                  {/*<span className="city-quotes">Kandy (2N), Colombo (2N)</span>*/}
                </div>
                <div className="quotes-right-block">
                    {/*
                    <span className={`status-quotes ${data.status == 2 ? "inactive" : ""}`}>{data.status < 2 ? "ACTIVE" : (data.status == 2 ? "INACTIVE" : "BOOKED")}</span>
                    */}
                  <span className={`status-quotes ${quote_status_clas[data.status ? data.status : 0]}`}>
                    { quote_status[data.status ? data.status : 0] }
                  </span>

                  

                  <label className="total-quotes">{data.budget ? currencyFormat(data.budget, 'INR') : "--"}</label>
                  {<div className="btn-sm-1 green-active-btn">VIEW QUOTE</div>}
                </div>
            </Link>
          }
          </>
        );
    }
}