import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class DestinationGuidePopularPackageSlide extends Component {
  constructor(props){
    super(props);
    //this.bindSlider = this.bindSlider.bind(this);
    this.state = {
      slideData: props.slideData
    };
  }

  // componentDidMount(){
  //     window.addEventListener('load', this.bindSlider);

  //     if(document.readyState === "complete" || document.readyState === "interactive"){
  //       this.bindSlider();
  //     }
  // }

  // componentWillReceiveProps(nextProps){
  //     if(nextProps.destname != this.state.destname){
  //         this.setState({
  //             slideData: nextProps.slideData,
  //             destname: nextProps.destname
  //         });
  //     }
  // }

  // componentDidUpdate(){
  //   window.addEventListener('load', () => {
  //     this.bindSlider();
  //   });

  //   if(document.readyState === "complete" || document.readyState === "interactive"){
  //     this.bindSlider();
  //   }
  // }

  // bindSlider(){
  //     const $ = window.$$;
  //     if($(window).width() > 991){
  //       $('.package-slides').owlCarousel({
  //         loop:false,
  //         margin:15,
  //         pagination: false,
  //         nav:true,
  //         navText: ["<img src='images/slider_icon_next.svg'/> ", "<img src='images/slider_icon_next.svg'/> "],
  //         responsive:{
  //             0:{
  //                 items:1.2
  //             },
  //             400:{
  //               items:1.4
  //           },
  //             500:{
  //               items:2.2
  //             },
  //             768:{
  //                 items:3
  //             },
  //             1000:{
  //                 items:4
  //             }
  //         }
  //       });
  //     }
  // }
	render() {
    	return (
    		<div className="item">
                <div className="carousel-inner">
                    <Link to={"/package/detail/"+ this.state.slideData.slug }>
                       	<figure><img src={this.state.slideData.landscape_image} alt=""/></figure>
                       	<figcaption className="package-detail-block">
                          	<ul className="or-btn">
                            	<li>{this.state.slideData.tags}</li>
                          	</ul>
                          	<h5>{this.state.slideData.package_name}</h5>
                          	<p>{this.state.slideData.package_name}</p>
                          	<label className="price-label">Starts from ₹ {this.state.slideData.minimum_price}/-</label>
                       	</figcaption>
                    </Link>
                </div>
            </div>
    	);
    }
}