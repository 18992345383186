import React, { Component } from 'react';

export default class TalkToUs extends Component {
   	render() {
   		return(
   			<div className="col-lg-5 col-md-12 col-sm-12 order-lg-1 ">
                <div className="booking-form-heading">
                    <h4>Did not find what you are looking for? 
                       <span> No worries!</span>
                    </h4>
                    <p>Tell us what you’re exactly looking for and you’ll get a callback from our travel experts with the multiple packages and best quotes </p>
                    <a href="javascript:;" className="btn g-btn">TALK TO US!</a>
                </div>
            </div>
   		);
   	}
}