import React, { Component } from 'react';
import PackageTypeFilters from '../package/PackageTypeFilters';
import DestinationListBlock from '../destination/DestinationListBlock';

export default class DestinationMainContentBlock extends Component {
  render() {
    return (
      <section className="main-content-block">
        <div className="container">
          <div className="row">
            <div id="filterToShow" className="col-xl-3 col-lg-12 col-md-12 col-sm-12 sidebar-outer ">
              <div className="wrap"></div>
              {/* all left side filters */}
              <PackageTypeFilters />
            </div>
            <div className="col-xl-9 col-lg-12 col-md-12 col-sm-12">
              <DestinationListBlock />
            </div>
          </div>
        </div>
      </section>
    );
  }
}