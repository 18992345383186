import React, { Component } from 'react';
import CustomizeRequestModal from './CustomizeRequestModal';

export default class PlanHolidayButton extends Component {

  constructor(props){
    super(props);
    this.showCustomRequestForm = this.showCustomRequestForm.bind(this);
    this.hideCustomRequestForm = this.hideCustomRequestForm.bind(this);
  }

  hideCustomRequestForm(){
    window.getFooter().setState({
        renderElement: null
    });
  }

  showCustomRequestForm(){
    window.getFooter().setState({
      renderElement: <CustomizeRequestModal toggle={this.hideCustomRequestForm} />
    });
  }

  render() {  
    return (
      <div style={{display: "inline-block"}}>
        <a href="javascript: void(0);" className="btn-1" onClick={this.showCustomRequestForm}>
          Plan My Holidays 
          <img src="/images/slider_icon_next.svg" />
        </a>
      </div>
    );
  }
}