import React, { Component } from 'react';
import ThemeDestinationListSlide from './ThemeDestinationListSlide';

export default class ThemePackageListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destinationlisting: props.destinationlist,
      status: props.status
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status != this.state.status) {
      this.setState({
        status: nextProps.status
      });
    }
  }

  render() {
    return (
      <div className="pacakages-listing-block package-card-block">
        <ul>
          {
            this.state.destinationlisting && this.state.destinationlisting.length > 0 &&
            this.state.destinationlisting.map((obj, index) => (
              <ThemeDestinationListSlide key={obj.id} slideData={obj} />
            ))
          }
        </ul>
      </div>
    );
  }
}