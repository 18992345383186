import React, { Component } from 'react';

export default class ListenLocals extends Component {
  render() {
    return (
      <section className="holiday-video-block expert-block">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="video-left-block">
                <label>#LocalExperts</label>
                <h4>Listen from Locals</h4>
                <p>Get to experience places from the locals who live there. They will guide you to the best places. We’ve videos from 100+ locals you can find your local guide.</p>
                <a href="javascript:;" className="orange-btn">FIND YOUR LOCAL EXPERT</a>
                <div className="video-block mob-view d-block d-md-none">
                  <button className="play-btn">
                    <ion-icon ios="ios-play" md="md-play"></ion-icon>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-sm-12 d-none d-md-block">
              <div className="video-block">
                <button className="play-btn video-play-button">
                  <span></span>
                  <p>Watch Now</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}