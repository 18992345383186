import React, { Component } from 'react';
import Modal from './Modal';
import ForgotPasswordForm from './ForgotPasswordForm';
import LoginModal from './LoginModal';

export default class ForgotPasswordModal extends Component {

  showModal(){
    window.getFooter().setState({
      renderElement: <LoginModal onHide={this.hideModal.bind(this)} />
    });
  }

  hideModal(){
    window.getFooter().setState({
        renderElement: null
    });
  }

  render() {
    return (
        <Modal 
        	id="forgotpassword-modal"
            show={true}
            onHide={this.props.onHide}
            header={<div><h3>Forgot Password</h3><p>Enter the email address associated with your account, and we’ll email you a link to reset your password.</p></div>}
            body={<ForgotPasswordForm />}
            footer={<p>Already have an account? <a href="javascript: void(0);" onClick={this.showModal.bind(this)}>Log in</a></p>}
        />
    );
  }
}