import React, { Component } from 'react';
import ThemeDestinationListing from './ThemeDestinationListing';
import BreadCrumb from '../common/BreadCrumb';
import TotalResultCounter from '../common/TotalResultCounter'
import { holidayTypeListWithDestination, holidayTypeListWithDestinationSuccess, resetFilters } from '../../actions/holidayType';
import { connect } from 'react-redux';
import ThemeDestinationListSlide from './ThemeDestinationListSlide';

class ThemeDestinationMainBlock extends Component {
    constructor(props){
          super(props);
          this.state = {
            themeDetail: {data: []},
            status: props.status,
            themeType: props.themeType,
            loaded: false
          };

          this.fetchRecords = this.fetchRecords.bind(this);
    }

    componentWillMount(){
        this.fetchRecords(this.props);
    }

    componentWillReceiveProps(nextProps){
        if(this.props.status != nextProps.status && nextProps.compName == 'filters'){
            this.fetchRecords(nextProps);
        }else if(this.state.themeType != nextProps.themeType){
          const newProps = {...nextProps, filters: {slug: nextProps.filters.slug}};
          this.fetchRecords(newProps);
        }
    }

    fetchRecords(props){
      this.setState({loaded: false});
      this.props.holidayTypeListWithDestination(props.filters).then((response) => {
          if(response.value.data.result === 'success'){
              this.setState({status: props.status, themeType: props.themeType, themeDetail: response.value.data.payload, loaded: true});
          }
      });
    }

	  render() {
      console.log(this.props, "props")
      if(!this.state.loaded){
          return null;
      }
    	return (
    		<div className="listing-right-block">
            	<div className="right-heading-block">
            		<BreadCrumb breadcrumb={this.state.themeDetail && this.state.themeDetail.breadcrumb }/>
            		<TotalResultCounter totalresult= {this.state.themeDetail && this.state.themeDetail.total }/>
            	</div>
              <div className="pacakages-listing-block package-card-block">
                <ul>
                  {
                    this.state.themeDetail.data.map((obj, index) => (
                      <ThemeDestinationListSlide key={obj.id} slideData = {obj} type={this.props.filters} />
                    ))
                  }
                </ul>
            </div>
         	</div>
    	);
    }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    status: state.holidayTypes.status,
    compName: state.holidayTypes.compName,
    filters: {...state.holidayTypes.filters}
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    holidayTypeListWithDestination: (params) => {
      return dispatch(holidayTypeListWithDestination(params));
    },
    holidayTypeListWithDestinationSuccess: (payload) => {
      dispatch(holidayTypeListWithDestinationSuccess(payload));
    }
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(ThemeDestinationMainBlock);