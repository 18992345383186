import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, browserHistory } from 'react-router';
import { contactUs } from './actions/testimonial';
class ContactUs extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      submitResult: '',
      submitMessage: "Send",
      requestSent: false
    }
  }

  submitContactus(e) {
    e.preventDefault();
    const $ = window.$$;
    var result = this.validateFields();

    if (result) {
      this.setState({ requestSent: true, submitMessage: '' });
      this.props.contactUs(result).then((response) => {
        this.setState({ requestSent: false });
        if (response.value.data && response.value.data.result == 'success') {
          this.refs.name.value = '';
          this.refs.email.value = '';
          this.refs.phno.value = '';
          this.refs.detail.value = '';
          this.refs.type_message.value = '';
          alert(response.value.data.flash_message);
        }
      }).catch(function (error) {
        if (error.response) {
          alert(error.response.data.error);
          // console.log('data',error.response.data);
          // console.log('status', error.response.status);
          // console.log(error.response.headers);
        }
      });
    }


    // if(result){
    //   this.setState({requestSent: true, submitMessage: ''});
    //   this.props.contactUs(result).then((response) => {
    //     this.setState({requestSent: false});
    //     if(response.value.data.data && response.value.data.data.surccess == 1){
    //       this.refs.contactUsForm.className = "success";
    //       this.refs.name.value = '';
    //       this.refs.email.value = '';
    //       this.refs.phno.value = '';
    //       this.refs.detail.value = '';
    //       this.refs.type_message.value = '';
    //       $(".is-floating-label").each(function(){
    //         $(this).removeClass('is-focused');
    //       })
    //       this.setState({submitResult: 'success', submitMessage: "Sent successfully"});
    //       this.setState({errorMessageName:'', errorMessageEmail:'', errorMessagePhno:'', errorMessageDetail:''});
    //       var obj = this;
    //       setTimeout(function(){
    //         obj.setState({submitResult: '', submitMessage: "Send"});
    //         obj.refs.contactUsForm.className = "";
    //       }, 5000)
    //     }else{
    //       this.setState({submitResult: 'error', submitMessage: "Failed! please try again"});
    //     }
    //   });
    // }
  }



  validateFields() {
    var name = this.refs.name.value.trim();
    var email = this.refs.email.value.trim();
    var phno = this.refs.phno.value.trim();
    var detail = this.refs.detail.value.trim();
    var type_message = this.refs.type_message.value.trim();
    var errorMessageName = '';
    var errorMessageEmail = '';
    var errorMessagePhno = '';
    var errorMessageDetail = '';
    var hasErrors = false;
    if (!name) {
      errorMessageName = "Enter your name";
      hasErrors = true;
    }
    if (!email) {
      errorMessageEmail = "Enter your email";
      hasErrors = true;
    } else {
      if (! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        errorMessageEmail = "Enter a valid email address";
        hasErrors = true;
      }
    }

    if (!phno) {
      errorMessagePhno = "Enter your phone number";
      hasErrors = true;
    }
    if (!detail) {
      errorMessageDetail = "Enter your message";
      hasErrors = true;
    }
    if (hasErrors) {
      this.setState({ errorMessageName, errorMessageEmail, errorMessagePhno, errorMessageDetail });
      return false;
    }
    return { name, email, phno, detail, type_message };

  }


  render() {
    return (
      <div className="main-body-content">
        <section className="contact-header">
          <div className="bg-contact" style={{ backgroundImage: `url(/images/contact-bg.jpg)` }}></div>
          <div className="container">
            <div className="contact-head">
              <span>Contact</span>
              <h1 className="heading-main">Get in Touch</h1>
              <p>The modern world is in a continuous movement and<br /> people everywhere are looking for.</p>
            </div>
            <div className="contact-form">
              <div className="row">
                <div className="col-4 order-md-2">
                  <div className="right-block-contact">
                    <h4>Contact Information</h4>
                    <div className="contact-fields-right">
                      <ul>
                        <li>
                          <label>For Media Relations</label>
                          <a href="mailto:info@travalot.com">info@travalot.com</a>
                        </li>
                        <li>
                          <label>Call</label>
                          <a href="tel:9877899878">(000)-000-0000</a>
                        </li>
                        <li>
                          <label>Main Website</label>
                          <a href="http://www.travalot.com">www.travalot.com</a>
                        </li>
                      </ul>
                    </div>
                    <div className="devider-contact"></div>
                    <div className="social-links">
                      <ul>
                        <li>
                          <a href="javascript:void(0);">
                            <i className="s-icons">Fb</i>
                            <span>Facebook</span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            <i className="s-icons">Tw</i>
                            <span>Twitter</span>
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">
                            <i className="s-icons">In</i>
                            <span>Instagram</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-8">
                  <div className="form-contact">
                    <h3>Send us a Message</h3>
                    <form onSubmit={(e) => this.submitContactus(e)} method="post" ref="contactUsForm" className={this.state.requestSent ? 'loading' : ''}>
                      <div className="form-fields">
                        <div className="row">
                          <div className="col-6">
                            <div className={this.state.errorMessageName ? 'field danger' : 'field'}>
                              <input type="text" ref="name" className="text-field" placeholder="Enter Name" />
                              <label>Name</label>
                              <span className="line-field"></span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className={this.state.errorMessageEmail ? 'field danger' : 'field'}>
                              <input type="email" ref="email" className="text-field" placeholder="Enter Email" />
                              <label>Email</label>
                              <span className="line-field"></span>
                            </div>
                          </div>

                          <div className="col-6">
                            <div className={this.state.errorMessagePhno ? 'field danger' : 'field'}>
                              <input type="text" className="text-field" placeholder="Enter Phone No." ref="phno" />
                              <label>Phone</label>
                              <span className="line-field"></span>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="field">
                              <input type="text" ref="type_message" className="text-field" placeholder="Type of Message" />
                              <label>Type of Message</label>
                              <span className="line-field"></span>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className={this.state.errorMessageDetail ? 'field danger' : 'field'}>
                              <textarea ref="detail" className="text-field" placeholder="Enter Message"></textarea>
                              <label>Message</label>
                              <span className="line-field"></span>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="submit-contact">
                              <input type="submit" className="btn n-blue-btn" value="SEND" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    contactUs: (data) => {
      return dispatch(contactUs(data));
    }
  }
}

export default connect(null, mapDispatchToProps)(ContactUs);