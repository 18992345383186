import React, { Component } from 'react';
import EditHolidayPreferences from './EditHolidayPreferences';
import ViewHolidayPreferences from './ViewHolidayPreferences';

export default class UserHolidayPerferences extends Component {

	constructor(props){
		super(props);
		this.state = {
			edit: false
		};

		this.toggle = this.toggle.bind(this);
	}

	toggle(){
		this.setState({
			edit: !this.state.edit
		});
	}

	render() {
		const {edit} = this.state;

    	return (
    		<div className="dashboard-block my-profile-block">
              	<div className="ui-blocks-main">
                	<div className="uiblock-head">
                  		<h3>HOLIDAY PREFERENCES</h3>
                	</div>
                	<div className="holiday-packages-fields">
                  		<h4>Complete your holiday preferences and get better quotes</h4>
                  		{
                  			edit === true
                  			?
                  			<EditHolidayPreferences toggle={this.toggle} />
                  			:
                  			<ViewHolidayPreferences toggle={this.toggle} />
                  		} 
		            </div>
              	</div>
            </div>
    	);
    }
}