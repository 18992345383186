import React, { Component } from 'react';
import {dateFormat} from './constants';

export default class UserMessageSnippet extends Component {
 
  render() {
    const {data, error} = this.props;
    if(typeof error !== 'undefined'){
      return (
        <div className="rcw-client error">
          <div className="rcw-message-text">
            <p>{error}</p>
          </div>
        </div>
      );
    }
    return (
      <div className="rcw-client">
        <div className="rcw-message-text">
          <p>{data.message}</p>
        </div>
        <div className="rcw-message-date">
          {dateFormat(data.created_at)}
        </div>
      </div>
    );
  }       
}
