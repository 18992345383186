import React, { Component } from 'react';
import { connect } from 'react-redux';
//import { updateFilters } from '../../actions/destinationPackage';


export default class DaysDurationFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: typeof props.selection !== 'undefined' ? props.selection : null
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.selection === 'undefined') {
      this.setState({
        duration: null
      });
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value
    });
    this.props.updateFilters({ duration: e.target.value });
  }

  render() {
    const { duration } = this.state;
    const durations = [{ name: '1-3', value: '1-3' }, { name: '4-6', value: '4-6' }, { name: '7-9', value: '7-9' }, { name: '10-12', value: '10-12' }, { name: 'More than 12', value: '12-0' }];
    return (
      <div className="content-block duration-tab">
        <div className="card">
          <div className="sidebar-heading-block">
            <a className="card-link" data-toggle="collapse" href="#Duration" aria-expanded="true" aria-controls="menuone">
              Duration (in days)
	                        <ion-icon ios="ios-arrow-down" md="ios-arrow-down"></ion-icon>
            </a>
            <div className="select-element">1-3 Days</div>
          </div>
          <div id="Duration" className="collapse show">
            <div className="inner-content">
              <ul className="duration-days">
                <form onChange={this.handleChange}>
                  {
                    durations.map((value, index) => {
                      return <li key={index}>
                        <input checked={duration == value.value} name="duration" type="radio" id={`days${index}`} value={value.value} />
                        <label htmlFor={`days${index}`}>{value.name} Days</label>
                      </li>
                    })
                  }
                </form>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//     updateFilters: (params) => {
//       return dispatch(updateFilters(params));
//     },
//   };
// }
// export default connect(null, mapDispatchToProps)(DaysDurationFilter);