import React, { Component } from 'react';
import moment from 'moment';
import { currencyFormat } from '../../constants';
import QuoteChat from '../../QuoteChat';
import { updateQuoteReadStatus } from '../../actions/quotations';
import { CSVLink, CSVDownload } from "react-csv";

export default class QuotaionDetailSlide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quotationDetail: props.quotationDetail,
      quote_index: props.quote_index,
      flight_status: props.flight_status,
      status: props.status,
      message: null,
      chatWindowStatus: new Date().getMilliseconds()
    };

    this.triggerQuoteChat = this.triggerQuoteChat.bind(this);
  }

  componentDidMount() {
    updateQuoteReadStatus(this.state.quotationDetail.id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.quote_index != this.state.quote_index) {
      this.setState({
        quotationDetail: nextProps.quotationDetail,
        quote_index: nextProps.quote_index,
        flight_status: nextProps.flight_status,
        status: nextProps.status
      });
      updateQuoteReadStatus(nextProps.quotationDetail.id);
    }
  }

  triggerQuoteChat(customizationType = '') {
    this.setState({
      message: customizationType,
      chatWindowStatus: new Date().getMilliseconds()
    });
  }

  render() {
    console.log('!!!!', this.state.flight_status);
    const detail = this.state.quotationDetail;
    console.log(detail, "quotationDetail")
    const formattedTotalCost = currencyFormat(parseInt(detail.total_package_price), detail.currency);
    const quoteNumber = parseInt(this.state.quote_index) + 1;
    console.log(this.state.quotationDetail.inclusion, "this.state.quotationDetail.inclusion")
    return (
      <div id="content" className="tab-content" role="tablist">
        <div id="pane-A" className="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-A">
          <div className="card-header" role="tab" id="heading-A">
            <a data-toggle="collapse" href="#collapse-A" aria-expanded="true" aria-controls="collapse-A">
              <h4>Quotation {quoteNumber}</h4>
              <p>{formattedTotalCost} ({detail.price_type})</p>
            </a>
          </div>
          <div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-A">
            <div className="card-body">
              <div className="quotation-content">
                <div className="quation-top-block">
                  <div className="quation-left">
                    <h3>Quotation {quoteNumber}</h3>
                    <p>By <span>{detail.tripadvisor_name}</span></p>
                  </div>
                  <div className="quation-right btn-block">
                    <div className="saved-packages-action">
                      {
                        detail ?
                          <CSVLink
                            data={[detail]}
                            className="btn gray-btn-second"
                            filename={"quote.csv"}
                          >
                            <ion-icon name="download"></ion-icon>Download
                          </CSVLink> : null
                      }
                      {/* <a href="javascript:void(0);" className="btn gray-btn-second"><ion-icon name="download"></ion-icon>Download</a> */}
                      <a href="javascript:void(0);" className="n-blue-btn" onClick={(e) => this.triggerQuoteChat('QUOTE')}><ion-icon name="options"></ion-icon>CUSTOMIZE</a>
                    </div>
                  </div>
                </div>
                <div id="stick-here"></div>
                <div className="listing-block" id="stickThis">
                  <ul>
                    <li className="active"><a href="#hotel"> Hotels</a></li>
                    <li><a href="#flight"> Flights</a></li>
                    <li><a href="#cab"> Cabs</a></li>
                    <li><a href="#w-include"> What’s Included</a></li>
                    <li><a href="#itinerary"> Itinerary</a></li>
                    <li><a href="#additional"> Additional Info</a></li>
                  </ul>
                  <p className="price-text">{formattedTotalCost} <span>({detail.price_type})</span></p>
                </div>

                <div className="listing-content-block">
                  <div className="content-block scroll-block" id="hotel">
                    <div className="heading">
                      <h5>Hotels</h5>
                      <a href="javascript: void(0);" onClick={(e) => this.triggerQuoteChat('HOTEL')}>Customize Hotels</a>
                    </div>

                    <ul className="hotel-listing">
                      {
                        this.state.quotationDetail.hotels && this.state.quotationDetail.hotels.length > 0 && this.state.quotationDetail.hotels.map((obj, index) => (

                          <li key={index}>
                            <div className="circle"></div>
                            <p className="italic">{obj.hotel_label_nights} Night</p>
                            <div className="hotel-img-block">
                              <figure><img src={obj.image_path} /></figure>
                            </div>
                            <div className="hotel-content-block">
                              <h3>{obj.hotel_name}</h3>
                              <p>{obj.address}</p>
                              <ul>
                                <li>{obj.hotel_rating} Star</li>
                                <li>{obj.hotel_room_type}</li>
                              </ul>
                            </div>
                          </li>

                        ))
                      }
                    </ul>
                  </div>


                  <div className="content-block scroll-block" id="flight">
                    <div className="heading">
                      <h5>Flight</h5>
                      <a href="javascript: void(0);" onClick={(e) => this.triggerQuoteChat('FLIGHT')}>REQUEST FOR FLIGHTS</a>
                    </div>

                    <div className="bottom-block">
                      <div className="flight-left-block">
                        <figure><ion-icon name="airplane"></ion-icon></figure>

                        {
                          (typeof this.state.flight_status != 'undefined' && (this.state.flight_status == 0))
                            ?
                            <h4>You have not selected Flights!</h4>
                            :
                            <h4>You have selected Flights!</h4>
                        }
                        <p dangerouslySetInnerHTML={{ __html: this.state.quotationDetail.flight_details }}></p>
                      </div>
                      <div className="buttons-block">
                        <a href="javascript:;" className="n-blue-btn">YES, Please</a>
                        <a href="javascript:;" className="outline-btn">No</a>
                      </div>
                    </div>
                  </div>

                  <div className="content-block scroll-block" id="cab">
                    <div className="heading">
                      <h5>Cabs</h5>
                      <a href="javascript: void(0);" onClick={(e) => this.triggerQuoteChat('CAB')}>CUSTOMIZE</a>
                    </div>
                    <div className="bottom-block">
                      <p dangerouslySetInnerHTML={{ __html: this.state.quotationDetail.cab_details }}></p>
                    </div>
                  </div>


                  <div className="content-block scroll-block" id="w-include">
                    <div className="heading">
                      <h5>What’s Included</h5>
                      <a href="javascript: void(0);" onClick={(e) => this.triggerQuoteChat('INCLUSION')}>CUSTOMIZE</a>
                    </div>
                    <div className="bottom-block">
                      <div className="left-block">
                        <h4>Inclusions</h4>
                        {
                          this.state.quotationDetail.inclusion
                          &&
                          this.state.quotationDetail.inclusion.length > 0
                          &&
                          this.state.quotationDetail.inclusion.map((obj, index) => (
                            <div className="field" key={index}>
                              <label>{obj.category}</label>
                              <ul>
                                {
                                  obj.title.split(',').map((item, index) => {
                                    return <li key={index}>{item}</li>
                                  })
                                }
                              </ul>
                            </div>
                          ))
                        }

                      </div>
                      <div className="right-block">
                        <h4>Exclusions</h4>
                        {
                          this.state.quotationDetail.exclusion && this.state.quotationDetail.exclusion.length > 0 && this.state.quotationDetail.exclusion.map((obj, index) => (
                            <div className="field" key={index}>
                              <label>{obj.category == "Other Inclusions" ? "Other Exclusions" : obj.category}</label>
                              <ul>
                                {
                                  obj.title.split(',').map((item, index) => {
                                    return <li key={index}>{item}</li>
                                  })
                                }
                              </ul>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>


                  <div className="content-block scroll-block" id="itinerary">
                    <div className="heading">
                      <h5>Itinerary</h5>
                      <a href="javascript: void(0);" onClick={(e) => this.triggerQuoteChat('ITINERARY')}>CUSTOMIZE</a>
                    </div>
                    <div className="bottom-block">
                      <ul className="itinerary-listing">
                        {
                          this.state.quotationDetail.itinerary && this.state.quotationDetail.itinerary.length > 0 && this.state.quotationDetail.itinerary.map((obj, index) => (
                            <li key={index}>
                              <div className="circle"></div>
                              <span><strong>Day {index + 1}</strong> ( {moment(obj.trip_date).format('DD-MM-YYYY')} )</span>
                              <h5>{obj.title} · {obj.location}</h5>
                              <p dangerouslySetInnerHTML={{ __html: obj.description }}></p>
                              <div className="spacer30"></div>
                              <p>{obj.activities}</p>
                              <div className="spacer30"></div>
                              <p>{obj.optional_activities}</p>
                            </li>

                          ))
                        }
                      </ul>
                    </div>
                  </div>
                  <div className="content-block scroll-block">
                    <div className="heading">
                      <h5>Additional Information</h5>
                    </div>
                    <div className="bottom-block" dangerouslySetInnerHTML={{ __html: this.state.quotationDetail.other_information }}>
                    </div>
                  </div>
                  <div className="content-block scroll-block" id="additional">
                    <div className="heading">
                      <h5>Terms & Conditions</h5>
                    </div>
                    <div className="bottom-block" dangerouslySetInnerHTML={{ __html: this.state.quotationDetail.terms }}>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <QuoteChat message={this.state.message} status={this.state.chatWindowStatus} index={this.state.quote_index} quote={this.state.quotationDetail} />
      </div>
    );
  }
}