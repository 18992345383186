import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import ThemeMobileFilterBlock from './components/partials/ThemeMobileFilterBlock';
import TravelPackageBlock from './components/package/TravelPackageBlock';
import { Link } from 'react-router-dom';
import { ROUTES } from './routesList';
import { connect } from 'react-redux';
import { resetFilters } from './actions/packages';
import Loader from './components/common/loader';
const queryString = require('query-string');

class TravelPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status,
      onMount: false,
      loading: true,
    }
  }

  componentWillMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 500);
    let filters = {};
    if (this.props.location.search != '') {
      filters = queryString.parse(this.props.location.search, { arrayFormat: 'bracket' });
      this.props.resetFilters(filters, false);
    }
  }

  // Update URL query string with filter change
  componentWillReceiveProps(nextProps) {
    if (this.state.onMount) {
      if (nextProps.compName == 'filters' && this.state.status != nextProps.status && nextProps.location === this.props.location) {
        this.setState({
          status: nextProps.status
        });
        this.props.history.push({
          pathname: nextProps.location.pathname,
          search: "?" + window.$$.param(nextProps.filters)
        });
      }
    } else {
      this.setState({
        onMount: true
      })
    }
  }

  onOpenFilter() {
    let element = document.getElementById("filterToShow");
    ReactDOM.findDOMNode(element).classList.add("activefilter");
  }

  render() {
    const { loading } = this.state;
    return (
      <Fragment>
        {
          loading ? <Loader /> :
            <div className="main-body-content">
              {/* <ThemeMobileFilterBlock /> */}

              <section className="mf-block">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <ul className="filter-listing">
                        <li onClick={this.onOpenFilter}>Filters</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </section>

              <section className="packages-banner all-packages">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12">
                      <div className="inner-banner-content">
                        <h1>All Packages</h1>
                        <p>Lorem ipsum is a pseudo-Latin text used in web design</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <TravelPackageBlock />
            </div>
        }
      </Fragment>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    travelPackage: state.packages.travelpackages,
    compName: state.packages.compName,
    status: state.packages.status,
    filters: { ...state.packages.filters }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetFilters: (filters) => {
      dispatch(resetFilters(filters));
    }
  };
}
export default connect(mapStatesToProps, mapDispatchToProps)(TravelPackage);