import React, { Component } from 'react';
import DestinationGuidePopularCityDetail from './DestinationGuidePopularCityDetail';
import Modal from '../../Modal';
import CustomizeRequestModal from '../../CustomizeRequestModal';

export default class DestinationGuidePopularCitiesSlide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideData: props.slideData
    };

    this.showDetail = this.showDetail.bind(this);
    this.hideDetail = this.hideDetail.bind(this);
    this.showCustomRequestForm = this.showCustomRequestForm.bind(this);
    this.hideCustomRequestForm = this.hideCustomRequestForm.bind(this);
  }

  hideDetail() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  showDetail() {
    window.getFooter().setState({
      renderElement: <div className="view-detail-modal">
        <Modal
          id="jd-modal"
          show={true}
          onHide={this.hideDetail}
          header={
            <div className="vd-main-heading">
              <h3>{this.state.slideData.name}</h3>
              <p>{this.state.slideData.address}</p>
            </div>
          }
          body={<DestinationGuidePopularCityDetail data={this.state.slideData} />}
          bodyWrapperClass="vd-main-content"
          footer={
            <div>
              <h4>Want best packages handpicked of <span>{this.state.slideData.name}</span> just for you?</h4>
              <p>Tell us your package requirements and our expert will reach you asap.</p>
              <a href="javascript: void(0);" className="btn m-ctn-btn" onClick={this.showCustomRequestForm}>CUSTOMIZE & BOOK</a>
            </div>
          }
          footerClass="vd-footer-block"
        />
      </div>
    });
  }

  hideCustomRequestForm() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  showCustomRequestForm() {
    window.getFooter().setState({
      renderElement: <CustomizeRequestModal toggle={this.hideCustomRequestForm} />
    });
  }

  render() {
    const { show } = this.state;
    return (
      <div className="item">
        <a href="javascript:void(0);" className="block-popular-content" onClick={this.showDetail}>
          <figure>
            <img src={this.state.slideData.pop_image} alt={this.state.slideData.name} />
          </figure>
          <div className="bottom-content-popular">
            <label>{this.state.slideData.tagline}</label>
            <h4>{this.state.slideData.name}</h4>
          </div>
        </a>
      </div>
    );
  }
}