import React, { Component } from 'react';

export default class RecommendedPackages extends Component {
   	render() {
   		return(
   			<section className="h-package-block add-block-outer">
	            <div className="container">
	               <div className="row">
	                  <div className="col-sm-12">
	                     <div className="adds-inner-block">
	                        <div className="add-banner">
	                           <a href="javascript:;">
	                              <figure><img src="/images/cruise-min.jpg" alt="" /></figure>
	                              <figcaption className="add-banner-content gradient1">
	                                 <label>RECOMMENDED</label>
	                                 <h4>Cruise Packages</h4>
	                                 <button href="javascript:;" className="btn white-btn navyblue"> VIEW ALL</button>
	                              </figcaption>
	                           </a>
	                        </div>
	                        <div className="add-banner">
	                           <a href="javascript:;">
	                              <figure><img src="/images/beach-min.jpg" alt="" /></figure>
	                              <figcaption className="add-banner-content w-text gradient2">
	                                 <label>RECOMMENDED</label>
	                                 <h4>Beach Packages</h4>
	                                 <button href="javascript:;" className="btn white-btn navylightblue"> VIEW ALL</button>
	                              </figcaption>
	                           </a>
	                        </div>
	                        {/* <div className="add-banner">
	                           <a href="javascript:;">
	                              <figure><img src="/images/religious.jpg" alt="" /></figure>
	                              <figcaption className="add-banner-content gradient3">
	                                 <label>RECOMMENDED</label>
	                                 <h4>Religious Trips</h4>
	                                 <button href="javascript:;" className="btn white-btn brown"> VIEW ALL</button>
	                              </figcaption>
	                           </a>
	                        </div> */}
	                        {/* <div className="add-banner">
	                           <a href="javascript:;">
	                              <figure><img src="/images/heritage-min.jpg" alt="" /></figure>
	                              <figcaption className="add-banner-content gradient4">
	                                 <label>RECOMMENDED</label>
	                                 <h4>Heritage Tours
	                                 </h4>
	                                 <button href="javascript:;" className="btn white-btn lbrown"> VIEW ALL</button>
	                              </figcaption>
	                           </a>
	                        </div> */}
	                     </div>
	                  </div>
	               </div>
	            </div>
	        </section>
   		);
   	}
}