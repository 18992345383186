import React, { Component } from 'react';
import EditTripPreferenecesModal from './EditTripPreferenecesModal';

export default class BookingButtonStyle2 extends Component {

  constructor(props){
    super(props);

    this.showCustomRequestForm = this.showCustomRequestForm.bind(this);
    this.hideCustomRequestForm = this.hideCustomRequestForm.bind(this);
  }

  hideCustomRequestForm(){
    window.document.body.className = '';
    window.getFooter().setState({
        renderElement: null
    });
  }

  showCustomRequestForm(){
    const {packageId, data, samePackage, callback, tripId} = this.props;

    window.getFooter().setState({
      renderElement: <EditTripPreferenecesModal callback={callback} tripId={tripId} toggle={this.hideCustomRequestForm} />
    });
  }

  render() {  
    const {customClass, label} = this.props;

    return (
      <a href="javascript: void(0);" className={`${typeof customClass !== 'undefined' ? customClass : "btn cta-btn"}`} onClick={this.showCustomRequestForm} dangerouslySetInnerHTML={{__html: typeof label !== 'undefined' ? label : "CUSTOMIZE & BOOK"}}></a>
    );
  }
}