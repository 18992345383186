import React, { Component } from 'react';
import FooterLogo from './FooterLogo';
import Newsletter from './Newsletter';
import TalkToUsWithPaymentOption from './TalkToUsWithPaymentOption';
import HolidayPackagesFooter from '../package/HolidayPackagesFooter';
import PopularDestinationFooter from '../destination/PopularDestinationFooter';
import FooterNaviagationPanel from './FooterNaviagationPanel';
import StaticNavigationPanel from './StaticNavigationPanel';
import CopyrightMsg from './CopyrightMsg';
import SocialNetworkLink from './SocialNetworkLink';

export default class FooterPanel extends Component {

	render() {
		return (
			<footer className="footer-block">
				<div className="footer-top-block">
					<div className="container">
						<div className="row footer-subscrbe-block">
							<div className="col-sm-6 justify-content-center align-self-center">
								<FooterLogo />
							</div>
							<div className="col-sm-6 justify-content-center align-self-center text-right">
								<Newsletter />
							</div>
						</div>
						<div className="row">
							<div className="col-lg-3 col-md-6 col-sm-6 ">
								<div className="footer-navs fooetr-newsletter-block">
									<TalkToUsWithPaymentOption />
								</div>
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<HolidayPackagesFooter />
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<PopularDestinationFooter />
							</div>
							<div className="col-lg-3 col-md-6 col-sm-6">
								<FooterNaviagationPanel />
							</div>
						</div>
					</div>
				</div>
				<div className="footer-bottom-block">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-12 order-md-4 justify-content-center align-self-center left-block">
								<StaticNavigationPanel />
							</div>
							<div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 order-md-1 justify-content-center align-self-center left-block">
								<CopyrightMsg />
							</div>
							<div className="col-lg-3 col-md-3 col-sm-6 col-xs-6 order-md-12 justify-content-center align-self-center right-block">
								<SocialNetworkLink />
							</div>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}