import React, { Component } from 'react';
import CityListSlide from './CityListSlide'

class CityFilterList extends Component {
	render() {
    	return (
      	<div className="content-block city-tab">
          <div className="card">
            <div className="sidebar-heading-block">
              <a className="card-link" data-toggle="collapse"  href="#Cities" aria-expanded="true" aria-controls="menuone">
                    Cities
                <ion-icon ios="ios-arrow-down" md="ios-arrow-down"></ion-icon>
              </a>
              <div className="select-element">Dubai</div>
            </div>
            <div id="Cities" className="collapse show">
              <div className="inner-content">
                <ul className="checkbox-listing">
                  <CityListSlide />
                  <CityListSlide />
                  <CityListSlide />
                  <CityListSlide />
                </ul>
              </div>
            </div>
          </div>
        </div>
    	);
    }
}

export default CityFilterList;