import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ROUTES } from '../../routesList';
import ProfilePicture from '../common/ProfilePicture';
import BookingButtonStyle1 from '../../BookingButtonStyle1';

export default class DashboardLeftNavigation extends Component {
  render() {
    return (
      <aside className="profile-left-block">
        <div className="profile-head">
          <figure>
            <ProfilePicture />
          </figure>
          <figcaption>
            <label>{this.props.user.name}</label>
            <Link className="edit-profile-link" to="/dashboard/editprofile">EDIT PROFILE</Link>
          </figcaption>
        </div>
        <div className="profile-nav">
          <ul>
            <li>
              <NavLink exact activeClassName="active" className="nav-link" to="/dashboard">My Bookings</NavLink>
            </li>
            <li>
              <NavLink exact className="nav-link" to="/dashboard/quoteslist">Quotes & Requests</NavLink>

              {/* <a href="quotes-profile.html">Quotes & Requests <span className="counter-profile">2 NEW</span></a> */}
            </li>
            <li>
              <NavLink exact activeClassName="active" className="nav-link" to="/dashboard/holidaypreferences">Holiday Preferences</NavLink>
            </li>
            <li>
              <NavLink exact activeClassName="active" className="nav-link" to="/dashboard/savedpackages">Saved Packages</NavLink>
            </li>
            <li>
              <NavLink exact activeClassName="active" className="nav-link" to="/dashboard/editprofile">My Profile</NavLink>
            </li>
            <li>
              <a href="">Refer & Earn</a>
            </li>
          </ul>
        </div>
        <div className="action-btn">
          <a href="javascript:void(0);" className="btn-blue"><img src="/images/phone-white.svg" alt="" /> REQUEST A CALLBACK</a>
          <BookingButtonStyle1 customClass="btn-gray" label="Get a free quote" />
        </div>
      </aside>
    );
  }
}