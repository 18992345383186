import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';

export default class ThemeTypes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      disabled: typeof props.disabled !== 'undefined' ? props.disabled : false,
      selectedValue: typeof props.selectedValue !== 'undefined' ? props.selectedValue : typeof this.props.theme_id !== 'undefined' ? this.props.theme_id : "",
      options: props.options
    };
    this.selectValue = this.selectValue.bind(this);
  }

  selectValue(e) {
    this.setState({
      selectedValue: e.target.value
    });
  }

  componentWillReceiveProps(nextProps) {
    if (typeof this.state.options === 'undefined' && typeof nextProps.options !== 'undefined') {
      this.setState({
        options: nextProps.options,
        disabled: typeof nextProps.disabled !== 'undefined' ? nextProps.disabled : false
      });
    }
  }

  render() {
    const { selectedValue, options, disabled } = this.state;

    return (
      <ul>
        {
          typeof options === 'undefined'
            ?
            <>
              <li>
                <label><Skeleton width={100} height={40} /></label>
              </li>
              <li>
                <label><Skeleton width={100} height={40} /></label>
              </li>
              <li>
                <label><Skeleton width={100} height={40} /></label>
              </li>
              <li>
                <label><Skeleton width={100} height={40} /></label>
              </li>
              <li>
                <label><Skeleton width={100} height={40} /></label>
              </li>
              <li>
                <label><Skeleton width={100} height={40} /></label>
              </li>
            </>
            :
            options.map((theme) => {
              return <li key={theme.id}>
                <input disabled={disabled}
                  checked={selectedValue == theme.id ? true : false}
                  ref="selected_theme" type="radio" name="theme_type"
                  id={`theme-type${theme.id}`} defaultValue={theme.id} onChange={this.props.realOnly ? undefined : this.selectValue} />
                <label htmlFor={`theme-type${theme.id}`}>{theme.title}</label>
              </li>
            })
        }
      </ul>
    );
  }
}