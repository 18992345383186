import React, { Component } from 'react';
import {connect} from 'react-redux'; 
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {ROUTES} from '../../routesList';
import Error from '../common/Error';
import {setPassword} from '../../actions/auth';

class SetPassword extends Component {
  constructor(props){
    super(props);
    this.state = {
      errors: {}
    };

    this.submit = this.submit.bind(this);
  }

  componentWillReceiveProps(nextProps){
      if(nextProps.status != this.state.status && nextProps.compName == 'password'){
          if(Object.keys(nextProps.errors).length > 0){
              this.setState({
                  errors: nextProps.errors,
                  status: nextProps.status
              });
          }else{
            this.props.history.push('/dashboard');
            toast.success("Your password has been set successfully!", {
              position: toast.POSITION.TOP_RIGHT
            });
          }
      }
  }

  submit(){
    this.setState({
      errors: {}
    });
    const {password, password_confirmation} = this.refs;
    const request = {password: password.value, password_confirmation: password_confirmation.value};

    this.props.setPassword(request);
  }


	render() {
    const {errors} = this.state;
  	return (
  		<div className="dashboard-block my-profile-block">
        <div className="ui-blocks-main">
          <div className="uiblock-head">
            <h3>Set New Password</h3>
            <div className="submit-holiday-action btn-profile-submit-action">
              <a href="javascript: void(0);" className="n-blue-btn save-holiday-btn" onClick={this.submit}>SAVE DETAILS</a>
              <Link className="blue-border-btn" to="/dashboard">CANCEL</Link>
            </div>
          </div>
          <div className="edit-profile-block">
        		<div className="profile-edit-main">
              <div className="block-profile-fields change-password-field">
                <label className="title">New Password</label>
                <div className="change-password fiedset">
                  <input autoFocus ref="password" className="textfield" type="password" placeholder="Set your password" />
                </div>
              </div>
              
              <div className="block-profile-fields change-password-field">
              	<label className="title">Confirm New Password</label>
              	<div className="change-password fiedset">
                	<input ref="password_confirmation" className="textfield" type="password" placeholder="Confirm password" />
              	</div>
                {typeof errors.password !== 'undefined' && <Error text={errors.password} />}
              </div>
              
              <div className="submit-holiday-action btn-profile-submit-action">
                <a href="javascript: void(0);" className="n-blue-btn save-holiday-btn" onClick={this.submit}>SAVE Password</a>
                <Link className="blue-border-btn" to="/dashboard">CANCEL</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
  	);
  }
}

const mapStatesToProps = (state) => {
  return {
    user: {...state.Auth.user},
    status: state.Auth.status,
    compName: state.Auth.compName,
    errors: state.Auth.errors
  }
}

export default connect(mapStatesToProps, {setPassword})(SetPassword);