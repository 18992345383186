import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';

export default class PackageListSkeleton extends Component {
  render() {
    return (
      <ul>
        <li className="pacakage-list">
          <div className="left-block">
            <Skeleton width={318} height={221} />
          </div>
          <div className="right-block dp-listing">
            <div className="top-block">
              <div className="left-content">
                <label className="days-title"> <Skeleton width={200} /> </label>
                <h3 className="heading2"><Skeleton height={20} /></h3>
                <h3 className="heading2"><Skeleton height={50} width={300} /></h3>
                <div className="rating mob-show">
                  <ion-icon ios="ios-star" md="ios-star" role="img" className="hydrated" aria-label="star"></ion-icon>
                  <span><Skeleton /></span>
                </div>
                <div className="things-listing ">
                  <label className="label-heading"><Skeleton /></label>
                  <ul>
                    <li><Skeleton /></li>
                  </ul>
                </div>
              </div>
              <div className="right-content">
                <div className="cs-price-outer">
                  <div className="rating mob-hide">
                    <ion-icon ios="ios-star" md="ios-star"></ion-icon>
                    <span><Skeleton /></span>
                  </div>
                  <div className="price-block">
                    <p>
                      <span className="start-text"><Skeleton /></span>
                      <Skeleton />
                    </p>
                  </div>
                </div>
                <div className="hi-block">
                  <label className="label-heading"><Skeleton /></label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ul>
              <li style={{ float: 'right' }}>
                <Skeleton width={208} height={52} />
              </li>
            </ul>
            <div style={{ clear: "both" }}></div>
          </div>
        </li>
      </ul>
    );
  }
}