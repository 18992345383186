// Vikas - 08-01-2018
import React, { Component } from 'react';

export default class Overview extends Component {
  constructor(props){
    super(props);

    const matches = typeof props.text !== 'undefined' && props.text !== null ? props.text.replace(/(?:\r\n|\r|\n)/g, '').match(/<(\w+)>(.*?)<\/\1>/g) : [];
    this.state = {
      text: typeof matches[0] !== 'undefined' ? matches[0] : props.text,
      readMore: typeof matches[1] !== 'undefined'
    };
  }

  showAllText(){
    this.setState({
      text: this.props.text,
      readMore: false
    });
  }

	render() {
      const {text, readMore} = this.state;
      
    	return (
    		<div>
          <div className="discription-block" dangerouslySetInnerHTML={{__html: text }}>
          </div>
          {readMore && <a href="javascript:void(0);" className="readmore-link-blue" onClick={this.showAllText.bind(this)}>READ MORE</a>}
        </div>
    	);
    }
}