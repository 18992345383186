import React, { Component, Fragment } from 'react';
import PackageDetailInnerBanner from './components/package/PackageDetailInnerBanner';
import PackageItinerary from './components/package/PackageItinerary';
import PackageHotels from './components/package/PackageHotels';
import FaqsPanel from './components/common/FaqsPanel';
import PackageDetailReviews from './components/package/PackageDetailReviews';
import PackagePolicy from './components/package/PackagePolicy';
import SimilarTypePackage from './components/package/SimilarTypePackage';
import InclusionsList from './components/package/InclusionsList';
import HotelDatePricingWidget from './components/package/HotelDatePricingWidget';
import BookingButtonStyle1 from './BookingButtonStyle1';
import { currencyFormat } from './constants';
import Overview from './components/common/Overview';
import Skeleton from 'react-loading-skeleton';
import Loader from './components/common/loader';
import ShareModal from './components/common/ShareModal';
import _ from 'lodash';

import { connect } from 'react-redux';
import { packageDetail, packageDetailSuccess } from './actions/packages';
import AddToWishlistButton from './components/package/AddToWishlistButton';

class PackageDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packDetail: {},
      status: props.status,
      loading: true
    };

    this.applyHotelRatingFilter = this.applyHotelRatingFilter.bind(this);
    this.bind = this.bind.bind(this);
    this.hide = this.hide.bind(this);
    this.shareModalOpen = this.shareModalOpen.bind(this);
  }

  componentDidMount() {
    this.props.packageDetail({ slug: this.props.match.params.index }).then((response) => {
      if (response.value.data.result === 'success') {
        this.setState({
          packDetail: response.value.data.payload,
          loading: false
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status && nextProps.compName == 'packageDetail') {
      this.setState({
        packDetail: nextProps.packDetail,
        status: nextProps.status
      });
    }
  }

  applyHotelRatingFilter(data, hotelsByRatings) {
    this.setState({
      packDetail: { ...this.state.packDetail, package_hotels: data }
    });
  }

  componentDidUpdate() {
    window.addEventListener('load', this.bind);

    if (document.readyState === "complete" || document.readyState === "interactive") {
      this.bind();
    }
  }

  bind() {
    if (window.$$(window).width() > 991) {
      window.$$(this.refs.slider).owlCarousel({
        margin: 15,
        pagination: false,
        nav: true,
        navText: ["<img src='images/slider_icon_next.svg'/> ", "<img src='images/slider_icon_next.svg'/> "],
        responsive: {
          0: {
            items: 1.2
          },
          400: {
            items: 1.4
          },
          500: {
            items: 2.2
          },
          768: {
            items: 3
          },
          991: {
            items: 3
          },
          1000: {
            items: 4
          }
        }
      })

      window.$$(window).scroll(function () {
        var scrollDistance = window.$$(window).scrollTop();
        // Assign active class to nav links while scolling
        window.$$('.scroll-block').each(function (i) {
          if (window.$$(this).position().top - 100 <= scrollDistance) {
            window.$$('.footer-navigation li.active').removeClass('active');
            window.$$('.footer-navigation li').eq(i).addClass('active');
          }
        });
      }).scroll();

      // $('.visited-slider-theme').owlCarousel({
      //   margin: 15,
      //   pagination: false,
      //   nav: true,
      //   navText: ["<img src='images/slider_icon_next.svg'/> ", "<img src='images/slider_icon_next.svg'/> "],
      //   responsive: {
      //     0: {
      //       items: 1.2
      //     },
      //     400: {
      //       items: 1.4
      //     },
      //     500: {
      //       items: 2.2
      //     },
      //     768: {
      //       items: 2
      //     },
      //     991: {
      //       items: 3
      //     },
      //     1000: {
      //       items: 3
      //     }
      //   }
      // })
    }
  }

  hide() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  shareModalOpen() {
    const { packageId, data, samePackage } = this.props;

    window.getFooter().setState({
      renderElement: <ShareModal toggle={this.hide} />
    });
  }


  render() {
    // if(this.state.packDetail === null)
    // 	return null;
    const { package_review_stats } = this.state.packDetail;
    const packageRating = (package_review_stats && package_review_stats.rating) ? parseFloat(package_review_stats.rating).toFixed(1) : 0;
    const reviews_cnt = (package_review_stats && package_review_stats.reviews_cnt) ? package_review_stats.reviews_cnt : 0;
    const requestData = { no_of_days: this.state.packDetail.duration, destination: this.state.packDetail.destination };

    let inclusions = this.state.packDetail.inclusions;

    let grouped = _.mapValues(_.groupBy(inclusions, 'category'),
      clist => clist.map(inclusions => _.omit(inclusions, 'category')));

    return (
      <Fragment>
        {
          this.state.loading ? <Loader /> :
            <div className="main-body-content">
              <div data-spy="scroll" data-target="#list-example" data-offset="0" className="scrollspy-example detail-container">
                {
                  this.state.packDetail.package_gallery
                    ?
                    <PackageDetailInnerBanner packageId={this.state.packDetail.id} gallery={this.state.packDetail.package_gallery} />
                    :
                    <Skeleton width={'100%'} height={410} />
                }

                <section className="detail-customize-view scroll-block">
                  <div className="container">
                    <div className="row">
                      <div className="col-8">
                        <div className="customize-block">
                          <div className="rating-block-detail">
                            {
                              this.state.packDetail && this.state.packDetail.rating != "0"
                              &&
                              <div className="rating">
                                <ion-icon ios="ios-star" md="ios-star" role="img" className="hydrated" aria-label="star">
                                </ion-icon>
                                <span>{this.state.packDetail.rating}</span>
                              </div>

                            }

                            {/*
				                 reviews_cnt != "0"
				                  &&
				                  <a href="javascript:void(0);" className="title">
							             {package_review_stats.total_travelor} Travellers · {reviews_cnt} Reviews
							             </a>
				              */}


                          </div>
                          <h1>{this.state.packDetail.package_name || <Skeleton width={'90%'} height={40} />}</h1>
                          <div className="package-time">
                            <span>{this.state.packDetail.duration} Days & {this.state.packDetail.duration && this.state.packDetail.duration - 1} Nights </span>
                            <a href="javascript:void(0);">CUSTOMIZABLE</a>
                          </div>
                          <div className="inclusions-block">
                            <label className="icons-heading">INCLUSIONS</label>
                            <InclusionsList grouped={grouped} />
                          </div>
                          <div className="booking-actions">
                            <BookingButtonStyle1 customClassNclassName="btn btn-pink-lg" packageId={this.state.packDetail.id} />
                            <BookingButtonStyle1 label="BOOK THE SAME" customClassNclassName="btn btn-border-lg" samePackage={true}
                              packageId={this.state.packDetail.id} data={requestData} theme_id={this.state.packDetail.theme_id} realOnly={true} />
                          </div>
                          <p className="note">Price is per person on twin sharing basis. Pricing will be varies for customized package.</p>
                        </div>
                      </div>

                      <div className="col-4">
                        <div className="blog-right-customize">

                          <div className="option-detail-head">
                            <AddToWishlistButton packageId={this.state.packDetail.id} />
                            <a href="javascript:void(0);" className="btn white-btn icon-btn" onClick={this.shareModalOpen}>
                              <img src="/images/ic_share.svg" alt="" />
                              <span>Share</span>
                            </a>
                          </div>
                          <div className="info-list">
                            <label className="title">PLACES</label>
                            <span>{this.state.packDetail.package_cities ? `${this.state.packDetail.package_cities} (${this.state.packDetail.duration} Days)` : <Skeleton width={120} height={30} />}</span>
                          </div>
                          {
                            typeof this.state.packDetail.package_price !== 'undefined'
                            &&
                            <HotelDatePricingWidget data={this.state.packDetail.package_price} filter={this.applyHotelRatingFilter} hotels={this.state.packDetail.package_hotels} />
                          }{/*typeof this.state.packDetail.package_hotels !== 'undefined' && */}
                        </div>
                      </div>

                    </div>
                  </div>
                </section>

                <section className="seprator-block">
                  <div className="container">
                    <div className="seprator-in"></div>
                  </div>
                </section>

                <section className="overview-block scroll-block" id="overview">
                  <div className="container">
                    <div className="row">
                      <div className="col-8">
                        <div className="overview-block-left">
                          <h2 className="heading-section">Overview</h2>
                          {
                            typeof this.state.packDetail.description !== 'undefined'
                              ?
                              <Overview text={this.state.packDetail.description} />
                              :
                              <>
                                <Skeleton width={'100%'} height={18} count={4} />
                                <Skeleton width={'50%'} height={18} />
                              </>
                          }
                          <div className="highlight-block">
                            <h3 className="subheading-block">Highlights</h3>
                            {
                              typeof this.state.packDetail.highlights !== 'undefined'
                                ?
                                <p dangerouslySetInnerHTML={{ __html: this.state.packDetail.highlights }} />
                                :
                                <>
                                  <Skeleton width={'100%'} height={18} count={6} />
                                  <Skeleton width={'50%'} height={18} />
                                </>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="detailofpackage">
                          <ul>
                            <li className="pink-list">
                              <figure><img src="/images/icon-couple.svg" alt="" /></figure>
                              <div className="disc-detail-package">
                                <label>Couple Friendly</label>
                                <p>Best package for couples</p>
                              </div>
                            </li>
                            <li className="yellow-list">
                              <figure><img src="/images/trending-icon.svg" alt="" /></figure>
                              <div className="disc-detail-package">
                                <label>Trending Package</label>
                                <p>Viewed by 40+ Times Today</p>
                              </div>
                            </li>
                            <li className="green-list">
                              <figure><img src="/images/chat-icon.svg" alt="" /></figure>
                              <div className="disc-detail-package">
                                <label>Talk to us</label>
                                <p>We’re available 24x7 for you</p>
                              </div>
                            </li>
                            <li className="blue-list">
                              <figure><img src="/images/customize-icon.svg" alt="" /></figure>
                              <div className="disc-detail-package">
                                <label>Fully Customizable</label>
                                <p>Fill your requirements & submit.</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="seprator-block">
                  <div className="container">
                    <div className="seprator-in"></div>
                  </div>
                </section>

                <section className="include-section scroll-block" id="included">
                  <div className="container">
                    <div className="list-sm">
                      {/* <h2 className="heading-section">What's Included</h2> */}
                      {/* <div className="row">
                  <div style={{ marginLeft: "2%" }}>
                    {
                      grouped["Meal Plan"] &&
                      <>
                        <h6>Meal Plan</h6>
                        <ul>
                          {
                            grouped["Meal Plan"].map(data => {
                              if (data.inclusion) {
                                return (
                                  <li>{data.title}</li>
                                )
                              }
                            })
                          }
                        </ul>
                      </>
                    }
                  </div>
                  <div style={{ marginLeft: "5%" }}>
                    {
                      grouped["Cab Type"] &&
                      <>
                        <h6>Cab Type</h6>
                        <ul>
                          {
                            grouped["Cab Type"].map(data => {
                              if (data.inclusion) {
                                return (
                                  <li>{data.title}</li>
                                )
                              }
                            })
                          }
                        </ul>
                      </>
                    }
                  </div>
                  <div style={{ marginLeft: "5%" }}>
                    {
                      grouped["AirPort Transfers"] &&
                      <>
                        <h6>AirPort Transfers</h6>
                        <ul>
                          {
                            grouped["AirPort Transfers"].map(data => {
                              if (data.inclusion) {
                                return (
                                  <li>{data.title}</li>
                                )
                              }
                            })
                          }
                        </ul>
                      </>
                    }
                  </div>
                  <div style={{ marginLeft: "5%" }}>
                    {
                      grouped["Other Inclusions"] &&
                      <>
                        <h6>Other Inclusions</h6>
                        <ul>
                          {
                            grouped["Other Inclusions"].map(data => {
                              if (data.inclusion) {
                                return (
                                  <li>{data.title}</li>
                                )
                              }
                            })
                          }
                        </ul>
                      </>
                    }
                  </div>
                </div> */}
                    </div>

                    <h2 className="heading-section">What's Included</h2>
                    <div className="include-block">
                      <div className="row">
                        <div className="col-4">
                          <div className="include-ui-block">
                            <figure>
                              <img src="/images/food-icon.svg" alt="" />
                            </figure>
                            <div className="include-disc" style={{ minHeight: 100 }}>
                              <label>Meal Plan</label>
                              <p>{
                                grouped["Meal Plan"] &&
                                <>
                                  <ul>
                                    {
                                      grouped["Meal Plan"].map(data => {
                                        if (data.inclusion) {
                                          return (
                                            <li>{data.title}</li>
                                          )
                                        }
                                      })
                                    }
                                  </ul>
                                </>
                              }</p>
                            </div>
                          </div>
                        </div>

                        <div className="col-4">
                          <div className="include-ui-block">
                            <figure>
                              <img src="/images/transport-icon.svg" alt="" />
                            </figure>
                            <div className="include-disc" style={{ minHeight: 100 }}>
                              <label>Cab Type</label>
                              <p>{
                                grouped["Cab Type"] &&
                                <>
                                  <ul>
                                    {
                                      grouped["Cab Type"].map(data => {
                                        if (data.inclusion) {
                                          return (
                                            <li>{data.title}</li>
                                          )
                                        }
                                      })
                                    }
                                  </ul>
                                </>
                              }</p>
                            </div>
                          </div>
                        </div>
                        {
                          grouped["AirPort Transfers"] &&
                          <div className="col-4">
                            <div className="include-ui-block">
                              <figure>
                                <img src="/images/air-freight.svg" alt="" />
                              </figure>
                              <div className="include-disc" style={{ minHeight: 100 }}>
                                <label>AirPort Transfers</label>
                                <p> {
                                  grouped["AirPort Transfers"] &&
                                  <>
                                    <ul>
                                      {
                                        grouped["AirPort Transfers"].map(data => {
                                          if (data.inclusion) {
                                            return (
                                              <li>{data.title}</li>
                                            )
                                          }
                                        })
                                      }
                                    </ul>
                                  </>
                                }</p>
                              </div>
                            </div>
                          </div>
                        }

                        <div className="col-4">
                          <div className="include-ui-block">
                            <figure>
                              <img src="/images/activities-icon.svg" alt="" />
                            </figure>
                            <div className="include-disc" style={{ minHeight: 100 }}>
                              <label>Other Inclusions</label>
                              <p>
                                {
                                  grouped["Other Inclusions"] &&
                                  <>
                                    <ul>
                                      {
                                        grouped["Other Inclusions"].map(data => {
                                          if (data.inclusion) {
                                            return (
                                              <li>{data.title}</li>
                                            )
                                          }
                                        })
                                      }
                                    </ul>
                                  </>
                                }
                              </p>
                            </div>
                          </div>
                        </div>


                        {/* <div className="col-4">
                    <div className="include-ui-block">
                      <figure>
                        <img src="/images/accomodation-icon.svg" alt="" />
                      </figure>
                      <div className="include-disc">
                        <label>3 days Accommodation</label>
                        <p>All days accommodation in twin rooms with private en-suite facilities.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="include-ui-block">
                      <figure>
                        <img src="/images/services-icon.svg" alt="" />
                      </figure>
                      <div className="include-disc">
                        <label>Taxes & Service Charges</label>
                        <p>All taxes for various services, food and accommodation are included in the package.</p>
                      </div>
                    </div>
                  </div> */}

                      </div>
                    </div>

                    <div className="list-sm">
                      <h3 className="subheading-block">Not Included</h3>
                      <ul>
                        {
                          inclusions && inclusions.map(data => {
                            if (!data.inclusion) {
                              return (
                                <li>{data.title}</li>
                              )
                            }
                          })
                        }
                      </ul>
                    </div>
                  </div>
                </section>
                <section className="seprator-block">
                  <div className="container">
                    <div className="seprator-in"></div>
                  </div>
                </section>

                {
                  this.state.packDetail.why_this_trip !== null
                  &&
                  this.state.packDetail.why_this_trip != ''
                  &&
                  <section className="why-choose-block scroll-block" id="whytrip">
                    <div className="container">
                      <div className="row">
                        <div className="col-7">
                          <h2 className="heading-section">Why this trip?</h2>
                          <div className="highlight-block">
                            {
                              typeof this.state.packDetail.why_this_trip !== 'undefined'
                                ?
                                <p dangerouslySetInnerHTML={{ __html: this.state.packDetail.why_this_trip }} />
                                :
                                <>
                                  <Skeleton width={'100%'} height={18} count={6} />
                                  <Skeleton width={'50%'} height={18} />
                                </>
                            }
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="blog--block">
                            <a href={this.state.packDetail && this.state.packDetail.blog_link} style={{ backgroundImage: `url(${this.state.packDetail.trip_image})` }}>
                              {/* <label>BLOG</label>
                        <h3>Quick guide to a Short 3 Days Vacation in Goa</h3>
                        <span className="station">Goa</span> */}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                }

                <section className="seprator-block">
                  <div className="container">
                    <div className="seprator-in"></div>
                  </div>
                </section>

                <PackageItinerary package_itinerary={this.state.packDetail.package_itinerary} />

                <section className="seprator-block">
                  <div className="container">
                    <div className="seprator-in"></div>
                  </div>
                </section>

                {
                  this.state.packDetail
                  &&
                  this.state.packDetail.package_hotels
                  &&
                  <PackageHotels package_hotels={this.state.packDetail.package_hotels} />
                }

                <section className="seprator-block">
                  <div className="container">
                    <div className="seprator-in"></div>
                  </div>
                </section>

                {
                  typeof this.state.packDetail.package_faq !== 'undefined'
                  &&
                  this.state.packDetail.package_faq.length > 0
                  &&
                  <>
                    <FaqsPanel faq={this.state.packDetail.package_faq} />
                    <section className="seprator-block">
                      <div className="container">
                        <div className="seprator-in"></div>
                      </div>
                    </section>
                  </>
                }



                {this.state.packDetail && this.state.packDetail.review_listing && <PackageDetailReviews rating={packageRating} data={this.state.packDetail.review_listing} />}

                <section className="seprator-block">
                  <div className="container">
                    <div className="seprator-in"></div>
                  </div>
                </section>

                <PackagePolicy />

                <section className="seprator-block">
                  <div className="container">
                    <div className="seprator-in"></div>
                  </div>
                </section>

                <SimilarTypePackage similar_package={this.state.packDetail.similar_package} />
              </div>

              <div className="footer-bg-height"></div>
              <div className="fixed-footer" id="list-example">
                <div className="footer-navigation">
                  <button className="dots-hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                  <ul className="nav">
                    <li className="active">
                      <a href="#overview">Overview</a>
                    </li>
                    <li>
                      <a href="#included">What’s Included</a>
                    </li>
                    <li>
                      <a href="#whytrip">Why this trip?</a>
                    </li>
                    <li>
                      <a href="#ltinerary">Itinerary</a>
                    </li>
                    <li>
                      <a href="#hotel-tab">Hotels</a>
                    </li>
                    <li>
                      <a href="#faqsection">FAQs</a>
                    </li>
                    <li>
                      <a href="#reviewstab">Reviews</a>
                    </li>
                  </ul>
                </div>
                <div className="stiky-footer-right">
                  <div className="contact-no web-mob-footer">
                    <a href="javascript:void(0);"><span><img src="/images/phone.svg" alt="" /></span>+91 78707 78707</a>
                  </div>
                  <div className="package-start-footer">
                    <div className="package-start-total">
                      <label>Starts from</label>
                      <span>
                        {
                          !isNaN(this.state.packDetail.minimum_price)
                            ?
                            currencyFormat(this.state.packDetail.minimum_price, "INR")
                            :
                            <Skeleton width={100} height={30} />
                        }
                      </span>
                      <sub>/ Per Person</sub>
                    </div>
                    <a href="tel:+91 78707 78707" className="call-mob"><img src="/images/phone-white.svg" alt="" /></a>
                    <BookingButtonStyle1 customClass="btn-pink-lg" packageId={this.state.packDetail.id} />
                  </div>
                </div>
              </div>
            </div>
        }
      </Fragment>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    status: state.packages.status,
    compName: state.packages.compName
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    packageDetail: (params) => {
      return dispatch(packageDetail(params));
    },
    packageDetailSuccess: (payload) => {
      dispatch(packageDetailSuccess(payload));
    }
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(PackageDetail);