import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { notificationList } from '../../actions/navigation';
import { dateFormat } from '../../constants';
import Skeleton from 'react-loading-skeleton';

class NavigationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chkNotification: []
    }
  }

  componentDidMount() {
    this.props.notificationList({ 'random': 1 }).then((response) => {
      if (response.value.data.result === 'success') {
        this.setState({
          chkNotification: response.value.data.payload.data
        })
      }
    })
  }

  render() {
    return (
      <div className="n-list-view">
        <ul>
          {
            this.state.chkNotification.length > 0
              ?
              this.state.chkNotification.map((obj, index) => (
                <li className={obj.panel_tag}>
                  <Link to={obj.type_url}>
                    <figure>
                      <img src="/images/customize-icon.svg" alt="" />
                    </figure>
                    <div className="n-detail">
                      <h2>{obj.panel_title}</h2>
                      <time>{dateFormat(obj.created_at)}</time>
                      <p dangerouslySetInnerHTML={{ __html: obj.message }} />
                    </div>
                  </Link>
                </li>
              ))
              :
              <>
                <li className="package-list">
                  <a href='#'>
                    <figure className="hidebackground">
                      <Skeleton circle={true} width={30} height={30} />
                    </figure>
                    <div className="n-detail">
                      <h2><Skeleton width={150} height={15} /></h2>
                      <time><Skeleton width={70} height={10} /></time>
                      <p>
                        <Skeleton width={290} height={12} />
                        <Skeleton width={250} height={12} />
                      </p>
                    </div>
                  </a>
                </li>
                <li className="package-list">
                  <a href='#'>
                    <figure className="hidebackground">
                      <Skeleton circle={true} width={30} height={30} />
                    </figure>
                    <div className="n-detail">
                      <h2><Skeleton width={150} height={15} /></h2>
                      <time><Skeleton width={70} height={10} /></time>
                      <p>
                        <Skeleton width={290} height={12} />
                        <Skeleton width={250} height={12} />
                      </p>
                    </div>
                  </a>
                </li>
              </>
          }
        </ul>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    notificationList: (params) => {
      return dispatch(notificationList(params));
    }
  };
}


export default connect(null, mapDispatchToProps)(NavigationList);