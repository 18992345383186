import React, { Component } from 'react';

export default class DestinationGuideListenFromLocals extends Component {
	render() {
    	return (
    		<section className="holiday-video-block listed-local-video expert-block" style={{backgroundImage: "url(../images/girl-banner.jpg)"}}>
          		<div className="container">
             		<div className="row">
                		<div className="col-md-6 col-sm-12">
                   			<div className="video-left-block">
                      			<label>#LocalsOfRajasthan</label>
			                    <h4>Listen from Locals</h4>
			                    <p>Get to experience places from the locals who live there. They will guide you to the best places. We’ve videos from 100+ locals you can find your local guide.</p>
			                    <a href="javascript:;" className="orange-btn">FIND YOUR LOCAL EXPERT</a>
			                </div>
                		</div>
             		</div>
          		</div>
       		</section>
    	);
    }
}