import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export {_dispatch};

export const HOLIDAY_TYPE_LIST_LIMIT = 'HOLIDAY_TYPE_LIST_LIMIT';
export const HOLIDAY_TYPE_LIST_LIMIT_SUCCESS = 'HOLIDAY_TYPE_LIST_LIMIT_SUCCESS';

export const HOLIDAY_TYPE_LIST_WITH_PACKAGE = 'HOLIDAY_TYPE_LIST_WITH_PACKAGE';
export const HOLIDAY_TYPE_LIST_WITH_PACKAGE_SUCCESS = 'HOLIDAY_TYPE_LIST_WITH_PACKAGE_SUCCESS';
export const HOLIDAY_TYPE_LIST_SUMMARY = 'HOLIDAY_TYPE_LIST_SUMMARY';
export const HOLIDAY_TYPE_LIST_SUMMARY_SUCCESS = 'HOLIDAY_TYPE_LIST_SUMMARY_SUCCESS';

export const HOLIDAY_TYPE_LIST_WITH_DESTINATION = 'HOLIDAY_TYPE_LIST_WITH_DESTINATION';
export const HOLIDAY_TYPE_LIST_WITH_DESTINATION_SUCCESS = 'HOLIDAY_TYPE_LIST_WITH_DESTINATION_SUCCESS';

export const UPDATE_FILTERS_THEME = 'UPDATE_FILTERS_THEME';

export const RESET_FILTERS_THEME = 'RESET_FILTERS_THEME';

export const HOLIDAY_TYPE_LIST = 'HOLIDAY_TYPE_LIST';
export const HOLIDAY_TYPE_LIST_SUCCESS = 'HOLIDAY_TYPE_LIST_SUCCESS';

export const HOLIDAY_TYPE_LIST_SUMMARY_PENDING = 'HOLIDAY_TYPE_LIST_SUMMARY_PENDING';
export const HOLIDAY_TYPE_LIST_SUMMARY_FULFILLED = 'HOLIDAY_TYPE_LIST_SUMMARY_FULFILLED';


export function resetFilters(filters, slugRequired = true){
  return {
    type: RESET_FILTERS_THEME,
    payload: filters,
    slugRequired
  };
}

const ROOT_URL = API_ROOT;
var token = "";

export function homeHolidayTypeList(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/themelisting`,
  });

  return {
    type: HOLIDAY_TYPE_LIST_LIMIT,
    payload: request
  };
}

export function homeHolidayTypeListSuccess(payload, total){
	return {
    	type: HOLIDAY_TYPE_LIST_LIMIT_SUCCESS,
    	payload: payload,
      total: total
  	}
}

export function holidayTypeListWithPakcage(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getthemedetail`,
  });

  return {
    type: HOLIDAY_TYPE_LIST_WITH_PACKAGE,
    payload: request
  };
}

export function holidayTypeListWithPakcageSuccess(payload){
  return {
      type: HOLIDAY_TYPE_LIST_WITH_PACKAGE_SUCCESS,
      payload: payload
    }
}

export function updateFilters(payload){
  return {
      type: UPDATE_FILTERS_THEME,
      payload: payload
    }
}

export function holidayTypeList(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/holidaytype/listing`,
  });

  return {
    type: HOLIDAY_TYPE_LIST,
    payload: request
  };
}

export function holidayTypeListSuccess(payload){
  return {
      type: HOLIDAY_TYPE_LIST_SUCCESS,
      payload: payload
    }
}

export function holidayTypeListSummary(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getthemedetail`,
  });

  return {
    type: HOLIDAY_TYPE_LIST_SUMMARY,
    payload: request
  };
}

export function holidayTypeListSummarySuccess(payload){
  return {
      type: HOLIDAY_TYPE_LIST_SUMMARY_SUCCESS,
      payload: payload
    }
}

export function holidayTypeListWithDestination(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getthemedestination`,
  });

  return {
    type: HOLIDAY_TYPE_LIST_WITH_DESTINATION,
    payload: request
  };
}

export function holidayTypeListWithDestinationSuccess(payload){
  return {
      type: HOLIDAY_TYPE_LIST_WITH_DESTINATION_SUCCESS,
      payload: payload
    }
}
