import React, { Component } from 'react';
import {arrayColumn, months, currencyFormat} from '../../constants';

class HotelDatePricingWidget extends Component {

	constructor(props){
		super(props);
		const {data} = props;

		this.state = {
			prices: arrayColumn(data, 'price', true),
			monthsList: arrayColumn(data, 'month'),
			ratings: arrayColumn(data, 'hotel_rating', true),
			selection: {}
		};

		this.handleChange = this.handleChange.bind(this);
		this.filterHotels = this.filterHotels.bind(this);
		// this.filter = this.filter.bind(this);
		this.bind = this.bind.bind(this);
	}

	componentDidMount(){
		window.addEventListener('load', this.bind);

	    if(document.readyState === "complete" || document.readyState === "interactive"){
	      this.bind();
	    }
	}

	bind(){
	    window.$$(this.refs.trip_month).selectpicker();
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevState.selection.rating != this.state.selection.rating){
			window.$$(this.refs.trip_month).selectpicker('refresh');
		}
    }

	handleChange(e){
		const {name, value} = e.target;
		let {prices, monthsList, ratings} = this.state;
		let result = {};
		const selection = {...this.state.selection, [name]: value};

		// Filter
		switch(name){
			case 'rating':
				result = this.filterMonthsByHotelRating(value);
				monthsList = result.months;
				prices = result.prices;
				break;
			case 'month':
				result = this.filterRatingsByMonth(value);
				ratings = result.ratings;
				prices = result.prices;
				break;
		}	

		this.setState({
			selection,
			monthsList,
			ratings,
			prices
		});

		// Filter hotels as per rating
		this.filterHotels(ratings, selection.rating)
	}

	filterMonthsByHotelRating(rating){
		const {data} = this.props;
		const months =  arrayColumn(
					data.filter((item) => {
						return item.hotel_rating == rating;
					}),
					'month'
				);
		const prices = arrayColumn(
					data.filter((item) => {
						return item.hotel_rating == rating;
					}),
					'price',
					true
				);

		return {months, prices}
	}

	filterRatingsByMonth(month){
		const {data} = this.props;
		const ratings =  arrayColumn(
					data.filter((item) => {
						return item.month == month;
					}),
					'hotel_rating'
				);
		const prices = arrayColumn(
					data.filter((item) => {
						return item.month == month;
					}),
					'price',
					true
				);

		return {ratings, prices}
	}

	filterHotels(ratings, selection){
		const {hotels, filter} = this.props;
		if(typeof selection !== 'undefined' && ratings.indexOf(parseInt(selection)) > -1){
			ratings = [parseInt(selection)];
		}
    // Update hotels list
    console.log(hotels, "hotels");
		filter(
				// hotels.filter((item) => {
				// 	return ratings.indexOf(parseInt(item.star_rating)) > -1;
				// })
			);
	}

   	render() {
   		const {prices, monthsList, ratings} = this.state;

      	return (
      		<div>
      			<div className="info-list">
                    <label className="title">HOTEL INCLUDED</label>
                    <div className="rating-hotel">
                    	{
                    		ratings.map((rating) => {
                    			return <label key={rating} className={`rating-btn rate-3`}>
				                        	<input defaultValue={rating} type="radio" name="rating" onChange={this.handleChange} />
				                        	<span>{rating} Star</span>
				                        	<ion-icon ios="ios-star" md="ios-star" role="img" className="hydrated" aria-label="star"></ion-icon> 
				                      	</label>
                    		})
                    	}
                    </div>
                    <div className="note">Note: You can change hotel type and price will be updated</div>
                </div>
                <div className="price-per-month">
                    <div className="select-title">Price for month</div>
                    <select ref="trip_month" name="month" className="selectpicker picker-style" onChange={this.handleChange}>
                    	{
                    		monthsList.map((month) => {
                    			return <option key={month} value={month}>{months[month]}</option>
                    		})
                    	}
                    </select>
                </div>
                <div className="total-package-price">
                    <div className="show-total-view">
                    	<span className="tag-icon">
                        	<img src="/images/rupee-tag.svg" alt="" />
                      	</span>
                      	<div className="price-start">
                        	<span>PRICE STARTS FROM</span>
                        	<label className="price">{currencyFormat(prices[0], 'INR')}*<sub>Per <br/>Person</sub></label>
                      	</div>
                    </div>
                </div>
      		</div>
      	);
    }
}
export default HotelDatePricingWidget;