import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export {_dispatch};

export const HOME_STORY_LIST = 'HOME_STORY_LIST';
export const HOME_STORY_LIST_SUCCESS = 'HOME_STORY_LIST_SUCCESS';

export const STORY_LIST = 'STORY_LIST';
export const STORY_LIST_SUCCESS = 'STORY_LIST_SUCCESS';

export const STORY_DETAIL = 'STORY_DETAIL';
export const STORY_DETAIL_SUCCESS = 'STORY_DETAIL_SUCCESS';

export const MOST_VIEW_STORY = 'MOST_VIEW_STORY';
export const MOST_VIEW_STORY_SUCCESS = 'MOST_VIEW_STORY_SUCCESS';

const ROOT_URL = API_ROOT;
var token = "";

export function homeStoryList(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/trvelotstories?random=1`,
    
  });

  return {
    type: HOME_STORY_LIST,
    payload: request
  };
}

export function homeStoryListSuccess(payload, total){
	return {
    	type: HOME_STORY_LIST_SUCCESS,
    	payload: payload,
      total: total
  	}
}

export function storyList(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/travelstorylisting`,
  });

  return {
    type: STORY_LIST,
    payload: request
  };
}

export function storyListSuccess(payload, total){
  return {
      type: STORY_LIST_SUCCESS,
      payload: payload
    }
}

export function storyDetail(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/trvelotstories`,
  });

  return {
    type: STORY_DETAIL,
    payload: request
  };
}

export function storyDetailSuccess(payload){
  return {
      type: STORY_DETAIL_SUCCESS,
      payload: payload
    }
}

export function mostViewStory(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/mostviewedtravelstories`,
  });

  return {
    type: MOST_VIEW_STORY,
    payload: request
  };
}

export function mostViewStorySuccess(payload){
  return {
      type: MOST_VIEW_STORY_SUCCESS,
      payload: payload
    }
}

