import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { dateFormat } from '../../constants';

export default class BlogList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideData: props.slideData
    }
  }
  render() {
    // console.log(this.state.slideData, "slideData");
    return (
      <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 ">
        <div className="blog-wrapper">
          <figure className="blog-img">
            <img src={this.state.slideData.thumbnail} alt={this.state.slideData.post_title} />
          </figure>
          <figcaption className="blog-content">
            <label>TRAVEL BLOG</label>
            <h4>{this.state.slideData.post_title}</h4>{/*<span className="light-orange">{this.state.slideData && this.state.slideData.tags}</span> */}
            <p>{this.state.slideData.post_excerpt}</p>
            <div className="blog-bottom-block">
              <div className="profile-block">
                <figure className="user-img"><img src={this.state.slideData.author_profile_image ? this.state.slideData.author_profile_image : '/images/user_placeholder.png'} alt={this.state.slideData.display_name} /></figure>
                <figcaption className="profile-username">
                  <h5>{this.state.slideData.display_name}</h5>
                  <span className="days-number">{dateFormat(this.state.slideData.post_date)}</span>
                </figcaption>
              </div>
              <div className="button-block">
                <Link className="btn readnow-btn" to={"/blog/" + this.state.slideData.post_name}> Read now</Link>
              </div>
            </div>
          </figcaption>
        </div>
      </div>
    );
  }
}


