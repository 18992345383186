import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { ROUTES } from '../../routesList';
import InnerHeader from '../common/InnerHeader';
import FooterPanel from '../common/FooterPanel';
import ThemesList from '../../ThemesList';
import DestinationPackage from '../../DestinationPackage';
import PackageDetail from '../../PackageDetail';
import ProfileDashborad from '../../ProfileDashborad';
import TravelPackages from '../../TravelPackages';
import AboutUs from '../../AboutUs';
import TermsCondition from '../../TermsCondition';
import PrivacyPolicy from '../../PrivacyPolicy';
import DestinationsList from '../../DestinationsList';
import ContactUs from '../../ContactUs';
import ThemesFullList from '../../ThemesFullList';
import HelpSupport from '../../HelpSupport';
import Cancellation from '../../Cancellation';
import OurPartners from '../../OurPartners';
import Career from '../../Career';
import Notification from '../../Notification';
import TravelotFaq from '../../TravelotFaq';
import ResetPassword from '../../ResetPassword';

class InnerLayout extends Component {

  render() {
    return (
      <div className="cs-wrapper">
        <InnerHeader />
        <Route path={ROUTES.TRAVELPACKAGES.LIST.URL} component={TravelPackages} />
        <Route path={ROUTES.THEME.LIST.URL} component={ThemesList} />
        <Route path={ROUTES.THEME.LISTING.URL} component={ThemesFullList} />
        <Route path={ROUTES.DESTINATIONPACKAGE.LIST.URL} component={DestinationPackage} />
        <Route path={ROUTES.PACKAGE.DETAIL.URL} component={PackageDetail} />
        <Route path={ROUTES.DASHBOARD.LIST.URL} component={ProfileDashborad} />
        <Route path={ROUTES.ABOUTUS.URL} component={AboutUs} />
        <Route path={ROUTES.TERMS.URL} component={TermsCondition} />
        <Route path={ROUTES.PRIVACYPOLICY.URL} component={PrivacyPolicy} />
        <Route path={ROUTES.CONTACTUS.URL} component={ContactUs} />
        <Route path={ROUTES.DESTINATION.LIST.URL} component={DestinationsList} />
        <Route path={ROUTES.HELPSUPPORT.URL} component={HelpSupport} />
        <Route path={ROUTES.CANCELLATION.URL} component={Cancellation} />
        <Route path={ROUTES.OURPARTNERS.URL} component={OurPartners} />
        <Route path={ROUTES.CAREER.URL} component={Career} />
        <Route path={ROUTES.NOTIFICATION.URL} component={Notification} />
        <Route path={ROUTES.TRAVELOTFAQ.URL} component={TravelotFaq} />
        <Route path={ROUTES.RESETPASSWORD.URL} component={ResetPassword} />
        <FooterPanel />
      </div>
    );
  }
}
export default InnerLayout;
