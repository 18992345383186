import React, { Component, Fragment } from 'react';

export default class Modal extends Component {

  componentDidMount() {
    const { show, id, onHide } = this.props;

    if (typeof show !== 'undefined' && show === true) {
      window.$$('#' + id).modal('show');

      // Remove modal from dom as it hides
      if (typeof onHide !== 'undefined') {
        window.$$('#' + id).on('hidden.bs.modal', (e) => {
          this.props.onHide();
        });
      }
    }
  }

  render() {
    const { header, footer, footerClass, body, bodyWrapperClass, id } = this.props;

    return (
      <Fragment>
        <div class="share-modal-outer">
          <div class="modal-block">
            <div class="modal fade" id="share-modal">
              <div class="modal-dialog ">
                <div class="modal-content">
                  {header}
                  {body}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}