import {
  _dispatch, QUOTATION_LIST_DETAIL_SUCCESS, QUOTATION_INVOICE_DETAIL_SUCCESS, QUOTATION_REJECT_REASON_SUCCESS
} from '../actions/quotations';

const INITIAL_STATE = { quotaiondetail:null, quotationinvoicedetail:null, quotaionrejectreason: null, status:null };

export default function(state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch(action.type) {

    case QUOTATION_LIST_DETAIL_SUCCESS:
      return (() => {
        const quotaiondetail = action.payload;
        return _dispatch({ ...state, quotaiondetail}, true);
      })();

    case QUOTATION_INVOICE_DETAIL_SUCCESS:
      return (() => {
        const quotationinvoicedetail = action.payload;
        return _dispatch({ ...state, quotationinvoicedetail}, true);
      })();

    case QUOTATION_REJECT_REASON_SUCCESS:
      return (() => {
        const quotaionrejectreason = action.payload;
        return _dispatch({ ...state, quotaionrejectreason}, true);
      })();

    default:
      return state;
  }
}