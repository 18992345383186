import React, { Component } from 'react';
import Modal from './Modal';
import SignupForm from './SignupForm';
import LoginModal from './LoginModal';

export default class SignupModal extends Component {

  showModal() {
    window.getFooter().setState({
      renderElement: <LoginModal onHide={this.hideModal.bind(this)} />
    });
  }

  hideModal() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  render() {
    return (
      <Modal
        id="signup-modal"
        show={true}
        onHide={this.props.onHide}
        header={<div><h3>Sign up to travalot</h3><p>Fill the details to Sign Up</p></div>}
        body={<SignupForm />}
        footer={<p>Already have an account? <a href="javascript: void(0);" onClick={this.showModal.bind(this)}>Log in</a></p>}

      />
    );
  }
}