import React, { Component } from 'react';
import {dateFormat} from './constants';

export default class ResponseMessageSnippet extends Component {
 
  render() {
    const {data} = this.props;

    return (
      <>
        <img src={data.user_image} className="rcw-avatar" alt="profile" />
        <div className="rcw-response">
          <div className="rcw-message-text">
            <p>{data.message}</p>
          </div>
          <div className="rcw-message-date">
            {dateFormat(data.created_at)}
          </div>
        </div>
      </>
    );
  }       
}
