import React, { Component } from 'react';

export default class WhatToEat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whattoeat: props.whattoeat,
      destname: props.destname
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.destname != this.state.destname) {
      this.setState({
        whattoeat: nextProps.whattoeat,
        destname: nextProps.destname
      });
    }
  }

  render() {
    return (
      <section className="eat-block scroll-block" id="what-eat">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="sm-heading-main">
                <h4>What to Eat in {this.state.destname}</h4>
                <p>Here ‘re the list of popular food of {this.state.destname}</p>
              </div>
            </div>
            {
              this.state.whattoeat.map((obj, index) => (
                <div className="col-md-6">
                  <div className="eat-inner-block">
                    <h4>{obj.title}</h4>
                    <p dangerouslySetInnerHTML={{ __html: obj.description }} />
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </section>
    );
  }
}