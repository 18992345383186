import React, { Component } from 'react';
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import Modal from './Modal';
import Error from './components/common/Error';
import TripActivities from './components/common/TripActivities';
import QuantityBox from './components/common/QuantityBox';
import HotelRatings from './components/common/HotelRatings';
import FlightOptions from './components/common/FlightOptions';
import CabOptions from './components/common/CabOptions';
import CabTypes from './components/common/CabTypes';

import DateRangePicker from './components/common/DateRangePicker';
import DateMonthPicker from './components/common/DateMonthPicker';
import Dropdown from './components/common/Dropdown';
import ThemeTypes from './components/common/ThemeTypes';


import { fetchBasicInfo, savePreferences, fetchUserPrefereneces } from './actions/customPackage';

class EditTripPreferenecesModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      basicinfo: null,
      data: {},
      errors: {}
    };

    this.hide = this.hide.bind(this);
  }

  componentWillMount() {
    let basicinfo = null;
    let data = null;
    fetchUserPrefereneces(this.props.tripId).then(response => {
      data = response.data.payload;
      if (basicinfo !== null) {
        this.setState({
          basicinfo,
          data
        });
      }
    });
    fetchBasicInfo().then(response => {
      basicinfo = response.data.payload;
      if (data !== null) {
        this.setState({
          basicinfo,
          data
        });
      }
    });
  }

  hide() {
    this.props.toggle();
  }

  // Toggle between Fixed Date or Month
  toggleFlexibleTiming(e) {
    e.preventDefault();
    this.setState({
      data: { ...this.state.data, flexible_timing: !this.state.data.flexible_timing }
    });
  }

  submit(e) {
    e.preventDefault();
    const { hotel_preferences, flight_status, adults, children, infants, cab_status, cab_type, selected_theme, no_of_days, departure_date, flexible_timing, additional_requirements, budget, budget_type, activities } = this.refs;
    // Call action
    savePreferences({
      selected_theme: selected_theme.state.selectedValue,
      no_of_days: no_of_days.refs.no_of_days.value,
      departure_date: departure_date.state.selectedValue,
      quote_id: this.props.tripId,
      flexible_timing: this.state.data.flexible_timing,
      hotel_preferences: hotel_preferences.state.selectedValue,
      flight_status: flight_status.state.selectedValue,
      adults: adults.refs.adults.value,
      children: children.refs.children.value,
      infants: infants.refs.infants.value,
      cab_status: cab_status.state.selectedValue,
      cab_type: cab_type.state.selectedValue,
      activities: activities.state.selectedValue.join(','),
      additional_requirements: additional_requirements.value,
      budget: budget.value,
      budget_type: budget_type.value
    })
      .then((response) => {
        toast.success("Trip preferences has been updated successfully!", {
          position: toast.POSITION.TOP_RIGHT
        });
        this.hide();
        this.props.callback();
      }).catch((error) => {
        this.setState({
          errors: error.response.data.error
        });
      });
  }

  render() {
    const disabled = this.state.data && this.state.data.installment_statics == 0 ? false : true;
    const { basicinfo, data } = this.state;
    const { errors } = this.state;
    const { hotel_preferences, flight_status, adults, children, infants, cab_status, cab_type, selected_theme, no_of_days, departure_date, flexible_timing, additional_requirements, budget, budget_type, activities } = data;

    return (
      <div className="cutsomize-pacakage-block edit-pref">
        <div className="modal-block">
          <Modal
            id="customize-modal"
            show={true}
            onHide={this.hide}
            body={
              <div className="customize-right-block">
                <div className="right-top-block">
                  <h3>Edit Your Preferences</h3>
                  <button type="button" className="close cncl-btn" data-dismiss="modal">CLOSE</button>
                </div>
                {
                  basicinfo !== null && Object.keys(data).length > 0
                    ?
                    <>
                      <div className="customize-form-block">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label className="form-title">Type</label>
                              <div className="packages-types check-box-list">
                                <ThemeTypes disabled={disabled} ref="selected_theme" options={basicinfo.themes} selectedValue={selected_theme} />
                              </div>
                              {typeof errors.selected_theme !== 'undefined' && <Error text={errors.selected_theme} />}
                            </div>
                          </div>
                          <div className="col-sm-6 ft-block">
                            <div className="row">
                              <div className="col-sm-8">
                                <div className="form-group">
                                  <label className="form-title">&nbsp;</label>
                                  <ul>
                                    <li>
                                      <button disabled={disabled} className={`flexible-timing ${flexible_timing ? "active" : ""}`} onClick={this.toggleFlexibleTiming.bind(this)}>
                                        <ion-icon name="resize"></ion-icon>
							                                                Flexible Timings
							                                            </button>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label className="form-title">DAYS</label>
                                  <div className="person-block sidenumber">
                                    <div className="age-block">
                                      <QuantityBox disabled={disabled} selectedValue={no_of_days} name="no_of_days" ref="no_of_days" />
                                    </div>
                                  </div>
                                  {typeof errors.no_of_days !== 'undefined' && <Error text={errors.no_of_days} />}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label className="form-title required">
                                {flexible_timing ? 'DEPARTURE MONTH' : 'DEPARTURE DATE'}
                              </label>
                              <div className="custom-datepicker">
                                {
                                  flexible_timing
                                    ?
                                    <DateMonthPicker disabled={disabled} selectedValue={departure_date} single={true} name="departure_date" ref="departure_date" />
                                    :
                                    <DateRangePicker disabled={disabled} selectedValue={departure_date} single={true} name="departure_date" ref="departure_date" />
                                }
                              </div>
                              {typeof errors.departure_date !== 'undefined' && <Error text={errors.departure_date} />}
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="customize-form-block">
                        <div className="row">
                          <div className="col-sm-12 nh-block">
                            <div className="form-group">
                              <label className="form-title">YOUR HOTEL PREFERENCE <span>(RATING)</span></label>
                              <HotelRatings disabled={disabled} ref="hotel_preferences" selectedValue={hotel_preferences} />
                              {typeof errors.hotel_preferences !== 'undefined' && <Error text={errors.hotel_preferences} />}
                            </div>
                          </div>
                          <div className="col-sm-12 flight-block">
                            <div className="form-group">
                              <label className="form-title">Want to include Flights?</label>
                              <FlightOptions disabled={disabled} ref="flight_status" selectedValue={flight_status} />
                              {typeof errors.flight_status !== 'undefined' && <Error text={errors.flight_status} />}
                            </div>
                          </div>
                          <div className="col-sm-12 ">
                            <div className="form-group">
                              <label className="form-title">NO OF PERSONS</label>
                              <div className="person-block">
                                <div className="age-block">
                                  <h5>Adults</h5>
                                  <p>Age more than 12 yrs</p>
                                  <QuantityBox disabled={disabled} name="adults" ref="adults" selectedValue={adults} />
                                  {typeof errors.adults !== 'undefined' && <Error text={errors.adults} />}
                                </div>
                                <div className="age-block">
                                  <div className="age-block-content">
                                    <h5>Children</h5>
                                    <p>Age less than 12 yrs</p>
                                    <QuantityBox disabled={disabled} name="children" ref="children" selectedValue={children} />
                                    {typeof errors.children !== 'undefined' && <Error text={errors.children} />}
                                  </div>
                                </div>
                                <div className="age-block">
                                  <div className="age-block-content">
                                    <h5>Infants</h5>
                                    <p>Age between 0-3 yrs</p>
                                    <QuantityBox disabled={disabled} name="infants" ref="infants" selectedValue={infants} />
                                    {typeof errors.infants !== 'undefined' && <Error text={errors.infants} />}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12 flight-block">
                            <div className="form-group">
                              <label className="form-title">NEED CABS FOR LOCAL SIGHT SEEING?</label>
                              <CabOptions disabled={disabled} ref="cab_status" selectedValue={cab_status} />
                              {typeof errors.cab_status !== 'undefined' && <Error text={errors.cab_status} />}
                            </div>
                          </div>
                          <div className="col-sm-12 cab-type">
                            <div className="form-group">
                              <label className="form-title">SELECT CAB TYPE</label>
                              <CabTypes disabled={disabled} ref="cab_type" selectedValue={cab_type} />
                              {typeof errors.cab_type !== 'undefined' && <Error text={errors.cab_type} />}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="customize-form-block">
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group ca-block">
                              <label className="form-title">CHOOSE ACTIVITIES </label>
                              {
                                activities && <TripActivities disabled={disabled} selectedValue={activities.split(',')} ref="activities" activities={basicinfo.activities} />
                              }
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-title">MENTION YOUR BUDGET</label>
                              <input disabled={disabled} defaultValue={budget} ref="budget" type="text" name="budget" placeholder="Eg. 40,000 - 70,000" />
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                              <label className="form-title">&nbsp;</label>
                              <select disabled={disabled} name="budget_type" ref="budget_type" defaultValue={budget_type}>
                                <option>per person</option>
                                <option>total</option>
                              </select>
                            </div>
                          </div>
                          <div className="col-md-11 col-sm-12">
                            <div className="form-group">
                              <label className="form-title">ADDITIONAL REQUIREMENTS</label>
                              <textarea defaultValue={additional_requirements} ref="additional_requirements" placeholder="Do you want to add something which is not listed above or any special requests? Mention it here…"></textarea>
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <button className="cta-btn step1" type="submit" onClick={this.submit.bind(this)}>
                              SUBMIT PREFERENCES
							                          <i className="next-iocn">
                                <img src="/images/Proceed_icon.svg" alt="" />
                              </i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                    :
                    <Skeleton width={'100%'} count={5} />
                }
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

export default connect(null)(EditTripPreferenecesModal);