import React, { Component } from 'react';
import DestinationThemePackageSlide from './DestinationThemePackageSlide';
import { connect } from 'react-redux';
import { destinationPackageList } from '../../actions/destinationPackage';
import PackageListSkeleton from '../common/PackageListSkeleton';

class DestinationThemePackageListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      packages: [],
      allData: null,
      destinationName: props.destinationName,
      status: props.status,
      loaded: false,
      skeleton: [1, 2, 3, 4, 5, 6, 7, 8]
    };

    this.fetchRecords = this.fetchRecords.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fetch_records === true && this.props.status != nextProps.status && nextProps.compName == 'filters') {
      this.fetchRecords(nextProps);
    } else if (this.state.destinationName != nextProps.destinationName) {
      this.fetchRecords(nextProps);
    }
  }

  componentDidMount() {
    this.fetchRecords(this.props);
  }

  fetchRecords(props) {
    this.setState({ loaded: false });
    this.props.destinationPackageList({ to_destination: props.destinationName, ...props.filters }).then((response) => {
      if (response.value.data.result === 'success') {
        this.setState({
          status: props.status,
          destinationName: props.destinationName,
          packages: response.value.data.payload.data,
          loaded: true,
          allData: response.value.data.payload
        });
      }
    });
  }

  render() {
    return (
      <div className="pacakages-listing-block">
        {
          !this.state.loaded
          &&
          <>
            {
              this.state.skeleton.map(ske => {
                return <PackageListSkeleton />
              })
            }
          </>
        }
        {
          this.state.packages
          &&
          this.state.packages.map((obj, index) => (
            <DestinationThemePackageSlide key={obj.id} slideData={obj} status={this.state.status} index={this.state.allData.breadcrumb} />
          ))
        }

      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    status: state.destinationPackage.status,
    fetch_records: state.destinationPackage.fetch_records,
    compName: state.destinationPackage.compName,
    filters: { ...state.destinationPackage.filters }
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    destinationPackageList: (params) => {
      return dispatch(destinationPackageList(params));
    }
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(DestinationThemePackageListing);
