import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import {ROUTES} from '../../routesList';
import InnerFullHeader from '../common/InnerFullHeader';
import FooterPanel from '../common/FooterPanel';
import DestinationGuide from '../../DestinationGuide';

class InnerFullHeaderLayout extends Component {

  	render() {
	    return (
		    <div className="cs-wrapper">
		    	<InnerFullHeader />
		    	<Route path={ROUTES.DESTINATIONGUIDE.URL} component={DestinationGuide} />
		    	<FooterPanel />
		    </div>
	    );
  	}
}
export default InnerFullHeaderLayout;
