import {
  _dispatch, DOMESTIC_FEATURED_LIST_DESTINATIONS_SUCCESS, INTERNATIONAL_FEATURED_LIST_DESTINATIONS_SUCCESS, CURRENT_MONTH_BEST_DESTINATION_LIST_SUCCESS, FILTERED_DOMESTIC_INTERNATIONAL_DESTINATION_SUCCESS, UPDATE_HOME_FILTERS_DESTINATION, RESET_HOME_FILTERS_DESTINATION, FOOTER_FEATURED_DESTINATION_LIST_SUCCESS

} from '../actions/destinations';

const INITIAL_STATE = { internationaldestinations:[], domesticdestinations:[], currentmonthbestdestination: [], domesticinternationaldestination:[], filters:{}, footerfeatureddestination:[], total_places: 0, status:null };

export default function(state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch(action.type) {

    case DOMESTIC_FEATURED_LIST_DESTINATIONS_SUCCESS:
      return (() => {
        const domesticdestinations = action.payload;
        return _dispatch({ ...state, domesticdestinations}, true);
      })();

    case INTERNATIONAL_FEATURED_LIST_DESTINATIONS_SUCCESS:
      return (() => {
        const internationaldestinations = action.payload;
        return _dispatch({ ...state, internationaldestinations}, true);
      })();

    case CURRENT_MONTH_BEST_DESTINATION_LIST_SUCCESS:
      return (() => {
        const currentmonthbestdestination = action.payload;
        const total_places = action.total_places;
        return _dispatch({ ...state, currentmonthbestdestination, total_places}, true, 'best-places-current-month');
      })();

    case FILTERED_DOMESTIC_INTERNATIONAL_DESTINATION_SUCCESS:
      return (() => {
        const domesticinternationaldestination = action.payload;
        return _dispatch({ ...state, domesticinternationaldestination}, true, 'destinations');
      })();

    case FOOTER_FEATURED_DESTINATION_LIST_SUCCESS:
      return (() => {
        const footerfeatureddestination = action.payload;
        return _dispatch({ ...state, footerfeatureddestination}, true);
      })();

    case UPDATE_HOME_FILTERS_DESTINATION:
      return (() => {
        const filters = {...state.filters, ...action.payload}
        //console.log(filters);
        return _dispatch({ ...state, filters}, true, 'filters');
      })();

    case RESET_HOME_FILTERS_DESTINATION:
      return (() => {
        return _dispatch({ ...state, filters:{}}, false);
      })();
    default:
      return state;
  }
}