import React, { Component } from 'react';
import NotificationWidget from './NotificationWidget';
import ProfileWidget from './ProfileWidget';

export default class AuthWidget extends Component {

    render() {
        return (
          <div className="user-profile-info">
            <NotificationWidget />
            <ProfileWidget />
          </div>
        );
    }
}