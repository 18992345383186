import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Error from './components/common/Error';
import { forgotpassword } from './actions/auth';

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status,
      errors: {}
    };

    this.reset = this.reset.bind(this);
  }

  /**
   * Submit login form
   */
  submit(e) {
    e.preventDefault();
    const { email } = this.refs;

    // Verify login credentials and login on success
    this.props.forgotpassword({ email: email.value });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status != this.state.status && nextProps.compName == 'forgotpassword') {
      if (nextProps.errors && Object.keys(nextProps.errors).length > 0) {
        this.setState({
          errors: nextProps.errors,
          status: nextProps.status
        });
      } else {
        window.$$('#forgotpassword-modal').modal('hide');
        window.$$('#verifyforgotpassword-modal').modal('show');
        this.reset();
      }
    }
  }

  /**
   * Reset form fields
   */
  reset() {
    Object.keys(this.refs).map((field) => {
      this.refs[field].value = '';
    });
  }

  render() {
    const { errors } = this.state;
    return (
      <div className="middle-content">
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">EMAIL</label>
          <input ref="email" type="email" className="form-control" placeholder="you@example.com" />
          {typeof errors.email !== 'undefined' && <Error text={errors.email} />}
        </div>

        <button type="submit" className="btn cta-btn" onClick={this.submit.bind(this)}>Proceed</button>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    status: state.Auth.status,
    compName: state.Auth.compName,
    errors: state.Auth.errors,
    auth: state.Auth.auth
  }
}

export default connect(mapStatesToProps, { forgotpassword })(ForgotPasswordForm);
