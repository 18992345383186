import React, { Component } from 'react';
import ChatWithUsBlueBox from './ChatWithUsBlueBox';

export default class FaqsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      faq: props.faq
    }
  }
  render() {
    return (
      <section className="faq-block scroll-block" id="faqsection">
        <div className="container">
          <h2 className="heading-section">FAQs</h2>
          <div className="row">
            <div className="col-8">
              <div className="faq-wrapper">
                <div className="faq-block-question">
                  <div className="question-accordion-container">
                    <div className="panel-group" id="accordion">
                      {
                        this.state.faq && this.state.faq.length > 0 &&
                        this.state.faq.map((obj, index) => (

                          <div className="panel panel-default">
                            <div className="panel-heading">
                              <h4 className="panel-title">
                                <a data-toggle="collapse" data-parent="#accordion" href={"#collapse" + index}><span className="glyphicon glyphicon-plus"></span>{obj.question}?</a>
                              </h4>
                            </div>
                            <div id={"collapse" + index} className="panel-collapse collapse">
                              <div className="panel-body">
                                <p dangerouslySetInnerHTML={{ __html: obj.answer }} />
                              </div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <ChatWithUsBlueBox />
            </div>
          </div>
        </div>
      </section>
    );
  }
}