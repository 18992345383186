import React, { Component } from 'react';
import LoginModal from './LoginModal';
import Modal from './Modal';

export default class VerifyEmailModal extends Component {

  showModal() {
    this.props.hideModal();
    window.getFooter().setState({
      renderElement: <LoginModal onHide={this.hideModal.bind(this)} />
    });
  }

  hideModal() {
    window.getFooter().setState({
      renderElement: null
    });
  }
  
  render() {
    return (
      <Modal
        id="verifyemail-modal"
        header={<div><h3>Sign up successful!</h3></div>}
        body={
          <div>
            Thanks for sign up!
                    <p>
              We have sent you a verification email. Please check your inbox.
                    </p>
          </div>
        }
        footer={<p>Already have an account? <a href="javascript: void(0);" onClick={this.showModal.bind(this)}>Log in</a></p>}

      />
    );
  }
}