import React, { Component } from 'react';
import {connect} from 'react-redux'; 

class UploadPicture extends Component {

  constructor(props){
    super(props);
    this.state = {
      src: props.src,
      status: props.status
    };
  }

  componentWillReceiveProps(nextProps){
    if(nextProps.status != this.state.status){
      if(nextProps.compName == 'photo' && nextProps.src != this.state.src){
        this.setState({
          src: nextProps.src,
          status: nextProps.status
        });
      }
    }else if(nextProps.defaultSrc != this.state.src && (typeof nextProps.type === 'undefined' || nextProps.type.match(/image/))){
      this.setState({
        src: nextProps.defaultSrc
      });
    }
  }

	render() {
      let {src} = this.state;
      src = src !== null ? src : "/images/profile-uploaded.png";

    	return (
    		<img src={src} alt="Picture" />
    	);
    }
}

const mapStatesToProps = (state) => {
    return {
        src: state.Auth.user.profile_image,
        status: state.Auth.status,
        compName: state.Auth.compName
    };
}

export default connect(mapStatesToProps)(UploadPicture);