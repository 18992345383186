import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class PopularDestination extends Component {
	constructor(props){
	    super(props);
	    this.state = {
	      slideData: props.slideData
	    }
	}
	render() {
		return(
         <li><Link to={"/destinationguide/" + this.state.slideData.slug }>{ this.state.slideData && this.state.slideData.destination_name }</Link></li>
		);
	}
}