import React, { Component } from 'react';
import SocialButton from './SocialButton';

class SocialLogin extends Component {
	constructor (props) {
		super(props);

		this.handleSocialLogin = this.handleSocialLogin.bind(this);
		this.handleSocialLoginFailure = this.handleSocialLoginFailure.bind(this);
	}

	handleSocialLogin(user){
	  console.log(user);
	}
	 
	handleSocialLoginFailure(err){
	  console.error(err);
	}

	render() {
		return (
		  <div className="social-button-block">
		        <ul>
		            
		            <li>
		            	<SocialButton
					      provider='facebook'
					      appId='YOUR_APP_ID'
					      onLoginSuccess={this.handleSocialLogin}
					      onLoginFailure={this.handleSocialLoginFailure}
					    >
						    <i className="icon facebook">
		            			<img src="images/ic_fb_btn.svg" alt="" />
		            		</i>
		            		Facebook
					    </SocialButton>
		            </li>

		            <li>
		        		<SocialButton
					      provider='google'
					      appId='844845104372-h8htjngp1os1tb79nksc54dq7tko4r8n.apps.googleusercontent.com'
					      onLoginSuccess={this.handleSocialLogin}
					      onLoginFailure={this.handleSocialLoginFailure}
					    >
						    <i className="icon google">
		            			<img src="images/ic_google_btn.svg" alt="" />
		            		</i>
		            		Google
					    </SocialButton>
		            </li>
		        
		        </ul>
		    </div>
		);
	}
}

export default SocialLogin;
