import React, { Component } from 'react';

export default class StepFour extends Component {
   	render() {

   		return(
   			<div className="customize-right-block fourth-step" style={{display: "inline-block"}}>
            <div className="customize-form-block">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="success-content">
                            <ion-icon ios="ios-checkmark-circle" md="ios-checkmark-circle-outline"></ion-icon>
                            <h3>Success</h3>
                            <p> Check your email for a booking confirmation. we'll <br />see you soon!.</p>
                            <a href="javascript: void(0);" className="cta-btn cncl-btn" data-dismiss="modal"> ok </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   		);
   	}
}