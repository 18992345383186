import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import {ROUTES} from '../../routesList';
import InnerBlogHeader from '../common/InnerBlogHeader';
import FooterPanel from '../common/FooterPanel';
import BlogList from '../../BlogList';
import BlogDetail from '../../BlogDetail';
import StoryList from '../../StoryList';
import StoryDetail from '../../StoryDetail';

class InnerFullHeaderLayout extends Component {

  	render() {
	    return (
		    <div className="cs-wrapper">
		    	<InnerBlogHeader />
		    	<Route exact path={ROUTES.BLOG.LIST.URL} component={BlogList} />
		    	<Route exact path={ROUTES.BLOG.DETAIL.URL} component={BlogDetail} />
		    	<Route exact path={ROUTES.TRAVELSTORY.LIST.URL} component={StoryList} />
		    	<Route exact path={ROUTES.TRAVELSTORY.DETAIL.URL} component={StoryDetail} />
		    	<FooterPanel />
		    </div>
	    );
  	}
}
export default InnerFullHeaderLayout;
