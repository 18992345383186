import React, { Component } from 'react';

export default class SocialNetworkLink extends Component {
	render() {
		return(
            <ul className="social-icons">
           		<li>
              		<a href="javascript:;"> <img src="/images/ic_facebook.svg" alt=""/></a>
           		</li>
           		<li>
              		<a href="javascript:;"><ion-icon name="logo-instagram"></ion-icon></a>
           		</li>
           		<li>
              		<a href="javascript:;"><ion-icon name="logo-twitter"></ion-icon></a>
           		</li>
        	</ul>
		);
	}
}