import React, { Component } from 'react';
import Modal from './Modal';
import LoginForm from './LoginForm';
import SignupModal from './SignupModal';

export default class LoginModal extends Component {

	showModal(){
	    window.getFooter().setState({
	      renderElement: <SignupModal onHide={this.hideModal.bind(this)} />
	    });
	}

	hideModal(){
	    window.getFooter().setState({
	        renderElement: null
	    });
	}

	render() {

		return (
		    <Modal 
		    	id="login-modal"
		    	show={true}
		    	onHide={this.props.onHide}
		        header={<div><h3>Welcome to Travalot</h3><p>Login to continue</p></div>}
		        body={<LoginForm />}
		        footer={<p> New to Travalot? <a href="javascript: void(0);" onClick={this.showModal.bind(this)}>Sign up</a></p>}

		    />
		);
	}
}