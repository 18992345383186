import React, { Component,Fragment } from 'react';
import Loader from './components/common/loader';
//import { connect } from 'react-redux';
import ThemeListing from './components/theme/ThemeListing';

class ThemeFullList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ loading: false });
    }, 300);
  }

  render() {
    const { loading } = this.state;
    return (
      <Fragment>
        {
          loading ? <Loader /> :
            <div className="main-body-content">
              <section className="packages-banner all-packages">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12">
                      <div className="inner-banner-content">
                        <h1>All Themes</h1>
                        <p>Lorem ipsum is a pseudo-Latin text used in web design</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* start package type */}
              <ThemeListing />
              {/* end package type */}
            </div>
        }
      </Fragment>
    );
  }
}

export default ThemeFullList;