import React, { Component } from 'react';

export default class CabTypes extends Component {
    
  constructor(props){
    super(props);
    this.state = {
      disabled: typeof props.disabled !== 'undefined' ? props.disabled : false,
      selectedValue: typeof props.selectedValue !== 'undefined' ? props.selectedValue : ''
    };
    this.selectValue = this.selectValue.bind(this);
  }

  selectValue(e){
    this.setState({
      selectedValue: e.target.value
    });
  }

	render() {
      const {selectedValue, disabled} = this.state;

    	return (
        <ul className="hotel-rating-block">
            <li>
                <input disabled={disabled} checked={selectedValue == 1 ? true : false} ref="cab_type" type="radio" name="cab_type" id="ctype1" defaultValue="1" onClick={this.selectValue} />
                <label htmlFor="ctype1">
                    <i className="cstm-icon"></i>
                    Private Cabs
                </label>
            </li>
            <li>
                <input disabled={disabled} checked={selectedValue == 2 ? true : false} ref="cab_type" type="radio" name="cab_type" id="ctype2" defaultValue="2" onClick={this.selectValue} />
                <label htmlFor="ctype2">
                    <i className="cstm-icon"></i>
                    Shared Cab
                </label>
            </li>
            <li>
                <input disabled={disabled} checked={selectedValue == 3 ? true : false} ref="cab_type" type="radio" name="cab_type" id="ctype3" defaultValue="3" onClick={this.selectValue} />
                <label htmlFor="ctype3">
                    Decide Later
                </label>
            </li>
        </ul>
    	);
    }
}