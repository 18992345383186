import React from 'react';
import { Component, Fragment } from 'react';
import Home from './Home';
import InnerLayout from './components/templates/InnerLayout';
import LeftBigRightLayout from './components/templates/LeftBigRightLayout';
import BlogLayout from './components/templates/BlogLayout';
import LogoutView from './components/common/LogoutView';
import InnerFullHeaderLayout from './components/templates/InnerFullHeaderLayout';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import RenderRemoteComponent from './RenderRemoteComponent';
import ConfirmTrip from './ConfirmTrip';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default class App extends Component {
  render() {
    return (
      <Fragment>
        <BrowserRouter>
          {/* <ScrollToTop> */}
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/confirm-trip" component={ConfirmTrip} />
              <Route exact path="/trip_request/*" component={LeftBigRightLayout} />
              <Route exact path="/trip_invoice/*" component={LeftBigRightLayout} />
              <Route exact path="/destinationguide/*" component={InnerFullHeaderLayout} />
              <Route exact path="/blog" component={BlogLayout} />
              <Route exact path="/blog/*" component={BlogLayout} />
              <Route exact path="/travelstories" component={BlogLayout} />
              <Route exact path="/travelstories/*" component={BlogLayout} />
              <Route exact path="/logout" component={LogoutView} />
              <Route exact path="/*" component={InnerLayout} />
            </Switch>
          {/* </ScrollToTop> */}
        </BrowserRouter>
        <RenderRemoteComponent />
        <ToastContainer autoClose={2000} />
      </Fragment>
    );
  }
}
