import React, { Component } from 'react';
import DestinationGuidePopularCitiesSlide from './DestinationGuidePopularCitiesSlide';


export default class DestinationGuidePopularCities extends Component {
  constructor(props){
    super(props);
    this.state = {
      popularcityinfo: props.popularcityinfo,
      slug: props.slug
    };

    this.bindSlider = this.bindSlider.bind(this);
  }

  componentDidMount(){
    window.addEventListener('load', this.bindSlider);

    if(document.readyState === "complete" || document.readyState === "interactive"){
      this.bindSlider();
    }
  }

  componentWillReceiveProps(nextProps){
    if(this.state.slug != nextProps.slug){
      this.setState({
        popularcityinfo: nextProps.popularcityinfo,
        slug: nextProps.slug
      });
    }
  }

  bindSlider(){
    const $ = window.$$;
    if($(window).width() > 991){
      $(this.refs.popularSliderTheme).owlCarousel({
        margin:15,
        pagination: false,
        nav:true,
        navText: [
          "<img src='/images/slider_icon_next.svg'/> ", 
          "<img src='/images/slider_icon_next.svg'/> "
        ],
        responsive:{
          0:{
              items:1.2
          },
          400:{
            items:1.4
          },
          500:{
            items:2.2
          },
          768:{
              items:3
          },
          991:{
              items:3
          },
          1000:{
              items:4
          }
        }
      })
    }
  }

	render() {
    	return (
    		<div className="puplar-block">
        	<div className="heading-poplar">
          	<div className="container">
            		<h3>Popular Cities</h3>
          	</div>
        	</div>
        	<div className="slider-popular slider-full popular-packages-slider-1">
          	<div className="container">
          		<div className="owl-carousel owl-theme popular-slider-theme" ref="popularSliderTheme">
                  {
                    this.state.popularcityinfo && this.state.popularcityinfo.length > 0 &&
                    this.state.popularcityinfo.map((obj, index) => (
                      <DestinationGuidePopularCitiesSlide key={index} slideData = {obj} />
                    ))
                  }
		          </div>
		        </div>
	        </div>
        </div>
	   	);
    }
}