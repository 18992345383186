import React, { Component } from 'react';

export default class UserCoTraveler extends Component {
	render() {
    	return (
    		<div className="co-travellar-block">
                <h2>CO-TRAVELLERS</h2>
                <div className="co-travellar-list">
                  	<div className="row">
                    	<div className="col-sm-4">
                      		<div className="block--ui--box-view">
                        		<figure>
                          			<img src="/images/co-travellar.png" alt="" />
                        		</figure>
                        		<a href="javascript:void(0);" className="delete-btn"><img src="/images/delete-icon-pink.svg" alt="" /></a>
                    			<div className="view-name-co">
                      				<h4 className="name">Kondo Ieyasu</h4>
                      				<label className="age">Female · 26Y</label>
                      				<a href="javascript:void(0);" className="btn gray-btn-second">EDIT DETAILS</a>
                    			</div>
                      		</div>
                    	</div>
                    	<div className="col-sm-4">
                      		<div className="block--ui--box-view">
                        		<figure>
                          			<img src="/images/co-travellar-1.png" alt="" />
                        		</figure>
                        		<a href="javascript:void(0);" className="delete-btn"><img src="images/delete-icon-pink.svg" alt="" /></a>
                        		<div className="view-name-co">
                          			<h4 className="name">Langke Zambo</h4>
                          			<label className="age">Male · 32Y</label>
                          			<a href="javascript:void(0);" className="btn gray-btn-second">EDIT DETAILS</a>
                        		</div>
                      		</div>
                    	</div>
                    
                    	<div className="col-sm-4">
                      		<div className="block--ui--box-view add-co-traveller">
                        		<figure>
                          			<img src="/images/add-user.png" alt="" />
                        		</figure>
                        		<div className="view-name-co">
                          			<h4 className="name">Add Co-Traveller</h4>
                          			<a href="javascript:void(0);" className="btn gray-btn-second btn-add">Enter DETAILS</a>
                        		</div>
                      		</div>
                    	</div>
                  	</div>
                </div>
            </div>
    	);
    }
}