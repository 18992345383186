import React, { Component } from 'react';
import { destinationGuideThingsToDoDetail } from '../../actions/destinationGuide';
import DestinationGuideThingToDoDetail from './DestinationGuideThingToDoDetail';
import Modal from '../../Modal';
import CustomizeRequestModal from '../../CustomizeRequestModal';
import { connect } from 'react-redux';

class DestinationGuideThingsTodoSlide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideData: props.slideData,
      thingsToDoDetail: null
    };

    this.showDetail = this.showDetail.bind(this);
    this.hideDetail = this.hideDetail.bind(this);
    this.fetchRecords = this.fetchRecords.bind(this);
    // this.showCustomRequestForm = this.showCustomRequestForm.bind(this);
    // this.hideCustomRequestForm = this.hideCustomRequestForm.bind(this);
  }

  fetchRecords(id) {
    this.props.destinationGuideThingsToDoDetail({ id: id }).then((response) => {
      if (response.value.data.result === 'success') {
        // console.log(response.value.data.payload, "response");
        this.setState({
          thingsToDoDetail: response.value.data.payload,
        });
      }
    });
  }

  componentDidUpdate() {
    if (this.state.thingsToDoDetail !== null) {
      this.showDetail();
    }
  }

  hideDetail() {
    window.getFooter().setState({
      renderElement: null
    });
  }

  showDetail() {
    window.getFooter().setState({
      renderElement: <div className="view-detail-modal">
        <Modal
          id="jd-modal"
          show={true}
          onHide={this.hideDetail}
          header={
            <div className="vd-main-heading">
              <h3>{this.state.thingsToDoDetail.things_to_do_name}</h3>
              <p>{this.state.thingsToDoDetail.citiesSection.country_name}</p>
            </div>
          }
          body={<DestinationGuideThingToDoDetail data={this.state.thingsToDoDetail} />}
          bodyWrapperClass="vd-main-content"
          // footer={
          //   <div>
          //     <h4>Want best packages handpicked of <span>this.state.slideData.name</span> just for you?</h4>
          //     <p>Tell us your package requirements and our expert will reach you asap.</p>
          //     <a href="javascript: void(0);" className="btn m-ctn-btn" onClick={this.showCustomRequestForm}>CUSTOMIZE & BOOK</a>
          //   </div>
          // }
          footerClass="vd-footer-block"
        />
      </div>
    });
  }

  // hideCustomRequestForm() {
  //   window.getFooter().setState({
  //     renderElement: null
  //   });
  // }

  // showCustomRequestForm() {
  //   window.getFooter().setState({
  //     renderElement: <CustomizeRequestModal toggle={this.hideCustomRequestForm} />
  //   });
  // }
  render() {
    const record = this.props.data;
    console.log(record, "record")
    return (
      <div className="item">
        <a href="javascript:void(0);" className="block-popular-content" onClick={() => this.fetchRecords(this.props.data.id)}>
          <figure>
            <img src={record.portrait_image_list} alt="" />
          </figure>
          <div className="slider-head-left">{record.country}</div>
          <div className="slider-footer-thing">
            <h3>{record.things_to_do_name}</h3>
            <p dangerouslySetInnerHTML={{ __html: record.description }}>{ }</p>
          </div>
        </a>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    destinationGuideThingsToDoDetail: (params) => {
      return dispatch(destinationGuideThingsToDoDetail(params));
    }
  };
}

export default connect(null, mapDispatchToProps)(DestinationGuideThingsTodoSlide);