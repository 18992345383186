import React, { Component } from 'react';

export default class Cancellation extends Component {

  constructor(props) {
    super(props);
  }


  render() {
    return (
      <div className="main-body-content">
        <section className="static-banner-block">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h2>Cancellation Policy</h2>
              </div>
            </div>
          </div>
        </section>
        <section className="static-content-block">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="static-inner-block">
                  <h4>Welcome to <strong>travalot.com</strong> ("Website") and the ‘Travalot’ android and iOS app (collectively, “App”). <br />The domain name of the Website and the App is owned by Travel Atlas Private Limited (“we/ us/ our/ TT”), a company incorporated under the provisions of the Companies Act, 1956.</h4>
                  <div className="spacer"></div>
                  <h5>The customer’s (“you/ your/ traveller”) use of the Website, App, services and tools are governed by the following terms and conditions ("Terms of Use") including the applicable policies which are incorporated herein by way of reference and constitutes a valid agreement between you and TT. If you transact on the Website or App, you shall be subject to the policies that are applicable, as displayed on the Website and App for such transaction. Your use of this Website and App constitutes your binding obligation and agreement to all such terms, conditions, and notices which are subject to amendment without any notice by TT. Any usage of the Website or App is subject to the following Terms of Use: </h5>
                  <div className="content-block">
                    <h3>Limitation on content usage</h3>
                    <p>Your use of any information or materials on this Website and App is entirely at your own risk, for which TT shall not undertake any liability. It shall be your own responsibility to ensure that any products, services or information available through this Website or App, meet your specific requirements. </p>
                    <p>You are restricted from modifying, copying, distributing, transmitting, displaying, performing, reproducing, publishing, licensing, creating derivative works from, transferring, or selling any information, software, products or services obtained from this Website or App. Reproduction of content from the Website and App is prohibited save and except in accordance with the copyright notice, which forms part of these Terms of Use. </p>
                    <p>Unauthorised use of the Website or App may give rise to a claim for damages and/or be a criminal offence. You shall solely bear the consequences arising out of the breach of any of the terms given above. Further TT reserves exclusive rights to alter, modify, and delete any of the contents without giving prior notice to any person.</p>
                  </div>
                  <div className="content-block">
                    <h3>Third party websites</h3>
                    <p>The Website and App contain materials which is either owned by, or licensed, to TT. This material includes, but is not limited to, the design, layout, look, appearance and graphics of the content displayed on the Website and App. From time to time, the Website and App may also include links to other websites. These links are provided for your convenience for providing further useful information. They do not signify that we endorse the website(s). We, or our contractors, agents, owners, and employees are not responsible for the content or policies of such other websites to which our Website and App may link or use offers from. Please take time to learn about them as well.</p>
                  </div>
                  <div className="content-block">
                    <h3>Limitation of liability for fault not attributable to TT</h3>
                    <p>You agree and acknowledge that TT will have the right of indemnification for any loss that may be caused to TT for any misuse of its Website or App, by you and TT will have all rights to approach the appropriate court of law for enforcement of such rights.
                    TT and/or its parents, subsidiaries, affiliates, officers, directors, employees, agents or suppliers and their respective successors, heirs and permitted assigns will not be liable for any loss that you may suffer due to rejection of your visa, hotel cancellation policies and/or any other policy of third parties service providers who are involved in the product that you purchase from TT.
                    It shall be your obligation to ensure that your passport is valid and eligible for undertaking international travel. TT and/or its parents, subsidiaries, affiliates, officers, directors, employees, agents or suppliers and their respective successors, heirs and permitted assigns will not be liable for passport being declared ineligible for entry/exit during or prior to undertaking the international travel.
                                </p>
                  </div>
                  <h6>Given below, are certain essential do’s and don’ts with respect to your passport:</h6>
                  <ul>
                    <li> Validity of passports should be at least 6 months from date of return;</li>
                    <li>Hand written passports will not be considered valid;</li>
                    <li>Passports must have barcode;</li>
                    <li>Passports should not be mutilated or tampered; and</li>
                    <li>Name on passport should match with name on other documents provided for visa.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}