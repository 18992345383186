import React, { Component } from 'react';

export default class TalkToUsWithPaymentOption extends Component {
  render() {
    return (
      <div className="footer-contact">
        <h4>Talk to us!</h4>
        <ul className="contact-detail">
          <li>
            <a href="javascript: void(0);">
              <ion-icon name="call"></ion-icon>
                     +91 78707 78707
                  </a>
          </li>
          <li>
            <a href="mailto:planner@travalot.in">
              <ion-icon name="mail"></ion-icon>
                     planner@travalot.in
                  </a>
          </li>
          <li>
            <a href="javascript: void(0);">
              <ion-icon name="chatbubbles"></ion-icon>
                     Chat with us
                  </a>
          </li>
        </ul>
        <div className="payment-logo">
          <ul>
            <li><a href="javascript: void(0);"> <img src="/images/ic_secure.png" alt="" /> </a></li>
            <li><a href="javascript: void(0);"><img src="/images/ic_mastercard.png" alt="" /> </a></li>
            <li><a href="javascript: void(0);"><img src="/images/ic_visa.png" alt="" /> </a></li>
            <li><a href="javascript: void(0);"><img src="/images/ic_pay_u.png" alt="" /> </a></li>
          </ul>
        </div>
      </div>
    );
  }
}