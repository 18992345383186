import React, { Component } from 'react';
import { connect } from 'react-redux';

class DestinationPackageBannerInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destInfo: props.destInfo,
      status: props.status
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log('nextProps', nextProps);
    if (this.state.status != nextProps.status) {
      this.setState({
        destInfo: nextProps.destInfo,
        status: nextProps.status
      });
    }
  }
  render() {
    //console.log('*****', this.state.destInfo);
    return (
      <div className="inner-banner-content">
        <label>Tour Packages</label>
        <h1>{this.state.destInfo.destination_name}</h1>
        <div className="r-block">
          <div className="rating">
            <ion-icon ios="ios-star" md="ios-star" role="img" className="hydrated" aria-label="star"></ion-icon>
            <span>4.1</span>
          </div>
          <p>300+ Travellers · 166 Reviews</p>
        </div>
        <h4 className="sub-heading">{this.state.destInfo.tag_line}</h4>
        <p dangerouslySetInnerHTML={{ __html: this.state.destInfo.excerpt }} />
        <a href="javascript:;" className="banner-read-more"> Read more</a>
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    destInfo: { ...state.destinationPackage.destinationinfo },
    status: state.destinationPackage.status
  }
}

export default connect(mapStatesToProps)(DestinationPackageBannerInfo);