import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeaderLogo from './HeaderLogo';
import HeaderNavigation from './HeaderNavigation';

class Header extends Component {
	render() {
		return (
			<header className={`header-main ${this.props.auth ? "authenticated" : ""}`}>
				<div className="container-fluid">
					{/* start header logo */}
					<HeaderLogo />
					{/* end header logo */}
					<button className="nav-hamburger">
						<span></span>
						<span></span>
					</button>
					{/* start navigation */}
					<HeaderNavigation />
					{/* end navigation */}
				</div>
			</header>
		)
	}
}

const mapStatesToProps = (state) => {
	return {
		auth: state.Auth.auth
	}
}

export default connect(mapStatesToProps)(Header);