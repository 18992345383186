import React, { Component } from 'react';
import { connect } from 'react-redux';

class AcceptQuoteForm extends Component {
  constructor(props){
    super(props);
  }
  
	render() {
      return ( 
          <div className="popup-infobox">
            <p>Hey {this.props.username},</p>
            <p>Thank you for your confirmation.</p>
            <p>Your invoice is getting ready. Will update you soon!.</p>
          </div>
    	);
    }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    username: state.Auth.user.name
  }
}


export default connect(mapStatesToProps, null)(AcceptQuoteForm);