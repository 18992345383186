import React, { Component } from 'react';
import {connect} from 'react-redux'; 
import QuoteItem from './QuoteItem';
import {fetchQuotesList} from '../../actions/auth';

class UserQuoteList extends Component {
	constructor(props){
	    super(props);
	    this.state = {
	      quotes: props.quotes,
	      status: props.status,
	      loaded: false
	    };
	}

	componentWillMount(){
	    this.props.fetchQuotesList();
	}

	componentWillReceiveProps(nextProps){
	    if(nextProps.status != this.state.status && nextProps.compName == 'quotes'){
	      this.setState({
	        quotes: nextProps.quotes,
	        status: nextProps.status,
	        loaded: true
	      });
	    }
	}
	render() {
		const {quotes, loaded} = this.state;

    	return (
    		<div className="dashboard-block my-profile-block">
              	<div className="ui-blocks-main">
                	<div className="uiblock-head">
                  		<h3>LATEST QUOTES & REQUESTS</h3>
                	</div>
                	{
                	  loaded
                	  ?	
                	  quotes.new_query.length
                	  ?	
	                  quotes.new_query.map((item, index) => {
	                      return <QuoteItem key={index} data={item} />
	                  })
	                  :
	                  <div className="no-record">No quote found!</div>
	                  :
	                  <QuoteItem count={quotes.new_query.length || 1} />
	                }
              	</div>
              	
              	<div className="past-quotes">
                	<h2>PAST QUOTES & REQUESTS</h2>
                	<div className='list-past-quotes'>
	                	{
	                	  loaded
	                	  ?
	                	  quotes.past_query.length
	                	  ?
		                  quotes.past_query.map((item, index) => {
		                      return <QuoteItem key={index} data={item} customClassName="past-quotes-block" />
		                  })
		                  :
		                  <div className="no-record">No quote found!</div>
		                  :
		                  <QuoteItem count={quotes.past_query.length || 1} />
		                }
	                </div>
              	</div>
            </div>
    	);
    }
}

const mapStatesToProps = (state) => {
    return {
        quotes: {...state.Auth.quotes},
        status: state.Auth.status,
        compName: state.Auth.compName
    }
}

export default connect(mapStatesToProps, {fetchQuotesList})(UserQuoteList);