import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routesList';
import { connect } from 'react-redux';
import { homeHolidayTypeList, homeHolidayTypeListSuccess } from '../../actions/holidayType';
import ThemeListSlide from '../theme/ThemeListSlide';
class PackageType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      holidayTypes: props.holidayTypes,
      totalThemes: props.totalThemes,
      status: props.status
    }
  }

  componentDidMount() {
    this.props.homeHolidayTypeList().then((response) => {
      if (response.value.data.result === 'success') {
        this.props.homeHolidayTypeListSuccess(response.value.data.payload, response.value.data.total);
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status) {
      this.setState({
        holidayTypes: nextProps.holidayTypes,
        totalThemes: nextProps.totalThemes,
        status: nextProps.status
      })
    }
  }


  render() {
    return (
      <section className="package-block">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="pacakage-inner-block">
                <div className="heading-block">
                  <h3 className="title">Packages for your kind of trip</h3>
                  <p className="sub-title">Find the best packages according to amenities you need</p>
                </div>
                <div className="package-listing-block">
                  {
                    this.state.holidayTypes && this.state.holidayTypes.length > 0 &&
                    this.state.holidayTypes.map((obj, index) => (
                      <ThemeListSlide key={index} index={index} slideData={obj} />

                    ))
                  }
                </div>
                <div className="view-all pos-top">
                  <Link to="/themelisting" className="n-blue-btn">Show all ({this.state.totalThemes}{this.state.holidayTypes && this.state.holidayTypes.length > 6 ? "+" : null})<img src="/images/button_arrow.svg" alt="" /></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    holidayTypes: [...state.holidayTypes.holidaytypes],
    totalThemes: state.holidayTypes.totalthemes,
    status: state.holidayTypes.status
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    homeHolidayTypeList: () => {
      return dispatch(homeHolidayTypeList());
    },
    homeHolidayTypeListSuccess: (payload, total) => {
      dispatch(homeHolidayTypeListSuccess(payload, total));
    },

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(PackageType);