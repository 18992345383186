// Vikas - 09-01-2018
import React, { Component } from 'react';

export default class GalleryCarousel extends Component {

  constructor(props){
    super(props);
    this.bind = this.bind.bind(this);
  }

  componentDidMount(){
    window.addEventListener('load', this.bind);

    if(document.readyState === "complete" || document.readyState === "interactive"){
      setTimeout(() => {
        this.bind();
      }, 100);
    }
  }

  bind(){
    const $ = window.$$;
    const {config} = this.props;
    const sliderConfig = typeof config !== 'undefined' && typeof config.slider !== 'undefined' ? config.slider : {};
    const carouselConfig = typeof config !== 'undefined' && typeof config.carousel !== 'undefined' ? config.carousel : {};
    $(this.refs.galleryCarousel).flexslider({
      animation: "slide",
      controlNav: false,
      animationLoop: false,
      slideshow: false,
      itemWidth: 72,
      itemMargin: 10,
      maxItems: 4,
      asNavFor: this.refs.gallerySlider,
      ...sliderConfig
    });
   
    $(this.refs.gallerySlider).flexslider({
      animation: "slide",
      controlNav: false,
      ...carouselConfig
    });
  }

  render() {
      const {data, sliderId, carouselId} = this.props;

      return (
        <div>
          <div id={typeof sliderId !== 'undefined' && sliderId} ref="gallerySlider" className="gallery-slide flexslider">
              <ul className="slides">
                {
                  typeof data !== 'undefined' 
                  && 
                  data.length > 0 
                  &&
                  data.map((obj, index) => (
                      <li key={index}>
                        <img src={obj.path} alt="" />
                      </li>
                  ))
                }
              </ul>
          </div>
          <div id={typeof carouselId !== 'undefined' && carouselId} ref="galleryCarousel"  className="gallery-carousel flexslider">
              <ul className="slides">
                  {
                    typeof data !== 'undefined' 
                    && 
                    data.length > 0 
                    &&
                    data.map((obj, index) => (
                        <li key={index}>
                          <img src={obj.path} alt="" />
                        </li>
                    ))
                  }
              </ul>
          </div>
        </div>
      );
    }
}