import React, { Component } from 'react';
import FooterNavigation from './FooterNavigation';

export default class FooterNavigationPanel extends Component {
	render() {
		return(
			<div className="footer-navs paddingleft50">
                    <h4>Quick Links</h4>
                    <FooterNavigation />
              </div>
		);
	}
}