import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routesList';

export default class PackageBreadCrumb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breadcrumb: props.breadcrumb
    }
  }
  render() {
    return (
      <div className="breadcrumb-block">
        <ul className="breadcrumb">
          <li><Link to="/">HOME </Link></li>
          <li>PACKAGES</li>
          <li>{this.state.breadcrumb}</li>
        </ul>
      </div>
    );
  }
}