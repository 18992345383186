import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class InnerHeaderLogo extends Component {
	render() {
    	return (
        	<div className="logo">
          		<Link to="/">
            		<img src="/images/m-logo.svg" className="mob-logo inner-logo" alt="logo"/>
          		</Link>
        	</div>
    	);
    }
}