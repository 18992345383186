import {
  _dispatch, HEADER_MAIN_MENU_SUCCESS, NOTIFICATION_LIST_SUCCESS
} from '../actions/navigation';

const INITIAL_STATE = { headermainmenu: [], notificationlist:[], status:null };

export default function(state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch(action.type) {

    case HEADER_MAIN_MENU_SUCCESS:
      return (() => {
        const headermainmenu = action.payload;
        return _dispatch({ ...state, headermainmenu}, true);
      })();

    case NOTIFICATION_LIST_SUCCESS:
      return (() => {
        const notificationlist = action.payload;
        return _dispatch({ ...state, notificationlist}, true, 'user-notification');
      })();

    default:
      return state;
  }
}