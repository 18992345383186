import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export {_dispatch};

export const QUOTATION_LIST_DETAIL = 'QUOTATION_LIST_DETAIL';
export const QUOTATION_LIST_DETAIL_SUCCESS = 'QUOTATION_LIST_DETAIL_SUCCESS';
export const QUOTATION_ACCEPT_STATUS_SUCCESS = 'QUOTATION_ACCEPT_STATUS_SUCCESS';
export const QUOTATION_ACCEPT_STATUS_ERROR = 'QUOTATION_ACCEPT_STATUS_ERROR';

export const QUOTATION_INVOICE_DETAIL = 'QUOTATION_INVOICE_DETAIL';
export const QUOTATION_INVOICE_DETAIL_SUCCESS = 'QUOTATION_INVOICE_DETAIL_SUCCESS';

export const QUOTATION_REJECT_REASON = 'QUOTATION_REJECT_REASON';
export const QUOTATION_REJECT_REASON_SUCCESS = 'QUOTATION_REJECT_REASON_SUCCESS';


const ROOT_URL = API_ROOT;
var token = "";

export function updateQuoteReadStatus(quoteId){
  axios({
    method: 'POST',
    data: {quotation_id: quoteId},
    url: `${ROOT_URL}/setquotereadtime`,
  });
}

export function quotationListDetail(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/getquotedetailing`,
  });

  return {
    type: QUOTATION_LIST_DETAIL,
    payload: request
  };
}

export function quotationListDetailSuccess(payload){
	return {
  	type: QUOTATION_LIST_DETAIL_SUCCESS,
  	payload: payload
	};
}

export function acceptQuotation(quotation_id){
  return axios({
    method: 'PUT',
    data: {quotation_id, accepted: 1},
    url: `${API_ROOT}/userquotereaction`,
  });
}

export function rejectQuotation(quotation_id, reasonType, comment){
  return axios({
    method: 'PUT',
    data: {quotation_id, accepted: 2, reasonType, comment},
    url: `${API_ROOT}/userquotereaction`,
  });
}

export function reactivateQuotation(quotation_id){
  return axios({
    method: 'PUT',
    data: {quotation_id},
    url: `${API_ROOT}/userquoteactivation`,
  });
}

export function submitCancellationReason(data){
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/cancelquery`,
  });
}

export function fetchQuoteChat(quote_id, lastMsgId){
  return axios({
    method: 'GET',
    params: {quote_id, last_id: lastMsgId},
    url: `${API_ROOT}/quotemessagelist`,
  });
}

export function sendQuoteMessage(quote_id, message){
  return axios({
    method: 'POST',
    data: {quote_id, message},
    url: `${API_ROOT}/messaging`,
  });
}

export function quotationInvoiceDetail(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/quotationinvoiceview`,
  });

  return {
    type: QUOTATION_INVOICE_DETAIL,
    payload: request
  };
}

export function quotationInvoiceDetailSuccess(payload){
  return {
    type: QUOTATION_INVOICE_DETAIL_SUCCESS,
    payload: payload
  };
}

export function quotationRejectReason(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/quotationrejectreason`,
  });

  return {
    type: QUOTATION_REJECT_REASON,
    payload: request
  };
}

export function quotationRejectReasonSuccess(payload){
  return {
    type: QUOTATION_REJECT_REASON_SUCCESS,
    payload: payload
  };
}