import React, { Component } from 'react';

export default class InnerBannerBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      image: props.image,
      sub_title: props.sub_title,
      detail: props.detail
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.title != nextProps.title) {
      this.setState({
        title: nextProps.title,
        image: nextProps.image,
        sub_title: nextProps.sub_title,
        detail: nextProps.detail
      })
    }
  }

  render() {
    // console.log('aaaaa', this.state.title);
    return (
      <section className="inner-banner-block" style={{ backgroundImage: `url(${this.state.image})` }} >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-12">
              <div className="inner-banner-content">
                <label>{this.state.sub_title === "0" ? null : this.state.sub_title}</label>
                <h1>{this.state.title}</h1>
                <p dangerouslySetInnerHTML={{ __html: this.state.detail }} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}