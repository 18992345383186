import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import DashboardSummary from './DashboardSummary';
import UpcomingTrips from './UpcomingTrips';
import PastTrips from './PastTrips';
import PastCancelTrips from './PastCancelTrips';
import NoTripBanner from './NoTripBanner';
import { fetchDashboardInfo } from '../../actions/auth';

class DashboardInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      redirectTo: false,
      dashboard: props.dashboard,
      status: props.status
    };
    // console.log('89898', this.state.dashboard.reditect_panel);
  }

  componentWillMount() {
    this.props.fetchDashboardInfo();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status != this.state.status && nextProps.compName == 'dashboard') {
      var redirectTo = false;
      if (nextProps.dashboard.reditect_panel == 0) {
        redirectTo = true;
      }
      this.setState({
        dashboard: nextProps.dashboard,
        status: nextProps.status,
        redirectTo: redirectTo
      });
    }
  }

  render() {
    const { dashboard } = this.state;
    // console.log('9999999', this.state.dashboard.reditect_panel);
    if (this.state.redirectTo) {
      return <Redirect to="/dashboard/setpassword" />
    }
    return (
      <div className="dashboard-block">
        <DashboardSummary data={dashboard.dashboard_basic_info} />
        {
          typeof dashboard.dashboard_basic_info.mytrips !== 'undefined'
            &&
            dashboard.dashboard_basic_info.mytrips > 0
            ?
            <div>
              <UpcomingTrips data={dashboard.upcoming_trips} />
              <PastTrips data={dashboard.past_trips} />
              <PastCancelTrips data={dashboard.past_cancel_trips} />
            </div>
            :
            <NoTripBanner />
        }

      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    dashboard: { ...state.Auth.dashboard },
    status: state.Auth.status,
    compName: state.Auth.compName,
    errors: state.Auth.errors
  }
}

export default connect(mapStatesToProps, { fetchDashboardInfo })(DashboardInfo);