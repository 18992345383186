import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {ROUTES} from './routesList';
import { quotationInvoiceDetail, quotationInvoiceDetailSuccess } from './actions/quotations';
import {currencyFormat} from './constants';

class RequestInvoiceView extends Component {
  constructor(props){
      super(props);
      this.state = {
        invoiceDetail: {},
        status: props.status
      };
  }

  componentDidMount(){
    this.props.quotationInvoiceDetail({quote_id: this.props.match.params.index}).then((response) => {
      if(response.value.data.result === 'success'){
        this.setState({invoiceDetail: response.value.data.payload});
        //this.props.quotationInvoiceDetailSuccess(response.value.data.payload);
      }
    });
  }
  

  render() {
      const qid = this.state.invoiceDetail.query && this.state.invoiceDetail.query.id;
      return (
        <div className="main-body-content">
          <section className="profile-section quotes-detail">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-xs-12">
                  <div className="dashboard-block my-profile-block invoice-block">
                    <div className="ui-blocks-main">
                      <div className="uiblock-head">
                        <h3><Link to={ "/trip_request/" + qid }><img src="/images/ic_back_quotes.svg" alt=""/>BACK TO QUOTE DETAIL</Link></h3>
                        <div className="right-content links-block pdf-btn">
                          <a href="javascript:void(0);" className="btn btn-gray-icon"><img src="/images/print-icon.png" alt="" /></a>
                          <a href="javascript:void(0);" className="btn n-blue-btn"><ion-icon name="download"></ion-icon>Download PDF</a>
                        </div>
                      </div>
                      <div className="quotes-id">
                        <div className="left-content">
                           <div className="booking-id booking-info-text">Booking ID: <span>{ this.state.invoiceDetail.query && this.state.invoiceDetail.query.id }</span></div>
                           <div className="booking-date booking-info-text">Booking Date: <span>{ this.state.invoiceDetail.query && this.state.invoiceDetail.query.created_at }</span></div>
                           <h4>Invoice</h4>
                        </div>
                      </div>

                      <div className="quotation-tab-block">
                        <div id="content" className="tab-content" role="tablist">
                          <div id="pane-A" className="card tab-pane fade show active" role="tabpanel" aria-labelledby="tab-A">
                            <div id="collapse-A" className="collapse show" data-parent="#content" role="tabpanel" aria-labelledby="heading-A">
                              <div className="card-body">
                                <div className="quotation-content">
                                  <div className="listing-content-block">
                                    <div className="content-block" id="booking">
                                      <div className="heading">
                                        <h5>Booking Summary</h5>
                                      </div>
                                      <ul className="booking-summary">
                                        <li>
                                          <label>Name</label>
                                          <span>{ this.state.invoiceDetail.query && this.state.invoiceDetail.query.name }</span>
                                        </li>
                                        <li>
                                          <label>Destination</label>
                                          <span>{ this.state.invoiceDetail.query && this.state.invoiceDetail.query.destination }</span>
                                        </li>
                                        <li>
                                          <label>No. of Persons</label>
                                          <span>{ this.state.invoiceDetail.query && this.state.invoiceDetail.query.totalpersons }</span>
                                        </li>
                                        <li>
                                          <label>Duration</label>
                                          <span>{ this.state.invoiceDetail.query && this.state.invoiceDetail.query.total_days }</span>
                                        </li>
                                        <li>
                                          <label>Travel Dates</label>
                                          <span>{ this.state.invoiceDetail.query && this.state.invoiceDetail.query.travel_date }</span>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="content-block" id="hotel">
                                      <div className="heading">
                                        <h5>Hotels</h5>
                                      </div>
                                      <ul className="hotel-listing">
                                      {
                                        this.state.invoiceDetail.query && this.state.invoiceDetail.query.quotation && this.state.invoiceDetail.query.quotation.hotels && this.state.invoiceDetail.query.quotation.hotels.length > 0 && this.state.invoiceDetail.query.quotation.hotels.map((obj, index) => (
                                            <li>
                                              <div className="hotel-img-block">
                                                <figure><img src={obj.image_path} /></figure>
                                             </div>
                                             <div className="hotel-content-block">
                                                <p>{ obj.hotel_label_nights } Nights· { obj.address }</p>
                                                <h3>{obj.hotel_name}</h3>
                                                <span>{ obj.address }</span>
                                                <div className="about-hotel-feature">{obj.hotel_rating} Star · {obj.hotel_room_type}</div>
                                             </div>
                                            </li>

                                          ))
                                        }
                                      </ul>
                                    </div>
                                    <div className="content-block" id="cab">
                                      <div className="heading">
                                        <h5>CAB DETAILS</h5>
                                      </div>
                                      <div className="cab-detail-tab">
                                        <p dangerouslySetInnerHTML={{__html: this.state.invoiceDetail.query && this.state.invoiceDetail.query.quotation && this.state.invoiceDetail.query.quotation.cab_details }} />
                                      </div>
                                    </div>
                                          
                                    <div className="content-block block-ui-in" id="w-include">
                                      <div className="heading">
                                        <h5>What’s Included</h5>
                                      </div>
                                      <div className="bottom-block">
                                        <div className="left-block">
                                          <h4>Inclusions</h4>
                                          {
                                          this.state.invoiceDetail.query && this.state.invoiceDetail.query.quotation && this.state.invoiceDetail.query.quotation.inclusion && this.state.invoiceDetail.query.quotation.inclusion.length > 0 && this.state.invoiceDetail.query.quotation.inclusion.map((obj, index) => (
                                              <div className="field">
                                                <label>{ obj.category }</label>
                                                <span>{ obj.title }</span>
                                              </div>
                                            ))
                                          }  
                                        </div>
                                        <div className="right-block">
                                          <h4>Exclusions</h4>
                                          {
                                          this.state.invoiceDetail.query && this.state.invoiceDetail.query.quotation && this.state.invoiceDetail.query.quotation.exclusion && this.state.invoiceDetail.query.quotation.exclusion.length > 0 && this.state.invoiceDetail.query.quotation.exclusion.map((obj, index) => (
                                              <div className="field">
                                                <label>{ obj.category }</label>
                                                <span>{ obj.title }</span>
                                              </div>
                                            ))
                                          }  
                                        </div>
                                      </div>
                                    </div>
                                    <div className="content-block document-block block-ui-in" id="w-include">
                                      <div className="heading">
                                        <h5>Deliverables</h5>
                                      </div>
                                      <div className="bottom-block">
                                        <div className="left-block full-block">
                                          <div className="field">
                                            <label>From Agent</label>
                                            {
                                            this.state.invoiceDetail.query && this.state.invoiceDetail.query.quotation && this.state.invoiceDetail.query.quotation.agents_doc && this.state.invoiceDetail.query.quotation.agents_doc.length > 0 && this.state.invoiceDetail.query.quotation.agents_doc.map((obj, index) => (
                                                <span>{obj.title}</span>
                                              ))
                                            } 
                                            
                                          </div>
                                          <div className="field listing">
                                            <label>Documents required from you</label>
                                            <ul>
                                              {
                                              this.state.invoiceDetail.query && this.state.invoiceDetail.query.quotation && this.state.invoiceDetail.query.quotation.user_docs && this.state.invoiceDetail.query.quotation.user_docs.length > 0 && this.state.invoiceDetail.query.quotation.user_docs.map((obj, index) => (
                                                  <li>{obj.title}</li>
                                                ))
                                              }
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-xs-12">
                  <aside className="profile-left-block installment-list invoice-intallment-list">
                    <div className="aside-inner-block">
                      <div className="top-title"> <label>Payment Details</label></div>
                        <div className="q-detail-block">
                          <div className="profile-head">
                            <ul>
                              {
                                this.state.invoiceDetail.query && this.state.invoiceDetail.query.quotation && this.state.invoiceDetail.query.quotation.invoice && this.state.invoiceDetail.query.quotation.invoice.installments && this.state.invoiceDetail.query.quotation.invoice.installments.length > 0 && this.state.invoiceDetail.query.quotation.invoice.installments.map((obj, index) => (
                                  <li className={ obj.payment_received == 1 ? 'first-payment-installer' : ''}>
                                    <div className="left-block">
                                      <p>{ obj.installment_no } installment</p>
                                    </div>
                                    <div className="right-block"><span>{currencyFormat(obj.installment, 'INR')}</span></div>
                                    <p className="due-date-block"> { obj.payment_received == 1 ? 'Paid' : 'Due'} on {obj.installment_date}</p>
                                  </li>
                                ))
                              }
                            </ul>
                          </div>

                          <div className="action-btn total-installment">
                            <div className="installment-price">
                              <label>Grand Price</label>
                              <span>{ this.state.invoiceDetail.query && this.state.invoiceDetail.query.quotation && currencyFormat(this.state.invoiceDetail.query.quotation.total_package_price, 'INR') }</span>
                            </div>
                            <div className="more-info-installer">
                              <Link to={ "/trip_request/" + qid }>
                                <img src="/images/info-icon.png" alt="" />
                                <span>More details</span>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="support-block-ui">
                        <div className="head-support">
                        <h3>Support</h3>
                        <Link to="/faqs" className="btn btn-sm-b">FAQs</Link>
                    </div>
                    <div className="inner-support-list">
                      <ul>
                        <li>
                          <label>CALL US</label>
                          <span>+91-9873546382</span>
                        </li>
                        <li>
                          <label>EMAIL US</label>
                          <span>getsupport@travalot.in</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </section>
      </div>   
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    quotationInvoiceDetail: (params) => {
      return dispatch(quotationInvoiceDetail(params));
    },
    quotationInvoiceDetailSuccess: (payload) => {
      dispatch(quotationInvoiceDetailSuccess(payload));
    }
  };
}

export default connect(null, mapDispatchToProps)(RequestInvoiceView);