import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import promise from "redux-promise-middleware";
import logger from "redux-logger";
import ReduxThunk from 'redux-thunk';
import axios from 'axios';
import storage from 'redux-persist/lib/storage';
//import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from '../reducers'; // the value from combineReducers
import { clearSession } from '../helpers';

import {verifyAndUpdateAppVersion} from '../constants';

// Verify app version and clear local storage if version updated
verifyAndUpdateAppVersion();

const middleware = applyMiddleware(promise(), ReduxThunk )
// {/* logger*/}
//const middleware = applyMiddleware(promise(), ReduxThunk, logger)

const persistConfig = {
	key: 'root',
	storage: storage,
	//stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer, compose(middleware));
export const persistor = persistStore(store);


// Send access token in every request
axios.interceptors.request.use(function (config) {
	const accessToken = store.getState().Auth.token;
	if(accessToken !== null)
		config.headers = { Authorization: `Bearer ${accessToken}` };
	return config;
}, function (error) {
	return Promise.reject(error);
});

// Add a response interceptor to check user session
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
	// If session is unauthorised, then logout the user.
  if(
  	typeof error.response !== 'undefined' 
  	&& typeof error.response.status !== 'undefined' 
  	&& error.response.status === 401){
  	clearSession();
  }
  // Do something with response error
  return Promise.reject(error);
});