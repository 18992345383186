import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuantityBox from '../../common/QuantityBox';
import HotelRatings from '../../common/HotelRatings';
import FlightOptions from '../../common/FlightOptions';
import CabOptions from '../../common/CabOptions';
import CabTypes from '../../common/CabTypes';
import Error from '../../common/Error';
import { saveSecondStep, fetchSecondStep } from '../../../actions/customPackage';

class StepTwo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      status: props.status,
      errors: {}
    };

    this.submit = this.submit.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.status != this.state.status && nextProps.compName == 'secondstep') {
      if (Object.keys(nextProps.errors).length > 0) {
        this.setState({
          errors: nextProps.errors,
          status: nextProps.status
        });
        return;
      }
      this.setState({
        data: nextProps.data,
        status: nextProps.status
      });
      this.props.updateStep(3);
    }
  }

  submit(e) {
    e.preventDefault();
    const { hotel_preferences, flight_status, adults, children, infants, cab_status, cab_type } = this.refs;

    // Call action
    this.props.saveSecondStep({ hotel_preferences: hotel_preferences.state.selectedValue, flight_status: flight_status.state.selectedValue, adults: adults.refs.adults.value, children: children.refs.children.value, infants: infants.refs.infants.value, cab_status: cab_status.state.selectedValue, cab_type: cab_type.state.selectedValue, quote_id: this.props.quote_id });
  }

  goBack(e) {
    e.preventDefault();
    this.props.updateStep(1);
  }

  render() {
    const { errors } = this.state;
    const { hotel_preferences, flight_status, adults, children, infants, cab_status, cab_type } = this.state.data;
    console.log(adults, "adultsadults")

    return (
      <div className="customize-right-block second-step" style={{ display: "inline-block" }}>
        <div className="right-top-block">
          <h3>Tell us what you prefer</h3>
          <button type="button" className="close cncl-btn" data-dismiss="modal">CLOSE</button>
        </div>
        <div className="customize-form-block">
          <div className="row">
            <div className="col-sm-12 nh-block">
              <div className="form-group">
                <label className="form-title">YOUR HOTEL PREFERENCE <span>(RATING)</span></label>
                <HotelRatings ref="hotel_preferences" selectedValue={hotel_preferences} />
                {typeof errors.hotel_preferences !== 'undefined' && <Error text={errors.hotel_preferences} />}
              </div>
            </div>
            <div className="col-sm-12 flight-block">
              <div className="form-group">
                <label className="form-title">Want to include Flights?</label>
                <FlightOptions ref="flight_status" selectedValue={flight_status} />
                {typeof errors.flight_status !== 'undefined' && <Error text={errors.flight_status} />}
              </div>
            </div>
            <div className="col-sm-12 ">
              <div className="form-group">
                <label className="form-title">NO OF PERSONS</label>
                <div className="person-block">
                  <div className="age-block">
                    <h5>Adults</h5>
                    <p>Age more than 12 yrs</p>
                    <QuantityBox name="adults" ref="adults" selectedValue={typeof adults === "undefined" ? 1 : adults} />
                    {typeof errors.adults !== 'undefined' && <Error text={errors.adults} />}
                  </div>
                  <div className="age-block">
                    <div className="age-block-content">
                      <h5>Children</h5>
                      <p>Age less than 12 yrs</p>
                      <QuantityBox name="children" ref="children" selectedValue={children} />
                      {typeof errors.children !== 'undefined' && <Error text={errors.children} />}
                    </div>
                  </div>
                  <div className="age-block">
                    <div className="age-block-content">
                      <h5>Infants</h5>
                      <p>Age between 0-3 yrs</p>
                      <QuantityBox name="infants" ref="infants" selectedValue={infants} />
                      {typeof errors.infants !== 'undefined' && <Error text={errors.infants} />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 flight-block">
              <div className="form-group">
                <label className="form-title">NEED CABS FOR LOCAL SIGHT SEEING?</label>
                <CabOptions ref="cab_status" selectedValue={cab_status} />
                {typeof errors.cab_status !== 'undefined' && <Error text={errors.cab_status} />}
              </div>
            </div>
            <div className="col-sm-12 cab-type">
              <div className="form-group">
                <label className="form-title">SELECT CAB TYPE</label>
                <CabTypes ref="cab_type" selectedValue={cab_type} />
                {typeof errors.cab_type !== 'undefined' && <Error text={errors.cab_type} />}
              </div>
            </div>
            <div className="col-sm-12 cp-btn-block">
              <button className="btn outline-btn back1" onClick={this.goBack}> Back</button>
              <button className="cta-btn step2" type="submit" onClick={this.submit}>PROCEED<i className="next-iocn"><img src="/images/Proceed_icon.svg" alt=
                "" /></i></button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    data: { ...state.CustomPackage.secondStep },
    quote_id: state.CustomPackage.firstStep.quote_id,
    status: state.CustomPackage.status,
    compName: state.CustomPackage.compName,
    errors: { ...state.CustomPackage.errors }
  }
}

export default connect(mapStatesToProps, { saveSecondStep, fetchSecondStep })(StepTwo);