import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export { _dispatch };

export const FEATURED_DOMESTIC_DESTINATION_LIST = 'FEATURED_DOMESTIC_DESTINATION_LIST';
export const DOMESTIC_FEATURED_LIST_DESTINATIONS_SUCCESS = 'DOMESTIC_FEATURED_LIST_DESTINATIONS_SUCCESS';

export const FEATURED_INTERNATIONAL_DESTINATION_LIST = 'FEATURED_INTERNATIONAL_DESTINATION_LIST';
export const INTERNATIONAL_FEATURED_LIST_DESTINATIONS_SUCCESS = 'INTERNATIONAL_FEATURED_LIST_DESTINATIONS_SUCCESS';

export const CURRENT_MONTH_BEST_DESTINATION_LIST = 'CURRENT_MONTH_BEST_DESTINATION_LIST';
export const CURRENT_MONTH_BEST_DESTINATION_LIST_SUCCESS = 'CURRENT_MONTH_BEST_DESTINATION_LIST_SUCCESS';

export const FILTERED_DOMESTIC_INTERNATIONAL_DESTINATION = 'FILTERED_DOMESTIC_INTERNATIONAL_DESTINATION';
export const FILTERED_DOMESTIC_INTERNATIONAL_DESTINATION_SUCCESS = 'FILTERED_DOMESTIC_INTERNATIONAL_DESTINATION_SUCCESS';

export const UPDATE_HOME_FILTERS_DESTINATION = 'UPDATE_HOME_FILTERS_DESTINATION';
export const RESET_HOME_FILTERS_DESTINATION = 'RESET_HOME_FILTERS_DESTINATION';

export const FOOTER_FEATURED_DESTINATION_LIST = 'FOOTER_FEATURED_DESTINATION_LIST';
export const FOOTER_FEATURED_DESTINATION_LIST_SUCCESS = 'FOOTER_FEATURED_DESTINATION_LIST_SUCCESS';

const ROOT_URL = API_ROOT;
var token = "";

export function resetFilters() {
  return {
    type: RESET_HOME_FILTERS_DESTINATION
  };
}

export function fetchAllDestinations() {
  return axios({
    method: 'GET',
    url: `${ROOT_URL}/getcustomdestination`,
  });
}

export function updateFilters(payload) {
  return (dispatch) => {
    const request = axios({
      method: 'GET',
      params: payload,
      url: `${ROOT_URL}/homedestinationfilter`,
    }).then(response => dispatch({
      type: FILTERED_DOMESTIC_INTERNATIONAL_DESTINATION_SUCCESS,
      payload: response.data.payload
    }));
  };
}

export function domesticFeaturedListDestinations(params) {
  //token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/domesticfeatureddestination`,

  });

  return {
    type: FEATURED_DOMESTIC_DESTINATION_LIST,
    payload: request
  };
}

export function domesticFeaturedListDestinationsSuccess(payload) {
  return {
    type: DOMESTIC_FEATURED_LIST_DESTINATIONS_SUCCESS,
    payload: payload
  }
}

export function internationalFeaturedListDestinations(params) {
  //token = localStorage.getItem('jwtToken');
  //alert(token);
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/internationalfeatureddestination`,

  });

  return {
    type: FEATURED_INTERNATIONAL_DESTINATION_LIST,
    payload: request
  };
}

export function internationalFeaturedListDestinationsSuccess(payload) {
  return {
    type: INTERNATIONAL_FEATURED_LIST_DESTINATIONS_SUCCESS,
    payload: payload
  }
}

export function currentMonthBestDestinationList(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/currentmonthbestdestination`,

  });

  return {
    type: CURRENT_MONTH_BEST_DESTINATION_LIST,
    payload: request
  };
}

export function currentMonthBestDestinationListSuccess(payload, total_places) {
  return {
    type: CURRENT_MONTH_BEST_DESTINATION_LIST_SUCCESS,
    payload: payload,
    total_places: total_places
  }
}

export function filteredDomesticInternationalDestinations(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/homedestinationfilter`,

  });

  return {
    type: FILTERED_DOMESTIC_INTERNATIONAL_DESTINATION,
    payload: request
  };
}

export function filteredDomesticInternationalDestinationsSuccess(payload) {
  return {
    type: FILTERED_DOMESTIC_INTERNATIONAL_DESTINATION_SUCCESS,
    payload: payload
  }
}

export function footerFeaturedDestinations(params) {
  const request = axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/featureddestinationsummary`,

  });

  return {
    type: FOOTER_FEATURED_DESTINATION_LIST,
    payload: request
  };
}

export function footerFeaturedDestinationsSuccess(payload) {
  return {
    type: FOOTER_FEATURED_DESTINATION_LIST_SUCCESS,
    payload: payload
  }
}

export function fetchDestinations(type = '') {
  return axios({
    method: 'GET',
    params: { type },
    url: `${ROOT_URL}/getalldestinations`,
  });
}
