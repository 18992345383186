import React, { Component } from 'react';
import {connect} from 'react-redux';

export default class DashboardSummary extends Component {
  constructor(props){
    super(props);
    this.state = {
      data: typeof props.data === 'undefined' ? {} : props.data
    };
  }

  componentWillReceiveProps(nextProps){
    this.setState({
      data: nextProps.data
    });
  }

	render() {
      const {data} = this.state;

    	return (
        	<div className="ui-blocks-main">
              <div className="uiblock-head">
                <h3>My Bookings</h3>
              </div>
              <div className="ui-block-content">
                <div className="row">
                  <div className="col-sm-4">
                    <div className="block-box-ui">
                      <label>MY TRIPS</label>
                      <p>{data.mytrips} {`Trip${!data.mytrips || data.mytrips < 2 ? "" : "s"}`}</p>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="block-box-ui">
                      <label>QUOTES</label>
                      <p>{data.myquotes} {`Quote${!data.myquotes || data.myquotes < 2 ? "" : "s"}`}</p>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="block-box-ui">
                      <label>Reviews</label>
                      <p>{data.reviews}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        );
    }
}