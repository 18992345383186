import React, { Component } from 'react';

export default class MonthDropDown extends Component {
	constructor(props){
		super(props);
		this.state = {
		  months: [],
		  monthNames:[],
		  selected: ''
		};

		this.handleChange = this.handleChange.bind(this);
		this.bind = this.bind.bind(this);
	}

	componentDidMount(){
		window.addEventListener('load', this.bind);

	    if(document.readyState === "complete" || document.readyState === "interactive"){
	      this.bind();
	    }
	}

	bind(){
	    window.$$(this.refs.trip_month).selectpicker();
	}

	handleChange(e){
		this.setState({
			selected: e.target.value
		});
	}

	componentWillMount(){
		let months = [];
		let currentMonth = new Date().getMonth();
		let currentYear = new Date().getFullYear();
		let monthNames = ["January", "February", "March", "April", "May", "June",
					  "July", "August", "September", "October", "November", "December"
					]
		for(let index=0; index<12; index++){
			months.push(currentMonth); 
			monthNames[currentMonth] += ` ${currentYear}` 
			if(currentMonth == 11){
				currentMonth = 0;
				currentYear++;
			}else{
				currentMonth++;
			}
		}

		this.setState({
			months,
			monthNames
		});
	}

	render(){
		const {months, monthNames} = this.state;
		return(
			<div className="custom-select-box">
              	<span className="select-title">Month of Travel</span>
              	<select ref="trip_month" className="selectpicker picker-style" onChange={this.handleChange}>
	                <option value="-1">Choose Month</option> 
	                {
	                	months.map((month) => {
	                		return <option key={month} value={month}>{monthNames[month]}</option>
	                	})
	                }
              	</select>
           	</div>
		);
	}
}