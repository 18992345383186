import React, { Component } from 'react';
import { connect } from 'react-redux';
import { holidayTypeList } from '../../actions/holidayType';

class CategoryFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      themeList: props.themeList,
      categories: typeof props.selection !== 'undefined' ? props.selection : []
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.props.holidayTypeList().then((response) => {
      if (response.value.data.result === 'success') {
        this.setState({
          themeList: response.value.data.payload
        });
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (typeof nextProps.selection === 'undefined') {
      this.setState({
        categories: []
      });
    }
  }

  handleChange(e) {
    const { name, value, checked } = e.target;
    let { categories } = this.state;

    if (checked) {
      categories.push(value);
    } else {
      categories.splice(categories.indexOf(value), 1);
    }
    this.setState({
      categories
    });
    this.props.updateFilters({ categories });
  }

  render() {
    const { categories } = this.state;

    return (
      <div className="content-block category-tab">
        <div className="card">
          <div className="sidebar-heading-block">
            <a className="card-link" data-toggle="collapse" href="#menuone" aria-expanded="true" aria-controls="menuone">
              Categories
                            <ion-icon ios="ios-arrow-down" md="ios-arrow-down"></ion-icon>
            </a>
            <div className="select-element">Honeymoon</div>
          </div>
          <div id="menuone" className="collapse show">
            <div className="inner-content">
              <form onChange={this.handleChange}>
                <ul className="checkbox-listing">
                  {
                    this.state.themeList && this.state.themeList.length > 0 &&
                    this.state.themeList.map((obj, index) => (
                      <li key={index}>
                        <input checked={categories.indexOf(obj.slug) > -1} type="checkbox" id={"category" + obj.id} value={obj.slug} />
                        <label for={"category" + obj.id}>{obj.title}</label>
                      </li>
                    ))
                  }
                </ul>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    themeList: [...state.holidayTypes.holidaytypelist]
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    holidayTypeList: () => {
      return dispatch(holidayTypeList());
    }
  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(CategoryFilter);