import React, { Component } from 'react';
//import SearchDestination from './SearchDestination';
import DestinationListing from './DestinationListing';

export default class ThemeDestinationFilter extends Component {
	render() {
    	return (
    		<div className="content-block detination-tab">
               	<div className="card">
                  	<div className="sidebar-heading-block">
                     	<a className="card-link" data-toggle="collapse"  href="#destination" aria-expanded="true" aria-controls="menuone">Destination <ion-icon ios="ios-arrow-down" md="ios-arrow-down"></ion-icon>
                     	</a>
                     	<div className="select-element">All Destinations</div>
                  	</div>
                  	<div id="destination" className="collapse show">
                      <DestinationListing updateFilters={this.props.updateFilters} selection={this.props.selection} />
                        {/*<a href="javascript:;" id="loadMore" className="show-more-listing">SHOW MORE</a>*/}
                     
                  </div>
               </div>
            </div>
    	);
    }
}