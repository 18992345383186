import React, { Component } from 'react';
import { connect } from 'react-redux';
import VerifyEmailModal from './VerifyEmailModal';
import PlanHolidayButton from './PlanHolidayButton';
import VerifyForgotPasswordModal from './VerifyForgotPasswordModal';

class AuthModals extends Component {
  componentDidUpdate() {
    if (this.props.auth === true) {
      window.$$('body').removeClass('modal-open');
    }
  }

  render() {
    if (this.props.auth === true) {

      return null;
    }

    return (
      <div style={{ display: "inline-block" }}>
        <div className="plan-btn">
          <PlanHolidayButton />
        </div>
        <VerifyEmailModal hideModal={this.props.hideModal} />
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    auth: state.Auth.auth
  }
}

export default connect(mapStatesToProps)(AuthModals);