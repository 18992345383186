import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { storyDetail, storyDetailSuccess, mostViewStory, mostViewStorySuccess } from './actions/travelstory';
import {dateFormat} from './constants';
import BookingButtonStyle1 from './BookingButtonStyle1';

class StoryDetail extends Component {
	constructor(props){
	    super(props);
	    this.state = {
	      storyDetailing: props.storyDetailing,
	      mostViewed: props.mostViewed,
	      status: props.status,
	      slug: this.props.match.params.index
	    }
	}

	componentDidMount(){
	    this.fetchRecords(this.state.slug);
    }

    fetchRecords(slug){
    	this.props.storyDetail({slug}).then((response) => {
	      if(response.value.data.result === 'success'){
	        this.setState({storyDetailing: response.value.data.payload});
	        this.props.storyDetailSuccess(response.value.data.payload);
	      }
	    });

	    this.props.mostViewStory({slug}).then((response) => {
	      if(response.value.data.result === 'success'){
	        this.setState({mostViewed: response.value.data.payload});
	        this.props.mostViewStorySuccess(response.value.data.payload);
	      }
	    });
    }

    componentWillReceiveProps(nextProps){
    	if(this.props.match.params.index != nextProps.match.params.index){
    		this.setState({slug: nextProps.match.params.index});
    		this.fetchRecords(nextProps.match.params.index);
    	}
	    if(this.props.status != nextProps.status){
	      	this.setState({
	      		storyDetailing: nextProps.storyDetailing,
		        mostViewed: nextProps.mostViewed,
		        status: nextProps.status
	      	})
	    }
	}

	render() {
    	return (
    		<div className="auto-blog">
    		<div className="main-body-content">
    			{
	          		this.state.storyDetailing && this.state.storyDetailing.map((obj, index) => (
	          			<div className="auto-blog">
	          				<div className="blog-detail-block">
		                		<div className="blog-detail-hero" style={{backgroundImage: `url(${ obj.thumbnail })`}}>
		                  		<div className="container">
		                    		<div className="bldetailContent">
		                      			<time>{dateFormat(obj.post_date)} · {obj.tags}</time>
		                      			<h1>{obj.post_title}</h1>
		                    		</div>
		                  		</div>
		                  		<div className="blog-author">
		                    		<div className="container">
		                      			<ul className="dl-left-block-hero">
		                        			<li>
		                          				<figure>
		                            				<img src={obj.author_profile_image ? obj.author_profile_image : '/images/user_placeholder.png'} alt="" />
		                          				</figure>
		                          				<figcaption>
		                            				<span>Written by</span>
		                            				<label>{obj.display_name}</label>
		                          				</figcaption>
		                        			</li>
		                        			<li>
		                          				<figure>
		                            				<img src="/images/three-line.png" alt="" />
		                          				</figure>
		                          				<figcaption>
		                            				<span>Total Views</span>
		                            				<label>{obj.views_count}</label>
		                          				</figcaption>
		                        			</li>
		                        			<li>
		                          				<figure>
		                            				<img src="/images/location-line.png" alt="" />
		                          				</figure>
		                          				<figcaption>
		                            				<span>Destination</span>
		                            				<label>{obj.location}</label>
		                          				</figcaption>
		                        			</li>
		                      			</ul>
					                    <div className="share-block">
					                        <a href="javascript:void(0);" className="share-btn-line"><ion-icon name="share-alt"></ion-icon><span>SHARE ARTICLE</span></a>
					                    </div>
		                    		</div>
		                  		</div>
		                	</div>
		              	</div>
		              	<div className="blog-full-detail">
		                	<div className="container">
		                  		<div className="dl-content">
		                    		<p dangerouslySetInnerHTML={{__html: obj.post_content }} />
		                  		</div>
		                	</div>
		              	</div>
		              	<div className="plan-trip-section" style={{ backgroundImage: `url(/images/blog-lead-back.jpg)` }}>
		                	<div className="container">
		                  		<div className="row">
		                    		<div className="col-sm-12 col-lg-8">
		                      			<h3>Plan Your Trip Today!</h3>
		                      			<p>Get Customized Travel Quotes from our expert Trip advisors</p>
		                    		</div>
		                    		<div className="col-sm-12 col-lg-4">
		                      			<BookingButtonStyle1 customClass="btn-1" label='Plan My Holidays  <img src="/images/slider_icon_next.svg" alt="" />' />
		                    		</div>
		                  		</div>
		                	</div>
		              	</div>

		            </div>
		              
	          	))
	         }      
            </div>

            <section className="lastest-article-block related-blogs">
              	<div className="head-block-blog">
                  	<div className="container">
                    	<h3>Most Viewed Stories</h3>
                  	</div>
              	</div>
              	<div className="container">
                	<div className="row">
                  		{
	          			this.state.mostViewed && this.state.mostViewed.map((obk, index) => (
	                  		<div class="col-sm-4">
		                      	<article class="blog-ui">
		                          	<Link to={"/travelstories/" + obk.post_name }>
		                              	<figure>
		                                  	<img src={obk.thumbnail} alt={obk.post_title} />
		                              	</figure>
		                              	<figcaption>
		                                  	<span>{dateFormat(obk.post_date)} · {obk.location}</span>
		                                  	<label>{obk.post_title}</label>
		                              	</figcaption>
		                          	</Link>
		                      	</article>
		                  	</div>
		                  	))
		          		}


                	</div>
              	</div>
            </section>
            </div>
    	);
    }
}

const mapStatesToProps = (state, ownProps) => {
  	return {
    	storyDetailing: [...state.travelstory.storydetail],
    	mostViewed: [...state.travelstory.mostviewstory],
    	status: state.travelstory.status
  	}
}

const mapDispatchToProps = (dispatch) => {
  	return {
    	storyDetail: (params) => {
      		return dispatch(storyDetail(params));
    	},
    	storyDetailSuccess: (payload, total) => {
      		dispatch(storyDetailSuccess(payload, total));
    	},
    	mostViewStory: (params) => {
      		return dispatch(mostViewStory(params));
    	},
    	mostViewStorySuccess: (payload, total) => {
      		dispatch(mostViewStorySuccess(payload, total));
    	},
  	};
}

export default connect(mapStatesToProps, mapDispatchToProps)(StoryDetail);