// Vikas - 09-01-2018
import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routesList';
import GalleryCarousel from '../common/GalleryCarousel';
import BookingButtonStyle1 from '../../BookingButtonStyle1';
import { currencyFormat } from '../../constants';

export default class TravelPackageSlide extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideData: props.slideData,
      status: props.status
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status) {
      this.setState({
        destPackage: nextProps.destPackage,
        status: nextProps.status
      });
    }
  }

  render() {
    return (
      <ul>
        <li className="pacakage-list">
          <div className="left-block">
            <GalleryCarousel data={this.state.slideData.gallery} />
          </div>
          <div className="right-block dp-listing">
            <div className="top-block">
              <div className="left-content">
                <label className="days-title">{this.state.slideData.duration_text}<span>CUSTOMIZABLE</span> </label>
                <h3 className="heading2">{this.state.slideData.package_name}</h3>
                <div className="rating mob-show">
                  <ion-icon ios="ios-star" md="ios-star" role="img" className="hydrated" aria-label="star"></ion-icon>
                  <span>{this.state.slideData && this.state.slideData.rating}</span>
                </div>
                <div className="icons-blocks">
                  <ul>
                    {
                      this.state.slideData.inclusions && this.state.slideData.inclusions.length > 0 && this.state.slideData.inclusions.map(inc => {
                        if (inc.name == "Meal Plan") {
                          return (
                            <li>
                              <figure><img src="/images/food-icon-listing.svg" alt="" /></figure>
                              <figcaption>
                                <p>Meals</p>
                              </figcaption>
                            </li>
                          )
                        }
                        if (inc.name == "Cab Type") {
                          return (
                            <li>
                              <figure><img src="/images/transport-icon-listing.svg" alt="" /></figure>
                              <figcaption>
                                <p>Cab</p>
                              </figcaption>
                            </li>
                          )
                        }
                        if (inc.name == "AirPort Transfers") {
                          return (
                            <li>
                              <figure><img src="/images/flight-icon.svg" alt="" /></figure>
                              <figcaption>
                                <p>AirPort</p>
                              </figcaption>
                            </li>
                          )
                        }
                        if (inc.name == "Other Inclusions") {
                          return (
                            <li>
                              <figure><img src="/images/activities-icon-listing.svg" alt="" /></figure>
                              <figcaption>
                                <p>Others</p>
                              </figcaption>
                            </li>
                          )
                        }
                      })
                    }
                    {/* <li>
                      <figure><img src="/images/cruise.svg" alt="" /></figure>
                      <figcaption>
                        <p>Cruise</p>
                      </figcaption>
                    </li>
                    <li>
                      <figure><img src="/images/bonfire.svg" alt="" /></figure>
                      <figcaption>
                        <p>Hotel Stay</p>
                      </figcaption>
                    </li>
                    <li>
                      <figure><img src="/images/parachute.svg" alt="" /></figure>
                      <figcaption>
                        <p>Meals</p>
                      </figcaption>
                    </li>
                    <li>
                      <figure><img src="/images/trekking.svg" alt="" /></figure>
                      <figcaption>
                        <p>Sight Seeing</p>
                      </figcaption>
                    </li>
                    <li>
                      <figure><img src="/images/beaches.svg" alt="" /></figure>
                      <figcaption>
                        <p>Freebies</p>
                      </figcaption>
                    </li> */}
                  </ul>
                </div>
                <div className="things-listing ">
                  <label className="label-heading">Cities</label>
                  <ul>
                    <li>{this.state.slideData.cities}</li>
                  </ul>
                </div>
              </div>
              <div className="right-content">
                <div className="cs-price-outer">
                  <div className="rating mob-hide">
                    <ion-icon ios="ios-star" md="ios-star"></ion-icon>
                    <span>{this.state.slideData && this.state.slideData.rating}</span>
                  </div>
                  <div className="price-block">
                    <p><span className="start-text">Starts from</span>
                      {currencyFormat(this.state.slideData.minimum_price, 'INR')}
                      <span className="per-person-text">Per Person</span>
                    </p>
                  </div>
                </div>
                <div className="hi-block">
                  <label className="label-heading">HOTEL INCLUDED</label>
                  <p>{this.state.slideData.hotel_rating} Star{/*<span>Change</span>*/}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="button-block">
            <ul>
              <li>
                <Link className="btn check-package-btn" to={"/package/detail/" + this.state.slideData.slug}>View Details</Link>
              </li>
              <li>
                <BookingButtonStyle1 packageId={this.state.slideData.id} index={this.state.slideData.country_name} />
              </li>
              <li className="mobile-btn">
                <BookingButtonStyle1 packageId={this.state.slideData.id} index={this.state.slideData.country_name} label={`
                <div class="left-btn-block">
                    <p>CUSTOMIZE & BOOK </p>
                    <span class="price">Starts from ${currencyFormat(this.state.slideData.minimum_price, 'INR')}</span>
                  </div>
                  <div class="right-btn-block">
                    <ion-icon ios="ios-arrow-round-forward" md="ios-arrow-round-forward"></ion-icon>
                  </div>`} />
                {/* <a href="javascript:;" className="btn cta-btn" data-dismiss="modal" data-toggle="modal" data-target="#customize-modal">
                  <div className="left-btn-block">
                    <p>CUSTOMIZE & BOOK </p>
                    <span className="price">Starts from ₹25,099</span>
                  </div>
                  <div className="right-btn-block">
                    <ion-icon ios="ios-arrow-round-forward" md="ios-arrow-round-forward"></ion-icon>
                  </div>
                </a> */}
              </li>
            </ul>
          </div>
        </li>
      </ul>
    );
  }
}