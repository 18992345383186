import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from './routesList';
import DashboardLeftNavigation from './components/profile/DashboardLeftNavigation';
import DashboardInfo from './components/profile/DashboardInfo';
import UserQuoteList from './components/profile/UserQuoteList';
import UserHolidayPreferences from './components/profile/UserHolidayPreferences';
import UserSavedPackages from './components/profile/UserSavedPackages';
import UserProfile from './components/profile/UserProfile';
import EditProfile from './components/profile/EditProfile';
import SetPassword from './components/profile/SetPassword';

class ProfileDashboard extends Component {
  render() {
    if (!this.props.auth) {
      return <Redirect to="/" />
    }

    return (
      <div className="main-body-content">
        <section className="profile-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-xs-12">
                <DashboardLeftNavigation user={this.props.user} />
              </div>
              <div className="col-lg-8 col-xs-12">
                <Route path={ROUTES.DASHBOARD.SETPASSWORD.URL} component={SetPassword} />
                <Route path={ROUTES.DASHBOARD.EDITPROFILE.URL} component={EditProfile} />
                <Route path={ROUTES.DASHBOARD.PROFILE.URL} component={UserProfile} />
                <Route path={ROUTES.DASHBOARD.SAVEDPACKAGES.URL} component={UserSavedPackages} />
                <Route path={ROUTES.DASHBOARD.HOLIDAYPREFERENCES.URL} component={UserHolidayPreferences} />
                <Route path={ROUTES.DASHBOARD.QUOTESLIST.URL} component={UserQuoteList} />
                <Route exact path={ROUTES.DASHBOARD.LIST.URL} component={DashboardInfo} />
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStatesToProps = (state) => {
  return {
    auth: state.Auth.auth,
    user: { ...state.Auth.user }
  }
}

export default connect(mapStatesToProps)(ProfileDashboard);