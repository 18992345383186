import React, { Component } from 'react';

export default class NoTripBanner extends Component {

	render() {

    	return (
        	<div className="no-data-profile" style={{backgroundImage: "url(/images/no-record-bg.png)"}}>
              <h2>Seems like you're new here. Let us plan the perfect<br /> trip for you</h2>
              <div className="go-forward-profile">
                  <blockquote>“The journey of a thousand miles begins with a single step.”</blockquote>
              </div>
              <a href="javascript:;" className="orange-btn-second">
                Plan My Holidays 
                <img src="images/button_arrow.svg" alt="Button" />
              </a>
          </div>
        );
    }
}