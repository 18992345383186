import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../routesList';

class ExploreSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searching: props.searching,
      chkNavigation: props.chkNavigation,
      status: props.status
    }
    this.bindSlider = this.bindSlider.bind(this);
  }

  componentDidUpdate() {
    window.addEventListener('load', this.bindSlider);
    if (document.readyState === "complete" || document.readyState === "interactive") {
      this.bindSlider();
    }
  }

  bindSlider() {
    const $ = window.$$;
    $(document).ready(function () {
      $('input.text-field').focus(function () {
        $(this).parent().addClass('active');
        $("body").addClass('overflow');
      }).blur(function () {
        $(this).parent().removeClass('active');
        $("body").removeClass('overflow');
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status && nextProps.compName == 'search') {
      this.setState({
        searching: nextProps.searching,
        chkNavigation: nextProps.chkNavigation,
        status: nextProps.status
      });
    }
  }

  render() {
    console.log(this.state.chkNavigation, "this.state.chkNavigation")
    return (
      <div className="search-dropdown ">
        <div className="search-content-block">
          <div className="top-block">
            <label className="title">EXPLORE</label>
            <ul className="explore-listing">
              <li className="active"><Link to="/travel-packages">ALL</Link></li>
              {
                this.state.chkNavigation.theme_menu && this.state.chkNavigation.theme_menu.length > 0 &&
                this.state.chkNavigation.theme_menu.map((obj, index) => (
                  <li key={index}>
                    <Link to={"travel-packages?categories[]=" + obj.slug}>{obj.title}</Link>
                  </li>
                ))
              }
              {/* <li><Link to="/travel-packages?categories[]=honeymoon">HONEYMOON</Link></li>
              <li><Link to="/travel-packages?categories[]=family">FAMILY</Link></li>
              <li><Link to="/travel-packages?categories[]=friends">FRIENDS</Link></li> */}
            </ul>
          </div>
          <div className="bottom-block">
            <label className="title">Search Results</label>
            <ul>
              {
                this.state.searching && this.state.searching.length > 0 ?
                  this.state.searching.map((obj, index) => (
                    <li key={index}>
                      <div className="left-block">
                        <h4>{obj.destination} {obj.theme} packages</h4>
                        <p>{obj.destination}</p>
                      </div>

                      <div className="right-block">
                        <Link to="/travel-packages" className="s-package">SHOW PACKAGES</Link>
                      </div>
                    </li>
                  )) :
                  <Fragment>
                    {
                      this.props.searched ?
                        <li>No results found</li> : null
                    }
                  </Fragment>
              }
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    chkNavigation: state.navigation.headermainmenu,
    status: state.navigation.status
  }
}

export default connect(mapStatesToProps)(ExploreSearch);