import React, { Component } from 'react';
import Error from './components/common/Error';
import { connect } from 'react-redux';
import {submitCancellationReason} from './actions/quotations';
import {quotationRejectReason, quotationRejectReasonSuccess} from './actions/quotations';

class CancelTheRequestForm extends Component {
  constructor(props){
    super(props);
    this.state = {
      rejectReasons: props.rejectReasons ? props.rejectReasons : [],
      status: props.status,
      errors: {},
      reasonType: null
    };
    this.chkRejectType = this.chkRejectType.bind(this);
  }

  componentDidMount(){
    this.props.quotationRejectReason({type : 1}).then((response) => {
      if(response.value.data.result === 'success'){
        this.props.quotationRejectReasonSuccess(response.value.data.payload);
      }
    });
  }

  componentWillReceiveProps(nextProps){
    if(this.state.status != nextProps.status){
      this.setState({
        rejectReasons: nextProps.rejectReasons,
        status: nextProps.status
      });
    }
  }

  submit(e){
    e.preventDefault();
    const {reasonType} = this.state;
    var { comment } = this.refs;
    
    if(reasonType === null){
      this.setState({
        errors: {reject_quote: 'Please select a reason for cancellation!'}
      });
      return;
    } else if(reasonType == '12' || reasonType == '18'){
      comment = this.refs.comment.value.replace(/\s+/, '');
      if(comment == ''){
        this.setState({
          errors: {reject_quote: 'Please enter some reason description!'}
        });
        return;
      }
    }

    submitCancellationReason({reject_comments: comment, id: this.props.id, type: reasonType}).then((response) => {
      this.props.history.push('/dashboard/quoteslist');
      window.getFooter().setState({
          renderElement: null
      });
    });
  }

  chkRejectType(e){
    this.setState({
        reasonType: e.target.value
    }); 
  }

	render() {
      const {errors, reasonType, rejectReasons} = this.state;

    	return ( 
        <form className="travelot-popup-panel" onSubmit={this.submit.bind(this)}>
          <ol>
            { 
                rejectReasons && rejectReasons.length > 0 &&
                rejectReasons.map((obj, index) => (
                  
                  <li key={obj.id}>
                    <span>{ obj.title }</span>
                      <ul>
                      {
                        obj.child && obj.child.length > 0 && obj.child.map((obk, imdex) => (
                          <li>
                              <input type="radio" name="reason" defaultValue={obk.id} onClick={this.chkRejectType} ref="reason" />
                              <span>{ obk.title }</span>
                          </li>
                        ))
                      }
                      </ul>
                  </li>
              ))
            }
          </ol>

          {
            (reasonType == '12' ||  reasonType == '18')
            &&
            <p>
              <label htmlFor="reject_comments">Please give a reason of cancellation</label>
              <textarea required ref="comment" id="reject_comments" name="reject_comments" rows="5" cols="55"></textarea>
              {typeof errors.reject_comments !== 'undefined' && <Error text={errors.reject_comments} />}
            </p>
          }

          <p>
            {typeof errors.reject_quote !== 'undefined' && <Error text={errors.reject_quote} />}
            <button className="btn n-blue-btn">Submit</button>
          </p>
        </form>
    	);
    }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    rejectReasons: state.quotations.quotaionrejectreason,
    status: state.quotations.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    quotationRejectReason: (params) => {
      return dispatch(quotationRejectReason(params));
    },
    quotationRejectReasonSuccess: (payload) => {
      dispatch(quotationRejectReasonSuccess(payload));
    },

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(CancelTheRequestForm);