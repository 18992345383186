import React, { Component } from 'react';
import { connect } from 'react-redux';
import InternationalFeaturedDestinationSlide from './InternationalFeaturedDestinationSlide'
import { internationalFeaturedListDestinations, internationalFeaturedListDestinationsSuccess } from '../../actions/destinations';

class InternationalFeaturedDestination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      featuredDestinations: props.featuredDestinations,
      status: props.status
    }
  }

  componentDidMount() {
    this.props.internationalFeaturedListDestinations().then((response) => {
      if (response.value.data.result == 'success') {
        this.props.internationalFeaturedListDestinationsSuccess(response.value.data.payload);
      }
    })
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.status != nextProps.status) {
      this.setState({
        featuredDestinations: nextProps.featuredDestinations,
        status: nextProps.status
      })
    }
  }

  render() {
    return (
      <ul className="">
        {
          this.state.featuredDestinations && this.state.featuredDestinations.length > 0 &&
          this.state.featuredDestinations.map((obj, index) => (
            <InternationalFeaturedDestinationSlide key={index} slideData={obj} />
          ))
        }
      </ul>
    );
  }
}

const mapStatesToProps = (state, ownProps) => {
  return {
    featuredDestinations: [...state.destinations.internationaldestinations],
    status: state.destinations.status
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    internationalFeaturedListDestinations: () => {
      return dispatch(internationalFeaturedListDestinations());
    },
    internationalFeaturedListDestinationsSuccess: (payload) => {
      dispatch(internationalFeaturedListDestinationsSuccess(payload));
    },

  };
}

export default connect(mapStatesToProps, mapDispatchToProps)(InternationalFeaturedDestination);