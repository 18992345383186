import React, { Component } from 'react';

export default class Dropdown extends Component {

  constructor(props){
    super(props);
    this.bind = this.bind.bind(this);
  }

  componentDidMount(){
    window.onload = () => {
      this.bind();
    };

    if(document.readyState === "complete" || document.readyState === "interactive"){
      this.bind();
    }
  }

  bind(){
    window.$$(this.refs[this.props.name]).selectpicker();
  }

	render() {
      const {options, name, customClass, value} = this.props;

    	return (
    		<select name={name} ref={name} className={`selectpicker ${typeof customClass !== 'undefined' ? customClass : ''}`} defaultValue={value}>
            {
              options.map((option, index) => {
                return <option key={index} value={option}>{option}</option>
              })
            }
        </select>
    	);
    }
}