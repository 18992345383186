import {
  _dispatch, HOME_STORY_LIST_SUCCESS, STORY_LIST_SUCCESS, STORY_DETAIL_SUCCESS, MOST_VIEW_STORY_SUCCESS
} from '../actions/travelstory';

const INITIAL_STATE = { homestorylist: [], totalstories:0, storylisting:[], storydetail:[], mostviewstory:[], status:null };

export default function(state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch(action.type) {

    case HOME_STORY_LIST_SUCCESS:
      return (() => {
        const homestorylist = action.payload;
        const totalstories = action.total;
        return _dispatch({ ...state, homestorylist, totalstories}, true);
      })();

    case STORY_LIST_SUCCESS:
      return (() => {
        const storylisting = action.payload;
        return _dispatch({ ...state, storylisting }, true);
      })();

    case STORY_DETAIL_SUCCESS:
      return (() => {
        const storydetail = action.payload;
        return _dispatch({ ...state, storydetail }, true);
      })();

    case MOST_VIEW_STORY_SUCCESS:
      return (() => {
        const mostviewstory = action.payload;
        return _dispatch({ ...state, mostviewstory }, true);
      })();


    default:
      return state;
  }
}