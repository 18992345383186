// Vikas - 08-01-2018
import React, { Component } from 'react';
import StepIcon from './StepIcon';

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonial: typeof props.testimonial !== 'undefined' ? props.testimonial : {},
      step: props.step
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.step != this.state.step) {
      this.setState({
        step: nextProps.step
      });
    } else {
      if (typeof nextProps.testimonial !== 'undefined') {
        this.setState({
          testimonial: nextProps.testimonial
        });
      }
    }
  }

  render() {
    const { testimonial, step } = this.state;

    return (
      <div className="customize-left-block">
        <div className="top-block">
          <div className="modal-heading">
            <div className="back-pacakage">
              <button type="button" className="close" data-dismiss="modal">
                <span className="p-back-arrow">
                  <img src="/images/icon_prev_w.svg" alt="" />
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="left-heading">
          <h3>Customize Your Trip</h3>
          <p>Plan your perfect holiday in just 3 simple steps</p>
        </div>
        <ul className="steps-block">
          <li className={`${step == 1 ? "active" : ""} ${step > 1 ? "complete" : ""}`}>
            <StepIcon step="1" title="Basic Information" subtitle="Tell us details of your holiday plan" />
          </li>
          <li className={`${step == 2 ? "active" : ""} ${step > 2 ? "complete" : ""}`}>
            <StepIcon step="2" title="Customize your trip" subtitle="Customize your holiday experience" />
          </li>
          <li className={`${step == 3 ? "active" : ""} ${step > 3 ? "complete" : ""}`}>
            <StepIcon step="3" title="Book & Enjoy!" subtitle="Select and Book the best package :)" />
          </li>
        </ul>
        <div className="step-testimonal-block">
          <i className="icon quotes-icon">
            <img src="/images/Quote_icon.svg" alt="" />
          </i>
          <p dangerouslySetInnerHTML={{ __html: testimonial.detail }}>
          </p>
          <div className="user-detail">
            <span className="p-block">
              <img src="/images/t-img1.jpeg" alt="" />
            </span>
            <p>
              {testimonial.author}
              <span>{testimonial.location}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}